// FundDialog.tsx
import React from "react";

import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";

import MHDataTable, { GridColDef } from "../../Common/DataTable/MHDataTable";
import { EmployeeData } from "../../../models/employee.model";

import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import { formatAmount, getURLWithQueryParams } from "../../../utils/utils";
import AuthContext from "../../../store/context/auth-context";
import { ReactComponent as ArrowLeftIcon } from "../../../static/svg/arrow-left.svg";
import { ReactComponent as PlusIcon } from "../../../static/svg/plus.svg";
import { ReactComponent as PayConfirmIcon } from "../../../static/svg/payConfirm.svg";
import { ReactComponent as DocumentIcon } from "../../../static/svg/document.svg";
import { ReactComponent as InsufficientIcon } from "../../../static/svg/insufficient.svg";

import MHDialog from "../../Common/Dialog/MHDialog";
import { Button, Typography } from "@mui/material";
import MHButton from "../../Common/Button/MHButton";
import { MHSwitch } from "../../Common/Form/MHSwitch";
import { useOnboardForm } from "../../../store/context/onboard-context";
import MHCollapsibleTable from "../../Common/DataTable/MHCollapsibleTable";
import DashboardContext from "../../../store/context/dashboard.context";

interface PayDialogProps {
  open: boolean;
  onClose: () => void;
}

const EmployeePayrollDialog: React.FC<PayDialogProps> = ({ open, onClose }) => {
  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<EmployeeData | null>(null);

  const [opens, setOpen] = React.useState(false);
  const [totalSalary, setTotalSalary] = React.useState<number>(0); // State to hold the total salary
  const { activeStep, setActiveStep } = useOnboardForm();

  const closure = () => {
    setActiveStep(0);
    onClose();
  };

  const [checkedState, setCheckedState] = React.useState<{
    [key: string]: boolean;
  }>({});

  const handleCheckState = (
    event: React.ChangeEvent<HTMLInputElement>,
    employeeId: string
  ) => {
    const isChecked = event.target.checked;

    setCheckedState((prevState) => {
      const newState = {
        ...prevState,
        [employeeId]: isChecked,
      };

      // Log the salary if needed
      const selectedEmployee = employees.find(
        (employee) => String(employee.id) === employeeId
      );

      if (!isChecked && selectedEmployee) {
        // If the switch is toggled off, subtract the salary
        setTotalSalary(
          (prevTotalSalary) =>
            prevTotalSalary -
            (selectedEmployee.salary +
              selectedEmployee.Accomodation +
              selectedEmployee.airtimeOrData +
              selectedEmployee.meals +
              selectedEmployee.transportation)
        );
      } else if (isChecked && selectedEmployee) {
        // If the switch is toggled back to true, add the deducted salary back
        setTotalSalary(
          (prevTotalSalary) =>
            prevTotalSalary +
            (selectedEmployee.salary +
              selectedEmployee.Accomodation +
              selectedEmployee.airtimeOrData +
              selectedEmployee.meals +
              selectedEmployee.transportation)
        );
      }

      return newState;
    });
  };

  // Filter the employees whose switches are still true
  const activeEmployees =
    employees && employees.filter((employee) => employee.status === "ACTIVE");

  // Filter the employees whose switches are still true
  const trueEmployees =
    activeEmployees &&
    activeEmployees.filter((employee) => checkedState[employee.id]);

  // Define a type for the employee object
  interface Employee {
    uuid: number; // Assuming uuid is of type number, adjust if it's a different type
    workingDays: string[]; // Assuming workingDays is an array of strings, adjust if it's a different type
    // Add other properties as needed
  }

  // Function to fetch workingDays of an employee with a specific uuid
  function getWorkingDaysByUuid(
    trueEmployees: EmployeeData[],
    uuid: number | undefined
  ): string[] {
    // Find the employee with the specific uuid and fetch their workingDays
    const specificEmployee = trueEmployees.find(
      (employee) => employee.uuid === uuid
    );

    // If specificEmployee is found and workingDays is defined, return it as an array, otherwise return an empty array
    if (specificEmployee && specificEmployee.workingDays) {
      if (typeof specificEmployee.workingDays === "string") {
        // If workingDays is a string, wrap it in an array
        return [specificEmployee.workingDays];
      } else {
        // If workingDays is an array, return it
        return specificEmployee.workingDays;
      }
    } else {
      // If specificEmployee is not found or workingDays is undefined, return an empty array
      return [];
    }
  }

  const columns: GridColDef<EmployeeData>[] = [
    // {
    //   headerName: "Contract Ref",
    //   type: "text",
    //   field: "createdDate",
    //   width: 300,

    //   cellRenderer: (row: EmployeeData) => (
    //     <Box display={"flex"} gap={1}>
    //       <Box pt={0.5}>
    //         {getWorkingDaysByUuid(trueEmployees, row?.uuid).length < 1 ? (
    //           "   "
    //         ) : (
    //           <PlusIcon className=" " />
    //         )}
    //       </Box>
    //       {"REF1239" + row?.firstName}
    //     </Box>
    //   ),
    // },
    {
      headerName: "First name",
      type: "text",
      field: "firstName",
      width: 200,
    },
    {
      headerName: "Last name",
      type: "text",
      field: "lastName",
      width: 200,
    },

    {
      headerName: "Role",
      type: "text",
      field: "role",
      width: 200,
    },
    {
      headerName: "Total (₦)",
      type: "text",
      field: "salary",
      width: 200,
      valueGetter: (row: EmployeeData) => {
        return formatAmount(
          row?.salary +
            row?.Accomodation +
            row?.airtimeOrData +
            row?.meals +
            row?.transportation
        );
      },
    },

    {
      headerName: "",
      type: "text",
      field: "",
      width: 100,
      align: "center",
      cellRenderer: (row: EmployeeData) => (
        <MHSwitch
          value={checkedState[row.id] || false}
          onChange={(event) => handleCheckState(event, String(row.id))}
        />
      ),
    },
  ];
  const secondColumns: GridColDef<EmployeeData>[] = [
    // {
    //   headerName: "Contract Ref",
    //   type: "text",
    //   field: "createdDate",
    //   valueGetter: (row: EmployeeData) => {
    //     return "REF93939" + row?.createdDate;
    //   },
    //   width: 300,
    // },
    {
      headerName: "First name",
      type: "text",
      field: "firstName",
      width: 300,
    },
    {
      headerName: "Last name",
      type: "text",
      field: "lastName",
      width: 300,
    },

    // {
    //   headerName: "Role",
    //   type: "text",
    //   field: "role",
    //   width: 200,
    // },
    {
      headerName: "Salary",
      type: "text",
      field: "salary",
      width: 200,
      valueGetter: (row: EmployeeData) => {
        return formatAmount(
          row?.salary +
            row?.Accomodation +
            row?.airtimeOrData +
            row?.meals +
            row?.transportation
        );
      },
    },
  ];

  // const collapse: GridColDef<EmployeeData>[] = [
  //   {
  //     headerName: "Expenses",
  //     type: "text",
  //     field: "lastName",
  //     width: 300,
  //   },
  //   {
  //     headerName: "Amount",
  //     type: "text",
  //     field: "salary",
  //     width: 200,
  //     valueGetter: (row: EmployeeData) => {
  //       return formatAmount(row?.salary);
  //     },
  //   },
  // ];

  React.useEffect(() => {
    // console.log("useEffect triggered");
    // Initialize checkedState with true for each employee ID
    const initialCheckedState: { [key: string]: boolean } = {};
    employees &&
      employees.forEach((employee) => {
        initialCheckedState[employee.id] = true;
      });
    // console.log("Initial checked state:", initialCheckedState);
    setCheckedState(initialCheckedState);

    // Clean-up function can be omitted since we're not subscribing to any external resources
  }, [employees]);

  const handleOpen = (employee: any) => {
    setOpen(true);
    setSelectedEmployee(employee);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  // console.log("RefID", user?.employerRefId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  React.useEffect(() => {
    // Calculate total salary when employees array changes
    const sum =
      trueEmployees &&
      trueEmployees.reduce(
        (acc, employee) =>
          acc +
          (employee.salary +
            employee.Accomodation +
            employee.airtimeOrData +
            employee.meals +
            employee.transportation || 0),
        0
      );
    setTotalSalary(sum);
  }, [trueEmployees]);

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  const date = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const PayError = () => {
    return (
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "500px" }}
        width={{ xs: "full", sm: "403px" }}
        className=" ml-0 mr-0 place-content-center items-center mx-auto "
      >
        <Button
          className=" removeUnderline"
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        >
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
            color={"primary"}
            gutterBottom
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <ArrowLeftIcon />
            Back
          </Typography>
        </Button>

        <Box
          display="flex"
          alignItems="center"
          // alignSelf="center"
          // alignContent="center"
          justifyContent="center"
          mt={3}
        >
          <InsufficientIcon className=" " color="#C7D8E4" />
        </Box>

        <Box display="flex" justifyContent="center">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            color={"primary"}
            gutterBottom
            align="center"
            justifyContent="center"
            // width={{ xs: "full", sm: "185px" }}
            // my={1}
          >
            Insufficient Balance!
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="body2"
            fontSize={{ xs: "12px", sm: "13px", md: "13px" }}
            lineHeight={{ xs: "150%", sm: "150%" }}
            color={"primary"}
            gutterBottom
            align="center"
            justifyContent="center"
            width={{ xs: "full", sm: "350px" }}
            my={2}
          >
            Kindly add funds to your wallet as it currently has insufficient
            balance to complete the transaction. Thank you.{" "}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" my={4}>
          <Box my={0} width={{ xs: "100%", sm: "256px" }}>
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className="  "
              fullWidth
              // startIcon={<PlusIcon />}
              onClick={onClose}
            >
              Fund Now!
            </MHButton>
          </Box>
        </Box>
      </Box>
    );
  };

  const month = months[date.getUTCMonth()];

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;
  const { accountDetails, getAcctDetails } = useOnboardForm();

  // console.log("accountDetails", accountDetails.bankName);
  // funded amount should be passed here.
  // This controls the empty state of the Fund Balance
  let currentFund = accountDetails?.balance ?? 0;

  const [formattedEmployees, setFormattedEmployees] = React.useState<any[]>([]);

  React.useEffect(() => {
    const formatEmployees = () => {
      const formatted = activeEmployees.map((employee) => ({
        uuid: employee.uuid,
        customerId: employee.customerId,
        extraPay: 0,
      }));
      setFormattedEmployees(formatted);
    };

    formatEmployees();
  }, [activeEmployees]);

  const data = {
    employerRefId: organization?.employerRefId,
    employeeList: formattedEmployees,
  };

  // console.log("data", data);

  const [paymentResponse, setPaymentResponse] = React.useState({});

  const payAllEmployees = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/tranx/pay-employees`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setPaymentResponse(response.status);
        console.log("Employees Payment submitted successfully");
        return response.status; // Return the status code for further processing
      } else {
        const errorData = await response.json();
        setPaymentResponse(response.status);
        console.error("Employees Payment submission failed:", errorData);
        return response.status; // Return the status code for further processing
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return null; // Return null to indicate an error
    }
  };

  const [transferCharges, setTransferCharges] = React.useState(0);

  const getTransferCharges = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/tranx/fee?amount=" + totalSalary,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsonData = await response.json();
      setTransferCharges(jsonData.fee);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  React.useEffect(() => {
    getTransferCharges();
  }, [totalSalary]);

  const grandTotal = totalSalary + transferCharges;

  // console.log("transferCharges", transferCharges);

  const makePay = async () => {
    if (currentFund >= totalSalary) {
      const responseStatus = await payAllEmployees();
      if (responseStatus === 404) {
        toast({
          variant: "error",
          message: "Account not found",
        });
      } else if (responseStatus === 200) {
        // On Success
        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
      } else {
        toast({
          variant: "error",
          message: "Cannot perform action, Try Again!",
        });
      }
    } else {
      setActiveStep(3);
    }
  };

  const handlePay = () => {
    if (totalSalary <= 0) {
      toast({
        variant: "error",
        message: "Invalid amount",
      });
      setActiveStep(0);
    } else {
      setActiveStep(1);
    }
  };

  const handlePayFlow = () => {
    if (activeStep === 0) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            // alignSelf="center"
            // alignContent="center"
            justifyContent={{ xs: "center", sm: "end" }}
            my={3}
          >
            <MHButton
              color="success"
              sx={{
                width: "248px",
                height: "40px",
                borderRadius: "0.375rem",
                color: "white",
              }}
              type="submit"
              className=" hover:text-[#194049]"
              // startIcon={<PlusIcon />}
              onClick={handlePay}
            >
              Pay: {totalSalary}
            </MHButton>
          </Box>
          <MHCollapsibleTable
            title={`${"Payroll: "} ${month}`}
            rows={activeEmployees}
            columns={columns}
            collapsibleRow
            frontEndPagination
            // actions={}
            // hidePagination
          />
        </>
      );
    } else if (activeStep === 1) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            // alignSelf="center"
            // alignContent="center"
            justifyContent="center"
            mt={6}
          >
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "14px", sm: "18px", md: "18px" }}
              lineHeight={{ xs: "100%", sm: "100%" }}
              letterSpacing={"0.01em"}
              alignSelf={"center"}
              textAlign={"center"}
            >
              Payment will automatically be disburse to the employee below
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            // alignSelf="center"
            // alignContent="center"
            justifyContent="center"
            mt={2}
            mb={6}
          >
            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "100%", sm: "100%" }}
              letterSpacing={"0.01em"}
              alignSelf={"center"}
              textAlign={"center"}
              display={"flex"}
            >
              The sum of {" " + formatAmount(totalSalary) + " "}
              will be deducted from your Household Payment Wallet.
            </Typography>
          </Box>
          <MHDataTable
            title={`Recap`}
            rows={trueEmployees}
            columns={secondColumns}
            // charges={formatAmount(transferCharges)}  // this shows an extra layer before Total
            totalAmount={formatAmount(totalSalary)}
            frontEndPagination

            // actions={}
            // hidePagination
          />

          <Box display={"flex"} width={"full"}>
            <Box className=" ">
              <MHButton
                color="info"
                sx={{}}
                type="submit"
                className=" w-[120px] "
                startIcon={<ArrowLeftIcon />}
                onClick={() =>
                  setActiveStep((prevActiveStep) => prevActiveStep - 1)
                }
              >
                Back
              </MHButton>
            </Box>

            <Box className=" mr-0 ml-auto" marginRight={0} marginLeft={"auto"}>
              <MHButton sx={{}} onClick={makePay} className="  w-[120px] ">
                Make Payment
              </MHButton>
            </Box>
          </Box>
        </>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={{ xs: "full", sm: 600, md: 850 }}
            my={3}
          >
            <PayConfirmIcon />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={6}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              fontSize={{ xs: "14px", sm: "18px", md: "18px" }}
              lineHeight={{ xs: "100%", sm: "100%" }}
              letterSpacing={"0.01em"}
              alignSelf={"center"}
              textAlign={"center"}
            >
              Payment Disbursed Successfully
            </Typography>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            width={"full"}
            sx={{ gap: 2 }}
          >
            <Box className=" " width={"258px"}>
              <MHButton
                sx={{}}
                onClick={closure}
                fullWidth
                className="  w-[258px]  "
              >
                Go To Dashboard
              </MHButton>
            </Box>

            <Box width={"258px"}>
              <MHButton
                color="secondary"
                sx={{}}
                type="submit"
                fullWidth
                className=" w-[258px] "
                endIcon={<DocumentIcon />}
                onClick={closure}
              >
                Download Statements
              </MHButton>
            </Box>
          </Box>
        </>
      );
    } else if (activeStep === 3) {
      // Render PayError component if there are insufficient funds

      return <PayError />;
    }
  };

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={` `}
        handleClose={closure}
        maxWidth="xl"
        // actions={}
        scroll="paper"
      >
        {/* <BackdropLoader open={loading} /> */}
        <Box
          display="flex"
          alignItems="center"
          // alignSelf="center"
          // alignContent="center"
          justifyContent="center"
          // my={3}
        >
          {activeStep === 3 ? (
            ""
          ) : (
            <Typography
              variant="h1"
              fontSize={{ xs: "24px", sm: "28px" }}
              align="center"
              mt={3}
              gutterBottom
              display={"flex"}
              alignSelf={"center"}
            >
              Household Employee Payroll
            </Typography>
          )}
        </Box>

        {handlePayFlow()}
      </MHDialog>
    </React.Fragment>
  );
};

export default EmployeePayrollDialog;
