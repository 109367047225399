import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// import { styled } from "@mui/material/styles";

import AuthNavigator from "./AuthNavigator";
// import DashboardNavigator from "./DashboardNavigator";
// import NotFound from "../pages/Error/404";
// import ErrorPage from "../pages/Error/404NotFound";

import AuthContext from "../store/context/auth-context";
import { FnComponent } from "../models/component.model";
import CaringBlocksNavigator from "./CaringBlocksNavigator";
import Index from "../pages/Index";
// import SignIn from "../pages/Auth/SignIn"; // Import your sign-in component here
import Layout from "../components/Layout/Layout";
import NotFoundPage from "../pages/Error/404NotFound";
import Caregivers from "../pages/CareGivers";
import Families from "../pages/Families";
import Corporate from "../pages/Corporate";
import TrainingInstitute from "../pages/TrainingInstitute";
import SingleTraining from "../pages/SingleTraining";
// import { AuthNavBar, HomeNavBar } from "../components/Layout/AppBar";

// const Wrapper = styled("div")(
//   ({ theme }) => `
//     padding: ${theme.spacing(3)};
//   `
// );

const AppNavigator: FnComponent<{}> = (props) => {
  const authCtx = React.useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    // Check if the current route is not '/auth/onboard'
    if (location.pathname !== "/auth/onboard") {
      // Remove the item from localStorage
      localStorage.removeItem("subscriptionType");
    }
  }, [location]);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact>
          <Layout>
            <React.Fragment>
              {/* <HomeNavBar /> Render NavBar for authenticated users */}
              <Index />
            </React.Fragment>
          </Layout>
        </Route>

        <Route path="/caregivers" exact>
          <Layout>
            <React.Fragment>
              {/* <HomeNavBar /> Render NavBar for authenticated users */}
              <Caregivers />
            </React.Fragment>
          </Layout>
        </Route>

        <Route path="/families" exact>
          <Layout>
            <React.Fragment>
              {/* <HomeNavBar /> Render NavBar for authenticated users */}
              <Families />
            </React.Fragment>
          </Layout>
        </Route>

        <Route path="/corporate" exact>
          <Layout>
            <React.Fragment>
              {/* <HomeNavBar /> Render NavBar for authenticated users */}
              <Corporate />
            </React.Fragment>
          </Layout>
        </Route>
        <Route path="/training" exact>
          <Layout>
            <React.Fragment>
              {/* <HomeNavBar /> Render NavBar for authenticated users */}
              <SingleTraining />
            </React.Fragment>
          </Layout>
        </Route>
        <Route path="/institute" exact>
          <Layout>
            <React.Fragment>
              {/* <HomeNavBar /> Render NavBar for authenticated users */}
              <TrainingInstitute />
            </React.Fragment>
          </Layout>
        </Route>

        <Route path="/auth">
          <AuthNavigator />
        </Route>

        <Route path="/organization">
          {authCtx.isAuthenticated === true ? (
            <React.Fragment>
              {/* <Wrapper> */}
              <CaringBlocksNavigator />
              {/* </Wrapper> */}
            </React.Fragment>
          ) : (
            <Redirect to="/auth/sign-in" />
          )}
        </Route>

        <Route path="/404" component={NotFoundPage} />
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default AppNavigator;
