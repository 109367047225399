import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import MHDialog from "../../components/Common/Dialog/MHDialog";
import useDialog from "../../hooks/use-dialog";
import useHttp from "../../hooks/use-http";
import SnackbarContext from "../../store/context/snackbar.context";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import * as validators from "../../utils/validators";

import { db } from "../../firebase";
import useInput from "../../hooks/use-input";
import MHFormControl from "../../components/Common/Form/MHFormControl";

type Props = {};

const NannyHire = (props: Props) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleDownload = () => {
    // console.log("Download");
    // const link = document.createElement("a");
    // link.href =
    //   "https://mh-resources-production.s3.us-west-1.amazonaws.com/NannyHiringGuideForParents.docx.pdf";
    // link.download =
    //   "https://mh-resources-production.s3.us-west-1.amazonaws.com/NannyHiringGuideForParents.docx.pdf"; // This attribute suggests the file name for download
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    window.open(
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/NannyHiringGuideForParents.docx.pdf"
    );
  };

  const {
    value: enteredfirstName,
    valid: enteredfirstNameIsValid,
    error: firstnameInputHasError,
    onChange: firstnameInputChangeHandler,
    onBlur: firstnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredlastName,
    valid: enteredlastNameIsValid,
    error: lastnameInputHasError,
    onChange: lastnameInputChangeHandler,
    onBlur: lastnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: emailInputHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    error: phoneInputHasError,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const formIsValid =
    enteredfirstNameIsValid &&
    enteredlastNameIsValid &&
    enteredEmailIsValid &&
    enteredPhoneIsValid;

  const resetForm = () => {
    firstnameInputChangeHandler("");
    lastnameInputChangeHandler("");
    phoneInputChangeHandler("");
    emailInputChangeHandler("");
  };

  const { loading } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const flodeskSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables

    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "Caring Blocks (www.caringblocks.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: enteredfirstName,
          last_name: enteredlastName,
          email: enteredEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
          custom_fields: {
            phone: enteredPhone,
          },
        }),
      });

      flodeskSegmentSubmit();

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["667eb26a756759b65f8fc6d6"]; // Replace with actual segment IDs
    // Segment: caring-blocks-nanny-guide

    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "Caring Blocks (www.caringblocks.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
        resetForm();
        handleCloseDialog();

        toast({
          message: "Thank you for your interest!",
          variant: "success",
        });
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    // emailInputRef.current!.focus();
    preventDefault(event);
    if (!formIsValid) {
      console.log("Complete Form");
      toast({
        message: "Kindly input your request",
        variant: "warning",
      });
      return;
    } else {
      await addDoc(collection(db, "caring-blocks-nanny-hire-prompt"), {
        firstName: enteredfirstName,
        lastName: enteredlastName,
        email: enteredEmail,
        phone: enteredPhone,
        createdAt: serverTimestamp(),
      });

      handleDownload();

      flodeskSubmit();

      // toast({
      //   message: "Thank you for your request. We will get back to you shortly!",
      //   variant: "success",
      // });
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          //   paddingTop: "20px",
          justifyContent: "space-around",
          alignItems: "center",

          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "700px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "500px", md: "680px" }}
          className=" items-center  order-first"
        >
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/nannyGuide.png"
            alt="Nanny Guide"
            className=" rounded-xl"
            draggable={false}
          />
        </Grid>
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" flex items-center  order-last"
        >
          <Box>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "24px", sm: "26px", md: "26px" }}
              lineHeight={{ xs: "120%", sm: "130.5%" }}
              letterSpacing={"-4%"}
              textAlign={{ xs: "center", sm: "start" }}
              className=" capitalize"
            >
              Nanny Hiring Guide for Parents / Guardians
            </Typography>

            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
              width={{ xs: "90%", sm: "100%" }}
            >
              Hiring a nanny is a significant decision that requires careful
              consideration and planning. The purpose of this guide is to
              provide parents with a structured approach to the hiring process,
              ensuring that they find the best possible caregiver for their
              children.
            </Typography>

            <MHButton
              color="primary"
              // sx={{}}
              // type="submit"
              className="mt-10 mb-12 md:mb-24 uppercase w-full md:w-[280px] h-[54px]"
              // startIcon={<PlusIcon />}
              // onClick={handleDownload}
              onClick={handleOpenDialog}
            >
              Download Guide
            </MHButton>
          </Box>
        </Grid>
      </Grid>
      <hr className="w-48 text-[#194049]" />

      <MHDialog
        open={openDialog}
        title={"Nanny Hire Guide "}
        sx={{ textAlign: "center" }}
        handleClose={handleCloseDialog}
        scroll="paper"
        maxWidth={"xs"}
        fullWidth
      >
        {openDialog ? (
          <Box p={2}>
            <Box component={"form"} onSubmit={handleSubmit}>
              <MHFormControl
                id="fname"
                type="text"
                // label="First Name"
                placeholder="First Name"
                value={enteredfirstName}
                onChange={firstnameInputChangeHandler}
                onBlur={firstnameInputBlurHandler}
                required
                // autoFocus
              />

              <MHFormControl
                id="lname"
                type="text"
                // label="Last Name"
                placeholder="Last Name"
                value={enteredlastName}
                onChange={lastnameInputChangeHandler}
                onBlur={lastnameInputBlurHandler}
                required
                // autoFocus
              />

              <MHFormControl
                id="email"
                type="email"
                // label="Email"
                placeholder="Email"
                value={enteredEmail}
                onChange={emailInputChangeHandler}
                onBlur={emailInputBlurHandler}
                required
                // autoFocus
              />
              <MHFormControl
                id="phone"
                type="phone"
                // label="Phone"
                placeholder="Phone"
                value={enteredPhone}
                onChange={phoneInputChangeHandler}
                onBlur={phoneInputBlurHandler}
                required
                // autoFocus
              />
              <MHButton
                color="primary"
                sx={{}}
                type="submit"
                loading={loading}
                className="pt-2  capitalize w-full h-[44px]"
                fullWidth
                // startIcon={<PlusIcon />}
                // onClick={handleSubmit}
                // onClick={() =>
                //   history.push("/organization/employee-onboarding")
                // }
              >
                Submit
              </MHButton>
            </Box>
          </Box>
        ) : null}
      </MHDialog>
    </React.Fragment>
  );
};

export default NannyHire;
