import React from "react";
import Slider from "react-slick";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import MHButton from "../components/Common/Button/MHButton";

import { ReactComponent as LeftBtn } from "../static/svg/left-btn.svg";
import { ReactComponent as RightBtn } from "../static/svg/right-btn.svg";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import useDialog from "../hooks/use-dialog";

import { useHistory } from "react-router-dom";
import useTitle from "../hooks/use-title";
import CaringApproachSection from "./Plans/CaringApproachSection";
import CaregiversHeroSlider from "./Plans/CaregiversHeroSlider";
import FAQ from "./CBFaq";
import { Caregivers_FAQ_Data } from "../utils/faq";
import LandingFooter from "./Landing/LandingFooter";

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 lg:-right-8 md:-right-6">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 -left-8">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

export const MagazineFeature = (props: {
  title: string;
  content: string;
  imageSrc: string;
  inverted?: boolean;
}) => {
  return (
    <Grid
      container
      direction={{
        xs: "column-reverse",
        md: props.inverted ? "row-reverse" : "row",
      }}
      mb={8}
    >
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: "90%", md: "70%" }}
          mx="auto"
          height="100%"
          mt={{ xs: 3, md: 0 }}
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "2rem", md: "2.4rem" }}
            width={{ xs: "100%", md: "90%" }}
            textTransform="capitalize"
            align="center"
            sx={{
              mb: 4,
            }}
            paragraph
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: "100%", md: "80%" }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={5}
          >
            {props.content}
          </Typography>

          {/* <MHButton
        sx={{
          width: '169px'
        }}>
        Start Your Application
      </MHButton> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          width={{ xs: "100%", md: "100%" }}
          mx="auto"
          // p={{ xs: 3, md: 0 }}
          // pr={{ xs: 2, md: 10 }}
          // pl={2}
          sx={{
            "& img": {
              width: "100%",
              [props.inverted ? "pl" : "pr"]: { xs: 2, md: 10 },
              [props.inverted ? "pr" : "pl"]: 2,
              // height: '500px'
            },
          }}
        >
          <img
            src={props.imageSrc}
            alt="WorkLife Dashboard"
            width="800"
            draggable={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const Caregivers = () => {
  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog,
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
  };

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  const handleCareRoute = () => {
    window.open("https://motherhonestly.typeform.com/cbcaregivers");
  };

  useTitle("For Caregivers");

  return (
    <React.Fragment>
      <Grid container direction={{ xs: "column-reverse", md: "row" }} mb={4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              "& > img": {
                height: 600,
                width: "100%",
                objectPosition: "center",
                objectFit: "cover",
              },
            }}
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/For+Caregivers.png"
              // src="https://res.cloudinary.com/mother-honestly/image/upload/v1688473369/F85899C8-2A59-4B54-8815-A41886ACCA37_wmf5qm.png"
              alt="Banner"
              width="700"
              draggable={false}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} bgcolor="#F3F4F6">
          <Stack
            height={600}
            justifyContent="center"
            alignItems="center"
            px={{ xs: 2, md: 10 }}
            sx={{
              "& > p": {
                textAlign: "center",
                pointerEvents: "none",
              },
            }}
          >
            <Typography
              variant="body1"
              fontFamily="Area-Extended"
              textTransform="uppercase"
              color="#194049"
              fontSize={10}
              letterSpacing="0.1em"
              lineHeight="102%"
              sx={{
                mb: 5,
              }}
              paragraph
            >
              for Caregivers
            </Typography>

            <Typography
              variant="h1"
              fontSize={{ xs: "30px", sm: "40px", md: "40px" }}
              textTransform="capitalize"
              width="75%"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              Welcome to Caring Blocks - Where Your Career in Care Begins
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="#194049"
              width="75%"
              sx={{
                mb: 7,
              }}
              paragraph
            >
              At Caring Blocks, we believe in the value and professionalism of
              caregiving. Whether you're a nanny, driver, housekeeper, or home
              tutor, we're here to support your career and connect you with
              families who appreciate your skills.
            </Typography>

            <MHButton
              sx={{
                width: "fit-content",
              }}
              onClick={handleCareRoute}
            >
              Start Your Application
            </MHButton>
          </Stack>
        </Grid>
      </Grid>

      <CaregiversHeroSlider />

      <CaringApproachSection />

      <Box
        width={{ xs: "90%", sm: "100%" }}
        mx="auto"
        py={5}
        sx={{ backgroundColor: "#f6f6f6" }}
      >
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          {/* extend care beyond the company’s walls, creating a win-win scenario
          where you and <br /> your employees thrive and prosper. */}
          Join the Caring Blocks Community
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph
        >
          We are a community of caregivers dedicated to making a difference. By
          joining Caring Blocks, you become <br />
          part of a network that values your work and strives to elevate the
          standard of care across Nigeria.
          <br />
          <br />
          Ready to take the next step in your career?
        </Typography>

        <MHButton
          sx={{
            width: "fit-content",
            display: "block",
            mx: "auto",
            mt: 5,
          }}
          onClick={handleCareRoute}
        >
          Start Your Application
        </MHButton>
      </Box>

      {/* <Divider
        variant="middle"
        sx={{
          borderColor: "primary.main",
          width: "91%",
          mx: "auto",
          opacity: 0.1,
          mb: 4,
        }}
      /> */}

      {/* <MembershipPlans />

      <Box mt={4} mb={8}>
        <MembershipFeatures theme="light" />
      </Box> */}

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" my={6}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', sm: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          Care Infrastructure Designed For All
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', sm: '55%' }}
          fontSize={'13px'}
          letterSpacing="0.02em"
          lineHeight="185%"
          align="center"
          mx="auto"
          paragraph>
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography>
      </Box>

      <Grid
        container
        width={{ xs: '85%', sm: '93%' }}
        mx={{ xs: 'auto' }}
        mb={10}>
        {CARE_FEATURES.map((feature) => (
          <Grid item xs={12} sm={3}>
            <CareFeature
              imageSrc={feature.imageSrc}
              caption={feature.caption}
            />
          </Grid>
        ))}
      </Grid>

      <Divider
        variant="middle"
        sx={{
          borderColor: 'primary.main',
          width: '91%',
          mx: 'auto',
          opacity: 0.1,
          my: 8
        }}
      /> */}

      {/* <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1689337928/A_New_Kind_Of_Care_Algorithm_oivrhv.png"
        content="Access workplace care and well-being insights, on-demand. MH WorkLife’s Insights Hub provides you on-demand access to key metrics on the caregiving responsibilities of your employees, where the pain points exist, and how your organization is directly reducing their care load for your workforce."
        title="A New Kind Of Care Algorithm"
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1676442922/Group_4_mot8dy.png"
        content="Your employees will have access to a personalized dashboard filled with content, resources, advisors, handpicked care merchants, company benefits, on-demand library of videos, podcasts, worksheets, toolkits, and guides to meet their personal and caregiving needs."
        title="Personalized WorkLife Care Dashboard"
        inverted
      />
      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Fund_lhhgcf.png"
        content="Minimize productivity disruptions and support the well-being of your employees by providing an added layer of care to help them meet their caregiving and wellness responsibilities when they fall short. Whether it is helping to pay for an additional can of baby formula, or covering the additional tutoring needs of a teenage child falling behind in school, employees will appreciate you stepping in to care."
        title="An Additional Layer Of Protection For Care"
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Work_Care_Guidance_ll60bs.png"
        content="Our 1:1 Advisors are available 24/7 to provide on-demand guidance to address a wide range of topics that includes relationships, career, parenting, eldercare, adoption, return-to-work, and more. Providing your employees with proactive care that minimizes stress and disruption to work."
        title="MH WorkLife’s Advisors provide proactive and timely support"
        inverted
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Cover_The_Gap_gwekvt.png"
        content="By covering the care gap with qualified and vetted MH Care Gap Consultants, your company can foster a more supportive work environment that respects the personal care needs of employees, reduces the risk of delayed projects or missed opportunities, and  upholds the company’s reputation for reliability and stability without placing additional burden on existing employees."
        title="Uninterrupted Productivity"
      /> */}

      <Box px={{ xs: 3, md: 8 }} mb={6}>
        <FAQ title="Frequently Asked Questions" faqdata={Caregivers_FAQ_Data} />
      </Box>

      <LandingFooter />

      {/* <JoinWaitlist open={open} onClose={handleClose} /> */}
    </React.Fragment>
  );
};

export default Caregivers;
