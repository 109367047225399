import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";
import { Button, Grid } from "@mui/material";
import WalletPattern from "../../../static/svg/wallet-pattern.svg";
import { ReactComponent as PlusIcon } from "../../../static/svg/plus-lg.svg";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";
import MHButton from "../../Common/Button/MHButton";
import useDialog from "../../../hooks/use-dialog";
import WithdrawDialog from "./WithdrawDialog";
import { isEmpty } from "../../../utils/utils";
import DashboardContext from "../../../store/context/dashboard.context";
import BvnDialog from "../Dashboard/BvnDialog";
import CreateAccountDialog from "../Dashboard/CreateAccountDialog";
import { useOnboardForm } from "../../../store/context/onboard-context";

// import { formatNumber } from "../../../utils/utils";

export type EmployeeWalletStatProps = {
  theme: "dark" | "light" | "default";
  icon: React.ReactElement;
  icon2?: React.ReactElement;
  onClick?: (e: React.MouseEvent) => void;
  title: string;
  role?: string;
  stat: string | number | any;
  containerSx?: SxProps<Theme>;
};

const EmployeeWalletStatDesign = ({
  theme,
  icon,
  icon2,
  onClick,
  title,
  role,
  stat,
  ...props
}: EmployeeWalletStatProps) => {
  const formatNumber = (value: any) => {
    // Check if the value is a number
    if (typeof value === "number") {
      // Use Intl.NumberFormat to format the number
      return new Intl.NumberFormat().format(value);
    } else {
      // If it's not a number, return the original string
      return value;
    }
  };

  const dashboardCtx = React.useContext(DashboardContext);

  const { organization, fetchOrganizationData } = dashboardCtx;

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const { accountDetails, getAcctDetails } = useOnboardForm();

  React.useEffect(() => {
    getAcctDetails();
  }, [accountDetails]);

  const {
    openDialog: openBvnDialog,
    handleOpenDialog: handleOpenBvnDialog,
    handleCloseDialog: handleCloseBvnDialog,
  } = useDialog();

  const {
    openDialog: openAcctCreationDialog,
    handleOpenDialog: handleOpenAcctCreationDialog,
    handleCloseDialog: handleCloseAcctCreationDialog,
  } = useDialog();

  const requestBody = {
    type: "Employer",
    employerRefId: organization?.employerRefId,
    customerId: organization?.customerId,
  };

  const generateAcct = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/tranx/create-account`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const successData = await response.json();
        console.log(successData);
        handleCloseAcctCreationDialog();
        getAcctDetails();
      } else {
        const text = await response.text();
        try {
          const errorData = JSON.parse(text);
          console.error("Request submission failed:", errorData);
        } catch (e) {
          console.error(
            "Request submission failed. Response was not JSON:",
            text
          );
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // React.useEffect(() => {
  //   getTaxRef();
  //   localStorage.setItem("fundAmount", enteredAmount);
  // }, [enteredAmount]);

  let bvn = "38484";

  const handleAcctCreate = () => {
    if (
      accountDetails?.AccountNumber === undefined ||
      accountDetails?.AccountNumber === 0
    ) {
      handleOpenAcctCreationDialog();
      generateAcct();
    } else {
      handleCloseAcctCreationDialog();
      // handleOpenDialog();
    }
  };

  const handleFundOrBVN = () => {
    if (isEmpty(bvn)) {
      handleOpenBvnDialog();
    } else {
      handleOpenDialog();
    }
  };

  const handleFundOrAcctCreate = () => {
    if (
      accountDetails?.AccountNumber === undefined ||
      accountDetails?.AccountNumber === 0
    ) {
      handleAcctCreate();
    } else {
      handleOpenDialog();
    }
  };

  return (
    <React.Fragment>
      <Box
        p={3}
        bgcolor={
          theme === "dark"
            ? "primary.main"
            : theme === "default"
            ? "common.white"
            : "#C7D8E4"
        }
        borderRadius={2}
        boxShadow="0px 5px 26px #C7D8E4"
        minHeight={180}
        sx={{
          ...props.containerSx,
          gap: 2,
          pt: 5,
          mb: 3,
          position: "relative",
          overflow: "hidden",
        }}
        display={"flex"}
      >
        <Box className=" absolute top-0 right-0">
          <img
            src={WalletPattern}
            alt="error"
            className="object-cover h-fit w-fit z-40 "
          />
        </Box>
        <Box>{icon}</Box>
        <Box className=" z-10">
          {stat === 0 ? (
            <>
              <Typography
                variant="body2"
                fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                gutterBottom
                className={`capitalize opacity-50`}
              >
                {title}
              </Typography>
              <Typography
                variant="h1"
                fontSize={{ xs: "30px", sm: "30px", md: "40px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                display="flex"
                alignItems="center"
                className={`capitalize opacity-50`}
              >
                <NairaIcon className=" w-8 h-8 mt-1" />
                {formatNumber(stat)}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="body2"
                fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                gutterBottom
                className={`capitalize`}
              >
                {title} &copy;
              </Typography>
              <Typography
                variant="h1"
                fontSize={{ xs: "30px", sm: "30px", md: "40px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                display="flex"
                alignItems="center"
              >
                <NairaIcon className=" w-8 h-8 mt-1" />
                {formatNumber(stat)}
              </Typography>
            </>
          )}
        </Box>

        <Box className="  grid ml-auto mr-0 content-between">
          {stat !== 0 ? (
            <Button className=" absolute top-3 right-0" onClick={onClick}>
              {icon2}
            </Button>
          ) : null}

          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className=" w-20 h-10 md:h-fit md:w-[120px] absolute bottom-6 right-3"
            startIcon={role === "Employer" ? <PlusIcon /> : null}
            onClick={handleOpenDialog}
            // onClick={handleFundOrAcctCreate}

            // onClick={() =>
            //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
            // }
          >
            Withdraw
          </MHButton>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "30px",
            right: "auto",
          }}
        >
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            color={theme === "dark" ? "common.white" : "primary.main"}
            gutterBottom
            className={`capitalize opacity-50`}
          >
            Bank: {accountDetails?.bankName}
          </Typography>
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            color={theme === "dark" ? "common.white" : "primary.main"}
            gutterBottom
            className={`capitalize opacity-50`}
          >
            Account: {accountDetails?.AccountNumber}
          </Typography>
        </Box>
      </Box>

      {openDialog && (
        <WithdrawDialog open={openDialog} onClose={handleCloseDialog} />
      )}

      {/* {openAcctCreationDialog && (
        <CreateAccountDialog
          open={openAcctCreationDialog}
          onClose={handleCloseAcctCreationDialog}
        />
      )} */}

      {/* {openBvnDialog && (
        <BvnDialog
          open={openBvnDialog}
          onClose={handleCloseBvnDialog}
          redirect_url={`${process.env.REACT_APP_LOCALHOST_USER_ROUTE}/organization/dashboard`}
          type="EMPLOYER"
          customerId={organization?.customerId}
          employerRefId={organization?.employerRefId}
        />
      )} */}
    </React.Fragment>
  );
};

export default EmployeeWalletStatDesign;
