import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Stack,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  useTheme,
} from "@mui/material";

import { ReactComponent as StepBox } from "../../static/svg/stepbox.svg";
import { ReactComponent as StepWhiteBox } from "../../static/svg/stepwhitebox.svg";
import { useOnboardForm } from "../../store/context/onboard-context";

interface StepArray {
  data?: string[];
}

interface StepIconProps {
  label: any;
  text?: any;
}

const StepperBar = (props: StepArray) => {
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#194049",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#194049",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme === "dark" ? "#C1C1C1" : "#C1C1C1",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const StepIcon: React.FC<StepIconProps> = ({ label, text }) => (
    <div style={{ position: "relative" }}>
      <StepBox />
      <div
        style={{
          color: "#fff",
          position: "absolute",
          top: 5,
          left: 0,
          width: "100%",
          height: "100%",
          textAlign: "center",
          lineHeight: "24px",
        }}
      >
        {label}
      </div>

      <Box className="absolute -left-10 top-9 w-[120px]">
        <Typography variant="caption" fontSize={{ xs: "10px", sm: "10px" }}>
          {text}
        </Typography>
      </Box>
    </div>
  );

  const StepIconWhite: React.FC<StepIconProps> = ({ label, text }) => (
    <div style={{ position: "relative" }}>
      <StepWhiteBox />
      <div
        style={{
          color: "#6B6B6B",
          position: "absolute",
          top: 5,
          left: 0,
          width: "100%",
          height: "100%",
          textAlign: "center",
          lineHeight: "24px",
        }}
      >
        {label}
      </div>
      <Box className="absolute -left-9 top-9 w-[120px]">
        <Typography variant="caption" fontSize={{ xs: "10px", sm: "10px" }}>
          {text}
        </Typography>
      </Box>
    </div>
  );

  const { activeStep, setActiveStep } = useOnboardForm();

  return (
    <Stack
      spacing={2}
      sx={{ mt: 5, mb: 10, width: "100%", justifyContent: "center" }}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {props.data &&
          props.data.map((label: any, index: any) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  icon={
                    activeStep === index ? (
                      <StepIcon label={index + 1} text={label} />
                    ) : (
                      <StepIconWhite label={index + 1} text={label} />
                    )
                  }
                  // onClick={() => console.log("Clicked")}
                />
                {/* <StepLabel {...labelProps}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: "10px", sm: "10px" }}
                  letterSpacing={{ xs: "11.5%", sm: "11.5%" }}
                  lineHeight={{ xs: "92%", sm: "120%" }}
                  color="primary"
                  noWrap
                >
                  {label}
                </Typography>
              </StepLabel> */}
              </Step>
            );
          })}
      </Stepper>
    </Stack>
  );
};

export default StepperBar;
