import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import { MHPagination } from "../../Common/UI/MHPagination";

type ArrayProps = {
  id?: number;
  label?: string;
  date?: string;
  gotoLink?: string;
  handleView?: () => void;
  handleDownload?: () => void;
};

type Props = {
  slipArray?: ArrayProps[];
};

const AssessmentCard = (props: Props) => {
  const [filteredArray, setFilteredArray] = React.useState<ArrayProps[]>([]);

  React.useEffect(() => {
    if (props.slipArray) {
      const sortedArray = [...props.slipArray].sort(
        (a: any, b: any) => b.id - a.id
      );
      // setFilteredArray(sortedArray.slice(0, 4));
      setFilteredArray(sortedArray);
    }
  }, [props.slipArray]);

  let itemsPerPage = 4;
  let noOfPage = Math.ceil((props.slipArray?.length || 0) / itemsPerPage);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(4);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredArray.slice(firstPostIndex, lastPostIndex);

  // console.log("filteredArray", filteredArray);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  return (
    <React.Fragment>
      <Box
        className={`ring-1 ring-[#D9D9D9] bg-white pb-8`}
        // className={`${
        //   filteredArray.length > 0
        //     ? "bg-white"
        //     : " ring-1 ring-[#D9D9D9] bg-white"
        // } `}
      >
        {/* <Box
          className={`${
            filteredArray.length > 0 ? "bg-white" : "bg-[#F7F9F9]"
          }   px-12 pt-2 pb-1`}
        > */}
        <Box className={`bg-[#F7F9F9]  px-12 pt-2 pb-1`}>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            lineHeight={{ xs: "135%", sm: "135%", md: "135%" }}
            // gutterBottom
            className="mt-2 "
          >
            Assessment Result
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "120%", sm: "120%", md: "120%" }}
            // gutterBottom
            className="mt-2 text-[#A9A9A9]"
          >
            Here are the results from your screening and test
          </Typography>
        </Box>

        {props.slipArray && props.slipArray.length > 0 ? (
          <>
            <Divider />
            <Box className="px-2 lg:px-12 pt-10 pb-2">
              <Box className=" py-2 flex flex-row !items-center">
                <Box className="basis-[60%]">
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                    lineHeight={"0px"}
                    color={"primary"}
                    // gutterBottom
                    className="pt-2 capitalize"
                  >
                    Description
                  </Typography>
                </Box>
                <Box className="basis-[16%]">
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                    lineHeight={"0px"}
                    color={"primary"}
                    // gutterBottom
                    className="pt-2 capitalize "
                  >
                    Date
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        ) : null}

        <Box className="">
          <Divider />

          {currentPosts.length > 0 ? (
            currentPosts.map((slip, index) => (
              <Box key={index}>
                <Box
                  className=" flex flex-row overflow-x-hidden !items-center py-1 px-2 lg:px-12"
                  // key={index}
                >
                  <Box className=" w-full truncate py-2 lg:pt-0 lg:pb-2 md:basis-[60%]">
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                      lineHeight={"0px"}
                      color={"primary"}
                      // gutterBottom
                      className="pt-2 capitalize"
                    >
                      {slip.label}
                    </Typography>
                  </Box>

                  <Box className="w-full truncate py-2 lg:pt-0 lg:pb-2 md:basis-[16%]">
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                      lineHeight={"0px"}
                      color={"primary"}
                      // gutterBottom
                      className="ml-2 lg:ml-0 pt-2 capitalize text-[#A9A9A9]"
                    >
                      {slip.date}
                    </Typography>
                  </Box>
                  <Box className="w-1/2 truncate py-2 lg:pt-0 lg:pb-2 md:basis-[12%]">
                    <MuiLink
                      // component={Link}
                      underline="always"
                      // to="/organization/employee/dashboard"
                      // to={slip.gotoLink}
                      onClick={slip.handleView}
                      // display="block"
                      // align="left"
                      className="text-blue-100   mt-2 no-underline cursor-pointer centerItems"
                    >
                      View
                    </MuiLink>
                  </Box>
                  <Box className="w-full truncate py-2 lg:pt-0 lg:pb-2 md:basis-[12%]">
                    <MuiLink
                      // component={Link}
                      underline="always"
                      // to={slip.gotoLink}
                      onClick={slip.handleDownload}
                      // display="block"
                      // align="left"
                      className="text-blue-100 mt-2 no-underline cursor-pointer centerItems"
                    >
                      Download
                    </MuiLink>
                  </Box>
                </Box>
                <Divider className="" />
              </Box>
            ))
          ) : (
            <p className="text-center pt-4">You have no assessment</p>
          )}

          {props.slipArray && props.slipArray.length > 0 ? (
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              <MHPagination
                count={noOfPage}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handlePageChange}
                color={"primary"}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AssessmentCard;
