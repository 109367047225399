import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import ContentCard from "./ContentCard";
import MHSlider, {
  MHSliderItem,
} from "../../components/Common/SliderCarousel/CBSlider/MHSlider";

const CONTENT = [
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Proffessional+Growth+and+Development.png",
    title: "Professional Growth and Development",
    description:
      "At Caring Blocks, we offer you more than just a job. We provide access to ongoing training and development through our Caring Blocks Institute, ensuring that you are always growing and enhancing your skills. We also offer certifications that can help you stand out and advance in your career.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Seamless+Onboard.png",
    title: "Seamless Onboarding and Support",
    description:
      "Our onboarding process is designed with you in mind. We handle everything from background checks and health assessments to contract signing and setting up your payroll. Our goal is to make your transition into a new role as smooth as possible, so you can focus on doing what you do best - providing excellent care.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Competitive+Pay+And+Benefits.png",
    title: "Competitive Pay and Benefits",
    description:
      "We understand the importance of fair compensation. Caring Blocks ensures that you receive competitive pay, on-time payments, and access to benefits that recognize your hard work. Your salary will be deposited directly into your Caring Blocks wallet, where you can easily transfer it to your bank account.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Care+Support2.png",
    title: "24/7 Care Concierge Service",
    description:
      "As a member of Caring Blocks, you'll have access to our 24/7 care concierge service. Whether you need support with your job or have a concern that needs addressing, we're here to help at any time, day or night.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/16.png",
    title: "Safe and Trusted Work Environment",
    description:
      "We prioritize your safety and well-being. All families you are matched with have undergone thorough vetting, and we ensure a respectful and professional work environment. Your privacy and security are of utmost importance to us.",
  },
];

const CaregiversHeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box width={{ xs: "90%", sm: "75%" }} mx="auto" mt={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          {/* extend care beyond the company’s walls, creating a win-win scenario
          where you and <br /> your employees thrive and prosper. */}
          Why Join Caring Blocks
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph
        >
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography>
      </Box>

      <Box py="3rem" px={{ xs: 0, md: "4rem" }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>

      {/* <Typography
        variant="body1"
        fontFamily="Area-Extended"
        textTransform="uppercase"
        textAlign="center"
        color="#194049"
        fontSize={12}
        letterSpacing="0.1em"
        sx={{
          mt: 0,
          mb: 2,
        }}
      >
        Top Companies Trust MH
      </Typography> */}

      {/* <MHmarquee /> */}

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={5}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', md: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          An Array Of Care Solutions Throughout Your Employees Working Lives
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Many Employees Are Being Crushed Under The Unrelenting and Unexpected
          Burden Of Care, It’s Time Your Benefits Care
        </Typography>
      </Box>

      {!matchesMdDown && (
        <Box py="3rem" px={'4rem'}>
          <MHSlider slidesToShow={3} slidesToScroll={1}>
            {CONTENT.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <ContentCard
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  onMouseClick={() => {}}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        </Box>
      )}

      {matchesMdDown && (
        <Grid
          container
          direction={{ xs: 'row' }}
          flexWrap="nowrap"
          rowSpacing={{ xs: 0, sm: 0 }}
          columnSpacing={3}
          mt={2}
          width={{ xs: '90%', sm: '80%' }}
          mx="auto"
          mb={8}
          overflow="auto"
          className="horizontal-scroll">
          {CONTENT.map((item) => (
            <Grid item xs={12} sm={4} flexShrink={0}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
    </div>
  );
};

export default CaregiversHeroSlider;
