import { Box, Button, Fab } from "@mui/material";
import React from "react";
import Home from "./Landing/Home";
import HowItWorks from "./Landing/HowItWorks";
import Connect from "./Landing/Connect";
import Subscribe from "./Landing/Subscribe";
import FAQ from "./Landing/FAQ";
import LandingFooter from "./Landing/LandingFooter";

import { ReactComponent as WhatsappIcon } from "../static/svg/whatsapp-white.svg";
import NannyHire from "./Landing/NannyHire";

type Props = {};

const Index = (props: Props) => {
  return (
    <React.Fragment>
      <title> Caring Blocks </title>

      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          position: "relative",
        }}
      >
        <Home />
        <HowItWorks />
        <NannyHire />
        <Connect />
        <Subscribe />
        <FAQ />
        <LandingFooter />

        <Fab
          color="secondary"
          aria-label="whatsapp"
          className=" fixed bottom-3 right-3 left-auto"
        >
          <Button
            onClick={() => {
              window.open("https://wa.me/message/PKKLUXLHVOLLC1");
            }}
          >
            <WhatsappIcon width={30} height={30} color={"#fff"} />
          </Button>
        </Fab>
      </Box>
    </React.Fragment>
  );
};

export default Index;
