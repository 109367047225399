import { useState, useEffect } from 'react';

const useCountdownTimer = (minutes: number) => {
  const [timeLeft, setTimeLeft] = useState(minutes * 60); // Convert minutes to seconds

  useEffect(() => {
    if (timeLeft <= 0) return; // Stop the countdown if time runs out

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1); // Decrement time by 1 second
    }, 1000);

    // Cleanup the timer when the component unmounts or timeLeft changes
    return () => clearInterval(timer);
  }, [timeLeft]);

  // Format the time as MM:SS or return "Time up" if the time has run out
  const formatTime = () => {
    if (timeLeft <= 0) {
      return "Time up";
    }
    
    const mins = Math.floor(timeLeft / 60);
    const secs = timeLeft % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return { timeLeft, formattedTime: formatTime() };
};

export default useCountdownTimer;
