import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";

import { ReactComponent as ExpandMoreIcon } from "../../../static/svg/caret-down-black.svg";
import { ReactComponent as AddIcon } from "../../../static/svg/folder-up.svg";
import MHButton from "../../../components/Common/Button/MHButton";
import { useOnboardForm } from "../../../store/context/onboard-context";
import { useHistory } from "react-router-dom";

type Props = {
  signState?: boolean;
  handleSignState?: () => void;
  handleSaveContract?: () => void;
  //   formState?: boolean;
  //   handleContract?: () => void;
  //   employeeState?: boolean;
  //   handleEmployee?: () => void;
  //   employerState?: boolean;
  //   handleEmployer?: () => void;
  //   sendContractInvite?: () => void;
};

const ContractSideBar = (props: Props) => {
  const { clearContract, addToContract } = useOnboardForm();

  let history = useHistory();
  const discardChanges = () => {
    clearContract();
    history.push("/organization/dashboard");
  };

  return (
    <React.Fragment>
      <Box className="pt-4  w-full h-full">
        <Typography
          variant="h3"
          align="center"
          color={"primary"}
          fontSize={{ xs: "16px", sm: "19px", md: "19px" }}
          lineHeight={{ xs: "19px", sm: "19px" }}
          letterSpacing={"0.01em"}
          gutterBottom
          className=" pb-2"
        >
          Placeholders
        </Typography>
        <Divider />

        <Box className=" w-full bg-transparent">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                variant="subtitle1"
                align="left"
                fontSize={{ xs: "16px", sm: "12px", md: "12px" }}
              >
                Contract placeholder
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. */}
              <div className=" grid grid-cols-2 gap-y-2">
                <div
                  // onClick={props.handleContract}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Start Date]
                </div>

                {/* <div>
                  <Button
                    onClick={props.handleContract}
                    className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                  >
                    [Witness]
                  </Button>
                </div> */}

                <div
                  // onClick={props.handleContract}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Contract Length]
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography
                variant="subtitle1"
                align="left"
                fontSize={{ xs: "16px", sm: "12px", md: "12px" }}
              >
                Employer placeholder
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className=" grid grid-cols-2 gap-y-2">
                <div
                  // onClick={props.handleEmployer}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Employer's Name]
                </div>

                <div
                  // onClick={props.handleEmployer}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Employer's Phone]
                </div>

                <div
                  // onClick={props.handleEmployer}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Employer's Address]
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography
                variant="subtitle1"
                align="left"
                fontSize={{ xs: "16px", sm: "12px", md: "12px" }}
              >
                Household Employee placeholder
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className=" grid grid-cols-2 gap-y-2">
                <div
                  // onClick={props.handleEmployee}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Employee's Name]
                </div>

                <div
                  // onClick={props.handleEmployee}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Employee's Phone]
                </div>

                <div
                  // onClick={props.handleEmployee}
                  className=" no-underline bg-green-100 w-fit h-fit p-2 text-[10px]"
                >
                  [Employee's Address]
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box mt={5} className="space-y-3 px-4">
          <MHButton
            //   type="submit"
            form="add-employee-form"
            //   loading={loading}
            className="rounded-[4px] cursor-pointer"
            fullWidth
            startIcon={<AddIcon />}
            onClick={props.handleSignState}
          >
            Add Signature
          </MHButton>

          <MHButton
            //   type="submit"
            form="add-employee-form"
            //   loading={loading}
            className="rounded-[4px] cursor-pointer"
            onClick={discardChanges}
            fullWidth
          >
            Discard Changes
          </MHButton>

          <MHButton
            //   type="submit"
            form="add-employee-form"
            //   loading={loading}
            className="rounded-[4px] cursor-pointer"
            fullWidth
            onClick={props.handleSaveContract}
          >
            Save Changes
            {/* Save Template */}
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ContractSideBar;
