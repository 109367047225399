import React, { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SelectOption } from "@mui/base";

import MHDialog from "../../../components/Common/Dialog/MHDialog";
import MHFormControl from "../../../components/Common/Form/MHFormControl";
import StyledActionButton from "../../../components/Common/Button/StyledActionButton";
import useInputArray from "../../../hooks/use-input-array";

import * as validators from "../../../utils/validators";
import MHButton from "../../../components/Common/Button/MHButton";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";
import useInput from "../../../hooks/use-input";
import { Button, Grid, Typography } from "@mui/material";
import InputAdornment from "../../../components/Common/Form/InputAdornment";
import { MHSelect } from "../../../components/Common/Form/MHSelect";
import {
  getURLWithQueryParams,
  resolveErrorMessage,
} from "../../../utils/utils";
import DashboardContext from "../../../store/context/dashboard.context";
import { useOnboardForm } from "../../../store/context/onboard-context";
import dayjs, { Dayjs } from "dayjs";
import MHDatePicker from "../../../components/Common/Form/MHDatePicker";

import { ReactComponent as ContractColoredIcon } from "../../../static/svg/contract.svg";
import { ReactComponent as ContractGreyIcon } from "../../../static/svg/contract-grey.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../static/svg/arrow-left.svg";
import {
  HouseholdRoles,
  generateUniqueReference,
} from "../../../utils/contracts";
import { useHistory } from "react-router-dom";
import { EmployeeData } from "../../../models/employee.model";

export const TYPES: SelectOption<string>[] = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

const ContractDialog = () => {
  // const { inputFields, onChange, addField, removeField } = useInputArray([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  // React.useEffect(() => {
  //   addField();
  // }, []);

  const { loading, error, sendHttpRequest } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const { contractForm, addToContract } = useOnboardForm();
  const history = useHistory();

  // const [startDate, setStartDate] = React.useState<Dayjs | null>(
  //   dayjs("2024-04-17")
  // );

  const uniqueReference = generateUniqueReference();
  // console.log("uniqueReference", uniqueReference);

  const [activeButton, setActiveButton] = React.useState("cb-contract");

  const {
    value: enteredEmployee,
    valid: enteredEmployeeIsValid,
    error: enteredEmployeeHasError,
    onChange: employeeInputChangeHandler,
    onBlur: employeeInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredRole,
    valid: enteredRoleIsValid,
    error: enteredRoleHasError,
    onChange: roleInputChangeHandler,
    onBlur: roleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredDays,
    valid: enteredDaysIsValid,
    error: daysInputHasError,
    onChange: daysInputChangeHandler,
    onBlur: daysInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const formIsValid = enteredRoleIsValid && enteredDaysIsValid;

  // const { loading, error, sendHttpRequest } = useHttp();

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  const filteredEmployees = employees.filter(
    (employee) => employee.status === "INACTIVE"
  );

  // const [selectedEmployee, setSelectedEmployee] =
  //   React.useState<EmployeeData | null>(null);
  const formattedEmployees = filteredEmployees.map((item) => ({
    value: String(item.customerId), // Convert to string
    label: `${item.firstName} ${item.lastName}`,
  }));

  const nameOfEmployee = formattedEmployees.find(
    (item) => item.value === enteredEmployee
  );

  const employeeData = employees.find(
    (employee) => employee.customerId === Number(enteredEmployee)
  );
  // console.log("employeeData", employeeData);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  const handleForm = (event: React.SyntheticEvent) => {
    event.preventDefault();

    // if (!formIsValid) {
    //   // console.log("Complete Form");
    //   toast({
    //     message: "Please input contract details",
    //     variant: "warning",
    //   });
    //   return;
    // }
    const currentDate = dayjs();

    addToContract({
      role: enteredRole,
      id: Number(employeeData?.id),
      employeeUUID: Number(enteredEmployee),
      employeeName: nameOfEmployee ? nameOfEmployee.label : "",
      employeePhone: employeeData?.whatsAppNumber,
      employeeEmail: employeeData?.email,
      startDate: currentDate,
      endDate: currentDate.add(Number(enteredDays), "days"),
      contractRef: uniqueReference,
      contractLength: enteredDays,
    });
    // history.push(`/organization/contract`);
    window.location.reload();

    // roleInputChangeHandler("");
    // daysInputChangeHandler("");
  };

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  return (
    <React.Fragment>
      <Box
        component="form"
        id="add-employee-form"
        onSubmit={handleForm}
        autoComplete="off"
        noValidate
      >
        <Box className="grid md:grid-cols-3 px-4 lg:px-8">
          <Button
            // onClick={() => handleClick("cb-contract")}
            className={`no-underline mb-3 md:mb-0 w-[300px] md:w-[220px] lg:w-[330px] lg:mr-2 p-4 h-[200px] border border-1 mx-auto ${
              activeButton === "cb-contract"
                ? "border-[#194049]"
                : "border-[#D9D9D9]"
            }`}
          >
            <Box
              className=" !place-content-start gap-3"
              // display="flex"
              // justifyContent="start"
              // justifyItems="start"
              // alignItems="start"
            >
              <Box className=" p-2">
                {activeButton === "cb-contract" ? (
                  <ContractColoredIcon className="h-10 w-10" />
                ) : (
                  <ContractGreyIcon className="h-10 w-10" />
                )}
              </Box>

              <Box className="text-start space-y-4">
                <Typography
                  variant="caption"
                  color={activeButton === "cb-contract" ? "primary" : "#C1C1C1"}
                  fontSize={{ xs: "16px", sm: "14px", md: "16px" }}
                  lineHeight={{ xs: "19px", sm: "19px" }}
                  letterSpacing={"0.01em"}
                  className=" text-start"
                >
                  Use Caring Blocks's Template
                </Typography>
                <Typography
                  variant="body1"
                  color={activeButton === "cb-contract" ? "primary" : "#C1C1C1"}
                  fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
                  lineHeight={{ xs: "19px", sm: "19px" }}
                  letterSpacing={"0.01em"}
                  className=" text-start"
                >
                  Use Caring Blocks's legally compliant contract templates
                </Typography>
              </Box>
            </Box>
          </Button>

          <Button
            // onClick={() => handleClick("my-contract")}
            className={`no-underline mb-3 md:mb-0 w-[300px] md:w-[220px] lg:w-[330px] lg:mr-2 p-4 h-[200px] border border-1 mx-auto ${
              activeButton === "my-contract"
                ? "border-[#194049]"
                : "border-[#D9D9D9]"
            }`}
          >
            <Box
              className=" !place-content-start gap-3"
              // display="flex"
              // justifyContent="start"
              // justifyItems="start"
              // alignItems="start"
            >
              <Box className=" p-2">
                {activeButton === "my-contract" ? (
                  <ContractColoredIcon className="h-10 w-10" />
                ) : (
                  <ContractGreyIcon className="h-10 w-10" />
                )}
              </Box>

              <Box className="text-start space-y-4">
                <Typography
                  variant="caption"
                  color={activeButton === "my-contract" ? "primary" : "#C1C1C1"}
                  fontSize={{ xs: "16px", sm: "14px", md: "16px" }}
                  lineHeight={{ xs: "19px", sm: "19px" }}
                  letterSpacing={"0.01em"}
                  className=" text-start"
                >
                  Upload my contract
                </Typography>
                <Typography
                  variant="body1"
                  color={activeButton === "my-contract" ? "primary" : "#C1C1C1"}
                  fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
                  lineHeight={{ xs: "19px", sm: "19px" }}
                  letterSpacing={"0.01em"}
                  className=" text-start"
                >
                  Click here to upload your signed contract *PDF only (Coming
                  soon)
                </Typography>
              </Box>
            </Box>
          </Button>

          <Button
            // onClick={() => handleClick("signed-contract")}
            className={`no-underline mb-3 md:mb-0 w-[300px] md:w-[220px] lg:w-[330px] lg:mr-2 p-4 h-[200px] border border-1 mx-auto ${
              activeButton === "signed-contract"
                ? "border-[#194049]"
                : "border-[#D9D9D9]"
            }`}
          >
            <Box
              className=" !place-content-start gap-3"
              // display="flex"
              // justifyContent="start"
              // justifyItems="start"
              // alignItems="start"
            >
              <Box className=" p-2">
                {activeButton === "signed-contract" ? (
                  <ContractColoredIcon className="h-10 w-10" />
                ) : (
                  <ContractGreyIcon className="h-10 w-10" />
                )}
              </Box>

              <Box className="text-start space-y-4">
                <Typography
                  variant="caption"
                  color={
                    activeButton === "signed-contract" ? "primary" : "#C1C1C1"
                  }
                  fontSize={{ xs: "16px", sm: "14px", md: "16px" }}
                  lineHeight={{ xs: "19px", sm: "19px" }}
                  letterSpacing={"0.01em"}
                  className=" text-start"
                >
                  Use your own already signed template
                </Typography>
                <Typography
                  variant="body1"
                  color={
                    activeButton === "signed-contract" ? "primary" : "#C1C1C1"
                  }
                  fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
                  lineHeight={{ xs: "19px", sm: "19px" }}
                  letterSpacing={"0.01em"}
                  className=" text-start"
                >
                  Click here to select one of your already signed templates
                  (Coming soon)
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>

        <Grid
          container
          spacing={3}
          className=" py-6 md:place-content-center lg:gap-0"
        >
          <Grid item xs={12} sm={6} md={2} lg={3.7} className="">
            <MHSelect
              label="Select Employee"
              placeholder="Select Employee"
              options={formattedEmployees}
              value={enteredEmployee}
              onChange={(val) => employeeInputChangeHandler(val as string)}
              onBlur={employeeInputBlurHandler}
              error={resolveErrorMessage(enteredEmployeeHasError)(
                "Please select employee employee"
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3.7} className="">
            <MHSelect
              label="Select Role"
              placeholder="Select"
              options={HouseholdRoles}
              value={enteredRole}
              onChange={(val) => roleInputChangeHandler(val as string)}
              onBlur={roleInputBlurHandler}
              error={resolveErrorMessage(enteredRoleHasError)(
                "Please select employee role"
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3.7} className="">
            <MHFormControl
              id="days"
              type="days"
              label="Notice period (days) to terminate the contract"
              placeholder="10"
              value={enteredDays}
              onChange={daysInputChangeHandler}
              onBlur={daysInputBlurHandler}
              endAdornment={
                <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                  days
                </InputAdornment>
              }
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={10}>
            <Box className="ml-0 mr-0 text-center">
              <MHButton
                sx={{}}
                // onClick={handleDetails}
                type="submit"
                className=" h-12 rounded-md  w-[180px] "
              >
                Next
              </MHButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ContractDialog;
