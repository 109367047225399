import React from "react";
import MHButton from "../../Common/Button/MHButton";
import { Box } from "@mui/material";

import MHFormControl from "../../Common/Form/MHFormControl";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";
import useInput from "../../../hooks/use-input";
import * as validators from "../../../utils/validators";
import InputAdornment from "../../Common/Form/InputAdornment";
import MHDialog from "../../Common/Dialog/MHDialog";

import { ReactComponent as LockIcon } from "../../../static/svg/lock.svg";
import { ReactComponent as ShieldIcon } from "../../../static/svg/shield.svg";
import SnackbarContext from "../../../store/context/snackbar.context";

interface BvnDialogProps {
  open: boolean;
  onClose: () => void;
  type?: string | any;
  redirect_url?: string | any;
  employerRefId?: number | any;
  customerId?: number | any;
}

const BvnDialog: React.FC<BvnDialogProps> = ({
  type,
  redirect_url,
  employerRefId,
  customerId,
  open,
  onClose,
}) => {
  const {
    value: enteredBvn,
    valid: enteredBvnIsValid,
    error: bvnInputHasError,
    onChange: bvnInputChangeHandler,
    onBlur: bvnInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const [bvnRef, setBvnRef] = React.useState("");

  const requestBody = {
    bvn: enteredBvn?.replace(/\D/g, ""),
    type: type,
    employerRefId: employerRefId,
    redirect_url: redirect_url,
    customerId: customerId,
  };

  const getBvnRef = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/tranx/bvn-consent`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Handle success
        const successData = await response.json();

        setBvnRef(successData.reference);
        localStorage.setItem("bvnRef", bvnRef);
        toast({
          variant: "success",
          message: "Bvn submission successful!",
        });
      } else {
        const errorData = await response.json();
        console.error("Request submission failed:", errorData);
        toast({
          variant: "error",
          message: "Bvn submission failed!",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast({
        variant: "error",
        message: " An error occurred",
      });
    }
  };

  const handleBvnVerify = () => {
    // console.log(requestBody);
    getBvnRef();
  };

  return (
    <MHDialog
      open={open}
      title={` Enter your BVN`}
      handleClose={onClose}
      maxWidth="md"
      // actions={}
      scroll="paper"
      sx={{ textAlign: "center" }}
    >
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "400px" }}
        width={{ xs: "full", sm: "400px" }}
        className=" ml-0 mr-0 place-content-center align-middle items-center mx-auto "
      >
        <Box display="flex" justifyContent="center" mt={3}>
          <RoundedLogoIcon
            sx={{
              width: 70,
              height: 70,
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ShieldIcon />
          </RoundedLogoIcon>
        </Box>

        <Box display="flex" justifyContent="center" my={4}>
          <Box width="256px">
            <MHFormControl
              id="bvn"
              type="text"
              label="Kindly input your bvn"
              placeholder=""
              value={enteredBvn}
              onChange={bvnInputChangeHandler}
              onBlur={bvnInputBlurHandler}
              startAdornment={
                <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                  <LockIcon className="" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box my={4} width={{ xs: "100%", sm: "256px" }}>
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className="  "
              fullWidth
              // startIcon={<PlusIcon />}
              onClick={handleBvnVerify}
            >
              Submit
            </MHButton>
          </Box>
        </Box>
      </Box>
    </MHDialog>
  );
};

export default BvnDialog;
