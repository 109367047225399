import React, { useRef } from "react";

import Box from "@mui/material/Box";
import { useReactToPrint } from "react-to-print";

import MHDataTable, { GridColDef } from "../../Common/DataTable/MHDataTable";
// import { TransactionProps } from "../../../models/employee.model";
import MHLink from "../../Common/Link/MHLink";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import BackdropLoader from "../../Common/Loading/BackdropLoader";
import SnackbarContext from "../../../store/context/snackbar.context";
import {
  convertUTCToDate,
  convertUTCToWordDate,
  formatAmount,
  getURLWithQueryParams,
} from "../../../utils/utils";
import AuthContext from "../../../store/context/auth-context";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-white-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../static/svg/download.svg";

import {
  Divider,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import NewPaySlip from "../Payments/NewPaySlip";
import moment from "moment";
import DashboardContext from "../../../store/context/dashboard.context";
import MHDialog from "../../Common/Dialog/MHDialog";
import {
  TransactionApiResponse,
  TransactionProps,
} from "../../../models/transaction.model";
import dayjs from "dayjs";

const EmployeeTransaction = () => {
  const [transactions, setTransactions] = React.useState<TransactionProps[]>(
    []
  );

  const columns: GridColDef<TransactionProps>[] = [
    {
      headerName: "Date",
      type: "text",
      field: "date",
      width: 100,
      cellRenderer: (row: TransactionProps) => (
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "100%", sm: "100%" }}
          letterSpacing={"0.01em"}
          alignSelf={"center"}
          className=" opacity-40 capitalize"
        >
          {convertUTCToDate(row?.CreatedDate)}
        </Typography>
      ),
    },
    {
      headerName: "Reference",
      type: "text",
      field: "reference",
      width: 200,
      valueGetter: (row: TransactionProps) => {
        return row?.reference;
      },
    },
    {
      headerName: "Bank Name",
      type: "text",
      field: "bankName",
      width: 200,
      valueGetter: (row: TransactionProps) => {
        return row?.bankName;
      },
    },

    {
      headerName: "Account Number",
      type: "text",
      field: "accountNumber",
      width: 400,
      valueGetter: (row: TransactionProps) => {
        return row?.accountNumber;
        // Hi, {authCtx.user?.firstName}
      },
    },
    {
      headerName: "Amount",
      type: "text",
      field: "amount",
      width: 200,
      valueGetter: (row: TransactionProps) => {
        return "₦" + row?.amount;
      },
    },

    // {
    //   headerName: "Processed",
    //   type: "number",
    //   field: "processed",
    //   width: 100,
    //   valueGetter: (row: TransactionProps) => {
    //     return Boolean(row?.processed);
    //   },
    // },
    {
      headerName: "Type",
      type: "text",
      field: "type",
      width: 100,
      valueGetter: (row: TransactionProps) => {
        return row?.type;
      },
    },
  ];

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  // console.log("RefID", user?.employerRefId);
  // console.log("organization", organization);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/tranx/history",
        {
          customerId: String(organization?.customerId),
          // fromDate: String(startDate),
          // toDate: String(presentDate),
        }
      ),
      {
        method: "GET",
      },
      (response) => {
        setTransactions(response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  // console.log("transactions", transactions);

  return (
    <React.Fragment>
      <BackdropLoader open={loading} />

      <Box className=" flex justify-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "20px", sm: "30px" }}
          align="center"
          mt={3}
          mb={3}
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Transactions
        </Typography>

        <Divider />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} className=" order-2 md:order-1">
          <MHDataTable
            title=""
            rows={transactions}
            columns={columns}
            frontEndPagination
            // hidePagination
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmployeeTransaction;
