import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useRouteMatch } from "react-router-dom";
import useDialog from "../../hooks/use-dialog";

// type Props = {}

const CorporateApproachSection = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/employers`);
  };

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog,
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Box className="pt-10">
        <Box className="items-center place-content-center ">
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            THE ENTERPRISE PLAN
          </Typography> */}

          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="h2"
            width={{ xs: "90%", md: "65%" }}
            fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            mx="auto"
            className="py-8  text-center"
          >
            What We Offer
          </Typography>

          {/* Web */}

          <Box
            className="hidden md:grid grid-cols-3 items-center mx-auto text-center"
            justifyContent="center"
          >
            {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
              Personalized Employee Work- <br /> Life Care Solutions
            </Typography> */}
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Experienced Professionals
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Our nannies and household staff are not just employees, but
                highly trained professionals certified in child <br />{" "}
                development, first aid, and CPR, ensuring your <br /> children
                receive exceptional care.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Hiring, Vetting, & Onboarding
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                We take care of the entire process of hiring and vetting nannies
                and household staff, ensuring they meet your employees' needs.
                Our seamless onboarding process integrates them into the family
                smoothly.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Caregiver Courses & Training
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Through our Caring Blocks Institute, we offer ongoing training
                and development for caregivers, ensuring they're equipped with
                the latest skills and knowledge to provide top-notch care.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Household Payroll Services
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Simplify the payroll process for your employees' household staff
                with our comprehensive payroll management services. We handle
                everything from salary calculations to tax withholdings, so your
                employees don't have to.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Performance Management
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                We provide tools and support to monitor and manage the
                performance of household staff, helping to ensure consistent
                quality and satisfaction. the job details and decide if it's the
                right fit for you.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                24/7 Care Concierge Service
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                As a member of Caring Blocks, your employees have access to our
                24/7 care concierge service. Whether you need support with your
                job or have a concern that needs addressing, we're here to help
                at any time, day or night.
              </Typography>
            </Box>

            {/* &bull; */}
          </Box>

          {/* Mobile */}

          <Box className="md:hidden block items-center place-content-center mx-auto text-center">
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Experienced Professionals
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Our nannies and household staff are not just employees, but
                highly trained professionals certified in child development,
                first aid, and CPR, ensuring your children receive exceptional
                care.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Hiring, Vetting, & Onboarding
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                We take care of the entire process of hiring and vetting nannies
                and household staff, ensuring they meet your employees' needs.
                Our seamless onboarding process integrates them into the family
                smoothly.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Caregiver Courses & Training
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Through our Caring Blocks Institute, we offer ongoing training
                and development for caregivers, ensuring they're equipped with
                the latest skills and knowledge to provide top-notch care.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Household Payroll Services
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Simplify the payroll process for your employees' household staff
                with our comprehensive payroll management services. We handle
                everything from salary calculations to tax withholdings, so your
                employees don't have to.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Performance Management
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                We provide tools and support to monitor and manage the
                performance of household staff, helping to ensure consistent
                quality and satisfaction. the job details and decide if it's the
                right fit for you.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                24/7 Care Concierge Service
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                As a member of Caring Blocks, your employees have access to our
                24/7 care concierge service. Whether you need support with your
                job or have a concern that needs addressing, we're here to help
                at any time, day or night.
              </Typography>
            </Box>

            {/* <Typography
              variant="body1"
              fontSize={{ xs: "12px" }}
              lineHeight={"170%"}
              letterSpacing={"0.02em"}
              className=" py-3 text-center"
            >
              Employees need help navigating life hurdles and
              <br /> the daily transitions between caregiving,
              <br /> work and life.
            </Typography> */}
          </Box>

          {/* <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={handleOpen}>
              Request a Demo
            </MHButton>
          </Box> */}
        </Box>

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
      </Box>
    </React.Fragment>
  );
};

export default CorporateApproachSection;
