import React from "react";
import { InstituteHeader } from "./TrainingInstitute/InstituteHeader";
import SingleTrainingBody from "./TrainingInstitute/SingleTrainingBody";
import LandingFooter from "./Landing/LandingFooter";
import useDialog from "../hooks/use-dialog";
import SingleTrainingDialog from "./TrainingInstitute/SingleTrainingDialog";

type Props = {};

const SingleTraining = (props: Props) => {
  const [selectedRole, setSelectedRole] = React.useState("");
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
  };

  const trainingCost = process.env.REACT_APP_TRAINING_COST;

  return (
    <React.Fragment>
      <InstituteHeader
        onRoleChange={handleRoleChange}
        title="Raise The Standard Of Care"
        content="Professional Training For Nannies In The Modern Nigerian Home."
        venue="The Baby Lounge VI"
        date="Sept 28th, 2024"
        time="10:00am - 4:00pm"
        cost={trainingCost}
        note="Lunch and childcare (if needed) will be provided."
        handelReserve={handleOpenDialog}
      />

      <SingleTrainingBody />

      <LandingFooter />
      <SingleTrainingDialog
        open={openDialog}
        onClose={handleCloseDialog}
        price={trainingCost}
      />
    </React.Fragment>
  );
};

export default SingleTraining;
