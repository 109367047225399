export const ASSESSDATA = [
    {
      value: "nanny",
      label: "Nanny",
      amount: 35000,
      text: "Tuberculosis (TB) Test##HIV I & II Test##Hepatitis A, B, and C Tests##Pregnancy Test##Genotype##BP",
    },
    {
      value: "driver/chauffeur",
      label: "Driver/Chauffeur",
      amount: 50000,
      text: "Tuberculosis (TB) Test##HIV I & II Test##Hepatitis A, B, and C Tests##Pregnancy Test##Genotype##BP##Drug Screening##Vision Test##Hearing Test##and Diabetes Screening",
    },
    {
      value: "chef/cook",
      label: "Chef/Cook",
      amount: 45000,
      text: "Tuberculosis (TB) Test##HIV I & II Test##Hepatitis A, B, and C Tests##Pregnancy##Genotype##BP##Stool Test##Drug Screening",
    },
    {
      value: "home tutor",
      label: "Home Tutor",
      amount: 40000,
      text: "Tuberculosis (TB) Test##HIV I & II Test##Hepatitis A, B, and C Tests##Drug Screening",
    },
    {
      value: "other",
      label: "Household Employee",
      amount: 50000,
      text: "Tuberculosis (TB) Test##HIV I & II Test##Hepatitis A, B, and C Tests##Drug Screening",
    },
  ]