import { Box, Button, Typography } from "@mui/material";
import React from "react";
import useDialog from "../../../hooks/use-dialog";
import MHButton from "../../Common/Button/MHButton";
import EmployerWithdrawDialog from "./EmployerWithdrawDialog";

import WalletPattern from "../../../static/svg/wallet-pattern.svg";
import { ReactComponent as PlusIcon } from "../../../static/svg/plus-lg.svg";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";
import DashboardContext from "../../../store/context/dashboard.context";
import { useOnboardForm } from "../../../store/context/onboard-context";
import { EmployeeWalletStatProps } from "../Household/EmployeeWalletStatDesign";

type Props = {};

const EmployerWithdrawal = ({
  theme,
  icon,
  icon2,
  onClick,
  title,
  role,
  stat,
  ...props
}: EmployeeWalletStatProps) => {
  const formatNumber = (value: any) => {
    // Check if the value is a number
    if (typeof value === "number") {
      // Use Intl.NumberFormat to format the number
      return new Intl.NumberFormat().format(value);
    } else {
      // If it's not a number, return the original string
      return value;
    }
  };

  const dashboardCtx = React.useContext(DashboardContext);

  const { organization, fetchOrganizationData } = dashboardCtx;

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const { accountDetails, getAcctDetails } = useOnboardForm();

  React.useEffect(() => {
    getAcctDetails();
  }, [accountDetails]);

  return (
    <React.Fragment>
      <Box
        p={3}
        bgcolor={
          theme === "dark"
            ? "primary.main"
            : theme === "default"
            ? "common.white"
            : "#C7D8E4"
        }
        borderRadius={2}
        boxShadow="0px 5px 26px #C7D8E4"
        minHeight={220}
        sx={{
          ...props.containerSx,
          gap: 2,
          pt: 5,
          mb: 3,
          position: "relative",
          overflow: "hidden",
        }}
        display={"flex"}
      >
        <Box className=" absolute top-0 right-0">
          <img
            src={WalletPattern}
            alt="error"
            className="object-cover h-fit w-fit z-40 "
          />
        </Box>
        <Box>{icon}</Box>
        <Box className=" z-10">
          {stat === 0 ? (
            <>
              <Typography
                variant="body2"
                fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                gutterBottom
                className={`capitalize opacity-50`}
              >
                {title}
              </Typography>
              <Typography
                variant="h1"
                fontSize={{ xs: "30px", sm: "30px", md: "40px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                display="flex"
                alignItems="center"
                className={`capitalize opacity-50`}
              >
                <NairaIcon className=" w-8 h-8 mt-1" />
                {formatNumber(stat)}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="body2"
                fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                gutterBottom
                className={`capitalize`}
              >
                {title} &copy;
              </Typography>
              <Typography
                variant="h1"
                fontSize={{ xs: "30px", sm: "30px", md: "40px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                display="flex"
                alignItems="center"
              >
                <NairaIcon className=" w-8 h-8 mt-1" />
                {formatNumber(stat)}
              </Typography>
            </>
          )}
        </Box>

        <Box className="  grid ml-auto mr-0 content-between">
          {stat !== 0 ? (
            <Button className=" absolute top-3 right-0" onClick={onClick}>
              {icon2}
            </Button>
          ) : null}

          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className=" h-10 md:h-fit w-20 md:w-[120px] absolute bottom-6 right-3"
            // startIcon={<PlusIcon />}
            onClick={handleOpenDialog}
          >
            Withdraw
          </MHButton>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "30px",
            right: "auto",
          }}
        >
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            color={theme === "dark" ? "common.white" : "primary.main"}
            gutterBottom
            className={`capitalize opacity-50`}
          >
            Bank: {accountDetails?.bankName}
          </Typography>
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            color={theme === "dark" ? "common.white" : "primary.main"}
            gutterBottom
            className={`capitalize opacity-50`}
          >
            Account: {accountDetails?.AccountNumber}
          </Typography>
        </Box>
      </Box>

      {openDialog && (
        <EmployerWithdrawDialog open={openDialog} onClose={handleCloseDialog} />
      )}
    </React.Fragment>
  );
};

export default EmployerWithdrawal;
