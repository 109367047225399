import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import { ReactComponent as GreenLeather } from "../../static/svg/green-leather.svg";
import FaqAccordion from "./FaqAccordion";

type Props = {};

const FAQ = (props: Props) => {
  const faqData = [
    {
      id: 0,
      label: "What Is Caring Blocks?",
      content:
        "Caring Blocks is designed to provide household employers with access to business-level management software, enabling you to run payrolls, generate reports, train and track employee performance thereby raising the expectations and standard of care in your homes, including the employer-employee relationship.",
    },
    {
      id: 1,
      label: "Who Are Household Contractors?",
      content:
        "Household Contractors work privately in your homes. Their work may include cleaning the house, cooking, washing and ironing clothes, taking care of children, or elderly or sick members of a family, gardening, guarding the house, driving for the family, and even taking care of household pets.",
    },
    {
      id: 2,
      label: "Is It Necessary To Pay My Household Contractor Legally?",
      content:
        "Even if you have a friendly relationship with your household contractor, you must generate and keep pay slips. This ensures your worker understands that this is a real job with an employment agreement, job description, and training to ensure they continue to meet/exceed the minimum standard of care for your home and loved ones. In addition, all employees, whether they work in an office or a home, need verifiable income so they can apply for a loan or rent an apartment. This may sound basic, but for household employees it’s rare. Less than 1% are paid legally, and we’re here to change that.  ",
    },
    {
      id: 3,
      label: "Can I Continue To Offer Monetary Gifts & Allowances Separately? ",
      content:
        "We recommend that all gifts, allowances, and other remittances be made exclusively within Caring Blocks to properly ensure we capture your total household contractor expenditure monthly and annually. ",
    },
    {
      id: 4,
      label:
        "How Does Caring Blocks Help With Training My Household Contractors?",
      content:
        "Upon logging into your Caring Blocks Dashboard, you can recommend training for your household employees from your nanny to driver to cooks. This helps raise the quality of care in your household, reducing stress, and ensuring the safety and wellbeing of your loved ones.",
    },
    {
      id: 5,
      label: "Do Caring Blocks Offer Background Checks?",
      content:
        "When you onboard a household contractor, we offer to provide background checks services registered with the State and Federal Authorities. We make sure your employees are who they say they are. ",
    },
    {
      id: 6,
      label:
        "Will I Have Access To The Caring Blocks Database For Vetting Purposes?",
      content:
        "If your contractor is within the Caring Blocks database prior to hiring, then you have access to that data to view their employment history, past performances, and other important information. The aim of Caring Blocks is to help household employers understand who they are bringing into their homes.",
    },
    {
      id: 7,
      label: "Does Caring Blocks Offer Health Assessments?",
      content:
        "Through our partners, we offer subsidized health assessments before and after hiring your employee. We recommend a yearly assessment of your workers to ensure your family is protected from irreversible diseases and infections.",
    },
  ];
  return (
    <React.Fragment>
      <Box
        sx={{
          paddingTop: "40px",
          justifyContent: "center",
          alignItems: "center",
          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "auto", sm: "auto", md: "auto" },
          backgroundColor: "#ffffff",
        }}
      >
        <div id="faq"></div>

        <Grid
          container
          spacing={2}
          sx={{ alignItems: "center", justifyContent: "center" }}
          py={4}
        >
          <Grid
            item
            xs={10.5}
            sm={5.5}
            md={5.5}
            sx={{
              paddingTop: "30px",
              alignItems: "center",
              justifyContent: "center",
              // textAlign: { xs: "center", sm: "start" },
            }}
          >
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "28px", sm: "28px", md: "29px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className="pb-4 capitalize text-center md:text-start"
            >
              Frequently Asked Questions (FAQ)
            </Typography>

            <FaqAccordion data={faqData} />
          </Grid>
          <Grid
            item
            xs={10.5}
            sm={5}
            md={5}
            sx={{
              justifyContent: "right",
              marginY: "80px",
            }}
            className="hidden md:block"
          >
            <Box
              sx={{
                marginLeft: { lg: "70px" },
                marginRight: { sm: "100px", md: "0px" },
              }}
            >
              {/* <GreenLeather className="  h-[290px] md:h-[330px] lg:h-[500px] w-fit" /> */}
              <img
                src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caringBlocks/green-leather.webp"
                alt=""
                draggable={false}
                className=" h-[290px] md:h-[330px] lg:h-[500px] w-fit"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default FAQ;
