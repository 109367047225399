import { Box, Button, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import { useOnboardForm } from "../../store/context/onboard-context";
import CongratsCard from "../../components/Features/Household/CongratsCard";
import Lottie from "react-lottie";

import * as animationData from "../../static/json-animate/Congratulations.json";
import { useHistory } from "react-router-dom";

type Props = {};

const ContractSigned = (props: Props) => {
  let history = useHistory();
  const [congratState, setCongratState] = React.useState(false);
  const { contractForm } = useOnboardForm();
  // let myPdfUrl =
  //   "https://www.aeee.in/wp-content/uploads/2020/08/Sample-pdf.pdf";
  // const fileName = myPdfUrl.substring(myPdfUrl.lastIndexOf("/") + 1);

  // const handleDownloadSignedPdf = (signedPdfUrl: string) => {
  //   // Create a temporary anchor element
  //   const link = document.createElement("a");
  //   link.href = contractForm.signedPdfUrl || ""; // Use the signed PDF URL from the context
  //   link.download = fileName; // Set the download file name
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleCongrats = () => {
    setCongratState(true);
  };

  const closeCongrats = () => {
    setCongratState(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleContinue = () => {
    history.push("/organization/employee/dashboard");
  };

  return (
    <React.Fragment>
      <Box className="py-20">
        <Box className=" relative">
          <Box className=" absolute top-0 bottom-0 right-0 left-0">
            <Lottie
              options={defaultOptions}
              height={"117.4px"}
              width={"206.97px"}
            />
          </Box>
          <Box className="centerItems">
            <Typography
              variant="h1"
              fontSize={{ xs: "35px", sm: "35px", md: "35px" }}
              gutterBottom
              className=" text-center md:w-[35%] lg:w-[20%] py-6"
            >
              Congratulations on your job!
            </Typography>
          </Box>
        </Box>

        <Box className="centerItems">
          {/* <Button
            // display="block"
            // align="left"
            className="text-blue-100 no-underline"
            onClick={() => {
              handleDownloadSignedPdf(contractForm.signedPdfUrl || "");
            }}
          >
            Download a copy
          </Button> */}
        </Box>
        <Box className="centerItems">
          <Typography
            variant="body1"
            fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
            gutterBottom
            className=" text-center md:w-[80%] lg:w-[45%] py-5"
          >
            We will notify you of the dates for your upcoming checks via our
            method of communication, which is through notifications on your
            dashboard, email, or WhatsApp. <br /> <br /> In the meantime, please
            visit your dashboard, as your employer has assigned additional
            benefits to you.
          </Typography>
        </Box>

        <Box className="py-10 flex gap-20 place-content-center">
          <MHButton
            //   type="submit"
            form="add-employee-form"
            //   loading={loading}
            className="rounded-[4px] cursor-pointer w-[210px] h-[43px]"
            // fullWidth
            onClick={handleCongrats}
          >
            I'll do it later
          </MHButton>
          <MHButton
            //   type="submit"
            form="add-employee-form"
            //   loading={loading}
            className="rounded-[4px] cursor-pointer w-[210px] h-[43px]"
            // fullWidth
            onClick={handleContinue}
          >
            Continue
          </MHButton>
        </Box>
      </Box>

      <CongratsCard open={congratState} handleClose={closeCongrats} />
    </React.Fragment>
  );
};

export default ContractSigned;
