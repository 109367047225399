import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import ContentCard from "./ContentCard";
import MHSlider, {
  MHSliderItem,
} from "../../components/Common/SliderCarousel/CBSlider/MHSlider";

const CONTENT = [
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Trust+And+Safety+At+The+Core.png",
    title: "Trust & Safety At The Core",
    description:
      "We take the guesswork out of hiring by implementing the most thorough vetting processes, fraud prevention, and safety screening in the industry.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/State-Of-The-Art+Verification.png",
    title: "State-of-the-art ID Verification",
    description:
      "in partnership with Lagos State (LASRRA), offering an additional layer of safety and transparency for your household.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Health+Assessment.png",
    title: "Health Assessments",
    description:
      "to ensure that every caregiver - nanny, driver, chef -  is physically and mentally fit and safe to be around your children.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/18.png",
    title: "Personalized Matchmaking",
    description:
      "ensures that the right employee, with the right skill set, is placed in your home. From full-time live-in nannies to part-time staff, we offer flexibility to meet your family's needs.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Professional+Growth+And+Development.png",
    title: "Comprehensive Training",
    description:
      "through our Caring Blocks Institute ensures that all nannies are upskilled in childcare, safety, hygiene, and household management, offering professional development and support.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Care+Support1.png",
    title: "24/7 Support and Care Management",
    description:
      "ensures that our job doesn't end after placement. We provide continuous monitoring, feedback, and support to maintain the highest standard of care in your home.",
  },
];

const FamiliesHeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box width={{ xs: "90%", sm: "75%" }} mx="auto" mt={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          {/* extend care beyond the company’s walls, creating a win-win scenario
          where you and <br /> your employees thrive and prosper. */}
          How Caring Blocks Puts Your Family First
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph
        >
          We know the modern Nigerian family faces real challenges when it comes
          to domestic workers. From cases of theft and dishonesty to
          heart-wrenching stories of children being harmed or worse, families
          today are grappling with concerns about whom to trust in their homes.{" "}
          <br />
          <br />
          <strong className=" font-areaBlack"> Caring Blocks</strong> is here to
          change that narrative. We are committed to professionalizing the role
          of nannies and domestic staff by offering a system that is built on
          safety, training, and transparency. Every caregiver and household
          employee is rigorously vetted, trained, and continuously supported so
          that your family can focus on what truly matters—your children's
          well-being and your household's harmony.
        </Typography>
      </Box>

      <Box py="3rem" px={{ xs: 0, md: "4rem" }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>

      {/* <Typography
        variant="body1"
        fontFamily="Area-Extended"
        textTransform="uppercase"
        textAlign="center"
        color="#194049"
        fontSize={12}
        letterSpacing="0.1em"
        sx={{
          mt: 0,
          mb: 2,
        }}
      >
        Top Companies Trust MH
      </Typography> */}

      {/* <MHmarquee /> */}

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={5}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', md: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          An Array Of Care Solutions Throughout Your Employees Working Lives
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Many Employees Are Being Crushed Under The Unrelenting and Unexpected
          Burden Of Care, It’s Time Your Benefits Care
        </Typography>
      </Box>

      {!matchesMdDown && (
        <Box py="3rem" px={'4rem'}>
          <MHSlider slidesToShow={3} slidesToScroll={1}>
            {CONTENT.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <ContentCard
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  onMouseClick={() => {}}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        </Box>
      )}

      {matchesMdDown && (
        <Grid
          container
          direction={{ xs: 'row' }}
          flexWrap="nowrap"
          rowSpacing={{ xs: 0, sm: 0 }}
          columnSpacing={3}
          mt={2}
          width={{ xs: '90%', sm: '80%' }}
          mx="auto"
          mb={8}
          overflow="auto"
          className="horizontal-scroll">
          {CONTENT.map((item) => (
            <Grid item xs={12} sm={4} flexShrink={0}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
    </div>
  );
};

export default FamiliesHeroSlider;
