import React from "react";
import AuthContext from "../../store/context/auth-context";
import useHttp from "../../hooks/use-http";
import { WalletStatProps } from "../../components/Features/Dashboard/WalletStatDesign";
import { Box, Grid, Typography } from "@mui/material";
import RoundedLogoIcon from "../../theme/icons/RoundedLogo";

import { ReactComponent as WalletsIcon } from "../../static/svg/wallets.svg";
import { ReactComponent as WalletIcon } from "../../static/svg/wallet.svg";

import { ReactComponent as VisibilityIcon } from "../../static/svg/visibility-grey.svg";
import { ReactComponent as VisibilityOffIcon } from "../../static/svg/visibility-grey-off.svg";
import SalaryPaymentDesign from "../../components/Features/Household/SalaryPaymentDesign";
import UploadReceipt from "../../components/Features/Household/UploadReceipt";
import UpcomingSchedule from "../../components/Features/Household/UpcomingSchedule";
import AssessmentCard from "../../components/Features/Household/AssessmentCard";
import TrainingEmployee from "../../components/Features/Household/TrainingEmployee";
import {
  assessmentData,
  employeeTestArray,
  testTraining,
} from "../../utils/constants";
import EmployeeWalletStatDesign from "../../components/Features/Household/EmployeeWalletStatDesign";

import DashboardContext from "../../store/context/dashboard.context";
import { useOnboardForm } from "../../store/context/onboard-context";
import { useHistory } from "react-router-dom";
import { ContractProps } from "../../models/contract.model";
import { getURLWithQueryParams } from "../../utils/utils";
import { HttpResponse } from "../../models/api.interface";
import { TrainingData } from "../../components/Features/Training/TrainingCard";

const WalletBalanceStatsGrid = () => {
  // React.useEffect(() => {
  //   sendHttpRequest(
  //     getURLWithQueryParams(
  //       process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
  //       {
  //         employerRefId: String(user?.employerRefId),
  //       }
  //     ),
  //     {
  //       method: "GET",
  //     },
  //     (response: HttpResponse<EmployeeData[]>) => {
  //       setEmployees(response.data);
  //     }
  //   );
  // }, []);

  // const [paydate, setPaydate] = React.useState<string | null>(null);
  const [toggleState, setToggleState] = React.useState(false);

  // useEffect(() => {
  //   const today = new Date();
  //   const currentDay = today.getDate();

  //   // Check if today is the 20th day of the month
  //   if (currentDay >= 20) {
  //     // Set paydate to the 20th of the next month
  //     const nextMonth = new Date(today);
  //     nextMonth.setMonth(nextMonth.getMonth() + 1);
  //     nextMonth.setDate(20);
  //     setPaydate(nextMonth.toDateString());
  //   } else {
  //     // Set paydate to the 20th of the current month
  //     const currentMonth = new Date(today);
  //     currentMonth.setDate(20);
  //     setPaydate(currentMonth.toDateString());
  //   }
  // }, []);

  const handleToggle = () => {
    setToggleState(!toggleState);
    // console.log(toggleState);
  };

  // funded amount should be passed here.
  // This controls the empty state of the Fund Balance
  // let currentFund = 0;
  const { accountDetails } = useOnboardForm();

  let currentFund = accountDetails?.balance ?? 0;

  const balance =
    currentFund === 0 ? 0 : toggleState === true ? currentFund : "******";
  // const balance = toggleState === true ? currentFund : "******"; // Assuming this is a number representing a balance

  const STATS: WalletStatProps[] = [
    {
      theme: "default",
      icon: (
        <RoundedLogoIcon
          sx={{
            p: 0.6,
            mb: 2,
            backgroundColor: currentFund === 0 ? "#EEEEEE" : "#C7D8E4",
          }}
        >
          {currentFund === 0 ? (
            <WalletIcon className="w-6 h-6" />
          ) : (
            <WalletsIcon className="w-6 h-6" />
          )}
        </RoundedLogoIcon>
      ),
      icon2: (
        <RoundedLogoIcon sx={{ backgroundColor: "transparent" }}>
          {toggleState === false ? (
            <VisibilityIcon className="w-6 h-6" />
          ) : (
            <VisibilityOffIcon className="w-6 h-6" />
          )}
        </RoundedLogoIcon>
      ),
      title: "Employee Wallet",
      stat: balance,
      role: "Employee",
      onClick: handleToggle,
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        className=" place-content-start md:justify-between gap-2 md:gap-0"
      >
        {STATS.map((stat) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            key={stat.title}
            p={4}
            // onClick={handleOpen}
            sx={{
              // cursor: "pointer",
              backgroundColor: "#F9F9F7",
              borderRadius: 3,
            }}
            className=" lg:flex gap-3"
          >
            <Box className=" w-full lg:w-1/2" position={"relative"}>
              <EmployeeWalletStatDesign {...stat} />
            </Box>
            <Box className="w-full lg:w-1/2 h-auto">
              <SalaryPaymentDesign
              // recurringAmount={totalSalary}
              // expensesAmount={expensesMonthly}
              />
            </Box>
          </Grid>
        ))}

        {/* <Grid item xs={12} sm={12} md={2} p={4}>
          Salary & Benefits
        </Grid>
        <Grid item xs={12} sm={12} md={2} p={4}>
          Payment slip
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

const EmployeeDashboard = () => {
  const authCtx = React.useContext(AuthContext);

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;
  let history = useHistory();

  const { sendHttpRequest } = useHttp();
  const [contractDetails, setContractDetails] =
    React.useState<ContractProps | null>(null);

  // console.log("customerId", customerId);

  const [training, setTraining] = React.useState<TrainingData[]>([]);
  const { sendHttpRequest: employeeTrainingReq } = useHttp();

  React.useEffect(() => {
    employeeTrainingReq(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/employee/trainings",

        {
          customerId: String(organization?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<TrainingData[]>) => {
        setTraining(response.data);
      }
    );
  }, []);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract",
        {
          customerId: String(organization?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps>) => {
        if (response.status === 200) {
          // console.log(response.data);
          setContractDetails(response.data);
        } else {
          console.error("Failed to fetch contract details:", response);
        }
      }
    ).catch((err) => {
      console.error("Error fetching contract details:", err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.customerId]);

  // console.log("contractDetails", contractDetails);

  React.useEffect(() => {
    if (contractDetails?.status !== "COMPLETED") {
      if (contractDetails?.customerId) {
        history.push(
          `/organization/employee/contract-agreement/${contractDetails?.customerId}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractDetails?.customerId]);

  // const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <Box mb={4} p={{ xs: 0, sm: 3, md: 3 }}>
        <Box>
          <Grid
            container
            spacing={2}
            className="flex justify-center align-middle items-center"
          >
            <Grid item xs={12} md={11}>
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "30px" }}
                align="center"
                mt={3}
                gutterBottom
                display={"flex"}
                alignSelf={"start"}
              >
                Hi, {authCtx.user?.firstName} !
              </Typography>
              <Typography
                variant="body2"
                mb={3}
                gutterBottom
                display={"flex"}
                alignSelf={"start"}
                className=" opacity-50"
              >
                Welcome to your dashboard
              </Typography>

              <WalletBalanceStatsGrid />
            </Grid>

            <Grid item xs={12} md={11}>
              <UploadReceipt />
            </Grid>
            <Grid item xs={12} md={11}>
              <Typography
                variant="caption"
                color={"primary"}
                fontSize={{ xs: "16px", sm: "24px", md: "26px" }}
                lineHeight={{ xs: "100%", sm: "40px", md: "53px" }}
                align="center"
                letterSpacing={"0.01em"}
                mb={2}
                className=" capitalize centerItems"
              >
                Training Assigned to you
              </Typography>
            </Grid>
            <Grid item xs={12} md={11}>
              <TrainingEmployee dataIn={training} />
            </Grid>

            <Grid item xs={12} md={11} className="mt-6">
              <UpcomingSchedule testArray={employeeTestArray} />
            </Grid>
            <Grid item xs={12} md={11} className="mt-6">
              <AssessmentCard slipArray={assessmentData} />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* {openDialog && (
        <BvnDialog
          open={openDialog}
          onClose={handleCloseDialog}
          redirect_url={`${process.env.REACT_APP_LOCALHOST_USER_ROUTE}/organization/employee/dashboard`}
          type="EMPLOYEE"
          customerId={organization?.customerId}
          employerRefId={organization?.employerRefId}
        />
      )} */}
    </React.Fragment>
  );
};

export default EmployeeDashboard;
