import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";

type ArrayProps = {
  id?: number;
  label?: string;
  gotoLink?: string;
  handleView?: () => void;
  handleDownload?: () => void;
};

type Props = {
  slipArray?: ArrayProps[];
};

const PayslipCard = (props: Props) => {
  const [filteredArray, setFilteredArray] = React.useState<ArrayProps[]>([]);

  React.useEffect(() => {
    if (props.slipArray) {
      const sortedArray = [...props.slipArray].sort(
        (a: any, b: any) => b.id - a.id
      );
      setFilteredArray(sortedArray.slice(0, 2));
    }
  }, [props.slipArray]);

  return (
    <React.Fragment>
      {filteredArray && <Divider />}
      {filteredArray.map((slip, index) => (
        <Box key={index}>
          <Box className=" flex justify-between !items-center">
            <Box>
              <Typography
                variant="body1"
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                lineHeight={"0px"}
                color={"primary"}
                // gutterBottom
                className="pt-2 capitalize "
              >
                {slip.label}
              </Typography>
            </Box>

            <Box>
              <MuiLink
                // component={Link}
                underline="always"
                // to="/organization/employee/dashboard"
                // to={slip.gotoLink}
                onClick={slip.handleView}
                // display="block"
                // align="left"
                className="text-blue-100 mt-2 no-underline cursor-pointer centerItems"
              >
                View
              </MuiLink>
            </Box>
            <Box>
              <MuiLink
                // component={Link}
                underline="always"
                // to={slip.gotoLink}
                onClick={slip.handleDownload}
                // display="block"
                // align="left"
                className="text-blue-100 mt-2 no-underline cursor-pointer centerItems"
              >
                Download
              </MuiLink>
            </Box>
          </Box>
          <Divider className="" />
        </Box>
      ))}
    </React.Fragment>
  );
};

export default PayslipCard;
