import React from "react";
import { Box, Button, Typography } from "@mui/material";
import MHDialog from "../../Common/Dialog/MHDialog";

import SnackbarContext from "../../../store/context/snackbar.context";
import { useOnboardForm } from "../../../store/context/onboard-context";

import { ReactComponent as CopyIcon } from "../../../static/svg/copy.svg";

interface AccountDialogProps {
  open: boolean;
  onClose: () => void;
}

const AccountDialog: React.FC<AccountDialogProps> = ({ open, onClose }) => {
  const { accountDetails, getAcctDetails } = useOnboardForm();

  // console.log("accountDetails", accountDetails.bankName);

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  // console.log("filteredAmount", filteredAmount);

  const copyBankToClipboard = () => {
    navigator.clipboard
      .writeText(accountDetails?.bankName)
      .then(() => {
        toast({
          message: "Copied Bank Name",
          variant: "success",
        });
      })
      .catch((err) => {
        console.error("Failed to copy account number: ", err);
      });
  };
  const copyAccountToClipboard = () => {
    navigator.clipboard
      .writeText(String(accountDetails?.AccountNumber))
      .then(() => {
        toast({
          message: "Copied Bank Account Number",
          variant: "success",
        });
      })
      .catch((err) => {
        console.error("Failed to copy account number: ", err);
      });
  };

  // console.log("transferCharges", transferCharges);

  React.useEffect(() => {
    getAcctDetails();
  }, [accountDetails]);

  return (
    <MHDialog
      open={open}
      title={`Fund Account`}
      handleClose={onClose}
      maxWidth="md"
      // actions={}
      scroll="paper"
      sx={{ textAlign: "center" }}
    >
      <Box
        // mb={0.6}
        height={{ xs: "full", sm: "400px" }}
        width={{ xs: "full", sm: "403px" }}
        className=" ml-0 mr-0 place-content-center items-center mx-auto "
      >
        <Box
          className=""
          alignItems={"center"}
          alignContent={"center"}
          alignSelf={"center"}
          display={"grid"}
          mt={5}
        >
          <Box px={3}>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
              color={"#6B6B6B"}
              gutterBottom
              align="center"
              justifyContent="center"
              width={{ xs: "full", sm: "100%" }}
              my={3}
            >
              Transfer the exact amount you intend to fund into the bank account
              below. Once your payment is fully processed. It will automatically
              reflect in your wallet.
            </Typography>
          </Box>

          {accountDetails ? (
            <>
              <Box className="" my={3} px={3}>
                <Box width="100%">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                      gutterBottom
                      align="left"
                      justifyContent="start"
                      pl={1}
                    >
                      Bank Name
                    </Typography>

                    <Button
                      onClick={copyBankToClipboard}
                      className="removeUnderline"
                      fullWidth
                      disableRipple
                    >
                      <Box
                        className=""
                        width={{ xs: "100%", sm: "100%" }}
                        height={{ xs: "45px", sm: "45px" }}
                        sx={{
                          border: 1,
                          borderColor: "#D9D9D9",
                          borderRadius: 1,
                          px: 2,
                        }}
                        alignItems="center"
                        display="flex"
                        gap={2}
                      >
                        <Typography
                          variant="subtitle1"
                          fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                          color={"primary"}
                          gutterBottom
                          align="left"
                          justifyContent="start"
                          width={{ xs: "100%", sm: "100%" }}
                          my={3}
                        >
                          {accountDetails?.bankName}
                        </Typography>

                        <CopyIcon />
                      </Box>
                    </Button>
                  </Box>

                  <br />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                      gutterBottom
                      align="left"
                      justifyContent="start"
                      pl={1}
                    >
                      Account Number
                    </Typography>

                    <Button
                      onClick={copyAccountToClipboard}
                      className="removeUnderline"
                      fullWidth
                      disableRipple
                    >
                      <Box
                        className=""
                        width={{ xs: "100%", sm: "100%" }}
                        height={{ xs: "45px", sm: "45px" }}
                        sx={{
                          border: 1,
                          borderColor: "#D9D9D9",
                          borderRadius: 1,
                          px: 2,
                        }}
                        alignItems="center"
                        display="flex"
                        gap={2}
                      >
                        <Typography
                          variant="subtitle1"
                          fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                          color={"primary"}
                          gutterBottom
                          align="left"
                          justifyContent="start"
                          width={{ xs: "100%", sm: "100%" }}
                          my={3}
                        >
                          {accountDetails?.AccountNumber}
                        </Typography>

                        <CopyIcon />
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box className="fullCenter " px={3} height={"300px"}>
              <Typography
                variant="subtitle1"
                color={"#6B6B6B"}
                fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                width={{ xs: "80%", sm: "80%" }}
                gutterBottom
                align="center"
                justifyContent="center fullCenter"
              >
                Please wait while we load your account details
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </MHDialog>
  );
};

export default AccountDialog;
