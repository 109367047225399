import { Box, Typography } from "@mui/material";
import React from "react";
import ContractSection from "./ContractItems/ContractSection";

import ContractSideBar from "./ContractItems/ContractSideBar";
import ContractDialog from "./ContractItems/ContractDialog";
import EmployeeContractDialog from "./ContractItems/EmployeeContractDialog";
import { useOnboardForm } from "../../store/context/onboard-context";
import ContractSignPad from "./ContractItems/ContractSignPad";
import TemplateDialog from "./ContractItems/TemplateDialog";
import { useHistory } from "react-router-dom";
import MHEditor from "../../components/Common/Editor/MHEditor";
import EmployeeNameDialog from "./ContractItems/EmployeeNameDialog";
import SnackbarContext from "../../store/context/snackbar.context";

type Props = {};

const Contract = (props: Props) => {
  const [contractState, setContractState] = React.useState(false);
  // const [employeeNameState, setEmployeeNameState] = React.useState(false);
  // let history = useHistory();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { addToContract, contractForm } = useOnboardForm();

  const handleOpenContract = () => {
    // console.log("contractState", contractState);
    if (contractForm.employerSignature === "") {
      // console.log("Complete Form");
      toast({
        message: "Please add Signature to document",
        variant: "warning",
      });
      return;
    }
    if (contractForm.employeeName === "") {
      // console.log("Complete Form");
      toast({
        message: "Please add Employee Name to document",
        variant: "warning",
      });
      return;
    }
    setContractState(true);
  };
  const handleCloseContract = () => {
    // console.log("contractState", contractState);
    setContractState(false);
  };

  // const handleOpenEmployee = () => {
  //   // console.log("employeeNameState", employeeNameState);
  //   setEmployeeNameState(true);
  // };
  // const handleCloseEmployee = () => {
  //   // console.log("employeeNameState", employeeNameState);
  //   setEmployeeNameState(false);
  // };

  const handleSignState = () => {
    // console.log("employeeState", employeeState);
    // setEmployeeState(false);
    addToContract({
      signState: true,
      contractInvite: "process",
    });
  };

  return (
    <React.Fragment>
      <Box className="bg-[#F9F9F7] h-full w-full md:grid md:grid-cols-10 md:space-x-3 lg:space-x-10">
        <Box className=" order-last md:order-none my-0 lg:my-8 pt-10 lg:pb-4 px-0 lg:px-4 bg-white w-full h-full md:h-fit lg:h-fit md:col-span-10 lg:col-start-2 lg:col-span-6 ">
          {/* <Typography
            variant="h3"
            align="center"
            color={"primary"}
            fontSize={{ xs: "16px", sm: "24px", md: "24px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            gutterBottom
            className="py-2 lg:py-8"
          >
            Employee Services Agreement
          </Typography> */}

          <ContractSection />
        </Box>

        <Box className=" pb-10 bg-white w-full h-fit md:w-full lg:w-[300px] md:h-fit col-span-4 md:col-start-2 md:col-span-8 lg:col-span-3">
          <ContractSideBar
            handleSignState={handleSignState}
            handleSaveContract={handleOpenContract}
            // formState={formState}
            // handleContract={handleContractOpen}
            // employeeState={employeeState}
            // handleEmployee={handleEmployeeOpen}
            // sendContractInvite={sendContractInvite}
          />
        </Box>
      </Box>
      {/* <EmployeeContractDialog
        open={employeeState}
        onClose={handleEmployeeClose}
      /> */}
      <TemplateDialog open={contractState} onClose={handleCloseContract} />
      {/* <EmployeeNameDialog
        open={employeeNameState}
        onClose={handleCloseEmployee}
      /> */}
      {contractForm.signState && (
        <ContractSignPad
        // handleEmployeeName={handleOpenEmployee}
        />
      )}
    </React.Fragment>
  );
};

export default Contract;
