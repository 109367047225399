import React from "react";
import MHButton from "../../Common/Button/MHButton";
import { Box, Typography } from "@mui/material";
import MHDialog from "../../Common/Dialog/MHDialog";

import { ReactComponent as RightIcon } from "../../../static/svg/arrow-right-white.svg";

import * as animationData from "../../../static/json-animate/safe.json";

import Lottie from "react-lottie";

interface CreateAccountDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateAccountDialog: React.FC<CreateAccountDialogProps> = ({
  open,
  onClose,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <MHDialog
      open={open}
      title={`  Account Creation`}
      handleClose={onClose}
      maxWidth="md"
      // actions={}
      scroll="paper"
      sx={{ textAlign: "center" }}
    >
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "320px" }}
        width={{ xs: "full", sm: "403px" }}
        className=" ml-0 mr-0 place-content-center align-middle items-center mx-auto "
      >
        <Box
          display="flex"
          alignItems="center"
          // alignSelf="center"
          // alignContent="center"
          justifyContent="center"
          mt={6}
        >
          <Box className="w-fit">
            <Lottie options={defaultOptions} height={200} width={200} />
          </Box>
        </Box>

        <Box className="centerItems">
          <Typography
            variant="body1"
            fontSize={{ xs: "18px", sm: "18px", md: "18px" }}
            textAlign={{ xs: "center", md: "center" }}
            width={{ xs: "200px", sm: "200px" }}
            lineHeight={"19px"}
            component="div"
            gutterBottom
            mb={1.5}
            className="centerItems text-center "
          >
            Please wait
          </Typography>
        </Box>
        <Box className="centerItems">
          <Typography
            variant="body1"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            width={{ xs: "300px", sm: "300px" }}
            lineHeight={"19px"}
            align="center"
            component="div"
            gutterBottom
            mb={1.5}
            className="centerItems "
          >
            Please be patient while we set up your account. This process may
            take a few moments. Thank you for your understanding.
          </Typography>
        </Box>

        {/* <Box display="flex" justifyContent="center">
          <Box my={4} width={{ xs: "100%", sm: "256px" }}>
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className="  "
              fullWidth
              endIcon={<RightIcon />}
              onClick={onClose}

              // onClick={() =>
              //   setWithdrawActiveStep((prevActiveStep) => prevActiveStep - 1)
              // }
            >
              Contact Now
            </MHButton>
          </Box>
        </Box> */}
      </Box>
    </MHDialog>
  );
};

export default CreateAccountDialog;
