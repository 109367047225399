import { Box, Button, Typography } from "@mui/material";
import React from "react";

type Props = {
  handleAdd?: (label: string) => void; // Accepts a string argument
  label?: string;
  data?: string;
};

const SectionCard = (props: Props) => {
  const handleButtonClick = () => {
    // Ensure props.handleAdd is defined before calling it
    if (props.handleAdd && props.label) {
      props.handleAdd(props.label); // Pass the label as an argument
    }
  };
  return (
    <React.Fragment>
      <Box className="pt-5 flex place-content-between">
        <Button className="no-underline" onClick={handleButtonClick}>
          <Typography
            variant="subtitle1"
            color={"primary"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=" text-start"
          >
            + Add Section
          </Typography>
        </Button>
        {/* Left side */}
        <Box></Box>
      </Box>
      <Box className="w-full h-fit p-5 bg-[#F9F9F9] border-2 border-[#D9D9D9]">
        {/* <p className=" font-areaBlack text-sm pb-2">1. Scope</p>
        <ul className=" font-areaSemi space-y-2">
          <li className=" font-areaSemi">
            a. Driver agrees to provide driving services for Company as required
            during the term of this Agreement.
            <div className=" indent-5 space-y-1">
              <li className=" font-areaSemi">
                ~ Transporting Company employees, clients, or goods to specified
                destinations.
              </li>
              <li className=" font-areaSemi">
                ~ Ensuring the safety and comfort of passengers during transit.
              </li>
              <li className=" font-areaSemi">
                ~ Adhering to all traffic laws and regulations while operating
                the vehicle.
              </li>
              <li className=" font-areaSemi">
                ~ Maintaining the cleanliness and proper maintenance of the
                vehicle used for transportation.
              </li>
              <li className=" font-areaSemi">
                ~ Providing assistance with loading and unloading of goods if ~
                applicable.
              </li>
            </div>
          </li>
          <li className=" font-areaSemi">
            b. The driving services shall include but not be limited to:
          </li>
          <li className=" font-areaSemi">
            c. Driver shall perform the services diligently and professionally,
            exercising reasonable skill and care at all times.
          </li>
        </ul> */}
        {props.data && <div dangerouslySetInnerHTML={{ __html: props.data }} />}
      </Box>
    </React.Fragment>
  );
};

export default SectionCard;
