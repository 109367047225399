import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

import MHButton from "../../Common/Button/MHButton";

// import { ReactComponent as DownloadIcon } from "../../../static/svg/downloads.svg";
// import { ReactComponent as Instagram } from "../../../static/svg/instagram.svg";
// import { ReactComponent as Facebook } from "../../../static/svg/facebook.svg";
// import { ReactComponent as LinkedIn } from "../../../static/svg/linkedin-icon.svg";
// import { ReactComponent as Pinterest } from "../../../static/svg/pinterest.svg";
// import { ReactComponent as Twitter } from "../../../static/svg/twitter.svg";
import { Link } from "react-router-dom";
import { ItemList } from "../../../models/resources.interface";

type HeaderProps = {
  titles?: string;
  description?: string;
  imageUrl?: string;
  headerClassName?: string;
  headerDateClass?: string;
  downloadClassName?: string;
  imageClass?: string;
  ticketClassName?: string;
  writtenBy?: string;
  bottomDateClass?: string;
  authorClassName?: string;
  SocialClassName?: string;
  TabSocialClass?: string;
  categoryClassName?: string;
  downloadLink?: string;
  ticketLink?: string;
  categoryOne?: string;
  category?: ItemList[] | undefined;
  date?: string | undefined;
  startTime?: string | undefined;
  endTime?: string | undefined;
  dateTwo?: string | undefined;
  onClick?: string;
  dateClassName?: string;
  podClassName?: string;
  episode?: string;
  season?: string;
  episodeClassName?: string;
  event?: boolean;
};

const ViewHeader = (props: HeaderProps) => {
  return (
    <Fragment>
      <Grid container spacing={0} className="relative overflown-hidden">
        <Grid item xs={12} sm={6.5} md={6.5} lg={6.5}>
          <Box className={props.headerClassName}>
            <Typography
              variant="body2"
              className={props.headerDateClass}
              color="primary"
            >
              {props.dateTwo}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              className="text-left my-6 w-4/4 font-columbia text-3xl capitalize font-[500]"
            >
              <Typography
                variant="body2"
                className={props.dateClassName}
                color="primary"
              >
                {props.date}
              </Typography>
              <Typography
                variant="body2"
                className={props.episodeClassName}
                color="primary"
              >
                Episode {props.season}.{props.episode}
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                className="md:text-left lg:leading-[120.5%] text-center h-fit md:w-[98%] lg:w-[98%] lg:line-clamp-4 md:line-clamp-4 line-clamp-6 font-columbia text-2xl md:text-xl lg:text-[32px] capitalize font-[500]"
                // h-28 md:h-24 lg:h-36
              >
                {props.titles}
              </Typography>
            </Typography>
            <Typography
              variant="body2"
              className="md:text-left pt-6 md:pt-0 lg:pt-2  text-center lg:w-[493px] md:w-[85%] text-[14px] line-clamp-6 md:line-clamp-3 font-areaSemi"
              color="primary"
            >
              {props.description}
            </Typography>
            {props.event && (
              <Box className="block md:hidden text-center py-5">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="p"
                  fontSize={{ xs: "20px", sm: "14px", md: "20px" }}
                >
                  {props.startTime} - {props.endTime} EST
                </Typography>
              </Box>
            )}

            <Box className={props.ticketClassName}>
              <MHButton
                onClick={() => {
                  window.open(props.ticketLink);
                }}
                sx={{ width: "155px" }}
              >
                Reserve A Seat
              </MHButton>
            </Box>

            <Box className={props.podClassName}>
              <button
                onClick={() => {
                  window.open(
                    "https://open.spotify.com/show/54t1C1cw8TeS2UK4doDTL4"
                  );
                }}
              >
                <img
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612804/spotify-pod_jyslea.png"
                  className="h-8 md:h-7 lg:h-10 md:w-fit absolute"
                  alt="Resources Image"
                />
              </button>
              <button
                onClick={() => {
                  window.open(
                    "https://podcasts.apple.com/us/podcast/mother-honestly-podcast/id1439395271"
                  );
                }}
              >
                <img
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/apple-pod_yzrrsw.png"
                  className="h-8 md:h-7 lg:h-10 md:w-fit absolute ml-24 md:-ml-5 lg:-ml-2"
                  alt="Resources Image"
                />
              </button>

              <button
                onClick={() => {
                  window.open(
                    "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL21vdGhlcmhvbmVzdGx5L2ZlZWQueG1s"
                  );
                }}
              >
                <img
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/google-pod_eavovx.png"
                  className="h-8 md:h-7 lg:h-10 md:w-fit absolute ml-[230px] md:ml-0 lg:ml-8"
                  alt="Resources Image"
                />
              </button>
            </Box>

            <Box className={props.authorClassName} color="primary">
              BY {props.writtenBy}
            </Box>

            <Box className={props.categoryClassName}>
              <Box className="lg:py-9 md:py-2 py-12">
                <Typography
                  className="text-[15px] text-center md:text-left font-areaExt uppercase text-navy-900"
                  gutterBottom
                  variant="h5"
                >
                  {props.categoryOne}
                </Typography>
                <Typography
                  variant="body2"
                  className="text-center md:text-left py-2 md:py-0 lg:py-2 text-[12px] opacity-50 capitalize font-areaSemi"
                  color="primary"
                >
                  {props.categoryOne ? (
                    <div
                      className="text-[14px] capitalize truncate text-navy-900 font-areaSemi"
                      // size="small"
                      // disabled
                    >
                      {props
                        .category!.map((item) => item.interestName)
                        .join(", ")}
                    </div>
                  ) : null}
                </Typography>
              </Box>

              <Box className={props.bottomDateClass}>
                <Typography
                  variant="body2"
                  className="lg:absolute text-left my-2 hidden md:block text-[12px] uppercase font-areaExt"
                  color="primary"
                >
                  {props.dateTwo}
                </Typography>
              </Box>

              {props.event && (
                <Box className="hidden md:block">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="p"
                    fontSize={{ xs: "20px", sm: "16px", md: "22px" }}
                  >
                    {props.startTime} - {props.endTime} EST
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} className="relative">
          <Box className="bg-green-100 h-96 w-full viewImage md:h-[375.83px] lg:h-[600px] lg:w-full object-cover">
            <img
              src={props.imageUrl}
              alt="Resources Image"
              className={props.imageClass}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ViewHeader;
