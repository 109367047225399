import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CheckStar } from "../../../static/svg/checkstar.svg";

import Lottie from "react-lottie";

import * as animationData from "../../../static/json-animate/Training.json";
import { CourseType } from "../Training/TrainingCard";
import dayjs from "dayjs";
import MHButton from "../../Common/Button/MHButton";
import AuthContext from "../../../store/context/auth-context";
import { convertTo12Hour } from "../../../utils/utils";

type Props = {
  training: CourseType | null;
};

const ReserveTrainingCard = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const objt = props.training?.objectives?.split(",");
  // console.log("obj", obj);
  const obj = objt?.slice(0, 2);

  // const obj = props.training?.objectives?.slice(0, 2) || [];

  const handleReserve = () => {
    console.log("Reserved");
  };

  return (
    <React.Fragment>
      <Box className="w-[400px] h-[530px] md:w-[850px] md:h-[530px] lg:w-[950px] lg:h-[575px] grid grid-cols-12">
        {/* Grid 1 */}
        <Box className="bg-white pl-8 col-span-12 md:col-span-5 lg:col-span-7 hidden md:block">
          <Typography
            variant="body1"
            mt={5}
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            alignSelf={"start"}
            fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
            className=""
          >
            Hi, <strong className=" font-areaBlack">{user?.firstName}</strong>
          </Typography>

          <Typography
            variant="subtitle1"
            // mt={5}
            mb={3}
            color="#194049"
            gutterBottom
            // display={"flex"}
            alignSelf={"start"}
            fontSize={{ xs: "22px", sm: "20px", md: "22px" }}
            className=" w-[70%]"
          >
            Welcome to Your New Learning Journey!
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"start"}
            // py={5}
            className=" md:mr-[180px] lg:mr-[350px]"
          >
            <Lottie options={defaultOptions} height={"220px"} width={"220px"} />
          </Box>

          <Typography
            variant="body1"
            mt={6}
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
            className=" w-[75%] "
          >
            Your employer has assigned a course for you to complete. After you
            successfully finish each level, you will receive a Caring Block
            Certificate.
          </Typography>
        </Box>

        {/* Grid 2 */}
        <Box className=" shadow-md col-span-12 md:col-span-7 lg:col-span-5 w-[90%] md:w-[330px]">
          <Box
            className={`${
              props.training?.trainingMode?.toLowerCase() === "in-person"
                ? "bg-[#F1F5F0]"
                : props.training?.trainingMode?.toLowerCase() === "on-the-job"
                ? "bg-[#FFF6EA]"
                : props.training?.trainingMode?.toLowerCase() === "online"
                ? "bg-[#E9F2F9]"
                : null
            }   w-[85%] md:w-[330px] h-[200px] rounded-t-lg`}
          >
            <Box
              className={`${
                props.training?.trainingMode?.toLowerCase() === "in-person"
                  ? "bg-[#D2E0CB]"
                  : props.training?.trainingMode?.toLowerCase() === "on-the-job"
                  ? "bg-[#E3CEAE]"
                  : props.training?.trainingMode?.toLowerCase() === "online"
                  ? "bg-[#C7D8E4]"
                  : null
              } w-full h-[95px]  rounded-t-lg pl-5`}
              alignItems={"center"}
            >
              <Typography
                variant="subtitle1"
                pt={2}
                // mb={1}
                color="#194049"
                // gutterBottom
                // display={"flex"}
                alignSelf={"start"}
                fontSize={{ xs: "11px", sm: "11px", md: "11px" }}
                className=""
              >
                Training :
              </Typography>
              <Typography
                variant="subtitle1"
                // mt={5}
                // mb={3}
                color="#194049"
                // gutterBottom
                // display={"flex"}
                alignSelf={"start"}
                fontSize={{ xs: "15px", sm: "15px", md: "16px" }}
                className=" w-full"
              >
                {props.training?.title}
              </Typography>
            </Box>
            <Box className="pt-3">
              {obj &&
                obj.map((item, index) => (
                  <Box
                    className="ml-5 w-[90%] flex items-center my-3"
                    key={index}
                  >
                    <Box className="">
                      <CheckStar />
                    </Box>
                    <Box className=" mr-auto ml-5">
                      <Typography
                        variant="body1"
                        fontSize={{ xs: "14px", sm: "14px" }}
                        // align="center"
                        // mt={3}
                        gutterBottom
                        // display={"flex"}
                        // alignSelf={"center"}
                      >
                        {item}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>

          <Box ml={{ xs: 0, sm: 3 }}>
            <Box>
              <Typography
                variant="body1"
                mt={5}
                mb={2}
                color="#194049"
                gutterBottom
                // display={"flex"}
                alignSelf={"start"}
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                className=""
              >
                Instructor:
                <strong className=" font-areaBlack ml-5">
                  {props.training?.instructor}
                </strong>
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                //   mt={5}
                mb={2}
                color="#194049"
                gutterBottom
                //   display={"flex"}
                //   justifyContent={"space-between"}
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                className=""
              >
                Date:
                <strong className=" font-areaBlack ml-5">
                  {/* {props.training?.startDate} */}
                  {dayjs(props.training?.createdDate).format("MMMM D, YYYY")}
                </strong>
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                //   mt={5}
                mb={2}
                color="#194049"
                gutterBottom
                //   display={"flex"}
                //   justifyContent={"space-between"}
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                className=""
              >
                Time:
                <strong className=" font-areaBlack ml-5">
                  {/* {props.training?.startDate} */}
                  {convertTo12Hour(props.training?.startDate)}
                </strong>
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"start"}
                //   alignItems={"center"}
                // py={5}
                //   className=" mr-[350px]"
              >
                <AvatarGroup total={24}>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/1.jpg"
                  />
                  <Avatar
                    alt="Travis Howard"
                    src="https://mui.com/static/images/avatar/2.jpg"
                  />
                  <Avatar
                    alt="Travis Howard"
                    src="https://mui.com/static/images/avatar/3.jpg"
                  />
                  <Avatar
                    alt="Agnes Walker"
                    src="https://mui.com/static/images/avatar/4.jpg"
                  />
                  <Avatar
                    alt="Trevor Henderson"
                    src="https://mui.com/static/images/avatar/5.jpg"
                  />
                </AvatarGroup>

                <Typography
                  variant="body1"
                  mt={2}
                  ml={0.5}
                  // mb={2}
                  color="#A9A9A9"
                  gutterBottom
                  // display={"flex"}
                  alignSelf={"start"}
                  fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                  className=""
                >
                  more employees
                </Typography>
              </Box>
              <Typography
                variant="body1"
                mt={2}
                // mb={2}
                color="#194049"
                gutterBottom
                // display={"flex"}
                alignSelf={"start"}
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                className=""
              >
                Join other employees just like you
              </Typography>

              <Box className="pt-4">
                <MHButton
                  // type="submit"
                  form="reserve-form"
                  onClick={() =>
                    window.open(
                      // "https://open.spotify.com/show/54t1C1cw8TeS2UK4doDTL4"
                      props.training?.address
                    )
                  }
                  // loading={loading}
                  // fullWidth
                  disabled={
                    props.training?.trainingMode?.toLowerCase() !== "online"
                      ? true
                      : false
                  }
                  sx={{
                    height: 40,
                    width: 225,
                  }}
                  className="rounded-sm "
                >
                  {props.training?.trainingMode?.toLowerCase() === "online"
                    ? "Watch Now"
                    : "ASSIGNED"}
                </MHButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ReserveTrainingCard;
