import React, { Fragment, useEffect, useState } from "react";
import ViewHeader from "../SubComponents/ViewHeader";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import ResCard from "../SubComponents/ResCard";
import { useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Slider from "react-slick";

import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray, convertUTCToDate } from "../../../utils/utils";
import shuffleArray from "../../../hooks/use-random";
import useTitle from "../../../hooks/use-title";

type ComponentProps = {
  thumbNailImageSrc?: string;
  author?: string;
  itemList?: string[];
  title?: string;
  tops?: string;
  content?: string;
  catchPhrase?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const ViewArticle = (props: ComponentProps) => {
  useTitle("Articles");

  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);

  const [noOfElement, setnoOfElement] = useState(4);
  const slice = resources.slice(0, noOfElement);

  const params = useParams<any>();

  var resUrl = `${process.env.REACT_APP_RES_ARTICLE_URL}`;
  var viewUrl = `${process.env.REACT_APP_ALL_RES_VIEW_URL}${params.id}`;

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      // Shuffle data to display randomly
      const randomizedData = shuffleArray(formattedResources); // Randomize the filtered array
      setResources(randomizedData);

      // setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  const [data, setData] = useState<ResourceItemType | null>(null);

  const getData = async () => {
    try {
      const response = await fetch(viewUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data }: HttpResponse<ResourceItemType> = await response.json();

      const priorityList: string[] = data.priorities.split(",");
      let newItemList: ItemList[] = [];

      for (const item of priorityList) {
        const newObject = data.itemList.find((it) => it.id === +item);

        if (newObject) {
          if (!newItemList.length) {
            newItemList = [newObject];
          } else {
            newItemList = [...newItemList, newObject];
          }
        }
      }

      const formattedResource: ResourceItemType = {
        ...data,
        newItemList,
      };

      setData(formattedResource);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: "22px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  useEffect(() => {
    getData();
    getResource();
  }, [params.id]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <ViewHeader
        titles={data.title}
        headerClassName="bg-lilac-300 w-full h-full px-4 md:px-0 md:pt-2 lg:pt-6 pt-6 md:pl-12 lg:pl-20 relative"
        description={data.content}
        headerDateClass="text-center block md:hidden mt-12 text-[12px] uppercase font-areaExt"
        imageUrl={data.thumbNailImageSrc}
        // imageUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661545700/image_hsichu.png"
        imageClass="overflow-hidden h-full w-full right-0 absolute"
        categoryClassName="lg:bottom-6 lg:absolute md:relative md:-bottom-10 mb-14 md:w-2/3"
        category={data.newItemList}
        categoryOne={
          checkInvalidArray(data.newItemList).length
            ? data.newItemList[0].interestName
            : ""
        }
        writtenBy={data.author}
        authorClassName="text-center md:text-left pt-12 md:pt-4 lg:pt-12 opacity-[0.3] tracking-wider text-[12px] mb-8 md:mb-0 uppercase font-areaExt"
        ticketClassName="py-6 hidden"
        SocialClassName="block"
        TabSocialClass="absolute gap-4 bottom-4 text-center place-content-center hidden md:flex lg:hidden md:right-4"
        podClassName="mt-10 flex gap-32 hidden"
        downloadClassName=" hidden flex -ml-4 my-6"
        date={dayjs(data.createdDate).format("DD/MM/YYYY h:mm")}
        dateTwo={convertUTCToDate(data.startTime)}
        // dateTwo={data.createdDate}
        dateClassName="hidden text-left pb-2 w-3/4 text-base font-areaSemi"
        episodeClassName="hidden"
      />

      <Box className="px-5 md:px-20 lg:px-[300px] py-10 bg-white">
        <Typography
          variant="h3"
          color="primary"
          className="text-3xl w-fit md:w-[90%] text-start font-columbia font-[500]"
        >
          {data.catchPhrase}
        </Typography>
        <img
          src={data.source}
          // src="https://res.cloudinary.com/kehinde-motherhoneestly/image/upload/v1669895883/MHresources/body2_ia9rqh.png"
          alt="Resource Image"
          className="mx-auto my-6 bg-navy-100 w-full h-[257px] object-cover bodyImage lg:w-full lg:h-[630px] md:h-[456px]"
        />

        {/* Article Content */}
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      </Box>
      <Box className="bg-gray-300 h-[1px] w-[91.4%] opacity-50 overflow-hidden mx-auto"></Box>
      {/* For Web */}
      <Box className="mx-auto pt-10 bg-white hidden md:block px-20 py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-12"
        >
          Articles You Might like
        </Typography>
        <Grid container spacing={2}>
          {slice.map((res, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={res.id}>
              <ResCard
                cardClass="relative w-[305px] mb-10 h-[500px] object-cover bg-cream-100 rounded-md shadow-sm"
                iconClass="hidden absolute top-10 ml-20 mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                imgBg="bg-navy-100 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                title={res.title}
                text={res.catchPhrase}
                podTop="hidden"
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/articles/${res.slug}/${res.id}`}
                playUrl={`/resources/articles/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* For Mobile */}
      <Box className="mx-auto pt-10 md:hidden block bg-white px-4 py-4 overflow-x-hidden">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-12"
        >
          Articles You Might like
        </Typography>
        <Slider {...settings}>
          {resources.map((res, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <ResCard
                cardClass="relative w-[305px] mb-10 h-[500px] object-cover bg-cream-100 rounded-md shadow-sm"
                iconClass="hidden absolute top-10 ml-20 mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                imgBg="bg-navy-100 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                title={res.title}
                text={res.catchPhrase}
                podTop="hidden"
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/articles/${res.slug}/${res.id}`}
                playUrl={`/resources/articles/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Slider>
      </Box>
      {/* <MHSubscribe />
      <MHFooter /> */}
    </Fragment>
  );
};

export default ViewArticle;
