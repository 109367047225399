import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas, {
  ReactSignatureCanvasProps,
} from "react-signature-canvas";

import MHDialog from "../../../components/Common/Dialog/MHDialog";
import { useOnboardForm } from "../../../store/context/onboard-context";
import { Box, Button, Typography } from "@mui/material";
import DashboardContext from "../../../store/context/dashboard.context";

type Props = {
  // handleEmployeeName: () => void | any;
};

const ContractSignPad = (props: Props) => {
  const signCanvas = useRef<ReactSignatureCanvas>(null);
  const [penColor, setPenColor] = useState("black");
  const colors = [
    "#194049",
    "#F8F246",
    "#C7D8E4",
    "#FFDA9C",
    "#D2E0CB",
    "#E3CEAE",
    "#C27171",
  ];

  const { addToContract, contractForm } = useOnboardForm();
  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const handleClear = () => {
    if (signCanvas.current) {
      signCanvas.current.clear();
    }
  };

  const nameOfEmployer = `${organization?.firstName} ${organization?.lastName}`;

  const createSignature = () => {
    if (signCanvas.current) {
      const URL = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      //   setImageURL(URL);
      addToContract({
        employerName: nameOfEmployer,
        employerRefId: organization?.employerRefId,
        contractInvite: "process",
        employerSignature: URL,
        signState: false,
      });
      // props.handleEmployeeName();
    }
  };

  const onClose = () => {
    addToContract({
      signState: false,
    });
  };

  return (
    <React.Fragment>
      <MHDialog
        title="Add A Signature"
        open={contractForm.signState}
        handleClose={onClose}
        maxWidth={"sm"}
        scroll="paper"
        //   actions={
        //     <MHButton
        //       type="submit"
        //       form="add-employee-form"
        //       loading={loading}
        //       fullWidth
        //     >
        //       Submit
        //     </MHButton>
        //   }
        fullWidth
      >
        <div className="modalContainer">
          <div className="signmodal">
            <div className="signPad__penColors">
              {colors.map((color) => (
                <span
                  key={color}
                  style={{
                    backgroundColor: color,
                    border: `${color === penColor ? `2px solid ${color}` : ""}`,
                  }}
                  onClick={() => setPenColor(color)}
                ></span>
              ))}
            </div>
            <div className="signmodal__bottom">
              <div className=" signPadContainer">
                <SignatureCanvas
                  penColor={penColor}
                  canvasProps={{ className: " signCanvas" }}
                  ref={signCanvas}
                />
              </div>

              <Box mt={6} className=" signButtons">
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  color="primary"
                  onClick={handleClear}
                >
                  Reset
                </Button>

                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  color="primary"
                  onClick={createSignature}
                >
                  Add to Document
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </MHDialog>
    </React.Fragment>
  );
};

export default ContractSignPad;
