import React from "react";
import { ReactComponent as CloseIcon } from "../../../static/svg/cancel.svg";
import { Typography, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";

const PlainDialog = ({
  children,
  open,
  title,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children?: React.ReactNode;
}) => {
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width: 640px)");
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");

  let maxDialogHeight = "calc(100vh - 120px)"; // Default height for sm and md screens
  let dialogTopMargin = "10vh"; // Default top margin

  if (isLargeScreen) {
    maxDialogHeight = "calc(100vh - 20px)"; // Increased height for lg screens
    dialogTopMargin = "10vh"; // Adjusted top margin for lg screens
  }

  return (
    <React.Fragment>
      {open && (
        <div className="fixed inset-0 overflow-hidden z-50 flex justify-center items-center ">
          <div className="fixed inset-0 bg-navy-900 opacity-50"></div>

          <div
            className={`relative bg-white rounded-lg h-auto ${
              isSmallScreen ? "w-full" : "w-auto"
            } ${isLargeScreen ? "w-[70%]" : "w-[90%]"}`}
            style={{ marginTop: dialogTopMargin }}
          >
            {/* {title && (
              <div className="py-1 items-center px-6 bg-white text-center drop-shadow-xl">
                <Typography
                  variant="h1"
                  fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
                  component="div"
                  gutterBottom
                  // mb={5}
                  className="pt-6"
                >
                  {title}
                </Typography>
              </div>
            )} */}

            <div
              className=" w-full overflow-x-hidden overflow-y-auto"
              style={{ maxHeight: maxDialogHeight }}
            >
              {title && title !== "" ? (
                <div className="relative w-full items-center  bg-white text-center ">
                  <Typography
                    variant="h1"
                    fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
                    component="div"
                    gutterBottom
                    // mb={5}
                    className="py-4"
                  >
                    {title}
                  </Typography>

                  <div className="absolute top-3 right-0 p-2">
                    <button onClick={handleClose}>
                      <CloseIcon className="h-6 w-6 text-navy-900" />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="absolute top-0 md:top-3 right-0 p-2">
                    <button onClick={handleClose}>
                      <CloseIcon className="h-6 w-6 text-navy-900" />
                    </button>
                  </div>
                  <br />
                </>
              )}

              <div className="p-6 ">{children}</div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PlainDialog;
