import React, { Fragment } from "react";

import {
  MuiTelInput,
  MuiTelInputProps,
  MuiTelInputCountry,
  MuiTelInputInfo,
  MuiTelInputContinent,
  matchIsValidTel,
} from "mui-tel-input";
import { styled } from "@mui/material/styles";
import Label from "../Form/Label";
import ErrorTip from "../Form/ErrorTip";

const StyledPhoneInput = styled(MuiTelInput)(({ theme }) => ({
  "& fieldset": {
    // fieldset creates an outline around input, better to stay hidden
    width: 0,
    display: "none",
  },

  [`& .MuiOutlinedInput-root`]: {
    borderRadius: 0,
    display: "flex",
    border: "1px solid #E0E3E7",
    alignItems: "center",
    transition: "all 0.4s ease-in",
    position: "relative",
    boxShadow: "none",
    outline: 0,
    backgroundColor: "#EEEEEE",
  },

  "& .MuiOutlinedInput-input": {
    fontSize: "0.75rem",
    lineHeight: 1.5,
    flexGrow: 1,
    color: "#1A2027",
    background: "inherit",
    border: "none",
    borderRadius: "inherit",
    // padding: '12px 12px',
    outline: 0,
    height: "1rem",
    backgroundColor: "#fff",
  },

  "&.MuiTelInput-TextField > .Mui-focused": {
    // border: '1px solid #A0AAB4',
    outline: 0,
    boxShadow: "none",
  },

  ".MuiTelInput-Menu": {
    // height: 300,
    maxHeight: 400,
  },
  "& .Mui-focused, & .Mui-focused > & .MuiInput-root.MuiInput-formControl": {
    border: "1px solid #A0AAB4",
    outline: 0,
    boxShadow: "none",
  },

  "& .MuiInput-root.Mui-focused": {
    // border: '1px solid #CDD2D7'
  },

  "& .MuiInput-root:hover": {
    // borderColor: '#CDD2D7'
  },
  width: "100%",
  marginBlockEnd: 24,
}));

type MHPhoneInputProps = {
  errorMessage?: string;
};

const MHPhoneInput = (props: MuiTelInputProps & MHPhoneInputProps) => {
  const continents: MuiTelInputContinent[] = [];
  const excludedCountries: MuiTelInputCountry[] = [];

  const inputChangeHandler = (newValue: string, info: MuiTelInputInfo) => {
    const valid = matchIsValidTel(newValue);
    props.onChange && props.onChange(newValue, info);
  };

  return (
    <Fragment>
      {/* continents={continents}
        excludedCountries={excludedCountries} */}
      {props.label && <Label>{props.label}</Label>}

      <StyledPhoneInput
        defaultCountry="NG"
        {...props}
        label={null}
        value={props.value}
        onChange={inputChangeHandler}
        sx={{}}
        MenuProps={{
          sx: {
            "& .MuiPaper-root": {
              maxHeight: 400,
            },
          },
        }}
      />
      {props.error && (
        <ErrorTip
          style={{
            position: "relative",
            top: "-1rem",
          }}
        >
          {props.error}
        </ErrorTip>
      )}
    </Fragment>
  );
};

export default MHPhoneInput;
