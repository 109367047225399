import React from "react";
import ProfileSubHeader from "./ProfileSubHeader";
import { Box, Divider } from "@mui/material";
import { EmployeeData } from "../../../models/employee.model";

type EmployeeProps = {
  employee: EmployeeData | null;
};

const PerformanceView = ({ employee }: EmployeeProps) => {
  return (
    <React.Fragment>
      <Divider />

      <ProfileSubHeader employee={employee} />
      <Box>....Performance Coming Soon</Box>
    </React.Fragment>
  );
};

export default PerformanceView;
