import React from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import MHFormControl from "../../../components/Common/Form/MHFormControl";
import MHButton from "../../../components/Common/Button/MHButton";
import InputAdornment from "../../../components/Common/Form/InputAdornment";
import useHttp from "../../../hooks/use-http";

import { ReactComponent as GoogleIcon } from "../../../static/svg/google-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../../static/svg/whatsapp-grey.svg";
import { ReactComponent as EmployerColoredIcon } from "../../../static/svg/categories/employer-color.svg";
import { ReactComponent as EmployerGreyIcon } from "../../../static/svg/categories/employer-grey.svg";
import { ReactComponent as StaffColoredIcon } from "../../../static/svg/categories/staff-color.svg";
import { ReactComponent as StaffGreyIcon } from "../../../static/svg/categories/staff-grey.svg";

import * as formReducer from "../../../store/reducers/form";
import * as validators from "../../../utils/validators";
// import AuthContext from "../../../store/context/auth-context";
// import NotificationContext from "../../../store/context/notifications.context";
import { Button, Divider } from "@mui/material";
import MHCheckbox from "../../../components/Common/Form/MHCheckbox";
import useCheckbox from "../../../hooks/use-checkbox";
import { useOnboardForm } from "../../../store/context/onboard-context";
import SnackbarContext from "../../../store/context/snackbar.context";
import {
  decrypt,
  encrypt,
  formatAmount,
  getURLWithQueryParams,
  resolveErrorMessage,
} from "../../../utils/utils";
import MHAutocomplete, { Option } from "../Form/MHAutocomplete";
import useInput from "../../../hooks/use-input";
import { SUBS_TYPE } from "../../../utils/constants";
import MHPhoneInput from "../Form/MHPhoneInput";
import usePhoneInput from "../../../hooks/use-phone";
import { SubscriptionData } from "../../../models/subscription.model";
import { HttpResponse } from "../../../models/api.interface";

const RoleSelection = () => {
  const {
    value: enteredSubs,
    valid: enteredSubsIsValid,
    error: subsInputHasError,
    onChange: subsInputChangeHandler,
    onBlur: subsInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredSubsDuration,
    valid: enteredSubsDurationIsValid,
    error: subsDurationInputHasError,
    onChange: subsDurationInputChangeHandler,
    onBlur: subsDurationInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const { checked, handleCheck } = useCheckbox(false);

  const subs = localStorage.getItem("subscriptionType");
  let finalSubType = "";

  if (!subs) {
    finalSubType = "";
  } else {
    const subsType = JSON.parse(decrypt(subs));
    finalSubType = subsType.subscriptionType;
  }

  // console.log("phoneNumber", phoneNumber);
  // console.log("enteredSubs", enteredSubs);
  // console.log("finalSubType", finalSubType);
  // const notificationCtx = React.useContext(NotificationContext);
  // const { pushNotification, popNotification } = notificationCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  const [activeButton, setActiveButton] = React.useState("employer");
  // put "" if you want to toggle activeButton

  const handleClick = (buttonName: string) => {
    setActiveButton(buttonName === activeButton ? "" : buttonName);
    console.log(activeButton);
  };

  React.useEffect(() => {
    console.log(activeButton);
  }, [activeButton]);

  React.useEffect(() => {
    // Reset the duration whenever the subscription type changes
    subsDurationInputChangeHandler(""); // <-- Reset duration
  }, [enteredSubs]); // Trigger when enteredSubs changes

  // React.useEffect(() => {
  //   const notificationId =
  //     queryParams.get("verify") &&
  //     pushNotification({
  //       message:
  //         "You're all set up! Please login with your work email and password to continue",
  //       type: "success",
  //       duration: 10000,
  //     });

  //   return () => popNotification(notificationId || 0);
  // }, []);

  const { loading, error, sendHttpRequest: signIn } = useHttp();

  const { sendHttpRequest } = useHttp();

  const [subscription, setSubscription] = React.useState<SubscriptionData[]>(
    []
  );

  const [selectedSubs, setSelectedSubs] = React.useState("");

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/subscription/plan/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<SubscriptionData[]>) => {
        setSubscription(response.data);
      }
    );
  }, []);

  const householdSubscription =
    subscription &&
    subscription.filter(
      (subs, index, self) =>
        subs.name && // Ensure name is not empty
        subs.name !== "Enterprise" && // Exclude "Enterprise"
        index === self.findIndex((s) => s.name === subs.name) // Ensure unique names
    );

  const selectedSubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name && subs?.name.toLowerCase() === selectedSubs.toLowerCase()
      )
    : [];

  // console.log("subscription", subscription);
  // console.log("selectedSubscription", selectedSubscription);
  // console.log("selectedSubs", selectedSubs);
  // console.log("enteredSubs", enteredSubs);
  // console.log("enteredSubsDuration", enteredSubsDuration);

  const subsTypeOptions: Option[] = householdSubscription.map((role) => ({
    label: role.name as string,
    value: role.name as string,
  }));

  const subsDurationOptions: Option[] = selectedSubscription.map((role) => {
    // Map duration to corresponding label
    let durationLabel = "";
    switch (role.duration) {
      case 1:
        durationLabel = "Monthly";
        break;
      case 3:
        durationLabel = "Quarterly";
        break;
      case 12:
        durationLabel = "Yearly";
        break;
      default:
        durationLabel = `${role.duration} Months`; // Default case for any other duration
    }

    return {
      label: `${role.name} - ${durationLabel}: (${formatAmount(role?.price)})`, // Create label with duration and price
      value: String(role.id), // Ensure id is treated as a string
    };
  });

  const handleSubsType = (val: string | null) => {
    subsInputChangeHandler(val as string);
    setSelectedSubs(val as string);
  };

  const OtpPrompt = async () => {
    // let whatsappNumber = formState.whatsapp.value;

    // if (whatsappNumber.startsWith("0")) {
    //   whatsappNumber = "+234" + whatsappNumber.slice(1);
    // } else if (whatsappNumber.startsWith("234")) {
    //   whatsappNumber = "+234" + whatsappNumber.slice(3);
    // } else if (whatsappNumber.startsWith("+234")) {
    //   return whatsappNumber;
    // } else {
    //   // Handle other cases if necessary
    //   return;
    // }
    // console.log(whatsappNumber);
    await signIn(
      process.env.REACT_APP_API_BASE_URL + "/employer/verification-code",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          whatsAppNo: phoneNumber.replace(/\s+/g, ""),
        }),
      },
      (response) => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        console.log(response);
        if (response.status === 200) {
          toast({
            message: "OTP sent to the Whatsapp number provided",
            variant: "success",
          });
        }
      }
    );
  };

  const { form, addToOnboard, setActiveStep } = useOnboardForm();

  const allowOnboardEmployer = () => {
    if (enteredSubsDuration === "") {
      toast({
        message: "A valid subscription duration is required",
        variant: "error",
      });
    }

    if (
      activeButton !== "" &&
      phoneNumber !== "" &&
      enteredSubs !== "" &&
      enteredSubsDuration !== ""
    ) {
      addToOnboard({
        type: activeButton,
        whatsapp: phoneNumber.replace(/\s+/g, ""),
        subscriptionType: enteredSubsDuration,
      });

      // OTP SEND
      OtpPrompt();
      localStorage.setItem(
        "subscriptionType",
        encrypt(JSON.stringify({ subscriptionType: enteredSubsDuration }))
      );

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (phoneNumber === "") {
      toast({
        message: "Whatsapp number is required",
        variant: "error",
      });
    } else if (enteredSubs === "") {
      toast({
        message: "Subscription is required",
        variant: "error",
      });
    }
  };

  const allowOnboardEmployee = () => {
    if (activeButton !== "" && phoneNumber !== "") {
      addToOnboard({
        type: activeButton,
        whatsapp: phoneNumber.replace(/\s+/g, ""),
        subscriptionType: enteredSubsDuration,
      });

      // OTP SEND
      OtpPrompt();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (phoneNumber === "") {
      toast({
        message: "Whatsapp number is required",
        variant: "error",
      });
    }
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const firstOnboardingHandler = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    // emailInputRef.current!.focus();
    preventDefault(event);

    if (activeButton === "employer") {
      allowOnboardEmployer();
      console.log("activeButton", activeButton);
    } else {
      allowOnboardEmployee();
      console.log("activeButton", activeButton);
    }
  };

  return (
    <React.Fragment>
      <Box className="text-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
          component="div"
          gutterBottom
          mb={1.5}
        >
          Tell us what describes you best
        </Typography>
      </Box>
      <Paper
        sx={{
          px: 3,
          py: 3,
          boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
        }}
        className="h-[full] max-w-md mx-auto"
      >
        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 3,
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* autoComplete="off"
            noValidate */}
        <Box component={"form"} onSubmit={firstOnboardingHandler}>
          <Box className=" space-y-6 mb-6">
            <Button
              onClick={() => handleClick("employer")}
              // return onClick to toggle activeButton
              className={`no-underline w-full p-4 h-fit border border-1 rounded-xl ${
                activeButton === "employer"
                  ? "border-[#194049]"
                  : "border-[#D9D9D9]"
              }`}
            >
              <Box
                className=" !place-content-start gap-3"
                display="flex"
                justifyContent="start"
                justifyItems="start"
                alignItems="start"
              >
                <Box className=" p-2">
                  {activeButton === "employer" ? (
                    <EmployerColoredIcon />
                  ) : (
                    <EmployerGreyIcon />
                  )}
                </Box>

                <Box className="text-start">
                  <Typography
                    variant="caption"
                    color={activeButton === "employer" ? "primary" : "#C1C1C1"}
                    fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                    lineHeight={{ xs: "19px", sm: "19px" }}
                    letterSpacing={"0.01em"}
                    className=" text-start"
                  >
                    Families & Household
                  </Typography>
                  <Typography
                    variant="body1"
                    color={activeButton === "employer" ? "primary" : "#C1C1C1"}
                    fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                    lineHeight={{ xs: "19px", sm: "19px" }}
                    letterSpacing={"0.01em"}
                    className=" text-start"
                  >
                    I want to onboard and pay or hire and pay Household
                    contractors through Caring Blocks
                  </Typography>
                </Box>
              </Box>
            </Button>

            <Button
              onClick={() => handleClick("employee")}
              className={`no-underline w-full p-4 h-fit border border-1 rounded-xl ${
                activeButton === "employee"
                  ? "border-[#194049]"
                  : "border-[#D9D9D9]"
              }`}
              // disabled // remove this for the button to function
            >
              <Box
                className=" !place-content-start gap-3"
                display="flex"
                justifyContent="start"
                justifyItems="start"
                alignItems="start"
              >
                <Box className=" p-2">
                  {activeButton === "employee" ? (
                    <StaffColoredIcon />
                  ) : (
                    <StaffGreyIcon />
                  )}
                </Box>

                <Box className="text-start">
                  <Typography
                    variant="caption"
                    color={activeButton === "employee" ? "primary" : "#C1C1C1"}
                    fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                    lineHeight={{ xs: "19px", sm: "19px" }}
                    letterSpacing={"0.01em"}
                    className=" text-start"
                  >
                    Household Employees
                  </Typography>
                  <Typography
                    variant="body1"
                    color={activeButton === "employee" ? "primary" : "#C1C1C1"}
                    fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                    lineHeight={{ xs: "19px", sm: "19px" }}
                    letterSpacing={"0.01em"}
                    className=" text-start"
                  >
                    {/* I want to work compliantly and get paid through Caring
                    Blocks. */}
                    I want to work compliantly and get paid through Caring
                    Blocks.
                  </Typography>
                </Box>
              </Box>
            </Button>
          </Box>

          {/* <MHFormControl
            id="email"
            type="email"
            label=""
            placeholder="Email address"
            onChange={inputChangeHandler}
            startAdornment={
              <InputAdornment>
                <MailIcon width="1rem" />
              </InputAdornment>
            }
            required
            // autoFocus
          /> */}
          {finalSubType === "" && activeButton === "employer" && (
            <Box my={2}>
              <MHAutocomplete
                label=""
                placeholder="Select Subscription"
                options={subsTypeOptions}
                onInputChange={handleSubsType}
                // onInputChange={(val) => subsInputChangeHandler(val as string)}
              />
            </Box>
          )}
          {enteredSubs !== "" && activeButton === "employer" && (
            <Box my={2}>
              <MHAutocomplete
                label=""
                placeholder="Select Duration"
                options={subsDurationOptions}
                onInputChange={(val) =>
                  subsDurationInputChangeHandler(val as string)
                }
              />
            </Box>
          )}

          <MHPhoneInput
            value={phoneNumber}
            onChange={phoneNumberInputChangeHandler}
            label="Whatsapp Number"
            errorMessage={resolveErrorMessage(phoneNumberIsValid)(
              "Please enter a valid phone number"
            )}
          />

          {/* <MHFormControl
            id="whatsapp"
            type="whatsapp"
            label=""
            placeholder="Whatsapp number"
            onChange={inputChangeHandler}
            startAdornment={
              <InputAdornment>
                <WhatsappIcon width="1rem" />
              </InputAdornment>
            }
            required
            // autoFocus
          /> */}

          {/* <Box className="w-full flex justify-center items-center">
            <Divider className="w-[45%]" />
            <Box className="w-[10%] text-center">
              <Typography color="primary" className="opacity-60">
                Or
              </Typography>
            </Box>

            <Divider className="w-[45%]" />
          </Box> */}

          {/* <MHButton
            sx={{}}
            // type="submit"
            loading={loading}
            fullWidth
            className="h-12 my-4 text-opacity-50 bg-white text-navy-900 ring-1 ring-navy-900 ring-opacity-30"
            startIcon={<GoogleIcon />}
          >
            Sign up with Google
          </MHButton> */}

          <Box my={2}>
            <MHCheckbox
              label="I agree to the Terms of Service and the Use Policy"
              value={checked}
              onChange={handleCheck}
              sx={{
                fontSize: "11px",
                lineHeight: "160.5%",
                letterSpacing: "0.04em",
                // [`& .${typographyClasses.root}`]: {
                //   opacity: '1 !important'
                // }
                // opacity: 0.5
              }}
              required
            />
          </Box>

          <MHButton sx={{}} type="submit" fullWidth>
            Next
          </MHButton>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default RoleSelection;
