import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

type Props = {
  label?: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export const MHSwitch = (props: Props) => {
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <Switch
            checked={props.value}
            onChange={props.onChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={props.label}
      />
    </React.Fragment>
  );
};
