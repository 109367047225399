import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import MHAccordion from "../components/Common/Accordion/MHAccordion";
// import { Short_FAQ_Data } from "../utils/faq";

type Props = {
  title?: string;
  faqdata?: any;
};

const FAQ = (props: Props) => {
  // console.log("faqdata", props.faqdata);

  return (
    <React.Fragment>
      <Box className=" w-[95%] mx-auto">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "30px", sm: "44px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={"-0.04em"}
          className="mx-auto text-center mt-10"
        >
          {props.title}
        </Typography>

        {/* {Summit_FAQ_Data.map((faq, index) => (
            <MHAccordion
              DataId={`${faq.id}`}
              question={faq.question}
              answer={faq.answer}
            />
          ))} */}

        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {props.faqdata
              .filter((faq: any) => faq.id % 2 !== 0)
              .map((faq: any) => (
                <MHAccordion
                  DataId={`${faq.id}`}
                  question={faq.question}
                  answer={faq.answer}
                  key={faq.id}
                />
              ))}
          </Grid>

          <Grid item xs={12} md={6}>
            {props.faqdata
              .filter((faq: any) => faq.id % 2 === 0)
              .map((faq: any) => (
                <MHAccordion
                  DataId={`${faq.id}`}
                  question={faq.question}
                  answer={faq.answer}
                  key={faq.id}
                />
              ))}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default FAQ;
