import React from "react";
import Slider from "react-slick";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import MHButton from "../components/Common/Button/MHButton";

import { ReactComponent as LeftBtn } from "../static/svg/left-btn.svg";
import { ReactComponent as RightBtn } from "../static/svg/right-btn.svg";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import useDialog from "../hooks/use-dialog";

import { useHistory } from "react-router-dom";
import useTitle from "../hooks/use-title";
import CorporateApproachSection from "./Plans/CorporateApproachSection";
// import CorporateBenefitsSection from "./Plans/CorporateBenefitsSection";
import CorporateHeroSlider from "./Plans/CorporateHeroSlider";
import FAQ from "./CBFaq";
import { Corporate_FAQ_Data } from "../utils/faq";
import CorporateBenefitsSection from "./Plans/CorporateBenefitsSection";
import LandingFooter from "./Landing/LandingFooter";
import MHFormControl from "../components/Common/Form/MHFormControl";
import MHDialog from "../components/Common/Dialog/MHDialog";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import * as validators from "../utils/validators";
import useInput from "../hooks/use-input";
import useHttp from "../hooks/use-http";
import { db } from "../firebase";
import SnackbarContext from "../store/context/snackbar.context";
import MHPhoneInput from "../components/Common/Form/MHPhoneInput";
import { resolveErrorMessage } from "../utils/utils";
import usePhoneInput from "../hooks/use-phone";
import BackdropLoader from "../components/Common/Loading/BackdropLoader";

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 lg:-right-8 md:-right-6">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 -left-8">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

export const MagazineFeature = (props: {
  title: string;
  content: string;
  imageSrc: string;
  inverted?: boolean;
}) => {
  return (
    <Grid
      container
      direction={{
        xs: "column-reverse",
        md: props.inverted ? "row-reverse" : "row",
      }}
      mb={8}
    >
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: "90%", md: "70%" }}
          mx="auto"
          height="100%"
          mt={{ xs: 3, md: 0 }}
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "2rem", md: "2.4rem" }}
            width={{ xs: "100%", md: "90%" }}
            textTransform="capitalize"
            align="center"
            sx={{
              mb: 4,
            }}
            paragraph
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: "100%", md: "80%" }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={5}
          >
            {props.content}
          </Typography>

          {/* <MHButton
        sx={{
          width: '169px'
        }}>
        Start Your Family Application
      </MHButton> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          width={{ xs: "100%", md: "100%" }}
          mx="auto"
          // p={{ xs: 3, md: 0 }}
          // pr={{ xs: 2, md: 10 }}
          // pl={2}
          sx={{
            "& img": {
              width: "100%",
              [props.inverted ? "pl" : "pr"]: { xs: 2, md: 10 },
              [props.inverted ? "pr" : "pl"]: 2,
              // height: '500px'
            },
          }}
        >
          <img
            src={props.imageSrc}
            alt="WorkLife Dashboard"
            width="800"
            draggable={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const Corporate = () => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    openDialog: openLoader,
    handleOpenDialog: handleOpenLoader,
    handleCloseDialog: handleCloseLoader,
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
    handleCloseLoader();
    handleRoute();
  };

  const {
    value: enteredName,
    valid: enteredNameIsValid,
    error: nameInputHasError,
    onChange: nameInputChangeHandler,
    onBlur: nameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: companyNameInputHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredjobTitle,
    valid: enteredJobTitleIsValid,
    error: jobTitleInputHasError,
    onChange: jobTitleInputChangeHandler,
    onBlur: jobTitleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: emailInputHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  // const {
  //   value: enteredPhone,
  //   valid: enteredPhoneIsValid,
  //   error: phoneInputHasError,
  //   onChange: phoneInputChangeHandler,
  //   onBlur: phoneInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const formIsValid =
    enteredNameIsValid &&
    enteredCompanyNameIsValid &&
    enteredJobTitleIsValid &&
    // phoneNumberIsValid &&
    enteredEmailIsValid;

  const resetForm = () => {
    nameInputChangeHandler("");
    companyNameInputChangeHandler("");
    jobTitleInputChangeHandler("");
    emailInputChangeHandler("");
  };

  const { loading } = useHttp();

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  useTitle("For Corporate");

  const handleRoute = () => {
    window.open("https://calendly.com/motherhonestlygroup/caringblocks");
  };

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const flodeskSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables

    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "Caring Blocks (www.caringblocks.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: enteredName,
          email: enteredEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
          custom_fields: {
            companyphone: phoneNumber,
            companyname: enteredCompanyName,
            role: enteredjobTitle,
          },
        }),
      });

      flodeskSegmentSubmit();

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["66e18085114d525ad5b6e632"]; // Replace with actual segment IDs
    // Segment: caring-blocks-corporate-application

    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "Caring Blocks (www.caringblocks.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
        resetForm();
        handleClose();

        toast({
          message: "Thank you for your interest!",
          variant: "success",
        });
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    // emailInputRef.current!.focus();
    preventDefault(event);
    if (!formIsValid) {
      console.log("Complete Form");
      toast({
        message: "Kindly input your request",
        variant: "warning",
      });
      return;
    } else {
      handleOpenLoader();
      handleCloseDialog();
      await addDoc(collection(db, "caring-blocks-corporate-application"), {
        name: enteredName,
        companyName: enteredCompanyName,
        jobTitle: enteredjobTitle,
        email: enteredEmail,
        phone: phoneNumber,
        createdAt: serverTimestamp(),
      });

      flodeskSubmit();

      // toast({
      //   message: "Thank you for your request. We will get back to you shortly!",
      //   variant: "success",
      // });
    }
  };

  return (
    <React.Fragment>
      <BackdropLoader open={openLoader} />
      <Grid container direction={{ xs: "column-reverse", md: "row" }} mb={4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              "& > img": {
                height: 600,
                width: "100%",
                objectPosition: "center",
                objectFit: "cover",
              },
            }}
          >
            <img
              // src="https://res.cloudinary.com/mother-honestly/image/upload/v1689337928/A_New_Kind_Of_Care_Algorithm_oivrhv.png"
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/For+Corporate.png"
              alt="Banner"
              width="700"
              draggable={false}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} bgcolor="#F3F4F6">
          <Stack
            height={600}
            justifyContent="center"
            alignItems="center"
            px={{ xs: 2, md: 10 }}
            sx={{
              "& > p": {
                textAlign: "center",
                pointerEvents: "none",
              },
            }}
          >
            <Typography
              variant="body1"
              fontFamily="Area-Extended"
              textTransform="uppercase"
              color="#194049"
              fontSize={10}
              letterSpacing="0.1em"
              lineHeight="102%"
              sx={{
                mb: 5,
              }}
              paragraph
            >
              for Corporate
            </Typography>

            <Typography
              variant="h1"
              fontSize={{ xs: "30px", sm: "40px", md: "40px" }}
              textTransform="capitalize"
              width="75%"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              Employee Benefits That Solves For Childcare At The Core
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="#194049"
              width="75%"
              sx={{
                mb: 7,
              }}
              paragraph
            >
              At Caring Blocks, we understand that the backbone of any
              successful company is a happy and focused workforce. That's why we
              partner with businesses to provide essential childcare and
              household support, ensuring your employees can dedicate their
              energy to work that truly matters.
            </Typography>

            <MHButton
              sx={{
                width: "fit-content",
              }}
              onClick={handleOpenDialog}
            >
              Schedule A Call
            </MHButton>
          </Stack>
        </Grid>
      </Grid>

      <CorporateBenefitsSection />

      <MHButton
        sx={{
          width: "fit-content",
          display: "block",
          mx: "auto",
          mt: 5,
        }}
        onClick={handleOpenDialog}
      >
        Start Your Corporate Application
      </MHButton>

      <CorporateHeroSlider />
      <MHButton
        sx={{
          width: "fit-content",
          display: "block",
          mx: "auto",
          mt: 5,
        }}
        onClick={handleOpenDialog}
      >
        Start Your Corporate Application
      </MHButton>

      <CorporateApproachSection />

      {/* <Divider
        variant="middle"
        sx={{
          borderColor: "primary.main",
          width: "91%",
          mx: "auto",
          opacity: 0.1,
          mb: 4,
        }}
      /> */}

      {/* <MembershipPlans />

      <Box mt={4} mb={8}>
        <MembershipFeatures theme="light" />
      </Box> */}

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" my={6}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', sm: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          Care Infrastructure Designed For All
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', sm: '55%' }}
          fontSize={'13px'}
          letterSpacing="0.02em"
          lineHeight="185%"
          align="center"
          mx="auto"
          paragraph>
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography>
      </Box>

      <Grid
        container
        width={{ xs: '85%', sm: '93%' }}
        mx={{ xs: 'auto' }}
        mb={10}>
        {CARE_FEATURES.map((feature) => (
          <Grid item xs={12} sm={3}>
            <CareFeature
              imageSrc={feature.imageSrc}
              caption={feature.caption}
            />
          </Grid>
        ))}
      </Grid>

      <Divider
        variant="middle"
        sx={{
          borderColor: 'primary.main',
          width: '91%',
          mx: 'auto',
          opacity: 0.1,
          my: 8
        }}
      /> */}

      {/* <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1689337928/A_New_Kind_Of_Care_Algorithm_oivrhv.png"
        content="Access workplace care and well-being insights, on-demand. MH WorkLife’s Insights Hub provides you on-demand access to key metrics on the caregiving responsibilities of your employees, where the pain points exist, and how your organization is directly reducing their care load for your workforce."
        title="A New Kind Of Care Algorithm"
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1676442922/Group_4_mot8dy.png"
        content="Your employees will have access to a personalized dashboard filled with content, resources, advisors, handpicked care merchants, company benefits, on-demand library of videos, podcasts, worksheets, toolkits, and guides to meet their personal and caregiving needs."
        title="Personalized WorkLife Care Dashboard"
        inverted
      />
      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Fund_lhhgcf.png"
        content="Minimize productivity disruptions and support the well-being of your employees by providing an added layer of care to help them meet their caregiving and wellness responsibilities when they fall short. Whether it is helping to pay for an additional can of baby formula, or covering the additional tutoring needs of a teenage child falling behind in school, employees will appreciate you stepping in to care."
        title="An Additional Layer Of Protection For Care"
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Work_Care_Guidance_ll60bs.png"
        content="Our 1:1 Advisors are available 24/7 to provide on-demand guidance to address a wide range of topics that includes relationships, career, parenting, eldercare, adoption, return-to-work, and more. Providing your employees with proactive care that minimizes stress and disruption to work."
        title="MH WorkLife’s Advisors provide proactive and timely support"
        inverted
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Cover_The_Gap_gwekvt.png"
        content="By covering the care gap with qualified and vetted MH Care Gap Consultants, your company can foster a more supportive work environment that respects the personal care needs of employees, reduces the risk of delayed projects or missed opportunities, and  upholds the company’s reputation for reliability and stability without placing additional burden on existing employees."
        title="Uninterrupted Productivity"
      /> */}

      <Box px={{ xs: 3, md: 8 }} mb={6}>
        <FAQ title="Frequently Asked Questions" faqdata={Corporate_FAQ_Data} />
      </Box>
      <LandingFooter />

      {/* <JoinWaitlist open={open} onClose={handleClose} /> */}

      <MHDialog
        open={openDialog}
        title={"Corporate Application"}
        sx={{ textAlign: "center" }}
        handleClose={handleCloseDialog}
        scroll="paper"
        maxWidth={"xs"}
        fullWidth
      >
        {openDialog ? (
          <Box p={2}>
            <Box component={"form"} onSubmit={handleSubmit}>
              <MHFormControl
                id="name"
                type="text"
                // label="Name"
                placeholder="Contact Name"
                value={enteredName}
                onChange={nameInputChangeHandler}
                onBlur={nameInputBlurHandler}
                required
                // autoFocus
              />
              <MHFormControl
                id="companyName"
                type="text"
                // label="Company Name"
                placeholder="Company Name"
                value={enteredCompanyName}
                onChange={companyNameInputChangeHandler}
                onBlur={companyNameInputBlurHandler}
                required
                // autoFocus
              />
              <MHFormControl
                id="job"
                type="text"
                // label="Contact Job Title"
                placeholder="Job Title"
                value={enteredjobTitle}
                onChange={jobTitleInputChangeHandler}
                onBlur={jobTitleInputBlurHandler}
                required
                // autoFocus
              />

              <MHFormControl
                id="email"
                type="email"
                // label="Email"
                placeholder="Contact Email"
                value={enteredEmail}
                onChange={emailInputChangeHandler}
                onBlur={emailInputBlurHandler}
                required
                // autoFocus
              />

              <MHPhoneInput
                value={phoneNumber}
                onChange={phoneNumberInputChangeHandler}
                // label="Phone Number"
                errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                  "Please enter a valid phone number"
                )}
              />
              <MHButton
                color="primary"
                sx={{}}
                type="submit"
                loading={loading}
                className="pt-2  capitalize w-full h-[44px]"
                fullWidth
                // startIcon={<PlusIcon />}
                // onClick={handleSubmit}
                // onClick={() =>
                //   history.push("/organization/employee-onboarding")
                // }
              >
                Submit
              </MHButton>
            </Box>
          </Box>
        ) : null}
      </MHDialog>
    </React.Fragment>
  );
};

export default Corporate;
