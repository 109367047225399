import React from "react";
import useInput from "../../../hooks/use-input";
import * as validators from "../../../utils/validators";
import { Box, Grid, Typography } from "@mui/material";
import { SelectOption } from "@mui/base";
import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../../components/Common/Form/InputAdornment";
import { MHSelect } from "../../Common/Form/MHSelect";
import MHButton from "../../Common/Button/MHButton";
import { resolveErrorMessage } from "../../../utils/utils";

import { ReactComponent as WhatsappGreyIcon } from "../../../static/svg/whatsapp-grey.svg";
import { ReactComponent as PhoneIcon } from "../../../static/svg/phone.svg";
import { useOnboardForm } from "../../../store/context/onboard-context";
import SnackbarContext from "../../../store/context/snackbar.context";
import { HouseholdRoles, NIGERIA_STATES } from "../../../utils/contracts";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import MHPhoneInput from "../../Common/Form/MHPhoneInput";
import usePhoneInput from "../../../hooks/use-phone";

export const FREQUENCY: SelectOption<string>[] = [
  // {
  //   value: "Weekly",
  //   label: "Weekly",
  // },
  {
    value: "Bi-Weekly",
    label: "Bi-Weekly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
];

export const GENDER: SelectOption<string>[] = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

type Props = {};

const EmployeeInfo = (props: Props) => {
  const { setActiveStep, form, addToOnboard } = useOnboardForm();
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAddress,
    valid: enteredAddressIsValid,
    onChange: addressInputChangeHandler,
    onBlur: addressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredState,
    valid: enteredStateIsValid,
    error: enteredStateHasError,
    onChange: stateInputChangeHandler,
    onBlur: stateInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const {
    phoneNumber: watzNumber,
    phoneInfo: watzInfo,
    phoneNumberInputChangeHandler: watzNumberInputChangeHandler,
    isValid: watzNumberIsValid,
  } = usePhoneInput([]);

  const {
    value: enteredWhatsapp,
    valid: enteredWhatsappIsValid,
    onChange: whatsappInputChangeHandler,
    onBlur: whatsappInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredSex,
    valid: enteredSexIsValid,
    onChange: sexInputChangeHandler,
    onBlur: sexInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredRole,
    valid: enteredRoleIsValid,
    error: enteredRoleHasError,
    onChange: roleInputChangeHandler,
    onBlur: roleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  // const formIsValid =
  //   enteredFirstNameIsValid &&
  //   enteredLastNameIsValid &&
  //   enteredStateIsValid &&
  //   enteredAddressIsValid &&
  //   enteredSexIsValid &&
  //   enteredEmailIsValid &&
  //   enteredPhoneIsValid &&
  //   enteredRoleIsValid &&
  //   enteredWhatsappIsValid;

  const handleDetails = () => {
    // if (!formIsValid) {
    //   // console.log("Complete Form");
    //   toast({
    //     message: "Please input all required field",
    //     variant: "warning",
    //   });
    //   return;
    // }

    addToOnboard({
      firstname: enteredFirstName,
      lastname: enteredLastName,
      state: enteredState,
      address: enteredAddress,
      email: enteredEmail,
      role: enteredRole,
      sex: enteredSex,
      phone: phoneNumber,
      whatsapp: watzNumber,
    });

    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    console.log("form info", form);
  };

  // React.useEffect(() => {
  //   console.log("form info", form);
  // }, [form]);

  const genderRolesOptions: Option[] = GENDER.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const householdRolesOptions: Option[] = HouseholdRoles.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const statesOption: Option[] = NIGERIA_STATES.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  return (
    <React.Fragment>
      {/* <Box className="text-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
          component="div"
          gutterBottom
          mb={5}
        >
          Household Employee Details
        </Typography>
      </Box> */}

      <Grid container spacing={1} className="lg:px-0 place-content-center">
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          <MHFormControl
            id="fname"
            type="text"
            label=""
            placeholder="First name"
            value={enteredFirstName}
            // value={enteredFirstName === "" ? form.firstname : enteredFirstName}
            onChange={firstNameInputChangeHandler}
            onBlur={firstNameInputBlurHandler}
            required
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          <MHFormControl
            id="lname"
            type="text"
            label=""
            placeholder="Last name"
            value={enteredLastName}
            // value={enteredLastName === "" ? form.lastname : enteredLastName}
            onChange={lastNameInputChangeHandler}
            onBlur={lastNameInputBlurHandler}
            required
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          {/* <MHSelect
            label=""
            placeholder="State"
            options={NIGERIA_STATES}
            // value={enteredState}
            value={enteredState}
            // value={enteredState === "" ? form.state ?? "" : enteredState}
            onChange={(val) => stateInputChangeHandler(val as string)}
            onBlur={stateInputBlurHandler}
            error={resolveErrorMessage(enteredStateHasError)(
              "Please select employee state"
            )}
          /> */}

          <MHAutocomplete
            label=""
            placeholder="Select State"
            options={statesOption}
            onInputChange={(val) => stateInputChangeHandler(val as string)}

            // options={NIGERIA_STATES_LCDAS.map((state) => ({
            //   label: state.state,
            //   value: state.state,
            // }))}
            // onInputChange={handleStateChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          <MHFormControl
            id="address"
            type="text"
            label=""
            placeholder="Household Employee Address"
            value={enteredAddress}
            // value={enteredAddress === "" ? form.address : enteredAddress}
            onChange={addressInputChangeHandler}
            onBlur={addressInputBlurHandler}
            required
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          {/* <MHFormControl
            type="text"
            id="phone"
            label=""
            placeholder="Phone Number"
            value={enteredPhone}
            // value={enteredPhone === "" ? form.phone : enteredPhone}
            onChange={phoneInputChangeHandler}
            onBlur={phoneInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <Typography
                  variant="body1"
                  fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                  lineHeight={"19px"}
                  component="div"
                  gutterBottom
                  className="text-center text-[#5D5D5D] mx-auto items-center align-middle"
                >
                  <PhoneIcon width={50} height={50} />
                </Typography>
              </InputAdornment>
            }
            required
            autoFocus
          /> */}

          <MHPhoneInput
            value={phoneNumber}
            onChange={phoneNumberInputChangeHandler}
            label="Phone Number"
            errorMessage={resolveErrorMessage(phoneNumberIsValid)(
              "Please enter a valid phone number"
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          {/* <MHFormControl
            id="phone"
            type="whatsapp"
            label=""
            placeholder="Whatsapp Number"
            value={enteredWhatsapp}
            // value={enteredWhatsapp === "" ? form.whatsapp : enteredWhatsapp}
            onChange={whatsappInputChangeHandler}
            onBlur={whatsappInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <WhatsappGreyIcon />
              </InputAdornment>
            }
            required
            autoFocus
          /> */}
          <MHPhoneInput
            value={watzNumber}
            onChange={watzNumberInputChangeHandler}
            label="Whatsapp Number"
            errorMessage={resolveErrorMessage(watzNumberIsValid)(
              "Please enter a valid whatsapp number"
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          <Box className="grid grid-cols-2 gap-2">
            {/* <MHSelect
              label=""
              placeholder="Sex"
              options={GENDER}
              // value={enteredSex}
              value={enteredSex === "" ? form.sex ?? "" : enteredSex}
              onChange={(val) => sexInputChangeHandler(val as string)}
              onBlur={sexInputBlurHandler}
              // error={resolveErrorMessage(enteredSexHasError)(
              //   "Please select employee gender"
              // )}
            /> */}

            <MHAutocomplete
              label=""
              placeholder="Select gender"
              options={genderRolesOptions}
              onInputChange={(val) => sexInputChangeHandler(val as string)}
            />

            {/* <MHSelect
              label=""
              placeholder="Role"
              options={HouseholdRoles}
              // value={enteredRole}
              value={enteredRole === "" ? form.role ?? "" : enteredRole}
              onChange={(val) => roleInputChangeHandler(val as string)}
              onBlur={roleInputBlurHandler}
              error={resolveErrorMessage(enteredRoleHasError)(
                "Please select employee role"
              )}
            /> */}

            <MHAutocomplete
              label=""
              placeholder="Select role"
              options={householdRolesOptions}
              onInputChange={(val) => roleInputChangeHandler(val as string)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="lg:px-4">
          <MHFormControl
            id="email"
            type="email"
            label=""
            placeholder="Email"
            value={enteredEmail}
            // value={enteredEmail === "" ? form.email : enteredEmail}
            onChange={emailInputChangeHandler}
            onBlur={emailInputBlurHandler}
            required
            autoFocus
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4.5}
          lg={5.93}
          className="lg:pr-[190px] mt-24 ml-auto"
        >
          <MHButton onClick={handleDetails} fullWidth>
            Next
          </MHButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmployeeInfo;
