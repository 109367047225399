import { Box } from "@mui/material";
import React from "react";

type Props = {};

const ProfileMessaging = (props: Props) => {
  return (
    <React.Fragment>
      <Box>...Coming Soon</Box>
    </React.Fragment>
  );
};

export default ProfileMessaging;
