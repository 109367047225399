import { Box, Typography } from "@mui/material";
import React from "react";
import MHCheckbox from "./MHCheckbox";

type Props = {
  label?: string;
  amount?: number;
  value?: boolean;
  handleCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const MHCheckboxContent = (props: Props) => {
  return (
    <Box className=" flex place-content-between">
      <MHCheckbox
        label={props.label}
        value={props.value}
        onChange={props?.handleCheck}
        sx={{
          fontSize: "11px",
          lineHeight: "160.5%",
          letterSpacing: "0.04em",
          //   [`& .${typographyClasses.root}`]: {
          //     opacity: "1 !important",
          //   },
          // opacity: 0.5
        }}
      />

      <Box
        className={`${
          props.value === true ? "bg-[#009688]" : "bg-[#D9D9D9] "
        } w-[70px] h-[30px] py-1 px-2`}
      >
        <Typography
          variant="body1"
          fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
          lineHeight={{ xs: "22px", sm: "22px" }}
          component="div"
          gutterBottom
          mb={5}
          className={`${
            props.value === true ? "text-white" : "text-[#A9A9A9] "
          } `}
        >
          {"N" + props.amount}
        </Typography>
      </Box>
    </Box>
  );
};

export default MHCheckboxContent;
