import React from "react";
import Slider, { LazyLoadTypes, Settings } from "react-slick";

import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";

import DirectionControl from "../../UI/DirectionControl";
import IconButtonStyled from "../../Button/IconButtonStyled";
import RoundedLogoIcon from "../../../../theme/icons/RoundedLogo";
import LoadingIndicator from "../../Loading/LoadingIndicator";
import CenteredFlexContainer from "../../UI/CenteredFlexContainer";
import classes from "./MHSlider.module.scss";

import { ReactComponent as DirectionLeftIcon } from "../../../../static/svg/caret-left.svg";
import { ReactComponent as DirectionRightIcon } from "../../../../static/svg/caret-right.svg";
// import { ReactComponent as DirectionLeftIcon } from "../../../../static/svg/direction/direction-left.svg";
// import { ReactComponent as DirectionRightIcon } from "../../../../static/svg/direction/direction-right.svg";

const directionSx: SxProps<Theme> = {
  bgcolor: "transparent",
  // boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
};

const buttonSx: SxProps<Theme> = {
  zIndex: (theme) => theme.zIndex.appBar + 100,
  "&::before": {
    display: "none",
  },
};

const settings = {
  // centerMode: true,
  // centerPadding: '0px',
  dots: false,
  infinite: false,

  speed: 500,
  autoplay: false,
  // autoplaySpeed: 1100,
  // to resolve position issue
  nextArrow: (
    <IconButtonStyled sx={{ ...buttonSx, right: "-1px !important" }}>
      <RoundedLogoIcon sx={directionSx}>
        <DirectionRightIcon />
      </RoundedLogoIcon>
    </IconButtonStyled>
  ),
  prevArrow: (
    <IconButtonStyled
      sx={{
        ...buttonSx,
      }}
    >
      <RoundedLogoIcon sx={directionSx}>
        <DirectionLeftIcon />
      </RoundedLogoIcon>
    </IconButtonStyled>
  ),
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
  ],
};

type SliderProps = {
  slidesToShow: number;
  slidesToScroll: number;
  children: React.ReactElement[];
  speed?: number;
  containerSx?: SxProps<Theme>;
  hideLoadingIndicator?: boolean;
};

export const MHSliderItem = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width: string;
}) => {
  return (
    <Box
      className={classes["carousel-item"]}
      px={{ xs: 2, sm: 1.5, md: 8 }}
      justifyContent={"center"}
    >
      {children}
    </Box>
  );
};

const MHSlider = ({
  children,
  containerSx,
  ...others
}: Settings & SliderProps) => {
  return (
    <React.Fragment>
      {React.Children.count(children) ? (
        <Box
          // height={530}
          position="relative"
          sx={{
            width: "100%",
            mx: "auto",
            ...containerSx,
          }}
        >
          <Slider {...settings} {...others}>
            {React.Children.map(children, (child, index) => {
              return React.cloneElement(child, { width: `${100}%` });
            })}
          </Slider>
        </Box>
      ) : (
        <CenteredFlexContainer height={300}>
          {!others.hideLoadingIndicator && <LoadingIndicator />}
        </CenteredFlexContainer>
      )}
    </React.Fragment>

    // <DirectionControl
    //   onLeft={handleLeftDirection}
    //   onRight={handleRightDirection}
    // />
  );
};

export default MHSlider;
