import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import { useHistory } from "react-router-dom";

import * as animationData from "../../static/json-animate/WhatsApp-Update.json";
import Lottie from "react-lottie";

type Props = {};

const Connect = (props: Props) => {
  const history = useHistory();

  const bulletPoint = [
    "    Obtain payslip in PDF or text format",
    "Request tax certificates",
    "Request for back-up care solutions",
    "Request for health assessments",
    "Request for additional benefits",
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          // paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",
          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "full", sm: "520px", md: "700px" },
          backgroundColor: "#f5f5f5",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid" } }}
          height={{ xs: "full", sm: "500px", md: "680px" }}
          className="items-center  md:-rotate-12 md:order-first order-last"
        >
          {/* <img
            src="ChatCaringBlocks.png"
            alt="CaringBlocks"
            // className="h-[480px]"
          /> */}
          <Lottie options={defaultOptions} height={"100%"} />
        </Grid>
        <Grid
          item
          xs={10.5}
          sm={5.5}
          md={4.8}
          className=" items-center order-first md:order-last"
        >
          <Typography
            variant="subtitle2"
            color="primary"
            fontSize={{ xs: "28px", sm: "26px", md: "29px" }}
            lineHeight={{ xs: "120%", sm: "130.5%" }}
            letterSpacing={"-4%"}
            textAlign={{ xs: "center", sm: "start" }}
            className=" capitalize"
          >
            Self-Service On WhatsApp
          </Typography>

          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="pt-3 pb-6 mx-auto capitalize text-center md:text-start"
            width={{ xs: "90%", sm: "100%" }}
          >
            Our household self-service on whatsapp will assist you and your
            household contractors with the following:
          </Typography>

          {/* <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="pb-8 capitalize text-center md:text-start"
          ></Typography> */}

          {bulletPoint.map((obj, index) => (
            <Box className="flex  py-[5px]" key={index}>
              <Typography
                variant="body1"
                color="primary"
                fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
                lineHeight={{ xs: "100%", sm: "100%" }}
                letterSpacing={"-4%"}
                className="  text-center md:text-start"
                key={index}
              >
                • {obj}
              </Typography>
            </Box>
          ))}

          <MHButton
            color="primary"
            // sx={{}}
            // type="submit"
            className="mt-10 mb-12 md:mb-24 uppercase w-full md:w-[280px] h-[54px]"
            // startIcon={<PlusIcon />}
            onClick={() => history.push("/#subscription")}

            // onClick={() => {
            //   window.open("https://wa.me/message/PKKLUXLHVOLLC1");
            // }}
          >
            {/* Chat With Us */}
            Get Started
          </MHButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Connect;
