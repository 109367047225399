import { Box, Typography } from "@mui/material";
import React from "react";
import ProfileInfo from "../../components/Features/Household/ProfileInfo";
import ProfileOverview from "../../components/Features/Household/ProfileOverview";
import AuthContext from "../../store/context/auth-context";
import DashboardContext from "../../store/context/dashboard.context";
import { useHistory } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import { ContractProps } from "../../models/contract.model";
import { getURLWithQueryParams } from "../../utils/utils";
import { HttpResponse } from "../../models/api.interface";

type Props = {};

const EmployeeProfile = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;
  let history = useHistory();

  const { loading, error, sendHttpRequest } = useHttp();
  const [contractDetails, setContractDetails] =
    React.useState<ContractProps | null>(null);

  // console.log("customerId", customerId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract",
        {
          customerId: String(organization?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps>) => {
        if (response.status === 200) {
          console.log(response.data);
          setContractDetails(response.data);
        } else {
          console.error("Failed to fetch contract details:", response);
        }
      }
    ).catch((err) => {
      console.error("Error fetching contract details:", err);
    });
  }, [organization?.customerId]);

  React.useEffect(() => {
    if (contractDetails?.status !== "COMPLETED") {
      if (contractDetails?.customerId) {
        history.push(
          `/organization/employee/contract-agreement/${contractDetails?.customerId}`
        );
      }
    }
  }, [contractDetails?.customerId]);

  // const [value, setValue] = React.useState(0);

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <React.Fragment>
      <Box className="bg-[#F5F5F5] ">
        <Box
          display="flex"
          justifyContent="left"
          pt={{ xs: 4, sm: 8 }}
          className="pl-4 md:pl-10 lg:pl-20"
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "20px", sm: "30px" }}
            align="center"
            mt={3}
            gutterBottom
            display={"flex"}
            alignSelf={"start"}
            className=" capitalize"
          >
            Hi {user?.firstName}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="left"
          mb={4}
          className="pl-4 md:pl-10 lg:pl-20"
        >
          <Typography
            variant="body2"
            mb={3}
            gutterBottom
            display={"flex"}
            alignSelf={"start"}
            className=" opacity-50"
          >
            Below are your activities overview
          </Typography>
        </Box>

        <Box className="md:flex md:flex-row gap-3 md:gap-0 lg:gap-8 justify-center pb-4 w-full">
          <Box className="lg:bg-white px-4 md:px-0 lg:px-4 md:basis-3/12 lg:basis-2/12 rounded-lg drop-shadow-lg h-full md:h-screen">
            <ProfileInfo
            // tabValue={value} handleTabChange={handleTabChange}
            />
          </Box>
          <Box className=" px-4  lg:p-4 md:basis-8/12 lg:basis-8/12 overflow-auto md:overflow-auto lg:overflow-visible h-full">
            <ProfileOverview
            // tabValue={value}
            // handleTabChange={handleTabChange}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default EmployeeProfile;
