import { Box, Typography } from "@mui/material";
import React from "react";
import { EmployeeData } from "../../../models/employee.model";
import { MHSwitch } from "../../Common/Form/MHSwitch";
import { HttpResponse } from "../../../models/api.interface";
import useHttp from "../../../hooks/use-http";
import AuthContext from "../../../store/context/auth-context";
import DashboardContext from "../../../store/context/dashboard.context";
import SnackbarContext from "../../../store/context/snackbar.context";

import { ReactComponent as Activated } from "../../../static/svg/activate.svg";
import { ReactComponent as Reactivated } from "../../../static/svg/reactivate.svg";
import EmployeeProfileTab from "./EmployeeProfileTab";
import ConfirmationDialog from "../../Common/Dialog/ConfirmationDialog";
import { ContractProps } from "../../../models/contract.model";
import { getURLWithQueryParams } from "../../../utils/utils";

export type EmployeeProps = {
  employee: EmployeeData | null;
};

const Profile = ({ employee }: EmployeeProps) => {
  const { loading, error, sendHttpRequest } = useHttp();

  const [discardState, setDiscardState] = React.useState(false);
  // const [statusState, setStatusState] = React.useState(false);
  const [activeState, setActiveState] = React.useState(false);
  // const [activeWord, setActiveWord] = React.useState("");

  const [contractDetails, setContractDetails] =
    React.useState<ContractProps | null>(null);

  React.useEffect(() => {
    setActiveState(employee?.status === "ACTIVE");
  }, [employee]);

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization, fetchOrganizationData, updateEmployeeStatus } =
    dashboardCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const openContractModal = () => {
    setDiscardState(true);
  };

  const closeContractModal = () => {
    setDiscardState(false);
  };

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract",
        {
          customerId: String(employee?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps>) => {
        if (response.status === 200) {
          setContractDetails(response.data);
        } else {
          console.error("Failed to fetch contract details:", response);
        }
      }
    ).catch((err) => {
      console.error("Error fetching contract details:", err);
    });
  }, [employee]);

  const handleSaveChange = () => {
    const newStatus = employee?.status === "ACTIVE" ? "DEACTIVATED" : "ACTIVE";
    handleEmployeeStatus(newStatus);
    setDiscardState(false);
  };

  const handleEmployeeStatus = async (newStatus: string) => {
    if (!updateEmployeeStatus) {
      console.error("updateEmployeeStatus is not defined in DashboardContext");
      return;
    }

    if (!employee) {
      return;
    }

    console.log("newStatus", newStatus);
    const updatedEmployee: EmployeeData = {
      // ...employee,
      status: newStatus,
      id: employee?.id,
      customerId: employee?.customerId,
      employerRefId: employee?.employerRefId,
      // Add other properties as needed
    };

    // console.log(updatedEmployee);

    if (employee) {
      await updateEmployeeStatus(updatedEmployee);
      // console.log("After Update", employee);
      // window.location.reload();
    } else {
      console.error("Employee data is null");
    }

    toast({
      message: "Successfully Updated Employee Status.",
      variant: "success",
    });
    // console.log(employee);
  };

  // console.log("contractDetails", contractDetails?.status);

  if (!employee) {
    return null;
  }

  return (
    <React.Fragment>
      <Box p={0}>
        {contractDetails?.status === "COMPLETED" ? (
          <Box
            display="flex"
            justifyContent="right"
            gap={1}
            alignItems={"center"}
            pt={{ xs: 1, sm: 3 }}
          >
            {activeState ? <Activated /> : <Reactivated />}

            <MHSwitch
              label={``}
              value={activeState}
              onChange={openContractModal}
            />
          </Box>
        ) : null}
        <Box display="flex" justifyContent="start" my={2}>
          <Typography
            variant="h1"
            fontSize={{ xs: "20px", sm: "30px" }}
            align="center"
            mt={3}
            gutterBottom
            display={"flex"}
            alignSelf={"start"}
            className=" capitalize"
          >
            {employee.firstName + " " + employee.lastName}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="left" my={1}>
          <Box className=" overflow-auto md:overflow-auto lg:overflow-visible h-full">
            <EmployeeProfileTab employee={employee} />
          </Box>
        </Box>

        <ConfirmationDialog
          content={` Are you sure you want to proceed with this action?`}
          open={discardState}
          onClose={closeContractModal}
          handleApprove={handleSaveChange}
          handleCancel={closeContractModal}
        />
      </Box>
    </React.Fragment>
  );
};

export default Profile;
