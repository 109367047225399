import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import MHButton from "../../components/Common/Button/MHButton";

import { ReactComponent as CalendarIcon } from "../../static/svg/calendar.svg";
import { ReactComponent as MailIcon } from "../../static/svg/envelope.svg";

import RoundedLogoIcon from "../../theme/icons/RoundedLogo";
import ContractCard, { ContractCardProps } from "./ContractItems/ContractCard";
import { Link, useHistory } from "react-router-dom";
import {
  contractHtml,
  householdDefaultContract,
  nannyDefaultContract,
  driverDefaultContract,
  generateUniqueReference,
  chefDefaultContract,
  guardDefaultContract,
  tutorDefaultContract,
} from "../../utils/contracts";
import AuthContext from "../../store/context/auth-context";
import { useOnboardForm } from "../../store/context/onboard-context";
import DashboardContext from "../../store/context/dashboard.context";
import ContractHeader from "./ContractItems/ContractHeader";
import ContractModal from "./ContractItems/ContractModal";
// import ConfirmationDialog from "./ConfirmationDialog";
import EmployeeContractDialog from "./ContractItems/EmployeeContractDialog";
import ConfirmationDialog from "../../components/Common/Dialog/ConfirmationDialog";
import dayjs from "dayjs";
import SnackbarContext from "../../store/context/snackbar.context";

import "../../../src/CustomEditor.scss";
import ReactQuill from "react-quill";

type Props = {};

const ViewContractPage = (props: Props) => {
  const { contractForm, clearContract } = useOnboardForm();

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const employer = `${organization?.firstName} ${organization?.lastName}`; // Format employer as a string

  const [inviteState, setInviteState] = useState(false);

  const handleInviteOpen = () => {
    setInviteState(true);
  };
  const handleInviteClose = () => {
    setInviteState(false);
  };

  // let invitation =
  //   contractForm.employerSignature !== ""
  //     ? false
  //     : contractForm.contractInvite === "pending"
  //     ? true
  //     : true;

  let invite =
    contractForm.contractInvite === ""
      ? true
      : contractForm.contractInvite === "process"
      ? false
      : contractForm.contractInvite === "pending"
      ? true
      : true;

  const currentDate = dayjs().format("MMMM DD, YYYY");

  // console.log(currentDate);
  //   console.log("organization", organization);

  function copyPageLinkToClipboard() {
    const currentPageLink = window.location.href;
    navigator.clipboard
      .writeText(currentPageLink)
      .then(() => {
        // console.log("Page link copied to clipboard:", currentPageLink);
        // You can provide feedback to the user that the link has been copied successfully
        toast({
          message: `Page link copied to clipboard`,
          variant: "success",
        });
      })
      .catch((error) => {
        // console.error("Error copying page link to clipboard:", error);
        // Handle any errors that may occur during copying
        toast({
          message: `Error copying page link to clipboard`,
          variant: "error",
        });
      });
  }

  const STATS: ContractCardProps[] = [
    {
      theme: "default",
      icon: (
        <RoundedLogoIcon
          sx={{
            p: 0.6,
            mb: 2,
            backgroundColor: "#C7D8E4",
          }}
        >
          <CalendarIcon className="w-6 h-6" />
        </RoundedLogoIcon>
      ),
      title: "Start Date",
      stat: currentDate,
      pattern: false,
      divide: true,
    },
    {
      theme: "default",
      icon: <Avatar className="w-16 h-16" src="" alt={employer} />,
      name: employer,
      employerEmail: organization?.email,
      pattern: true,
      buttonLabel: "Invite Employee",
      disableBtn: invite,
      onClick: handleInviteOpen,
    },
  ];
  const steps = ["Contract Created", "Invitation Sent", "Employee Signature"];

  //   const uniqueReference = generateUniqueReference();
  //   console.log("uniqueReference", uniqueReference);

  const [formState, setFormState] = useState(false);
  const [discardState, setDiscardState] = useState(false);
  const [contractData, setContractData] = useState("");

  let history = useHistory();
  const discardChanges = () => {
    clearContract();
    setDiscardState(false);
    history.push("/organization/contract");
  };

  const openContract = () => {
    setFormState(true);
  };
  const handleContractClose = () => {
    setFormState(false);
  };

  const openContractModal = () => {
    setDiscardState(true);
  };
  const closeContractModal = () => {
    setDiscardState(false);
  };

  const locations = [
    // {
    //   label: "Preview",
    //   link: `/organization/contract/preview/${contractForm.contractRef}`,
    // },
    {
      label: "Cancel Contract",
      link: "",
      path: openContractModal,
    },
    {
      label: "Edit Template",
      link: `/organization/contract/edit/${contractForm.contractRef}`,
    },
  ];

  React.useEffect(() => {
    // if (contractForm.editorHtml === "") {
    if (contractForm?.role === "nanny") {
      setContractData(nannyDefaultContract);
    } else if (contractForm?.role === "driver/chauffeur") {
      setContractData(driverDefaultContract);
    } else if (contractForm?.role === "chef/cook") {
      setContractData(chefDefaultContract);
    } else if (contractForm?.role === "security guard") {
      setContractData(guardDefaultContract);
    } else if (contractForm?.role === "home tutor") {
      setContractData(tutorDefaultContract);
    } else {
      // Default Contract for all, but now we are using nanny as default
      setContractData(householdDefaultContract);
    }
    // } else {
    //   setContractData(contractForm?.editorHtml || ""); // Provide a default value of '' if contractForm?.editorHtml is undefined
    // }
  }, [contractForm?.role]);

  return (
    <React.Fragment>
      <ContractHeader
        label={`Contract For:`}
        label2={`${contractForm.employeeName}`}
        // buttonLabel="New Contract"
        // handleContract={openContract}
      />

      <Grid
        container
        spacing={0}
        className=" place-content-start md:justify-between gap-2 md:gap-0 bg-[#F9F9F7]"
      >
        {STATS.map((stat, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={index === 0 ? 5 : 7} // Set md value based on index
            key={stat.title}
            p={4}
            // onClick={handleOpen}
            sx={{
              // cursor: "pointer",
              backgroundColor: "#F9F9F7",
              borderRadius: 3,
            }}
            className=" order-1 lg:order-2"
          >
            <Box className=" " position={"relative"}>
              <ContractCard {...stat} />
            </Box>
          </Grid>
        ))}

        {contractForm.contractInvite === "process" ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            // px={4}
            // py={0}
            sx={{
              // cursor: "pointer",
              backgroundColor: "#F9F9F7",
              borderRadius: 3,
            }}
            className="order-3 mx-auto px-0 md:px-4 py-0"
          >
            <Box
              px={8}
              py={4}
              bgcolor={"common.white"}
              borderRadius={2}
              boxShadow="0px 5px 26px #C7D8E4"
              minHeight={100}
              sx={{
                gap: 2,
                pt: 5,
                mb: 3,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box className=" lg:flex justify-evenly items-center space-x-6">
                <Box className="order-1 lg:order-none py-2 flex justify-center space-x-6">
                  <Avatar
                    sx={{ bgcolor: "#28404A" }}
                    className="w-12 h-12 md:w-16 md:h-16"
                    src={contractForm.employeeEmail}
                    alt={"M"}
                  >
                    <MailIcon className="w-5 h-5 md:w-8 md:h-8" />
                  </Avatar>
                  <Box>
                    <Typography
                      //   justifyItems={"center"}
                      variant="subtitle1"
                      fontSize={{ xs: "15px", sm: "18px", md: "20px" }}
                      color={"primary.main"}
                      //   gutterBottom
                      className={`pt-2`}
                    >
                      Invitation pending
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                      color={"primary.main"}
                      gutterBottom
                      className={`opacity-60 rounded-md bg-[#EEEEEE] text-[#A9A9A9] px-3 py-0 w-fit`}
                    >
                      Household Staff
                    </Typography>
                  </Box>
                </Box>

                <Box className="order-2 lg:order-none lg:float-right block md:flex justify-center  lg:block lg:space-x-0">
                  <Box className="w-full centerItems">
                    <Typography
                      //   justifyItems={"center"}
                      variant="subtitle1"
                      fontSize={{ xs: "10px", sm: "18px", md: "14px" }}
                      color={"primary.main"}
                      //   gutterBottom
                      className={`capitalize flex items-center justify-between gap-6`}
                    >
                      Email:
                      <p className=" lowercase font-areaSemi">
                        {contractForm.employeeEmail}
                      </p>
                    </Typography>
                  </Box>
                  <Box className="w-full centerItems">
                    <Typography
                      //   justifyItems={"center"}
                      variant="subtitle1"
                      fontSize={{ xs: "10px", sm: "18px", md: "14px" }}
                      color={"primary.main"}
                      //   gutterBottom
                      className={`capitalize flex items-center justify-between gap-6`}
                    >
                      Whatsapp:
                      <p className=" lowercase font-areaSemi">
                        +{contractForm.employeePhone}
                      </p>
                    </Typography>
                  </Box>
                </Box>

                <Box className="order-3 lg:order-none lg:float-right pt-4 flex justify-center space-x-6 lg:block lg:space-x-0">
                  <Box className=" items-center">
                    <MHButton
                      //   type="submit"
                      form="add-employee-form"
                      //   loading={loading}
                      className="rounded-[4px] cursor-pointer "
                      //   onClick={deleteContract}
                      sx={{
                        width: { xs: 180, sm: 180, md: 180 },
                        bgcolor: "#E0CBCB",
                        color: "#C27171",
                        "&:hover": {
                          // Define hover styles here
                          bgcolor: "#E0CBCB",
                          color: "#C27171",
                        },
                      }}
                    >
                      Cancel Invitation
                    </MHButton>

                    <Box className="centerItems">
                      <Button
                        onClick={copyPageLinkToClipboard}
                        className="no-underline cursor-pointer -ml-2"
                      >
                        <Typography
                          variant="body1"
                          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                          color={"#0060F0"}
                          gutterBottom
                          className={`capitalize py-1`}
                        >
                          Copy invitation link
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          sm={12}
          md={8.5}
          p={4}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#F9F9F7",
            borderRadius: 3,
          }}
          className="order-4"
        >
          <Box
            p={3}
            bgcolor={"common.white"}
            borderRadius={2}
            boxShadow="0px 5px 26px #C7D8E4"
            minHeight={180}
            sx={{
              gap: 2,
              pt: 5,
              mb: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "18px", md: "18px" }}
              color={"primary.main"}
              gutterBottom
              className={`capitalize`}
            >
              {editorHtml}
            </Typography> */}

            {/* <Box className="relative quillEditor">
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: contractData }}
              />
            </Box> */}

            <Box className="relative quillEditor">
              <ReactQuill
                value={contractData}
                readOnly={true}
                theme={"bubble"}
              />
            </Box>

            <Box
              mt={12}
              mb={0}
              className=" flex justify-between items-baseline"
            >
              <Box className="space-y-4">
                <Box>
                  {contractForm.employerSignature && (
                    <>
                      <img
                        src={contractForm.employerSignature}
                        alt="signature"
                        className="signature"
                      />
                    </>
                  )}
                  <hr className="w-48" />
                </Box>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                  className=" capitalize"
                >
                  {`${organization?.firstName + " " + organization?.lastName} `}
                </Typography>
              </Box>
              <Box className="space-y-4">
                <Box>
                  {contractForm.employeeSignature && (
                    <>
                      <img
                        src={contractForm.employeeSignature}
                        alt="signature"
                        className="signature"
                      />
                    </>
                  )}
                  <hr className="w-48" />
                </Box>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                  className=" capitalize"
                >
                  {`${
                    contractForm.employeeName === ""
                      ? "[Employee Name]"
                      : contractForm.employeeName
                  } `}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={3.5}
          p={4}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#F9F9F7",
            borderRadius: 3,
          }}
          className="order-5"
        >
          <Box
            p={3}
            bgcolor={"common.white"}
            borderRadius={2}
            boxShadow="0px 5px 26px #C7D8E4"
            minHeight={180}
            sx={{
              gap: 2,
              pt: 5,
              mb: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "10px", sm: "18px", md: "18px" }}
              color={"primary.main"}
              gutterBottom
              className={`capitalize ml-6`}
            >
              Contractor Timeline
            </Typography>

            <Stepper
              className="ml-6 py-5"
              activeStep={2}
              orientation="vertical"
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>
                    <Typography
                      variant="subtitle1"
                      fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                      color={"primary.main"}
                      gutterBottom
                      className={`capitalize`}
                    >
                      {step}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box className=" text-center">
              {contractForm.contractRef !== "" ? (
                <MHButton
                  //   type="submit"
                  form="add-employee-form"
                  //   loading={loading}
                  className="rounded-[4px] cursor-pointer "
                  //   onClick={clearContract}
                  sx={{
                    width: { xs: 180, sm: 180, md: 180 },
                  }}
                >
                  Download PDF
                </MHButton>
              ) : null}

              <Box className="text-start ml-14 py-8">
                {locations.map((item, index) =>
                  item.link.includes("/organization") ? (
                    contractForm.contractRef !== "" ? (
                      contractForm.contractInvite === "pending" ? null : (
                        <Link
                          to={item.link}
                          key={index}
                          className="no-underline cursor-pointer"
                        >
                          <Typography
                            variant="subtitle1"
                            fontSize={{ xs: "12px", sm: "12px", md: "13px" }}
                            color={"primary.main"}
                            gutterBottom
                            className={`capitalize py-1`}
                          >
                            {item.label}
                          </Typography>
                        </Link>
                      )
                    ) : null
                  ) : contractForm.contractRef !== "" ? (
                    contractForm.contractInvite === "pending" ? null : (
                      <Button
                        onClick={item.path}
                        className="no-underline cursor-pointer -ml-2"
                      >
                        <Typography
                          variant="subtitle1"
                          fontSize={{ xs: "12px", sm: "12px", md: "13px" }}
                          color={"primary.main"}
                          gutterBottom
                          className={`capitalize py-1`}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    )
                  ) : null
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* <MHEditor />
      <br />
      <br />
      <br />
      <br />
      <br /> */}
      <ContractModal open={formState} onClose={handleContractClose} />
      <EmployeeContractDialog open={inviteState} onClose={handleInviteClose} />
      <ConfirmationDialog
        content={` Are you sure you want to cancel contract?`}
        open={discardState}
        onClose={closeContractModal}
        handleApprove={discardChanges}
        handleCancel={closeContractModal}
      />
    </React.Fragment>
  );
};

export default ViewContractPage;
