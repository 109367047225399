import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import MHDatePicker from "../../Common/Form/MHDatePicker";
import dayjs, { Dayjs } from "dayjs";
import MuiLink from "@mui/material/Link";

import { ReactComponent as DiskIcon } from "../../../static/svg/disk-icon.svg";
import { ReactComponent as CalendarIcon } from "../../../static/svg/calend.svg";
import { ReactComponent as PdfIcon } from "../../../static/svg/pdf.svg";
import { ReactComponent as ArrowBackIcon } from "../../../static/svg/arrow-left.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../static/svg/arrow-right.svg";
import { matchPath, useLocation } from "react-router-dom";
// import Pagination from "../../Common/UI/Pagination";
import MHSlider, {
  MHSliderItem,
} from "../../Common/SliderCarousel/Slider/MHSlider";
import { MHPagination } from "../../Common/UI/MHPagination";

type PayProps = {
  title?: string;
  size?: string;
  date?: string;
};
type Props = {
  payslips: PayProps[];
};

const PaymentSlips = (props: Props) => {
  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    dayjs("2024-04-17")
  );
  const [selectedMonth, setSelectedMonth] = React.useState("");

  const handleDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    const monthInText = newValue ? newValue.format("MMMM") : "";
    setSelectedMonth(monthInText);
  };

  const handleDownload = () => {
    console.log("Download");
  };

  const [pageType, setPageType] = React.useState(1);
  const theme = useTheme();

  // 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const isWeb = useMediaQuery(theme.breakpoints.down("lg"));

  React.useEffect(() => {
    if (isMobile) {
      setPageType(12);
      setPostsPerPage(12);
    } else if (isTab) {
      setPageType(12);
      setPostsPerPage(12);
    } else {
      setPageType(6);
      setPostsPerPage(6);
    }
  }, [isMobile, isTab, isWeb]);

  //   Training Pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(pageType);
  //   const lastPostIndex = currentPage * postsPerPage;
  //   const firstPostIndex = lastPostIndex - postsPerPage;
  //   const currentPosts =
  //     props.payslips && props.payslips.slice(firstPostIndex, lastPostIndex);

  const [filteredPayslips, setFilteredPayslips] = React.useState<PayProps[]>(
    props.payslips
  );

  // Get month in text

  React.useEffect(() => {
    const filterPayslips = () => {
      if (selectedMonth !== "") {
        const filtered = props.payslips.filter((payslip) => {
          const payslipMonth = dayjs(payslip.date).format("MMMM");
          return payslipMonth === selectedMonth;
        });
        setFilteredPayslips(filtered);
      } else {
        setFilteredPayslips(props.payslips);
      }
    };

    filterPayslips();
  }, [selectedMonth, props.payslips]);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredPayslips.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 4;
  let noOfPage = Math.ceil((currentPosts?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const location = useLocation();

  const dashboardMatch = matchPath(location.pathname, {
    path: "/organization/dashboard",
    exact: true,
    strict: true,
  });

  //   console.log("currentPosts: ", currentPosts);

  return (
    <React.Fragment>
      <Box className=" bg-white hidden lg:block rounded-lg">
        <Box className=" mx-auto w-[90%] ">
          {/* h-[70%] w-[60%] */}
          <Box className=" flex items-center justify-between ">
            <Box>
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "20px", sm: "24px", md: "24px" }}
                gutterBottom
                className=" capitalize"
              >
                Payment Slip
              </Typography>
            </Box>
            <MuiLink
              // component={Link}
              underline="always"
              // to="/organization/employee/dashboard"
              onClick={() => setSelectedMonth("")}
              // display="block"
              // align="left"
              className="text-blue-100 mt-2 mb-4 no-underline cursor-pointer centerItems"
            >
              Clear Months
            </MuiLink>
            <Box className=" w-2/6">
              <MHDatePicker
                value={startDate} // Convert startDate to Dayjs if needed
                // onChange={(newvalue) => {
                //   setStartDate(newvalue);
                //   const monthInText = startDate ? startDate.format("MMMM") : "";
                //   setSelectedMonth(monthInText);
                // }}
                onChange={handleDateChange}
              />
            </Box>
          </Box>
        </Box>

        <Divider />
        <Box className="mt-5 mx-auto md:w-[90%] lg:w-[90%]">
          {/* h-[70%] w-[60%] */}
          <Box className=" grid grid-cols-3  ">
            {currentPosts &&
              currentPosts.map((item, index) => (
                <Box className="bg-white w-60 rounded-lg mb-5 drop-shadow-lg">
                  <Box className="bg-gray-50 flex gap-3 p-5 items-center justify-center rounded-t-lg">
                    <Box className="">
                      <PdfIcon />
                    </Box>
                    <Typography
                      variant="subtitle1"
                      fontSize={{ xs: "12px", sm: "12px" }}
                      // align="center"
                      //   mt={5}
                      //   mb={3}
                      //   gutterBottom
                      //   display={"flex"}
                      //   alignSelf={"center"}
                    >
                      {item.title}
                    </Typography>
                  </Box>

                  <Box>
                    <Box className="flex gap-3 px-5 items-center justify-center my-3">
                      <Box className="">
                        <DiskIcon />
                      </Box>
                      <Box className=" mr-auto ml-5">
                        <Typography
                          variant="body1"
                          fontSize={{ xs: "12px", sm: "12px" }}
                          className=" text-ash-50 mt-1"
                          // align="center"
                          // mt={3}
                          // gutterBottom
                          // display={"flex"}
                          // alignSelf={"center"}
                        >
                          {item.size}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="flex gap-3 px-5 items-center justify-center my-3">
                      <Box className="">
                        <CalendarIcon />
                      </Box>
                      <Box className=" mr-auto ml-5">
                        <Typography
                          variant="body1"
                          fontSize={{ xs: "12px", sm: "12px" }}
                          className=" text-ash-50 mt-1"
                          // align="center"
                          // mt={3}
                          // gutterBottom
                          // display={"flex"}
                          // alignSelf={"center"}
                        >
                          {item.date}
                        </Typography>
                      </Box>
                    </Box>

                    <MuiLink
                      // component={Link}
                      underline="always"
                      // to="/organization/employee/dashboard"
                      onClick={handleDownload}
                      // display="block"
                      // align="left"
                      className="text-blue-100 mt-2 mb-4 no-underline cursor-pointer centerItems"
                    >
                      Download
                    </MuiLink>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>

        {/* Pagination */}
        <Box display={"flex"} justifyContent={"center"} my={2}>
          <MHPagination
            count={noOfPage}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            color={"primary"}
          />
        </Box>

        {/* {props.payslips && (
          <Box className="hidden md:block ">
            <Pagination
              totalPosts={
                selectedMonth === ""
                  ? props.payslips.length
                  : currentPosts.length
              }
              postsPerPage={postsPerPage}
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              onClicked={() => {
                setCurrentPage(currentPage + 1);
              }}
              currentPage={currentPage}
            />
          </Box>
        )} */}
      </Box>
      <Box className=" bg-white lg:hidden block">
        <Box className=" mx-auto w-[99%]">
          {/* h-[70%] w-[60%] */}
          <Box className=" flex items-center justify-between ">
            <Box>
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "16px", sm: "20px", md: "24px" }}
                gutterBottom
                className=" capitalize"
              >
                Payment Slip
              </Typography>
            </Box>
            <MuiLink
              // component={Link}
              underline="always"
              // to="/organization/employee/dashboard"
              onClick={() => setSelectedMonth("")}
              // display="block"
              // align="left"
              className="text-blue-100 mt-2 mb-4 no-underline cursor-pointer centerItems"
            >
              {isMobile ? "Clear" : "Clear all"}
            </MuiLink>
            <MHDatePicker
              value={startDate} // Convert startDate to Dayjs if needed
              // onChange={(newvalue) => {
              //   setStartDate(newvalue);
              //   const monthInText = startDate ? startDate.format("MMMM") : "";
              //   setSelectedMonth(monthInText);
              // }}
              onChange={handleDateChange}
            />
          </Box>
        </Box>

        <MHSlider
          slidesToShow={dashboardMatch ? 1 : 2}
          slidesToScroll={1}
          className="block lg:hidden"
        >
          {currentPosts &&
            currentPosts.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <Box className="bg-white w-[270px] rounded-lg mb-5 drop-shadow-lg">
                  <Box className="bg-gray-50 flex gap-3 p-5 items-center justify-center rounded-t-lg">
                    <Box className="">
                      <PdfIcon />
                    </Box>
                    <Typography
                      variant="subtitle1"
                      fontSize={{ xs: "12px", sm: "12px" }}
                      // align="center"
                      //   mt={5}
                      //   mb={3}
                      //   gutterBottom
                      //   display={"flex"}
                      //   alignSelf={"center"}
                    >
                      {item.title}
                    </Typography>
                  </Box>

                  <Box>
                    <Box className="flex gap-3 px-5 items-center justify-center my-4">
                      <Box className="">
                        <DiskIcon />
                      </Box>
                      <Box className=" mr-auto ml-5">
                        <Typography
                          variant="body1"
                          fontSize={{ xs: "12px", sm: "12px" }}
                          className=" text-ash-50 mt-1"
                          // align="center"
                          // mt={3}
                          // gutterBottom
                          // display={"flex"}
                          // alignSelf={"center"}
                        >
                          {item.size}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="flex gap-3 px-5 items-center justify-center my-4">
                      <Box className="">
                        <CalendarIcon />
                      </Box>
                      <Box className=" mr-auto ml-5">
                        <Typography
                          variant="body1"
                          fontSize={{ xs: "12px", sm: "12px" }}
                          className=" text-ash-50 mt-1"
                          // align="center"
                          // mt={3}
                          // gutterBottom
                          // display={"flex"}
                          // alignSelf={"center"}
                        >
                          {item.date}
                        </Typography>
                      </Box>
                    </Box>

                    <MuiLink
                      // component={Link}
                      underline="always"
                      // to="/organization/employee/dashboard"
                      onClick={handleDownload}
                      // display="block"
                      // align="left"
                      className="text-blue-100 mt-2 mb-4 pb-4  no-underline cursor-pointer centerItems"
                    >
                      Download
                    </MuiLink>
                  </Box>
                </Box>
              </MHSliderItem>
            ))}
        </MHSlider>
      </Box>
    </React.Fragment>
  );
};

export default PaymentSlips;
