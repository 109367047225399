// EmployeeDialog.tsx
import React from "react";

import { EmployeeOnboarding } from "./EmployeeOnboarding";
import MHDialog from "../../components/Common/Dialog/MHDialog";
import CBDialog from "../../components/Common/Dialog/CBDialog";

interface EmployeeDialogProps {
  open: boolean;
  onClose: () => void;
}
// Household Employee Details
const EmployeeDialog: React.FC<EmployeeDialogProps> = ({ open, onClose }) => {
  return (
    <CBDialog
      open={open}
      title={`Household Employee Details`}
      handleClose={onClose}
    >
      {<EmployeeOnboarding />}
    </CBDialog>
  );
};

export default EmployeeDialog;
