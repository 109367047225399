import React from "react";
import { Link, useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";

import MHButton from "../../components/Common/Button/MHButton";
import MHFormControl from "../../components/Common/Form/MHFormControl";
import InputAdornment from "../../components/Common/Form/InputAdornment";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import useTitle from "../../hooks/use-title";

import { ReactComponent as MailIcon } from "../../static/svg/mail.svg";
import { ReactComponent as InfoRoundedIcon } from "../../static/svg/info-rounded.svg";
import { FnComponent } from "../../models/component.model";
// import { BGImage } from "../../models/background-image.model";
import * as validators from "../../utils/validators";
import { HttpResponse } from "../../models/api.interface";
import { EMAIL_FROM } from "../../utils/constants";

const ForgotPassword: FnComponent<{
  // onRouteChange: (image: BGImage) => void;
  title: string;
}> = (props) => {
  // const { onRouteChange } = props;

  const { loading, error, sendHttpRequest: sendResetLink } = useHttp();
  useTitle(props.title);

  const history = useHistory();

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: emailInputHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.email(value) }]);

  const emailErrorTip = emailInputHasError ? "Please enter a valid email" : "";

  // React.useEffect(() => {
  //   onRouteChange({
  //     imageSrc:
  //       "https://res.cloudinary.com/mother-honestly/image/upload/v1657836148/lawrence-crayton-KXOaNSU63NE-unsplash_1_c4copk.png",
  //     imageAlt: "Lawrence Crayton",
  //   });
  // }, [onRouteChange]);

  const sendResetLinkHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (emailInputHasError && !enteredEmailIsValid) {
      return;
    }

    sendResetLink(
      process.env.REACT_APP_API_BASE_URL + "employee/dashboard/passwordreset",
      {
        method: "PUT",
        body: JSON.stringify({
          email: enteredEmail,
        }),
      },
      (response: HttpResponse<unknown>) => {
        history.push({
          pathname: "/auth/forgot-password/reset-link",
          state: {
            email: enteredEmail,
          },
        });
      }
    );
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          px: 3,
          py: 3,
          width: "100%",
        }}
        className={enteredEmail ? "h-full" : "h-[400px]"}
      >
        <Box sx={{}}>
          {/* <Typography
            variant="body2"
            color="#6F6F6F"
            component={"div"}
            gutterBottom
            mb={2}
          >
            Let's get you into your account
          </Typography> */}

          <Box className=" place-content-center text-center space-y-6 mb-10">
            <Typography
              variant="h1"
              component="h1"
              fontSize={{ xs: "24px", sm: "30px", md: "30px" }}
              gutterBottom
              mb={1}
            >
              Can’t remember your password?
            </Typography>

            <Typography variant="body2" component={"div"} gutterBottom mb={3}>
              Not a problem! Just enter your email address and we’ll send you a
              link to reset it.
            </Typography>
          </Box>

          {error && (
            <Alert
              severity="error"
              sx={{
                mb: 3,
              }}
            >
              {error.message}
            </Alert>
          )}

          <Box component={"form"} onSubmit={sendResetLinkHandler}>
            <MHFormControl
              id="email"
              type="email"
              label="Email"
              placeholder="Enter your email address"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              error={emailErrorTip}
              startAdornment={
                <InputAdornment>
                  <MailIcon width="1rem" />
                </InputAdornment>
              }
              required
            />

            {enteredEmail && (
              <ListItem
                sx={{
                  background: "#ECF7FF",
                  border: 0.2,
                  borderColor: "#194049",
                  color: "#6B6B6B",
                  my: 3,
                  py: 3,
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt="!"
                    sx={{
                      bgcolor: "transparent",
                    }}
                  >
                    <InfoRoundedIcon width="100%" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  If your email address exists in our database, youll receive an
                  email with instructions to reset your password.
                </ListItemText>
              </ListItem>
            )}

            <MHButton sx={{ mb: 2 }} type="submit" loading={loading} fullWidth>
              Send reset link
            </MHButton>

            <MuiLink
              component={Link}
              underline="always"
              to="/auth/sign-in"
              display="block"
              align="left"
              className="text-blue-100 removeUnderline"
            >
              Go back to the login page
            </MuiLink>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ForgotPassword;
