import React from "react";
import { Backdrop, Box, Button, Typography } from "@mui/material";
import MHButton from "../../Common/Button/MHButton";
import AuthContext from "../../../store/context/auth-context";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const CongratsCard = (props: Props) => {
  const authCtx = React.useContext(AuthContext);

  const { user } = authCtx;
  // {authCtx.user?.firstName}

  const gotoCaringBlocks = () => {
    window.open("https://caringblocks.com");
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
        onClick={props.handleClose}
      >
        <Box className="bg-white w-[520px]">
          <Box className="centerItems">
            <Typography
              variant="body1"
              fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
              gutterBottom
              className=" text-start md:w-[80%] lg:w-[80%] py-6"
            >
              Congratulations{" "}
              <strong className="font-areaBlack">
                {user?.firstName + " " + user?.lastName}! 🎉
              </strong>
            </Typography>
          </Box>

          <Box className="centerItems">
            <Button className=" no-underline" onClick={gotoCaringBlocks}>
              {" "}
              <Typography
                variant="body1"
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                gutterBottom
                className=" text-start md:w-[80%] lg:w-[80%] py-5"
              >
                Your dashboard is where everything takes place, so don't delay.
                <br />
                <br />
                When you're ready, please visit{" "}
                <strong className=" text-blue-100">
                  caringblocks.com,
                </strong>{" "}
                and log in with your email and password to proceed.
                <br />
                <br />
                Best regards
              </Typography>
            </Button>
          </Box>

          <Box className="centerItems py-5">
            <MHButton
              //   type="submit"
              form="add-employee-form"
              //   loading={loading}
              className=" rounded-md cursor-pointer w-[108px] h-[38px]"
              // fullWidth
              onClick={props.handleClose}
            >
              Close
            </MHButton>
          </Box>
        </Box>
      </Backdrop>
    </React.Fragment>
  );
};

export default CongratsCard;
