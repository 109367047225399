import React from 'react';

const useDialog = (action?: { onOpen: () => void; onClose: () => void; }) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    action && action.onOpen();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    action && action.onClose();
  };

  return {
    openDialog,
    handleOpenDialog,
    handleCloseDialog
  };
};

export default useDialog;
