// PaymeDialog.tsx
import React from "react";
import MHDialog from "../../Common/Dialog/MHDialog";
import { Box, Button, Typography, IconButton } from "@mui/material";
import MHButton from "../../Common/Button/MHButton";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";

import useInput from "../../../hooks/use-input";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";

import { ReactComponent as WalletIcon } from "../../../static/svg/wallets.svg";
import MHPhoneInput from "../../Common/Form/MHPhoneInput";
import usePhoneInput from "../../../hooks/use-phone";
import { resolveErrorMessage } from "../../../utils/utils";
import useHttp from "../../../hooks/use-http";
import DashboardContext from "../../../store/context/dashboard.context";

interface PaymeDialogProps {
  open: boolean;
  onClose: () => void;
}

const PaymeDialog: React.FC<PaymeDialogProps> = ({ open, onClose }) => {
  const {
    value: enteredAmount,
    valid: enteredAmountIsValid,
    error: amountInputHasError,
    onChange: amountInputChangeHandler,
    onBlur: amountInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPaymentReason,
    valid: enteredPaymentReasonIsValid,
    error: paymentReasonInputHasError,
    onChange: paymentReasonInputChangeHandler,
    onBlur: paymentReasonInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const dashboardCtx = React.useContext(DashboardContext);

  const { organization } = dashboardCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const phoneUseNumberIsValid = phoneNumber.trim() !== ""; // Or use a more complex regex check
  const filteredNumber = enteredAmount?.replace(/\D/g, "");
  const filteredPhoneNumber = phoneNumber?.replace(/\s/g, "");

  const formIsValid =
    enteredAmountIsValid &&
    enteredPaymentReasonIsValid &&
    phoneUseNumberIsValid;

  const reqBody = {
    customerId: organization?.customerId,
    serviceDetail: enteredPaymentReason,
    amount: Number(filteredNumber),
    recipientWhatsAppNo: filteredPhoneNumber,
  };

  const submitHandler = async () => {
    if (!formIsValid) {
      toast({
        variant: "warning",
        message: "All fields are required",
      });
      return;
    }

    // console.log("reqBody", reqBody);

    const url = process.env.REACT_APP_API_BASE_URL + `/tranx/payment-details`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        // Parse the response body as JSON
        const resData = await response.json();
        console.log("resData", resData); // Correctly accessing the 'data' property
        toast({
          variant: "success",
          message: "Payment request sent successfully.",
        });
        onClose();
      } else {
        toast({
          message: "Payment request failed.",
          variant: "error",
        });
        onClose();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast({
        message: `An error occurred: ${error}`,
        variant: "error",
      });
      onClose();
      return null;
    }
  };

  return (
    <MHDialog
      open={open}
      title={` `}
      handleClose={onClose}
      maxWidth="md"
      // actions={}
      scroll="paper"
    >
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "450px" }}
        width={{ xs: "full", sm: "400px" }}
        className=" ml-0 mr-0 place-content-center items-center mx-auto "
      >
        <Box
          display="flex"
          alignItems="center"
          // alignSelf="center"
          // alignContent="center"
          justifyContent="center"
          mt={1}
        >
          <RoundedLogoIcon sx={{ backgroundColor: "#C7D8E4" }}>
            <WalletIcon className="w-6 h-6" />
          </RoundedLogoIcon>
          <Typography
            variant="h1"
            fontSize={{ xs: "18px", sm: "20px" }}
            align="center"
            gutterBottom
            display={"flex"}
            alignSelf={"center"}
            ml={2}
          >
            Request Payment
          </Typography>
        </Box>

        <Box
        // component="form"
        // id="payment-request-form"
        // onSubmit={submitHandler}
        // autoComplete="off"
        // noValidate
        >
          <Box display="flex" justifyContent="center" mt={4}>
            <Box width="280px">
              <MHFormControl
                id="amount"
                type="number"
                label="Enter amount"
                placeholder=""
                value={enteredAmount}
                onChange={amountInputChangeHandler}
                onBlur={amountInputBlurHandler}
                startAdornment={
                  <InputAdornment className="!bg-[#D9D9D9] md:h-[43px]">
                    &#x20A6;
                  </InputAdornment>
                }
                required
                autoFocus
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box width="280px">
              <MHFormControl
                id="reason"
                type="text"
                label="Reason for payment"
                placeholder="Why should this person pay you?"
                value={enteredPaymentReason}
                onChange={paymentReasonInputChangeHandler}
                onBlur={paymentReasonInputBlurHandler}
                required
                autoFocus
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box width="280px">
              <MHPhoneInput
                value={phoneNumber}
                onChange={phoneNumberInputChangeHandler}
                label="Whatsapp Number"
                errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                  "Please enter a valid phone number"
                )}
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box my={2} width={{ xs: "100%", sm: "280px" }}>
              <MHButton
                color="primary"
                sx={{}}
                // type="submit"
                className=" "
                fullWidth
                onClick={submitHandler}
              >
                Submit Request
              </MHButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </MHDialog>
  );
};

export default PaymeDialog;
