import React, { useState } from "react";
import InputUnstyled, {
  InputUnstyledProps,
  inputUnstyledClasses,
} from "@mui/base/InputUnstyled";
import { styled } from "@mui/system";
import { IconButton, InputAdornment, Typography } from "@mui/material";

import { ReactComponent as Visibility } from "../../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../static/svg/visibility-off.svg";
import { ReactComponent as CheckCircleOutline } from "../../../static/svg/check-filled.svg";
import { ReactComponent as UnCheckedCircleOutline } from "../../../static/svg/check-unfilled.svg";

interface PasswordInputProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  minLength?: number;
  requiredUppercase?: boolean;
  requiredLowercase?: boolean;
  requiredNumber?: boolean;
  requiredSpecialChar?: boolean;
}

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  display: flex;
  font-weight: 400;
  border: 1px solid ${theme.palette.grey[200]};
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in;
  position: relative;
`
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
  
  width: 180%;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: ${theme.palette.grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 12px 12px;
  outline: 0;
  box-sizing: border-box;
`
);

const IconWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const MHPasswordControl: React.FC<PasswordInputProps> = ({
  label = "Password",
  value,
  onChange,
  minLength = 8,
  requiredUppercase = true,
  requiredLowercase = true,
  requiredNumber = true,
  requiredSpecialChar = true,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validatePassword = () => {
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);

    return {
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
    };
  };

  const { hasUppercase, hasLowercase, hasNumber, hasSpecialChar } =
    validatePassword();

  const isPasswordValid =
    value.length >= minLength &&
    (!requiredUppercase || hasUppercase) &&
    (!requiredLowercase || hasLowercase) &&
    (!requiredNumber || hasNumber) &&
    (!requiredSpecialChar || hasSpecialChar);

  return (
    <div>
      <StyledInputRoot className="mb-6">
        <InputUnstyled
          components={{
            Input: StyledInputElement,
          }}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={handleChange}
        />
        <InputAdornment position="end">
          <IconButton onClick={toggleShowPassword}>
            {showPassword ? (
              <VisibilityOff className="h-4 w-4" />
            ) : (
              <Visibility className="h-4 w-4" />
            )}
          </IconButton>
        </InputAdornment>
      </StyledInputRoot>
      <Typography
        variant="body2"
        fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
        className={`flex gap-3 items-center py-2 ${
          value.length >= minLength ? " line-through" : null
        }`}
      >
        {value.length >= minLength ? (
          <CheckCircleOutline className=" h-4 w-4" style={{ color: "green" }} />
        ) : (
          <UnCheckedCircleOutline
            className="h-4 w-4"
            style={{ color: "red" }}
          />
        )}{" "}
        At least 8 characters
      </Typography>
      <Typography
        variant="body2"
        fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
        className={`flex gap-3 items-center py-2 ${
          hasUppercase ? " line-through" : null
        }`}
      >
        {hasUppercase ? (
          <CheckCircleOutline className=" h-4 w-4" style={{ color: "green" }} />
        ) : (
          <UnCheckedCircleOutline
            className="h-4 w-4"
            style={{ color: "red" }}
          />
        )}{" "}
        At least 1 Uppercase letter
      </Typography>
      <Typography
        variant="body2"
        fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
        className={`flex gap-3 items-center py-2 ${
          hasLowercase ? " line-through" : null
        }`}
      >
        {hasLowercase ? (
          <CheckCircleOutline className=" h-4 w-4" style={{ color: "green" }} />
        ) : (
          <UnCheckedCircleOutline
            className="h-4 w-4"
            style={{ color: "red" }}
          />
        )}{" "}
        At least 1 lowercase letter
      </Typography>
      <Typography
        variant="body2"
        fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
        className={`flex gap-3 items-center py-2 ${
          hasNumber ? " line-through" : null
        }`}
      >
        {hasNumber ? (
          <CheckCircleOutline className=" h-4 w-4" style={{ color: "green" }} />
        ) : (
          <UnCheckedCircleOutline
            className="h-4 w-4"
            style={{ color: "red" }}
          />
        )}{" "}
        At least 1 number
      </Typography>
      <Typography
        variant="body2"
        fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
        className={`flex gap-3 items-center py-2 ${
          hasSpecialChar ? " line-through" : null
        }`}
      >
        {hasSpecialChar ? (
          <CheckCircleOutline className=" h-4 w-4" style={{ color: "green" }} />
        ) : (
          <UnCheckedCircleOutline
            className="h-4 w-4"
            style={{ color: "red" }}
          />
        )}{" "}
        At least 1 special character
      </Typography>
      {/* {isPasswordValid && (
        <CheckCircleOutline className=" h-4 w-4" style={{ color: "green" }} />
      )} */}
    </div>
  );
};

export default MHPasswordControl;
