import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";
import { Button, Divider, Grid } from "@mui/material";
import WalletPattern from "../../../static/svg/wallet-pattern.svg";
import { ReactComponent as PlusIcon } from "../../../static/svg/plus-lg.svg";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";
import MHButton from "../../../components/Common/Button/MHButton";
import useDialog from "../../../hooks/use-dialog";

// import { formatNumber } from "../../../utils/utils";

export type ContractCardProps = {
  theme: "dark" | "light" | "default";
  icon?: React.ReactElement;
  icon2?: React.ReactElement;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  onClick?: (e: React.MouseEvent) => void;
  title?: string;
  name?: string | any;
  buttonLabel?: string;
  employerEmail?: string;
  stat?: string | number | any;
  pattern?: boolean;
  disableBtn?: boolean;
  divide?: boolean;
  containerSx?: SxProps<Theme>;
};

const ContractCard = ({
  theme,
  icon,
  icon2,
  startIcon,
  endIcon,
  divide,
  name,
  pattern,
  disableBtn,
  employerEmail,
  buttonLabel,
  onClick,
  title,
  stat,
  ...props
}: ContractCardProps) => {
  const formatNumber = (value: any) => {
    // Check if the value is a number
    if (typeof value === "number") {
      // Use Intl.NumberFormat to format the number
      return new Intl.NumberFormat().format(value);
    } else {
      // If it's not a number, return the original string
      return value;
    }
  };

  // const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <Box
        p={3}
        bgcolor={
          theme === "dark"
            ? "primary.main"
            : theme === "default"
            ? "common.white"
            : "#C7D8E4"
        }
        borderRadius={2}
        boxShadow="0px 5px 26px #C7D8E4"
        minHeight={180}
        sx={{
          ...props.containerSx,
          gap: 2,
          pt: 5,
          mb: 3,
          position: "relative",
          overflow: "hidden",
        }}
        // display={"flex"}
        className="block md:flex"
      >
        {pattern === true ? (
          <>
            <Box className=" absolute top-0 right-0">
              <img
                src={WalletPattern}
                alt="error"
                className="object-cover h-fit w-fit z-40 "
              />
            </Box>
          </>
        ) : null}
        <Box>{icon}</Box>

        <Box className="z-10">
          <>
            {title && (
              <Typography
                variant="body1"
                fontSize={{ xs: "14px", sm: "18px", md: "18px" }}
                color={theme === "dark" ? "common.white" : "primary.main"}
                gutterBottom
                className={`capitalize`}
              >
                {title}
              </Typography>
            )}
            {name && (
              <Box className="pt-2 pb-10">
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: "16px", sm: "18px", md: "18px" }}
                  color={theme === "dark" ? "common.white" : "primary.main"}
                  gutterBottom
                  className={`capitalize`}
                >
                  {name}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                  color={theme === "dark" ? "common.white" : "primary.main"}
                  gutterBottom
                  className={`opacity-60`}
                >
                  {employerEmail}
                </Typography>

                <Typography
                  variant="body1"
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color={theme === "dark" ? "common.white" : "primary.main"}
                  gutterBottom
                  className={`opacity-60 rounded-md bg-[#EEEEEE] text-[#A9A9A9] px-3 py-0 w-fit`}
                >
                  Employer
                </Typography>
              </Box>
            )}
            {divide && <Divider className="my-10 w-[300px]" />}

            <Typography
              variant="body1"
              fontSize={{ xs: "20px", sm: "20px", md: "25px" }}
              color={theme === "dark" ? "common.white" : "primary.main"}
              display="flex"
              alignItems="center"
            >
              {/* <NairaIcon className=" w-8 h-8 mt-1" /> */}
              {stat && formatNumber(stat)}
            </Typography>
          </>
        </Box>

        {buttonLabel && (
          <Box className={`  md:grid ml-auto mr-0 content-between`}>
            <MHButton
              color="primary"
              sx={{
                width: { xs: 180, sm: 180, md: 180 },
              }}
              type="submit"
              className=" rounded-md absolute bottom-4 right-3"
              startIcon={startIcon}
              endIcon={endIcon}
              onClick={onClick}
              disabled={disableBtn}
            >
              {buttonLabel}
            </MHButton>
          </Box>
        )}
      </Box>
      {/* {openDialog && (
        <FundDialog open={openDialog} onClose={handleCloseDialog} />
      )} */}
    </React.Fragment>
  );
};

export default ContractCard;
