import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import EmployeeTransaction from "./EmployeeTransaction";

type Props = {};

const ProfileTransactions = (props: Props) => {
  const rows = [
    [
      "The Sliding Mr. Bones",
      "Malcolm Lockyer",
      1961,
      "The Sliding Mr. Bones",
      "Malcolm Lockyer",
      1961,
    ],
    ["Witchy Woman", "The Eagles", 1972, "Witchy Woman", "The Eagles", 1972],
    [
      "Shining Star",
      "Earth, Wind, and Fire",
      1975,
      "Shining Star",
      "Earth, Wind, and Fire",
      1975,
    ],
  ];

  // const handleViewClick = (row: (string | number)[]) => {
  //   alert(`View button clicked for row: ${JSON.stringify(row)}`);
  // };
  return (
    <React.Fragment>
      <Box className="bg-white w-full drop-shadow-lg py-6">
        <Box className="px-4 md:px-6 lg:px-12">
          <EmployeeTransaction />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ProfileTransactions;
