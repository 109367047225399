import React, { useState } from "react";

import { Box } from "@mui/material";
import useInputArray from "../../hooks/use-input-array";

import * as validators from "../../utils/validators";
import useHttp from "../../hooks/use-http";
import { HttpResponse } from "../../models/api.interface";
import SnackbarContext from "../../store/context/snackbar.context";
import AuthContext from "../../store/context/auth-context";
import DashboardContext from "../../store/context/dashboard.context";
import { useParams } from "react-router-dom";
import { EmployeeData } from "../../models/employee.model";
import Profile from "../../components/Features/Employees/Profile";

const ViewEmployee = () => {
  const params = useParams<any>();
  const url = "/employee/getByUuid?uuid=";

  const [employee, setEmployee] = React.useState<EmployeeData | null>(null); // Change the type to EmployeeData | null since initially, there's no data

  const { loading, error, sendHttpRequest } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const getData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + url + params.uuid,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsonData: HttpResponse<EmployeeData> = await response.json();
      setEmployee(jsonData.data);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  React.useEffect(() => {
    getData();
  }, [employee]);

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  return (
    <React.Fragment>
      <Box className=" px-0  lg:px-10">
        <Profile employee={employee} />
      </Box>
    </React.Fragment>
  );
};

export default ViewEmployee;
