import React from "react";
import { MHPagination } from "../../Common/UI/MHPagination";
import { Box, Button, Divider, Typography } from "@mui/material";
import moment from "moment";
import { testTraining } from "../../../utils/constants";

import { ReactComponent as CompleteIcon } from "../../../static/svg/complete.svg";
import { ReactComponent as UnattendedIcon } from "../../../static/svg/unassigned.svg";
import useHttp from "../../../hooks/use-http";
import { EmployeeData } from "../../../models/employee.model";
import { getURLWithQueryParams } from "../../../utils/utils";
import { EmployeeProps } from "./Profile";
import { HttpResponse } from "../../../models/api.interface";
import { TrainingData } from "../Training/TrainingCard";

type Props = {};

export type TrainProps = {
  id?: number;
  level?: number;
  title?: string;
  date?: string;
  status?: string;
};

const TrainingWidget = (props: TrainProps) => {
  return (
    <>
      <Box className=" gap-2 grid md:flex items-center h-full md:h-[58px] p-4 md:p-0 md:justify-center ">
        <Box className=" md:w-[20%] md:ml-4 md:mx-0">
          <Box className=" w-[43px] h-[16px] bg-[#EEEEEE] items-center flex justify-center ">
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "9px", sm: "9px" }}
              lineHeight="150%"
              align="center"
              m={0}
              color="#A9A9A9"
              whiteSpace="nowrap" // Prevents text from wrapping
              overflow="hidden" // Hides any overflow
              textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
            >
              Level {props.level}
            </Typography>
          </Box>
        </Box>
        <Box className=" md:w-[60%]">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "14px", sm: "14px" }}
            lineHeight="150%"
            align="left"
            m={0}
            color="primary"
            whiteSpace="nowrap" // Prevents text from wrapping
            overflow="hidden" // Hides any overflow
            textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
          >
            {props?.title}
          </Typography>

          <Box className=" flex gap-2">
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "10px", sm: "10px" }}
              lineHeight="150%"
              align="left"
              m={0}
              color="primary"
              whiteSpace="nowrap" // Prevents text from wrapping
              overflow="hidden" // Hides any overflow
              textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
            >
              {moment(props.date).format("ddd MMMM D, YYYY")}
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "10px", sm: "10px" }}
              lineHeight="150%"
              align="left"
              m={0}
              color="primary"
              whiteSpace="nowrap" // Prevents text from wrapping
              overflow="hidden" // Hides any overflow
              textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
            >
              | {moment(props.date).format("h:mma")}
            </Typography>
          </Box>
        </Box>
        <Box className=" w-full md:w-[20%]">
          {props.status === "completed" ? (
            <CompleteIcon />
          ) : props.status === "unattended" ? (
            <UnattendedIcon />
          ) : props.status === "assigned" ? (
            <Button className=" no-underline bg-[#FFFC9B]">
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "10px", sm: "10px" }}
                lineHeight="150%"
                align="left"
                m={0}
                color="primary"
                whiteSpace="nowrap" // Prevents text from wrapping
                overflow="hidden" // Hides any overflow
                textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
              >
                Assigned
              </Typography>
            </Button>
          ) : null}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const BasicTab = () => {
  return (
    <Box className=" flex gap-4">
      <Box className=" relative">
        {" "}
        <Typography
          variant="subtitle2"
          fontSize={{ xs: "12px", sm: "12px" }}
          lineHeight="150%"
          align="left"
          color="primary"
          whiteSpace="nowrap" // Prevents text from wrapping
          overflow="hidden" // Hides any overflow
          textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
        >
          Assigned{" "}
          <b className="text-[9px] absolute -top-3 -right-2">
            {testTraining.filter((item) => item.status === "assigned").length}
          </b>
        </Typography>
      </Box>
      <Box className=" relative">
        <Typography
          variant="subtitle2"
          fontSize={{ xs: "12px", sm: "12px" }}
          lineHeight="150%"
          align="left"
          color="primary"
          whiteSpace="nowrap" // Prevents text from wrapping
          overflow="hidden" // Hides any overflow
          textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
        >
          Completed{" "}
          <b className=" text-[9px] absolute -top-3 -right-2">
            {testTraining.filter((item) => item.status === "completed").length}
          </b>
        </Typography>
      </Box>
      <Box className=" relative">
        <Typography
          variant="subtitle2"
          fontSize={{ xs: "12px", sm: "12px" }}
          lineHeight="150%"
          align="left"
          color="primary"
          whiteSpace="nowrap" // Prevents text from wrapping
          overflow="hidden" // Hides any overflow
          textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
        >
          Unattended{" "}
          <b className=" text-[9px] absolute -top-3 -right-2">
            {testTraining.filter((item) => item.status === "unattended").length}
          </b>
        </Typography>
      </Box>
    </Box>
  );
};

const AssignedTraining = ({ employee }: EmployeeProps) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const [training, setTraining] = React.useState<TrainingData[]>([]);

  const { loading, error, sendHttpRequest: employeeTrainingReq } = useHttp();

  React.useEffect(() => {
    employeeTrainingReq(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/employee/trainings",

        {
          customerId: String(employee?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<TrainingData[]>) => {
        setTraining(response.data);
      }
    );
  }, []);

  //   Training Pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(4);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = training.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 4;
  let noOfPage = Math.ceil((training?.length || 0) / itemsPerPage);

  const assignedTraining =
    training &&
    training.filter(
      (train) => train.attendanceStatus?.toLowerCase() === "assigned"
    );

  // console.log("employee", employee);
  // console.log("training", training);
  // console.log("assignedTraining", assignedTraining);

  return (
    <Box className="  my-4 lg:my-0 h-full relative w-full lg:h-[380px] lg:w-[624px] overflow-hidden border-2 border-[#D9D9D9] rounded-md ">
      <Box className=" p-6 flex justify-between ">
        <Typography
          variant="subtitle1"
          fontSize={{ xs: "12px", sm: "14px" }}
          lineHeight="150%"
          align="left"
          color="primary"
        >
          Assigned
        </Typography>
        <BasicTab />
      </Box>
      <Divider />

      {currentPosts &&
        currentPosts.map((train) => (
          <TrainingWidget
            id={train.id}
            level={train.level}
            title={train.title}
            date={train.date}
            status={train.status}
          />
        ))}

      {/*<Box className=" lg:absolute bottom-0 left-0 right-0 h-16"> <Pagination
              totalPosts={testTraining.length}
              postsPerPage={postsPerPage}
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              onClicked={() => {
                setCurrentPage(currentPage + 1);
              }}
              currentPage={currentPage}
            /> </Box> */}
      <Box display={"flex"} justifyContent={"center"} my={2}>
        <MHPagination
          count={noOfPage}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePageChange}
          color={"primary"}
        />
      </Box>
    </Box>
  );
};

export default AssignedTraining;
