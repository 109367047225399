import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { IconButton } from "@mui/material";
import MuiLink from "@mui/material/Link";

import MHButton from "../../components/Common/Button/MHButton";
import MHFormControl from "../../components/Common/Form/MHFormControl";
import RoundedLogoIcon from "../../theme/icons/RoundedLogo";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import InputAdornment from "../../components/Common/Form/InputAdornment";

import { ReactComponent as KeyIcon } from "../../static/svg/key.svg";
import { FnComponent } from "../../models/component.model";
// import { BGImage } from "../../models/background-image.model";
import * as validators from "../../utils/validators";
import { generateRandomString, getURLWithQueryParams } from "../../utils/utils";

import { ReactComponent as VisibilityIcon } from "../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../static/svg/visibility-off.svg";
import { ReactComponent as LockIcon } from "../../static/svg/lock.svg";

const PasswordReset: FnComponent<{
  // onRouteChange: (image: BGImage) => void;
}> = (props) => {
  // const { onRouteChange } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const history = useHistory();

  const { sendHttpRequest: activateEmp } = useHttp();
  const { loading, error, sendHttpRequest: resetPassword } = useHttp();

  const {
    value: enteredPassword,
    valid: enteredPasswordIsValid,
    error: passwordInputHasError,
    onChange: passwordInputChangeHandler,
    onBlur: passwordInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.password(value),
    },
  ]);

  const {
    value: enteredRepeatPassword,
    valid: enteredRepeatPasswordIsValid,
    error: repeatPasswordInputHasError,
    onChange: repeatPasswordInputChangeHandler,
    onBlur: repeatPaswordInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) =>
        validators.matchingFields(value)(enteredPassword),
    },
  ]);

  const handleClickShowPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    preventDefault(event);
    setShowPassword((prevState) => !prevState);
  };

  let passwordErrorTip = passwordInputHasError
    ? "Minimum of 8 characters, consisting of at least an uppercase letter, a lowercase letter, a number and a special character"
    : undefined;
  let repeatPasswordErrorTip = repeatPasswordInputHasError
    ? "Passwords must match"
    : undefined;

  let formIsValid = false;

  if (enteredPasswordIsValid && enteredRepeatPasswordIsValid) {
    formIsValid = true;
  }

  // React.useEffect(() => {
  //   onRouteChange({
  //     imageSrc:
  //       "https://res.cloudinary.com/mother-honestly/image/upload/v1657836331/alex-lvrs-4N5huJDOydQ-unsplash_1_1_qubnfw.png",
  //     imageAlt: "Lex Lvrs",
  //   });

  //   if (token)
  //     activateEmp(
  //       process.env.REACT_APP_API_BASE_URL + "employee/dashboard/activate",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({}),
  //       },
  //       (data: any) => {}
  //     );
  // }, [onRouteChange, activateEmp, token]);

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const setPasswordHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    preventDefault(event);

    if (!formIsValid) {
      return;
    }

    resetPassword(
      process.env.REACT_APP_API_BASE_URL + "employee/dashboard/password/new",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: enteredPassword,
        }),
      },
      (data: any) => {
        history.push({
          pathname: `/auth/reset-password/${generateRandomString({
            length: 7,
          })}/success`,
          state: {
            token: token,
          },
        });
      }
    );
  };

  return (
    <React.Fragment>
      {/* <Box className=" place-content-center text-center space-y-6 my-8"></Box> */}
      <Paper
        sx={{
          px: 8,
          py: 5,
          width: "100%",
        }}
      >
        <Box sx={{}}>
          <Typography
            variant="h3"
            my={3}
            align="center"
            fontSize={{ xs: "24px", sm: "30px", md: "30px" }}
            gutterBottom
          >
            New Password
          </Typography>
          {/* <RoundedLogoIcon sx={{ mx: "auto" }}>
            <KeyIcon width="1rem" />
          </RoundedLogoIcon> */}

          {error && (
            <Alert
              severity="error"
              sx={{
                mb: 3,
              }}
            >
              {error.message}
            </Alert>
          )}

          <Box
            component={"form"}
            onSubmit={setPasswordHandler}
            autoComplete="off"
            noValidate
          >
            <MHFormControl
              id="password"
              type={showPassword ? "text" : "password"}
              label="New Password"
              placeholder="Enter new password"
              onChange={passwordInputChangeHandler}
              startAdornment={
                <InputAdornment>
                  <LockIcon width="1.2rem" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment>
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? (
                      <VisibilityOffIcon width="1rem" height="1rem" />
                    ) : (
                      <VisibilityIcon width="1rem" height="1rem" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              error={passwordErrorTip}
              required
            />

            <MHButton sx={{}} type="submit" loading={loading} fullWidth>
              Reset
            </MHButton>
          </Box>
        </Box>
      </Paper>

      <MuiLink
        component={Link}
        underline="always"
        display="block"
        align="center"
        to="/auth/sign-in"
        className="text-blue-100 my-8 removeUnderline"
      >
        Go back to the login page
      </MuiLink>
    </React.Fragment>
  );
};

export default PasswordReset;
