// WithdrawalDialog.tsx
import React from "react";
import MHDialog from "../../Common/Dialog/MHDialog";
import {
  Box,
  Button,
  Typography,
  TextField,
  Divider,
  CircularProgress,
  FormControl,
  Autocomplete,
} from "@mui/material";

import { SelectOption } from "@mui/base";
import { useOnboardForm } from "../../../store/context/onboard-context";
import MHButton from "../../Common/Button/MHButton";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";

import * as animationData from "../../../static/json-animate/success.json";
import * as animationData2 from "../../../static/json-animate/BLOCKED ACCOUNT.json";

import useInput from "../../../hooks/use-input";
import { isEmpty, resolveErrorMessage, sortArray } from "../../../utils/utils";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";

import { ReactComponent as WalletsIcon } from "../../../static/svg/wallets.svg";
import { ReactComponent as RightIcon } from "../../../static/svg/arrow-right-white.svg";
import { ReactComponent as CancelIcon } from "../../../static/svg/cancel.svg";
import { ReactComponent as NairaIcon } from "../../../static/svg/naira.svg";

import MHLink from "../../Common/Link/MHLink";
import Lottie from "react-lottie";
import { clearCookie, getCookie, setCookie } from "../../../hooks/use-cookie";
import { ReactComponent as ArrowLeftIcon } from "../../../static/svg/arrow-left.svg";
import Label from "../../Common/Form/Label";

interface EmployerWithdrawDialogProps {
  open: boolean;
  onClose: () => void;
}

const WithdrawOne = (props: EmployerWithdrawDialogProps) => {
  const { setActiveStep: setWithdrawActiveStep } = useOnboardForm();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;
  const [banks, setBanks] = React.useState<SelectOption<string>[]>([]);
  // const [bankName, setBankName] = React.useState<SelectOption<string>[]>([]);
  const [bankName, setBankName] = React.useState<SelectOption<string> | null>(
    null
  );
  // const [acctName, setAcctName] = React.useState("");

  const {
    value: enteredWithdrawAmount,
    onChange: withdrawAmountInputChangeHandler,
    onBlur: withdrawAmountInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAccountNo,
    onChange: accountNoInputChangeHandler,
    onBlur: accountNoInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  // const {
  //   value: enteredBankName,
  //   error: enteredBankNameHasError,
  //   onChange: bankNameInputChangeHandler,
  //   onBlur: bankNameInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  const [enteredBankName, setEnteredBankName] = React.useState<string>(""); // State for selected bank name

  const getBanks = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/tranx/banks",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsonData = await response.json();

      if (response.ok) {
        const bankOptions = jsonData.data.map(
          (bank: { id: number; providerBankCode: string; name: string }) => ({
            value: bank.providerBankCode.toString(), // Convert 'code' to string if necessary
            label: bank.name.toString(), // Use 'name' as the label
          })
        );
        setBanks(bankOptions);

        // console.log("Banks Info fetched successfully");
        return response.status; // Return the status code for further processing
      } else {
        // const errorData = await response.json();
        // console.error("Banks Info fetch failed:", errorData);
        return response.status; // Return the status code for further processing
      }
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  React.useEffect(() => {
    getBanks();
  }, []); // Empty dependency array to ensure it runs only once

  React.useEffect(() => {
    const selectedBank = banks.find((item) => item.value === enteredBankName);
    setBankName(selectedBank || null);
  }, [enteredBankName, banks]);

  const sortedBankNames = sortArray(banks);

  // console.log("banks", banks);
  // console.log("bankName", bankName);

  const verifyBody = {
    bankCode: enteredBankName,
    AccountNumber: enteredAccountNo,
  };

  // console.log("verifyBody", verifyBody);

  const bankAcctVerifier = async () => {
    const url =
      process.env.REACT_APP_API_BASE_URL + `/tranx/verify-bank-account`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(verifyBody),
      });

      if (response.ok) {
        // Parse the response body as JSON
        const resData = await response.json();
        const accountName = resData.data;
        // console.log("accountName", accountName); // Correctly accessing the 'data' property
        localStorage.setItem("enteredWithdrawAccountName", accountName);
        // setAcctName(accountName);

        fundPrompt();
      } else {
        toast({
          message: "Invalid Account Number",
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return null;
    }
  };

  const handleVerifier = (e: any) => {
    e.preventDefault();
    if (enteredAccountNo === "") {
      return;
    }

    bankAcctVerifier();
  };

  // const requestBody = {
  //   // uuid: 690341452, // Employee UUID
  //   // uuid: user?.uuid, // Employee UUID
  //   employerRefId: user?.employerRefId, // Employer Ref ID
  //   amount: Number(enteredWithdrawAmount),
  //   destinationBank: bankName?.label,
  //   destinationAccountNumber: enteredAccountNo,
  //   destinationAccountName: acctName,
  //   destinationBankCode: enteredBankName,
  // };

  // console.log("requestBody", requestBody);

  // const withdrawToBank = async () => {
  //   const url = process.env.REACT_APP_API_BASE_URL + `/tranx/withdraw/emplr`;
  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     if (response.ok) {
  //       // Handle success
  //       // const successData = await response.json();

  //       toast({
  //         message: "Withdrawal was successful",
  //         variant: "success",
  //       });
  //       // console.log("Request submitted successfully");
  //       // Withdraw here if pin is correct
  //       setWithdrawActiveStep(2);
  //       // setWithdrawActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     } else {
  //       const errorData = await response.json();
  //       // console.error("Request submission failed:", errorData);
  //       // console.log("requestBody", requestBody);
  //       toast({
  //         message: `${errorData.error}`,
  //         variant: "error",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };

  const fundPrompt = () => {
    if (isEmpty(enteredWithdrawAmount) || Number(enteredWithdrawAmount) <= 0) {
      return toast({
        message: "Input an amount",
        variant: "warning",
      });
    }
    localStorage.setItem("enteredWithdrawAmount", enteredWithdrawAmount);
    localStorage.setItem("enteredWithdrawBankCode", enteredBankName);
    if (bankName && typeof bankName.label === "string") {
      localStorage.setItem("enteredWithdrawBank", bankName.label);
    } else {
      localStorage.setItem("enteredWithdrawBank", " ");
    }
    localStorage.setItem("enteredWithdrawAccount", enteredAccountNo);
    setWithdrawActiveStep(1);
    // withdrawToBank();
  };

  const closure = () => {
    localStorage.removeItem("enteredWithdrawAmount");
    localStorage.removeItem("enteredWithdrawBank");
    localStorage.removeItem("enteredWithdrawAccount");
    // setWithdrawActiveStep(0);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "500px" }}
        width={{ xs: "full", sm: "403px" }}
        className=" ml-0 mr-0 place-content-center items-center mx-auto withdraw-dialog"
      >
        <Box
          display="flex"
          alignItems="start"
          // alignSelf="start"
          // alignContent="start"
          justifyContent="end"
          mt={3}
        >
          <Button onClick={closure} className=" removeUnderline">
            <CancelIcon width="20px" />
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="start"
          // alignSelf="center"
          // alignContent="center"
          width="350px"
          justifyContent="start"
          mt={6}
          ml={{ xs: 0, sm: 3 }}
        >
          <RoundedLogoIcon sx={{ backgroundColor: "#C7D8E4" }}>
            <WalletsIcon className="w-6 h-6" />
          </RoundedLogoIcon>
          <Typography
            variant="h1"
            fontSize={{ xs: "18px", sm: "18px" }}
            align="center"
            gutterBottom
            display={"flex"}
            alignSelf={"center"}
            ml={2}
          >
            Withdrawal
          </Typography>
        </Box>

        <Box component={"form"} onSubmit={handleVerifier}>
          <Box display="flex" justifyContent="center" mt={2}>
            <Box width="350px">
              {banks.length === 0 ? (
                <Box mb={2}>
                  <Label
                    style={{
                      fontFamily: "Area-Normal-Semibold",
                      fontSize: 13,
                      fontWeight: 400,
                    }}
                  >
                    Bank Name
                  </Label>
                  <Label
                    style={{
                      fontFamily: "Area-Normal-Semibold",
                      fontSize: 13,
                      fontWeight: 400,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    Please wait while we fetch banks
                    <CircularProgress size={20} color="primary" />
                  </Label>
                </Box>
              ) : (
                <FormControl fullWidth>
                  <label
                    htmlFor="fundbank-autocomplete-employee"
                    style={{ marginBottom: "4px" }}
                  >
                    Bank Name
                  </label>
                  <Autocomplete
                    id="fundbank-autocomplete-employee"
                    options={sortedBankNames}
                    // getOptionLabel={(option) => option.label || ""}
                    getOptionLabel={(options) =>
                      (options as { label: string }).label
                    }
                    renderOption={(items, options) => (
                      <Button
                        onClick={() => setEnteredBankName(options.value || "")}
                        fullWidth
                        className="removeUnderline no-underline text-start"
                        sx={{
                          justifyContent: "start", // Aligns button content to the start
                        }}
                      >
                        <Box
                          component="li"
                          //   height={{ xs: "200px", sm: "200px" }}
                          sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "space-between",
                          }}
                          padding={1}
                          {...items}
                          className=" space-x-6 w-full"
                        >
                          <Typography
                            variant="subtitle2"
                            fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                            lineHeight={{ xs: "10px", sm: "10px" }}
                            textAlign={{ xs: "start", sm: "start" }}
                            color="primary"
                            className=" "
                            gutterBottom
                          >
                            {options.label}
                          </Typography>
                        </Box>
                      </Button>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          style: { fontSize: "12px" },
                          sx: {
                            height: "45px",
                            padding: "0 14px",
                            border: "none",
                            borderRadius: 0,
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                // <MHSelect
                //   label="Bank Name"
                //   placeholder=""
                //   options={sortedBankNames}
                //   value={enteredBankName}
                //   onChange={(val) => bankNameInputChangeHandler(val as string)}
                //   onBlur={bankNameInputBlurHandler}
                //   error={resolveErrorMessage(enteredBankNameHasError)(
                //     "Please select a bank"
                //   )}
                // />
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" my={1}>
            <Box width="350px">
              <MHFormControl
                id="employee_bank_account"
                type="text"
                label="Bank Account"
                placeholder=""
                value={enteredAccountNo}
                onChange={accountNoInputChangeHandler}
                onBlur={accountNoInputBlurHandler}
                required
                autoFocus
              />
              {/* <MHTextInput
              id="search-interest"
              type="text"
              placeholder=""
              value={enteredAccountNo}
              onChange={accountNoInputChangeHandler}
              onBlur={accountNoInputBlurHandler}
              className="flex-grow"
              autoFocus
            /> */}
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box width="350px">
              <MHFormControl
                id="withdraw-amount"
                type="number"
                label="Enter the amount you want to withdraw"
                placeholder=""
                value={enteredWithdrawAmount}
                onChange={withdrawAmountInputChangeHandler}
                onBlur={withdrawAmountInputBlurHandler}
                startAdornment={
                  <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                    &#x20A6;
                  </InputAdornment>
                }
                required
                autoFocus
              />

              {/* <MHCheckbox
                id="withdraw_checkbox"
                label="Withdraw all"
                value={checked}
                onChange={handleCheck}
                sx={{
                  fontSize: "11px",
                  lineHeight: "160.5%",
                  letterSpacing: "0.04em",
                  // [`& .${typographyClasses.root}`]: {
                  //   opacity: '1 !important'
                  // }
                  // opacity: 0.5
                }}
              /> */}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box my={4} width={{ xs: "100%", sm: "350px" }}>
              <MHButton
                color="primary"
                sx={{}}
                type="submit"
                className="  "
                fullWidth
                // startIcon={<PlusIcon />}
                // onClick={fundPrompt}

                // onClick={() =>
                //   setWithdrawActiveStep((prevActiveStep) => prevActiveStep - 1)
                // }
              >
                Transfer
              </MHButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const VerifyWithdrawal = () => {
  const { setActiveStep: setWithdrawActiveStep } = useOnboardForm();

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  let enteredWithdrawAmount = localStorage.getItem("enteredWithdrawAmount");
  let enteredWithdrawBank = localStorage.getItem("enteredWithdrawBank");
  let enteredWithdrawBankCode = localStorage.getItem("enteredWithdrawBankCode");
  let enteredWithdrawAccount = localStorage.getItem("enteredWithdrawAccount");
  let enteredWithdrawAccountName = localStorage.getItem(
    "enteredWithdrawAccountName"
  );

  const requestBody = {
    // uuid: 690341452, // Employee UUID
    // uuid: user?.uuid, // Employee UUID
    employerRefId: user?.employerRefId, // Employer Ref ID
    amount: Number(enteredWithdrawAmount),
    destinationBank: enteredWithdrawBank,
    destinationAccountNumber: enteredWithdrawAccount,
    destinationAccountName: enteredWithdrawAccountName,
    destinationBankCode: enteredWithdrawBankCode,
  };

  // console.log("requestBody", requestBody);

  const withdrawToBank = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/tranx/withdraw/emplr`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Handle success
        // const successData = await response.json();

        toast({
          message: "Withdrawal was successful",
          variant: "success",
        });
        // console.log("Request submitted successfully");
        // Withdraw here if pin is correct
        setWithdrawActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        const errorData = await response.json();
        // console.error("Request submission failed:", errorData);
        // console.log("requestBody", requestBody);
        toast({
          message: `${errorData.error}`,
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  if (isEmpty(enteredWithdrawAmount)) {
    setWithdrawActiveStep(0);
  }

  return (
    <React.Fragment>
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "500px" }}
        width={{ xs: "full", sm: "403px" }}
        className=" ml-0 mr-0 place-content-center  mx-auto "
      >
        <Box display="flex" alignItems="start" justifyContent="start">
          <Button
            onClick={() =>
              setWithdrawActiveStep((prevActiveStep) => prevActiveStep - 1)
            }
            className=" removeUnderline"
          >
            <Typography
              variant="body2"
              fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
              color={"primary"}
              gutterBottom
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <ArrowLeftIcon />
              Back
            </Typography>
          </Button>
        </Box>

        <Box
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="h3"
              fontSize={{ xs: "14px", sm: "18px", md: "18px" }}
              color={"#191940"}
              gutterBottom
              align="center"
              justifyContent="center"
              width={{ xs: "full", sm: "100%" }}
            >
              Recipient
            </Typography>
          </Box>
          {/* <Box className="centerItems">
            <Typography
              variant="body1"
              fontSize={{ xs: "18px", sm: "18px", md: "18px" }}
              textAlign={{ xs: "center", md: "center" }}
              width={{ xs: "250px", sm: "250px" }}
              lineHeight={"19px"}
              component="div"
              gutterBottom
              mb={1.5}
              className="centerItems text-center"
            >
              {errorMsg
                ? "Wrong Pin"
                : "Please enter PIN to initiate wallet-to-bank transfer."}
            </Typography>
          </Box>
          <Box className="centerItems">
            <Typography
              variant="body1"
              fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
              width={{ xs: "330px", sm: "330px" }}
              lineHeight={"19px"}
              align="center"
              component="div"
              gutterBottom
              mb={1.5}
              className="centerItems "
            >
              {errorMsg
                ? "The PIN you entered it invalid, Please enter the right PIN to initiate wallet-to-bank transfer."
                : "Enter a 4-digit PIN"}
            </Typography>
          </Box>

          <Box className="centerItems" mt={5}>
            <Box className="  mx-auto place-content-center text-center rounded-xl">
              {otp.map((value, index) => (
                <TextField
                  key={index}
                  id={`otp-input-${index}`}
                  variant="outlined"
                  value={value}
                  onChange={(e) => handleVerifyChange(index, e.target.value)}
                  onPaste={handleVerifyPaste}
                  inputProps={{ maxLength: 1 }}
                  style={{
                    marginRight: "10px",
                    width: "50px",
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box className="centerItems" mt={1}>
            (Default PIN: 1234)
          </Box>

          <Box className="centerItems" mt={2} mb={4}>
            <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              width={{ xs: "130px", sm: "130px" }}
              lineHeight={"19px"}
              color={"#FF0F00"}
              align="center"
              component="div"
              gutterBottom
              className="centerItems "
            >
              {getCount && Number(getCount) > 0
                ? countLeft + " more left"
                : null}
            </Typography>
          </Box> */}

          <Box
            className=""
            alignItems={"center"}
            alignContent={"center"}
            alignSelf={"center"}
            display={"grid"}
            mt={3}
          >
            {enteredWithdrawAccountName ? (
              <>
                <Box className="" my={1} px={3}>
                  <Box width="100%">
                    <Box>
                      <Typography
                        variant="subtitle2"
                        fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                        gutterBottom
                        align="left"
                        justifyContent="start"
                        pl={1}
                      >
                        Bank Name
                      </Typography>

                      <Button
                        // onClick={copyBankToClipboard}
                        disabled
                        className="removeUnderline"
                        fullWidth
                        disableRipple
                      >
                        <Box
                          className=""
                          width={{ xs: "100%", sm: "100%" }}
                          height={{ xs: "45px", sm: "45px" }}
                          sx={{
                            border: 1,
                            borderColor: "#D9D9D9",
                            borderRadius: 1,
                            px: 2,
                          }}
                          alignItems="center"
                          display="flex"
                          gap={2}
                        >
                          <Typography
                            variant="subtitle1"
                            fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                            color={"primary"}
                            gutterBottom
                            align="left"
                            justifyContent="start"
                            width={{ xs: "100%", sm: "100%" }}
                            my={3}
                          >
                            {enteredWithdrawBank}
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                    <br />
                    <Box>
                      <Typography
                        variant="subtitle2"
                        fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                        gutterBottom
                        align="left"
                        justifyContent="start"
                        pl={1}
                      >
                        Account Name
                      </Typography>

                      <Button
                        // onClick={copyBankToClipboard}
                        disabled
                        className="removeUnderline"
                        fullWidth
                        disableRipple
                      >
                        <Box
                          className=""
                          width={{ xs: "100%", sm: "100%" }}
                          height={{ xs: "45px", sm: "45px" }}
                          sx={{
                            border: 1,
                            borderColor: "#D9D9D9",
                            borderRadius: 1,
                            px: 2,
                          }}
                          alignItems="center"
                          display="flex"
                          gap={2}
                        >
                          <Typography
                            variant="subtitle1"
                            fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                            color={"primary"}
                            gutterBottom
                            align="left"
                            justifyContent="start"
                            width={{ xs: "100%", sm: "100%" }}
                            my={3}
                          >
                            {enteredWithdrawAccountName}
                          </Typography>
                        </Box>
                      </Button>
                    </Box>

                    <br />
                    <Box>
                      <Typography
                        variant="subtitle2"
                        fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                        gutterBottom
                        align="left"
                        justifyContent="start"
                        pl={1}
                      >
                        Account Number
                      </Typography>

                      <Button
                        // onClick={copyAccountToClipboard}
                        disabled
                        className="removeUnderline"
                        fullWidth
                        disableRipple
                      >
                        <Box
                          className=""
                          width={{ xs: "100%", sm: "100%" }}
                          height={{ xs: "45px", sm: "45px" }}
                          sx={{
                            border: 1,
                            borderColor: "#D9D9D9",
                            borderRadius: 1,
                            px: 2,
                          }}
                          alignItems="center"
                          display="flex"
                          gap={2}
                        >
                          <Typography
                            variant="subtitle1"
                            fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                            color={"primary"}
                            gutterBottom
                            align="left"
                            justifyContent="start"
                            width={{ xs: "100%", sm: "100%" }}
                            my={3}
                          >
                            {enteredWithdrawAccount}
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                    <br />
                    <Box
                      mx={1.1}
                      // width="193px"
                      p={2}
                      borderRadius={2}
                      bgcolor="#D2E0CB"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden" // Prevents content overflow from floating out of the box
                    >
                      <Button disabled>
                        <Typography
                          variant="h1"
                          fontSize={{ xs: "20px", sm: "24px" }}
                          // lineHeight="100%"
                          gap={2}
                          align="center"
                          alignItems="center"
                          display="flex"
                          color="primary"
                          whiteSpace="nowrap" // Prevents text from wrapping
                          overflow="hidden" // Hides any overflow
                          textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                        >
                          Total:
                          <Box display="flex" alignItems="center">
                            <NairaIcon className=" ml-2 w-6 h-6" />
                            <Typography
                              variant="h1"
                              fontSize={{ xs: "24px", sm: "28px" }}
                              // lineHeight="100%"
                              gap={2}
                              align="center"
                              alignItems="center"
                              display="flex"
                              color="primary"
                              whiteSpace="nowrap" // Prevents text from wrapping
                              overflow="hidden" // Hides any overflow
                              textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                            >
                              {enteredWithdrawAmount}
                            </Typography>
                          </Box>
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="fullCenter " px={3} height={"300px"}>
                <Typography
                  variant="subtitle1"
                  color={"#6B6B6B"}
                  fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                  width={{ xs: "80%", sm: "80%" }}
                  gutterBottom
                  align="center"
                  justifyContent="center fullCenter"
                >
                  Please wait while we load your account details
                </Typography>
              </Box>
            )}
          </Box>

          <Box className="w-full flex  mt-10 centerItems">
            <Box className=" " width={{ xs: "100%", sm: "86%" }}>
              <MHButton
                sx={{}}
                onClick={withdrawToBank}
                className="  "
                fullWidth
              >
                Continue
              </MHButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const SuccessfulPayPrompt = (props: EmployerWithdrawDialogProps) => {
  const { setActiveStep: setWithdrawActiveStep } = useOnboardForm();
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  let enteredWithdrawAmount = localStorage.getItem("enteredWithdrawAmount");
  let enteredWithdrawBank = localStorage.getItem("enteredWithdrawBank");
  let enteredWithdrawAccount = localStorage.getItem("enteredWithdrawAccount");
  let enteredWithdrawAccountName = localStorage.getItem(
    "enteredWithdrawAccountName"
  );

  const closure = () => {
    localStorage.removeItem("enteredWithdrawAmount");
    localStorage.removeItem("enteredWithdrawAccountName");
    localStorage.removeItem("enteredWithdrawBank");
    localStorage.removeItem("enteredWithdrawAccount");
    setWithdrawActiveStep(0);
    props.onClose();
  };
  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "500px" }}
      width={{ xs: "full", sm: "403px" }}
      className=" ml-0 mr-0 place-content-center align-middle mx-auto "
    >
      <Box
        display="flex"
        alignItems="start"
        // alignSelf="start"
        // alignContent="start"
        justifyContent="end"
        mt={3}
      >
        <Button onClick={closure} className=" removeUnderline">
          <CancelIcon width="20px" />
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        // alignSelf="center"
        // alignContent="center"
        justifyContent="center"
        mt={5}
      >
        <Box className="w-fit">
          <Lottie options={defaultOptions} />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" my={2}>
        <Typography
          variant="body1"
          fontSize={{ xs: "18px", sm: "18px", md: "18px" }}
          textAlign={{ xs: "center", md: "center" }}
          width={{ xs: "250px", sm: "250px" }}
          lineHeight={"19px"}
          component="div"
          gutterBottom
          mb={1.5}
          className="centerItems text-center"
        >
          Transfer Successful{" "}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={4} mb={1}>
        <Typography
          variant="subtitle1"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          textAlign={{ xs: "start", md: "start" }}
          width={{ xs: "250px", sm: "250px" }}
          lineHeight={"19px"}
          component="div"
          gutterBottom
          className="text-start"
        >
          Details
          <Divider />
        </Typography>
      </Box>
      <Box className=" mx-auto place-content-center place-items-center">
        <TransferWidget
          title="Recipient"
          description={`${enteredWithdrawAccountName}`}
        />
        <TransferWidget
          title="Bank name"
          description={`${enteredWithdrawBank}`}
        />
        <TransferWidget
          title="Acc. num"
          description={`${enteredWithdrawAccount}`}
        />
        <TransferWidget
          title="Amount"
          description={`₦ ${enteredWithdrawAmount}`}
        />
      </Box>

      <Box display="flex" justifyContent="center">
        <Box my={4} width={{ xs: "100%", sm: "350px" }}>
          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className="  "
            fullWidth
            onClick={closure}
          >
            Download Receipt
          </MHButton>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <MHLink
          type="info"
          underline="none"
          className=" removeUnderline"
          // onClick={closure}
          onClick={() => setWithdrawActiveStep(0)}
        >
          <Typography
            variant="subtitle2"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            textAlign={{ xs: "center", md: "center" }}
            width={{ xs: "250px", sm: "250px" }}
            lineHeight={"19px"}
            component="div"
            gutterBottom
            className="text-center"
          >
            Back to Dashboard
          </Typography>
        </MHLink>
      </Box>
    </Box>
  );
};
const FailedWithdrawalPrompt = (props: EmployerWithdrawDialogProps) => {
  const getCount = getCookie("employeePinCount");
  const { setActiveStep: setWithdrawActiveStep } = useOnboardForm();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (getCount && Number(getCount) === 3) {
    setWithdrawActiveStep(3);
  }

  const closure = () => {
    setWithdrawActiveStep(0);
    props.onClose();
  };

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "500px" }}
      width={{ xs: "full", sm: "403px" }}
      className=" ml-0 mr-0 place-content-center align-middle items-center mx-auto "
    >
      <Box
        display="flex"
        alignItems="start"
        // alignSelf="start"
        // alignContent="start"
        justifyContent="end"
        mt={3}
      >
        <Button onClick={closure} className=" removeUnderline">
          <CancelIcon width="20px" />
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        // alignSelf="center"
        // alignContent="center"
        justifyContent="center"
        mt={6}
      >
        <Box className="w-fit">
          <Lottie options={defaultOptions} height={150} width={150} />
        </Box>
      </Box>

      <Box className="centerItems">
        <Typography
          variant="body2"
          fontSize={{ xs: "18px", sm: "18px", md: "18px" }}
          textAlign={{ xs: "center", md: "center" }}
          width={{ xs: "200px", sm: "200px" }}
          lineHeight={"19px"}
          component="div"
          gutterBottom
          mb={1.5}
          className="centerItems text-center"
        >
          Transfer Restricted
        </Typography>
      </Box>
      <Box className="centerItems">
        <Typography
          variant="body1"
          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
          width={{ xs: "200px", sm: "200px" }}
          lineHeight={"19px"}
          align="center"
          component="div"
          gutterBottom
          mb={1.5}
          className="centerItems "
        >
          Contact Caring Blocks Associate for assistance
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box my={4} width={{ xs: "100%", sm: "350px" }}>
          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className="  "
            fullWidth
            endIcon={<RightIcon />}
            onClick={closure}

            // onClick={() =>
            //   setWithdrawActiveStep((prevActiveStep) => prevActiveStep - 1)
            // }
          >
            Contact Now
          </MHButton>
        </Box>
      </Box>
    </Box>
  );
};

export const TransferWidget = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <Box className=" mx-auto">
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="body2"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "150%", sm: "215%" }}
          color={"primary"}
          gutterBottom
          align="center"
          justifyContent="center"
          width={{ xs: "80%", sm: "80%" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "150%", sm: "215%" }}
          color={"primary"}
          gutterBottom
          align="center"
          justifyContent="center"
          width={{ xs: "80%", sm: "80%" }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const EmployerWithdrawDialog: React.FC<EmployerWithdrawDialogProps> = ({
  open,
  onClose,
}) => {
  const { activeStep: withdrawStep, setActiveStep: setWithdrawActiveStep } =
    useOnboardForm();

  // const filteredNumber = enteredWithdrawAmount.replace(/\D/g, "");

  const closure = () => {
    localStorage.removeItem("enteredWithdrawAmount");
    localStorage.removeItem("enteredWithdrawBank");
    localStorage.removeItem("enteredWithdrawAccount");
    setWithdrawActiveStep(0);
    onClose();
  };

  // React.useEffect(() => {
  //   // console.log(withdrawStep);
  // }, [withdrawStep, enteredWithdrawAmount, enteredAccountNo]);

  // console.log("getCount", getCount);
  // console.log("countLeft", countLeft);

  const handleWithdrawFlow = () => {
    if (withdrawStep === 0) {
      return <WithdrawOne open={open} onClose={onClose} />;
    } else if (withdrawStep === 1) {
      return <VerifyWithdrawal />;
    } else if (withdrawStep === 2) {
      return <SuccessfulPayPrompt open={open} onClose={onClose} />;
    } else if (withdrawStep === 3) {
      return <FailedWithdrawalPrompt open={open} onClose={onClose} />;
    } else {
      return null;
    }
  };

  return (
    <MHDialog
      title=""
      open={open}
      onClose={closure}
      handleClose={closure}
      maxWidth="md"
      scroll="paper"
      className=" modal-backdrop"
      style={{ zIndex: 1049 }}
    >
      {handleWithdrawFlow()}
    </MHDialog>
  );
};

export default EmployerWithdrawDialog;
