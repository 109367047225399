import React from "react";

import useHttp from "../../hooks/use-http";

import { HttpResponse } from "../../models/api.interface";

import { Organization } from "../../models/employer.model";
import { getURLWithQueryParams } from "../../utils/utils";
import { EmployeeData } from "../../models/employee.model";

type DashboardCtxType = {
  // staticDataCacheMap: Map<string, any[]>;
  organization: Organization | null;
  // employee: EmployeeData | null;
  // computeCategoryExpenses: (
  //   expensePerCategory: ExpensePerCategory,
  //   totalExpenses: number
  // ) => void;
  fetchOrganizationData: (employerRefId: number) => void;
  fetchOrganizationEmployee: (uuid: number) => void;
  // updateEmployerData: (employer: Employer) => void;
  updateEmployeeRecord: (data: Organization) => void;
  updateEmployeeDetails: (data: Organization, employee: EmployeeData) => void;
  updateEmployeeStatus: (data: EmployeeData) => void;
  updateEmployerRecord: (data: Organization) => void;
  // updateEmployerData: (emp: EmployerOnboarding) => void;
};

const DashboardContext = React.createContext<DashboardCtxType>({
  // staticDataCacheMap: new Map<string, any[]>(),
  organization: null,
  // employee: null,
  // computeCategoryExpenses: (
  //   expensePerCat: ExpensePerCategory,
  //   totalExpenses: number
  // ) => {},
  fetchOrganizationData: () => {},
  fetchOrganizationEmployee: () => {},
  // updateEmployerData: (employer: Employer) => {},
  updateEmployeeRecord: () => {},
  updateEmployeeDetails: () => {},
  updateEmployeeStatus: () => {},
  updateEmployerRecord: () => {},
});

export const DashboardContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // const [dataCacheMap, setDataCacheMap] = React.useState<
  //   Map<string, Category[] | Merchant[]>
  // >(new Map());

  const [organization, setOrganization] = React.useState<Organization | null>(
    null
  );

  // const {
  //   loading: fetchingCategories,
  //   error,
  //   // sendHttpRequest: fetchCategories,
  // } = useHttp();
  // const { sendHttpRequest: fetchMerchants } = useHttp();

  // const reqOptions = {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // React.useEffect(() => {
  //   fetchCategories(
  //     process.env.REACT_APP_API_BASE_URL + "/employee/dashboard/category/all",
  //     reqOptions,
  //     (response: HttpResponse<Category[]>) => {
  //       setCategoryData(response.data);
  //     }
  //   );

  // fetchMerchants(
  //   process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/merchant/all',
  //   reqOptions,
  //   (response: HttpResponse<Merchant[]>) => {
  //     setMerchantData(response.data);
  //   }
  // );
  // }, []);

  // const setCategoryData = (categories: Category[]) => {
  //   setDataCacheMap((prevState) => {
  //     const newState = new Map(prevState);
  //     const mappedCategories: (Category & SelectOption<string>)[] =
  //       categories.map((category) => ({
  //         ...category,
  //         value: category.id + "",
  //         label: category.categoryName,
  //       }));
  //     newState.set("categories", mappedCategories);
  //     return newState;
  //   });
  // };

  // const setMerchantData = (merchants: Merchant[]) => {
  //   setDataCacheMap((prevState) => {
  //     const newState = new Map(prevState);
  //     const mappedMerchants: (Merchant & SelectOption<string>)[] = merchants
  //       .sort((a, b) => {
  //         if (a.id < b.id) {
  //           return -1;
  //         }
  //         if (a.id > b.id) {
  //           return 1;
  //         }
  //         return 0;
  //       })
  //       .concat({
  //         id: -1,
  //         merchantName: "Other",
  //       } as Merchant)
  //       .map((merchant) => ({
  //         ...merchant,
  //         value: merchant.id + "",
  //         label: merchant.merchantName,
  //         // categoryList: sortListByIdAsc(merchant.categoryList) // causes error when list is undefined
  //       }));
  //     newState.set("merchants", mappedMerchants);
  //     return newState;
  //   });
  // };

  // const computeCategoryExpenses = React.useCallback(
  //   (expPerCategory: ExpensePerCategory, totalExpenses: number) => {
  //     let categories: Category[] = dataCacheMap.get("categories") as Category[];

  //     if (!categories) {
  //       return;
  //     }

  //     for (const key of Object.keys(expPerCategory)) {
  //       const categoryIndex = categories.findIndex(
  //         (cat) =>
  //           cat.categoryName.trim() ===
  //           CategoryName[key as keyof typeof CategoryName]
  //       );
  //       if (categoryIndex === -1) {
  //         continue;
  //       }
  //       categories[categoryIndex].expensePercent =
  //         (expPerCategory[key as keyof typeof expPerCategory] / totalExpenses) *
  //         100;
  //     }

  //     setDataCacheMap((prevState) => {
  //       const newState = new Map(prevState);
  //       newState.set("categories", categories as Category[]);
  //       return newState;
  //     });
  //   },
  //   [dataCacheMap]
  // );

  const { sendHttpRequest: getOrganization } = useHttp();

  const fetchOrganizationData = React.useCallback(
    (employerRefId: number) => {
      getOrganization(
        getURLWithQueryParams(
          process.env.REACT_APP_API_BASE_URL + "/employer/employer_refId",
          {
            employerRefId: String(employerRefId),
          }
        ),
        {
          method: "GET",
        },
        (response: HttpResponse<Organization>) => {
          setOrganization(response.data);
        }
      );
    },
    [getOrganization]
  );

  const fetchOrganizationEmployee = React.useCallback(
    (uuid: number) => {
      getOrganization(
        getURLWithQueryParams(
          process.env.REACT_APP_API_BASE_URL + "/employee/getByUuid",
          {
            uuid: String(uuid),
          }
        ),
        {
          method: "GET",
        },
        (response: HttpResponse<Organization>) => {
          setOrganization(response.data);
        }
      );
    },
    [getOrganization]
  );

  // const updateEmployerData = React.useCallback(
  //   (employerData: Employer) => {
  //     setOrganization((organization: any) => {
  //       if (!organization) {
  //         return organization;
  //       }

  //       // const modifiedAllocations = organization.allocationList.map(
  //       //   (item: any) => {
  //       //     // const allocationIndex = allocations.findIndex(
  //       //     //   (alloc) => alloc.catReferenceId === item.catReferenceId
  //       //     // );

  //       //     // if (allocationIndex === -1) {
  //       //     //   return item;
  //       //     // }

  //       //     // return {
  //       //     //   ...item,
  //       //     //   allocationAmount: allocations[allocationIndex].allocation,
  //       //     // };
  //       //   }
  //       // );

  //       return {
  //         // allocationList: modifiedAllocations,
  //         employer: {
  //           ...organization,
  //           ...employerData,
  //         },
  //       };
  //     });
  //   },
  //   []
  // );

  const updateEmployerRecord = async (data: Organization) => {
    if (!organization) {
      // Handle the case where organization is null
      console.error("Organization is null");
      return;
    }

    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/employer/update",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.id === 0 ? organization.id : data.id,
          employerRefId:
            data.employerRefId === 0
              ? organization.employerRefId
              : data.employerRefId,
          customerId:
            data.customerId === 0 ? organization.customerId : data.customerId,
          numberOfEmployees:
            data.numberOfEmployees === 0
              ? organization.numberOfEmployees
              : data.numberOfEmployees,
          Accomodation:
            data.Accomodation === 0
              ? organization.Accomodation
              : data.Accomodation,
          transportation:
            data.transportation === 0
              ? organization.transportation
              : data.transportation,
          airtimeOrData:
            data.airtimeOrData === 0
              ? organization.airtimeOrData
              : data.airtimeOrData,
          meals: data.meals === 0 ? organization.meals : data.meals,

          Children: data.Children === 0 ? organization.Children : data.Children,
          subscriptionType:
            data.subscriptionType === ""
              ? organization.subscriptionType
              : data.subscriptionType,
          firstName:
            data.firstName === "" ? organization.firstName : data.firstName,

          lastName:
            data.lastName === "" ? organization.lastName : data.lastName,
          bvn: data.bvn === "" ? organization.bvn : data.bvn,
          middleName:
            data.middleName === "" ? organization.middleName : data.middleName,

          role: data.role === "" ? organization.role : data.role,
          homeAddress:
            data.homeAddress === ""
              ? organization.homeAddress
              : data.homeAddress,
          address: data.address === "" ? organization.address : data.address,
          nin: data.nin === "" ? organization.nin : data.nin,
          sex: data.sex === "" ? organization.sex : data.sex,
          status: data.status === "" ? organization.status : data.status,
          maritalStatus:
            data.maritalStatus === ""
              ? organization.maritalStatus
              : data.maritalStatus,

          whatsAppNumber:
            data.whatsAppNumber === ""
              ? organization.whatsAppNumber
              : data.whatsAppNumber,
          email: data.email === "" ? organization.email : data.email,
          password:
            data.password === "" ? organization.password : data.password,
          state: data.state === "" ? organization.state : data.state,
          lcda: data.lcda === "" ? organization.lcda : data.lcda,
          telephone:
            data.telephone === "" ? organization.telephone : data.telephone,
          startDate: organization.startDate,
          endDate: organization.endDate,
          lagosHmo:
            data.lagosHmo === "" ? organization.lagosHmo : data.lagosHmo,
          casavaHmo:
            data.casavaHmo === "" ? organization.casavaHmo : data.casavaHmo,
          countryCode:
            data.countryCode === ""
              ? organization.countryCode
              : data.countryCode,
          createdDate: organization.createdDate,
          lastActivityDate: organization.lastActivityDate,
          allocationList: organization.allocationList,
          createdBy:
            data.createdBy === "" ? organization.createdBy : data.createdBy,
          lastActivityBy:
            data.lastActivityBy === ""
              ? organization.lastActivityBy
              : data.lastActivityBy,
        }),
      }
    );

    if (response.ok) {
      fetchOrganizationData(Number(organization?.employerRefId));
      // window.location.reload();
    } else {
      console.error("Failed to update employee record");
    }
  };

  const updateEmployeeRecord = async (data: Organization) => {
    if (!organization) {
      // Handle the case where organization is null
      console.error("Organization is null");
      return;
    }

    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/employee/update",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: data.id === 0 ? organization.id : data.id,
          uuid: data.uuid === 0 ? organization.uuid : data.uuid,
          employerRefId:
            data.employerRefId === 0
              ? organization.employerRefId
              : data.employerRefId,
          customerId:
            data.customerId === 0 ? organization.customerId : data.customerId,
          Accomodation:
            data.Accomodation === 0
              ? organization.Accomodation
              : data.Accomodation,
          transportation:
            data.transportation === 0
              ? organization.transportation
              : data.transportation,
          airtimeOrData:
            data.airtimeOrData === 0
              ? organization.airtimeOrData
              : data.airtimeOrData,
          meals: data.meals === 0 ? organization.meals : data.meals,
          additionalAllowance:
            data.additionalAllowance === 0
              ? organization.additionalAllowance
              : data.additionalAllowance,
          salary: data.salary === 0 ? organization.salary : data.salary,
          noOfChildren:
            data.noOfChildren === 0
              ? organization.noOfChildren
              : data.noOfChildren,
          firstName:
            data.firstName === "" ? organization.firstName : data.firstName,
          allowanceDescription:
            data.allowanceDescription === ""
              ? organization.allowanceDescription
              : data.allowanceDescription,
          lastName:
            data.lastName === "" ? organization.lastName : data.lastName,
          bvn: data.bvn === "" ? organization.bvn : data.bvn,
          middleName:
            data.middleName === "" ? organization.middleName : data.middleName,
          payFrequency:
            data.payFrequency === ""
              ? organization.payFrequency
              : data.payFrequency,
          healthInsurance:
            data.healthInsurance === ""
              ? organization.healthInsurance
              : data.healthInsurance,
          healthInsuranceName:
            data.healthInsuranceName === ""
              ? organization.healthInsuranceName
              : data.healthInsuranceName,
          role: data.role === "" ? organization.role : data.role,
          jobTitle:
            data.jobTitle === "" ? organization.jobTitle : data.jobTitle,
          address: data.address === "" ? organization.address : data.address,
          nin: data.nin === "" ? organization.nin : data.nin,
          sex: data.sex === "" ? organization.sex : data.sex,
          status: data.status === "" ? organization.status : data.status,
          maritalStatus:
            data.maritalStatus === ""
              ? organization.maritalStatus
              : data.maritalStatus,
          bankName:
            data.bankName === "" ? organization.bankName : data.bankName,
          bankAccount:
            data.bankAccount === ""
              ? organization.bankAccount
              : data.bankAccount,
          whatsAppNumber:
            data.whatsAppNumber === ""
              ? organization.whatsAppNumber
              : data.whatsAppNumber,
          email: data.email === "" ? organization.email : data.email,
          password:
            data.password === "" ? organization.password : data.password,
          stateOfOrigin:
            data.stateOfOrigin === ""
              ? organization.stateOfOrigin
              : data.stateOfOrigin,
          telephone:
            data.telephone === "" ? organization.telephone : data.telephone,
          startDate: organization.startDate,
          endDate: organization.endDate,
          lagosHmo:
            data.lagosHmo === "" ? organization.lagosHmo : data.lagosHmo,
          casavaHmo:
            data.casavaHmo === "" ? organization.casavaHmo : data.casavaHmo,
          countryCode:
            data.countryCode === ""
              ? organization.countryCode
              : data.countryCode,
          createdDate: organization.createdDate,
          lastActivityDate: organization.lastActivityDate,
          allocationList: organization.allocationList,
          createdBy:
            data.createdBy === "" ? organization.createdBy : data.createdBy,
          lastActivityBy:
            data.lastActivityBy === ""
              ? organization.lastActivityBy
              : data.lastActivityBy,
        }),
      }
    );

    if (response.ok) {
      fetchOrganizationEmployee(Number(organization?.uuid));
      // window.location.reload();
    } else {
      console.error("Failed to update employee record");
    }
  };

  const updateEmployeeDetails = async (
    data: Organization,
    employee: EmployeeData
  ) => {
    if (!organization) {
      console.error("Organization is null");
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/employee/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: data.id === 0 ? employee.id : data.id,
            uuid: data.uuid === 0 ? employee.uuid : data.uuid,
            employerRefId:
              data.employerRefId === 0
                ? employee.employerRefId
                : data.employerRefId,
            customerId:
              data.customerId === 0 ? employee.customerId : data.customerId,
            Accomodation:
              data.Accomodation === 0
                ? employee.Accomodation
                : data.Accomodation,
            transportation:
              data.transportation === 0
                ? employee.transportation
                : data.transportation,
            airtimeOrData:
              data.airtimeOrData === 0
                ? employee.airtimeOrData
                : data.airtimeOrData,
            meals: data.meals === 0 ? employee.meals : data.meals,
            additionalAllowance:
              data.additionalAllowance === 0
                ? employee.additionalAllowance
                : data.additionalAllowance,
            salary: data.salary === 0 ? employee.salary : data.salary,
            noOfChildren:
              data.noOfChildren === 0
                ? employee.noOfChildren
                : data.noOfChildren,
            firstName:
              data.firstName === "" ? employee.firstName : data.firstName,
            allowanceDescription:
              data.allowanceDescription === ""
                ? employee.allowanceDescription
                : data.allowanceDescription,
            lastName: data.lastName === "" ? employee.lastName : data.lastName,
            bvn: data.bvn === "" ? employee.bvn : data.bvn,
            middleName:
              data.middleName === "" ? employee.middleName : data.middleName,
            payFrequency:
              data.payFrequency === ""
                ? employee.payFrequency
                : data.payFrequency,
            healthInsurance:
              data.healthInsurance === ""
                ? employee.healthInsurance
                : data.healthInsurance,
            healthInsuranceName:
              data.healthInsuranceName === ""
                ? employee.healthInsuranceName
                : data.healthInsuranceName,
            role: data.role === "" ? employee.role : data.role,
            jobTitle: data.jobTitle === "" ? employee.jobTitle : data.jobTitle,
            address: data.address === "" ? employee.address : data.address,
            nin: data.nin === "" ? employee.nin : data.nin,
            sex: data.sex === "" ? employee.sex : data.sex,
            status: data.status === "" ? employee.status : data.status,
            maritalStatus:
              data.maritalStatus === ""
                ? employee.maritalStatus
                : data.maritalStatus,
            bankName: data.bankName === "" ? employee.bankName : data.bankName,
            bankAccount:
              data.bankAccount === "" ? employee.bankAccount : data.bankAccount,
            whatsAppNumber:
              data.whatsAppNumber === ""
                ? employee.whatsAppNumber
                : data.whatsAppNumber,
            email: data.email === "" ? employee.email : data.email,
            password: data.password === "" ? employee.password : data.password,
            stateOfOrigin:
              data.stateOfOrigin === ""
                ? employee.stateOfOrigin
                : data.stateOfOrigin,
            telephone:
              data.telephone === "" ? employee.telephone : data.telephone,
            startDate: employee.startDate,
            endDate: employee.endDate,
            lagosHmo: data.lagosHmo === "" ? employee.lagosHmo : data.lagosHmo,
            casavaHmo:
              data.casavaHmo === "" ? employee.casavaHmo : data.casavaHmo,
            countryCode:
              data.countryCode === "" ? employee.countryCode : data.countryCode,
            createdDate: employee.createdDate,
            lastActivityDate: employee.lastActivityDate,
            allocationList: employee.allocationList,
            createdBy:
              data.createdBy === "" ? employee.createdBy : data.createdBy,
            lastActivityBy:
              data.lastActivityBy === ""
                ? employee.lastActivityBy
                : data.lastActivityBy,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("API response:", result);

      // Assuming you need to update the employee state or any other state
      // Update the context or state here if needed
      // For example:
      // updateEmployeeContext(result.updatedEmployee);
    } catch (error) {
      console.error("Failed to update employee details:", error);
    }
  };

  const updateEmployeeStatus = async (data: EmployeeData) => {
    if (!organization) {
      console.error("Organization is null");
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/employee/status",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerId: data.customerId,
            employerRefId: data.employerRefId,
            status: data.status,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("API response:", result);

      // Assuming you need to update the employee state or any other state
      // Update the context or state here if needed
    } catch (error) {
      console.error("Failed to update employee details:", error);
    }
  };

  // React.useEffect(() => {
  //   if (errorGettingOrg) {
  //     setCompleted(false);
  //     pushNotification({
  //       type: 'error',
  //       message: errorGettingOrg.message
  //     });
  //   }
  // }, [errorGettingOrg]);

  // if (errorGettingOrg) {
  // return (
  //   <Box
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     minHeight="80vh">
  //     <Typography variant="body1" align="center" fontSize="1.5rem">
  //       An unexpected error occured
  //     </Typography>
  //   </Box>
  // );
  // }

  const contextValue: DashboardCtxType = {
    // staticDataCacheMap: dataCacheMap,
    // computeCategoryExpenses,
    organization,
    fetchOrganizationData,
    fetchOrganizationEmployee,
    // updateEmployerData,
    updateEmployeeRecord,
    updateEmployeeDetails,
    updateEmployerRecord,
    updateEmployeeStatus,
    // employee: null,
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {/* {error && <MHSnackbar variant="error" message={error.message} />} */}
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContext;
