// FundDialog.tsx
import React from "react";

import Box from "@mui/material/Box";

import { Backdrop, Typography } from "@mui/material";
import { ReactComponent as CheckStar } from "../../../static/svg/checkstar.svg";
import { ReactComponent as CancelIcon } from "../../../static/svg/cancel-mark-rounded-lg.svg";
import DashboardContext from "../../../store/context/dashboard.context";

interface PayDialogProps {
  open: boolean;
  onClose: () => void;
  // benefits: string[];
}

const BenefitsDialog: React.FC<PayDialogProps> = ({
  open,
  onClose,
  // benefits,
}) => {
  const dashboardCtx = React.useContext(DashboardContext);

  const { organization } = dashboardCtx;

  const salary = organization?.salary ?? 0;
  const meals = organization?.meals ?? 0;
  const airtime = organization?.airtimeOrData ?? 0;
  const accomodation = organization?.Accomodation ?? 0;
  const transportation = organization?.transportation ?? 0;

  const benefits = [
    {
      id: 0,
      name: "Salary",
      value: salary,
    },
    {
      id: 1,
      name: "Transportation",
      value: transportation,
    },
    {
      id: 2,
      name: "Accomodation",
      value: accomodation,
    },
    {
      id: 3,
      name: "Meals",
      value: meals,
    },
    {
      id: 4,
      name: "Airtime / Data",
      value: airtime,
    },
  ];

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          // width: "90%",
        }}
        open={open}
        onClick={onClose}
      >
        <Box className="bg-white w-[90%] md:w-96  rounded-lg">
          <Box className="bg-green-50 centerItems rounded-t-lg">
            <Typography
              variant="caption"
              fontSize={{ xs: "20px", sm: "24px" }}
              // align="center"
              mt={5}
              mb={3}
              gutterBottom
              display={"flex"}
              alignSelf={"center"}
            >
              Salary & Benefits
            </Typography>
          </Box>

          <Box className=" mx-auto" mb={8} mt={5}>
            {benefits &&
              benefits.map((item, index) => (
                <Box
                  className="mx-auto w-1/2 flex items-center my-3"
                  key={index}
                >
                  <Box className="">
                    {item.value > 0 ? <CheckStar /> : <CancelIcon />}
                  </Box>
                  <Box className=" mr-auto ml-5">
                    <Typography
                      variant="h1"
                      fontSize={{ xs: "14px", sm: "16px" }}
                      // align="center"
                      // mt={3}
                      gutterBottom
                      // display={"flex"}
                      // alignSelf={"center"}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Backdrop>
    </React.Fragment>
  );
};

export default BenefitsDialog;
