
export const Short_FAQ_Data =  [
    {
      id: 1,
      question: "What Is Caring Blocks?    ",
      answer: "Caring Blocks is designed to provide household employers with access to business-level management software, enabling you to run payrolls, generate reports, train and track employee performance thereby raising the expectations and standard of care in your homes, including the employer-employee relationship.    ", 
    },
    
    {
      id: 2,
      question: "Who Are Household Employees?    ",
      answer: "Household Employees work privately in your homes. Their work may include cleaning the house, cooking, washing and ironing clothes, taking care of children, or elderly or sick members of a family, gardening, guarding the house, driving for the family, and even taking care of household pets.    ", 
    },
    {
      id: 3,
      question: "Is My Nanny, Driver, Or Cook An Employee Or Independent Contractor?    ",
      answer: "Nannies and other care workers should be treated as employees. A key feature of this determination is control. If you have control over how and when the work is performed, then you must classify yourself as an employer.    ", 
    },
    {
      id: 4,
      question: "Is It Necessary To Pay My Household Employee Legally?    ",
      answer: "Even if you have a friendly relationship with your employee, you must generate and keep pay slips. This ensures your employee understands that this is a real job with an employment agreement, job description, and training to ensure they continue to meet/exceed the minimum standard of care for your home and loved ones. In addition, all employees, whether they work in an office or a home, need verifiable income so they can apply for a loan or rent an apartment. This may sound basic, but for household employees it’s rare. Less than 1% are paid legally, and we’re here to change that.      ", 
    },
    {
      id: 5,
      question: "Can I Continue To Offer Monetary Gifts & Allowances Separately?    ",
      answer: "We recommend that all gifts, allowances, and other remittances be made exclusively within Caring Blocks to properly ensure we capture your total household employee expenditure monthly and annually. This allows you to take tax credit for it legally.    ", 
    },
    {
      id: 6,
      question: "How Does Caring Blocks Help With Training My Household Employees    ",
      answer: "Upon logging into your Caring Blocks Dashboard, you can recommend training for your household employees from your nanny to driver to cooks. This helps raise the quality of care in your household, reducing stress, and ensuring the safety and wellbeing of your loved ones.    ", 
    },
    {
      id: 7,
      question: "Do Caring Blocks Offer Background Checks?    ",
      answer: "When you onboard an employee, we offer to provide background checks services registered with the State and Federal Authorities. We make sure your employees are who they say they are.     ", 
    },
    {
      id: 8,
      question: "Will I Have Access To The Caring Blocks Database For Vetting Purposes?    ",
      answer: "If your employee is within the Caring Blocks database prior to hiring, then you have access to that data to view their employment history, past performances, and other important information. The aim of Caring Blocks is to help household employers understand who they are bringing into their homes.    ", 
    },
    {
      id: 9,
      question: "Does Caring Blocks Offer Health Assessments?    ",
      answer: "Through our partners, we offer subsidized health assessments before and after hiring your employee. We recommend a yearly assessment of your workers to ensure your family is protected from irreversible diseases and infections.    ", 
    },
    {
      id: 10,
      question: "I Identify As A Household Employee. How Do I Enroll?    ",
      answer: "Unfortunately, at this time you can only enroll when you receive an invitation from your Household Employer to join Caring Blocks. You can encourage your employer to start paying you through Caring Blocks.    ", 
    },
  ]
  

export const Caregivers_FAQ_Data =  [
    {
      id: 1,
      question: "What qualifications do I need to join Caring Blocks?",
      answer: "To join Caring Blocks, you should have relevant experience in the caregiving field, whether as a nanny, driver, housekeeper, or tutor. We value candidates who demonstrate reliability, a positive attitude, and a commitment to providing high-quality care. While formal qualifications and certifications are not always required, they are highly beneficial and can increase your chances of securing a position. Additionally, you should be willing to undergo a background check and health assessment as part of our vetting process.", 
    },
    
    {
      id: 2,
      question: "How does the vetting process work?",
      answer: "Our vetting process is thorough to ensure the safety and well-being of both our caregivers and the families they work with. After your application is accepted, you will undergo a background check that includes verifying your criminal record, previous employment, and references. You will also be required to complete a health assessment to ensure you are physically capable of fulfilling your duties. This process is designed to ensure that only the most trustworthy and capable caregivers are placed with families.", 
    },
    {
      id: 3,
      question: "How do I get matched with families or corporate clients?",
      answer: "Once you have successfully completed the vetting process and any required training, Caring Blocks will begin matching you with families or corporate clients who need your specific skills. Our matching process takes into account your experience, preferences, and availability, as well as the specific needs of the family or client. You will have the opportunity to review the details of the job and decide if it is the right fit for you before moving forward.", 
    },
    {
      id: 4,
      question: "How do I get paid, and when can I expect my salary?",
      answer: "Your salary will be paid directly into your Caring Blocks wallet, which is linked to your account on our platform. Payment is typically processed on a regular schedule, depending on the terms of your employment contract (e.g., weekly or monthly). Once the payment is deposited into your wallet, you can easily transfer the funds to your bank account at any time. You will also receive a detailed payslip outlining the breakdown of your earnings, including any deductions or bonuses.", 
    },
    {
      id: 5,
      question: "What kind of training and development opportunities does Caring Blocks offer?",
      answer: "Caring Blocks is committed to your professional growth. We offer a range of training programs through the Caring Blocks Institute, covering various aspects of caregiving, such as child development, first aid, household management, and more. These courses are designed to enhance your skills and knowledge, making you more competitive and effective in your role. Successful completion of these courses can also lead to certifications that will further advance your career.", 
    },
    {
      id: 6,
      question: "What should I do if I encounter a problem with the family or client I’m working with?",
      answer: "If you encounter any issues or feel uncomfortable in your role, you should immediately contact our care concierge service. We are committed to resolving conflicts and ensuring that both caregivers and families are satisfied with the arrangement. Depending on the situation, we may mediate discussions between you and the family or, if necessary, reassign you to a different role that better suits your needs.", 
    },
    {
      id: 7,
      question: "What support can I expect from Caring Blocks once I start working?",
      answer: "As a Caring Blocks caregiver, you will have access to our 24/7 care concierge service, which is available to assist you with any work-related issues or concerns. Whether you need advice, have a problem with your placement, or require urgent assistance, our team is here to help. Additionally, we provide ongoing support in areas such as contract management, payroll processing, and performance reviews to ensure your experience is as smooth and rewarding as possible.", 
    },

    {
      id: 8,
      question: "How secure is my personal information on the Caring Blocks platform?",
      answer: "At Caring Blocks, your privacy and security are our top priorities. All personal information you provide is securely stored and only used for the purposes of matching you with families or clients, processing payroll, and maintaining your employment records. We use advanced security measures to protect your data from unauthorized access and will never share your information with third parties without your consent.", 
    },
    {
      id: 9,
      question: "Can I negotiate the terms of my employment contract?",
      answer: "Yes, Caring Blocks allows for flexibility in employment contracts to ensure they meet the needs of both the caregiver and the employer. If you have specific requests or conditions, you can discuss them during the contract negotiation phase. Our team is here to facilitate these discussions and help reach an agreement that is fair and satisfactory for all parties involved.", 
    },
    {
      id: 10,
      question: "What happens if I want to leave a job before the contract ends?",
      answer: "If you wish to leave a job before your contract ends, you should notify both the family or client and Caring Blocks as soon as possible. We understand that circumstances can change, and we are here to support you through the transition. We will work with you to find a replacement caregiver for the family and assist you in finding a new placement if desired. It is important to follow the proper procedures outlined in your contract to ensure a smooth and professional departure.", 
    },
  ]
  

export const Families_FAQ_Data =  [
    {
      id: 1,
      question: "What types of household staff can Caring Blocks help us find?",
      answer: "Caring Blocks can assist you in hiring a variety of household staff, including nannies, housekeepers, drivers, home tutors, and more. Each employee is carefully vetted and trained to meet your family’s unique needs.", 
    },
    
    {
      id: 2,
      question: "How does Caring Blocks vet potential employees?",
      answer: "We have a rigorous vetting process that includes comprehensive background checks, health assessments, and reference verifications. We ensure that every employee is qualified, trustworthy, and well-suited to your household.", 
    },
    {
      id: 3,
      question: "What is the onboarding process like?",
      answer: "Our onboarding process is seamless and thorough. We handle all the details, including health assessments, background checks, contract agreements, and any necessary training. This ensures that your household staff is fully prepared to start their role from day one.", 
    },
    {
      id: 4,
      question: "Can Caring Blocks manage payroll and benefits for my household staff?",
      answer: "Yes, Caring Blocks offers a comprehensive payroll management service. We handle salary payments, benefits, and even provide payslips for your household employees. This service helps ensure that your household runs smoothly without the administrative burden.", 
    },
    {
      id: 5,
      question: "What if I have specific requirements for my household employee?",
      answer: "We understand that every family has unique needs. During the application process, you can specify your preferences and requirements, and we will match you with a household employee who meets those criteria.", 
    },
    {
      id: 6,
      question: "Do you provide ongoing support and training for household employees?",
      answer: "Absolutely. We offer continuous training and development through our Caring Blocks Institute to help your household staff stay motivated, improve their skills, and perform their duties efficiently. We also provide 24/7 care support for any issues that may arise.", 
    },
    {
      id: 7,
      question: "How does Caring Blocks ensure the privacy and safety of my family?",
      answer: "The safety and privacy of your family are our top priorities. All our employees are required to sign confidentiality agreements, and we use secure, encrypted systems to handle your personal information.", 
    },

    {
      id: 8,
      question: "What happens if the household employee is not a good fit for my family?",
      answer: "If you feel that the employee is not the right fit, Caring Blocks offers a replacement guarantee within a specified period. We work with you to understand your concerns and find a better match for your household.", 
    },
    {
      id: 9,
      question: "How quickly can we hire a household employee through Caring Blocks?",
      answer: "The timeline for hiring depends on your specific needs and preferences. However, our streamlined process typically allows families to onboard a new household employee within a few weeks, ensuring that all vetting and onboarding steps are completed thoroughly.", 
    },
    {
      id: 10,
      question: "Can Caring Blocks help with temporary or emergency childcare?",
      answer: "Yes, we offer solutions for both long-term and short-term needs, including emergency childcare. Our care concierge service is available 24/7 to assist you with urgent needs, ensuring that you always have the support you require.", 
    },
  ]
  
  

export const Corporate_FAQ_Data =  [
    {
      id: 1,
      question: "What services does Caring Blocks offer to corporate clients?",
      answer: "Caring Blocks partners with companies to provide comprehensive childcare and household support for their employees. Our services include hiring, vetting, and onboarding of nannies and household staff, caregiver training through our Caring Blocks Institute, household payroll management, and ongoing performance management. We tailor these services to meet the unique needs of your workforce, helping you retain talent and boost productivity.", 
    },
    
    {
      id: 2,
      question: "What are the benefits of providing childcare support to employees?",
      answer: "Offering childcare support through Caring Blocks can lead to numerous benefits, including reduced absenteeism, improved mental and emotional well-being, and enhanced employee satisfaction. When employees don’t have to worry about managing childcare, they can concentrate on their work, leading to higher productivity and a more positive workplace culture.", 
    },
   
    {
      id: 3,
      question: "What kind of training do caregivers receive through Caring Blocks?",
      answer: "Caregivers enrolled in Caring Blocks receive ongoing training and development through our Caring Blocks Institute. This training covers a wide range of topics, including child development, first aid, and other essential skills to ensure they are well-equipped to provide exceptional care to your employees’ families.", 
    },
    {
      id: 4,
      question: "How does the onboarding process for household staff work?",
      answer: "Our onboarding process is seamless and comprehensive. We handle everything from the initial vetting and hiring of household staff to their onboarding into the employee’s household. This includes background checks, health assessments, and any necessary training to ensure they are fully prepared to provide the highest level of care.", 
    },
    {
      id: 5,
      question: "How can our employees access Caring Blocks’ services?",
      answer: "Once your company is onboarded with Caring Blocks, employees can access our services through our platform. They can easily hire, manage, and pay for household staff, schedule training sessions, and access support from our 24/7 care managers.", 
    },
    {
      id: 6,
      question: "How does Caring Blocks handle payroll for household staff?",
      answer: "Caring Blocks offers a full-service payroll solution for household staff, including salary calculations, tax withholdings, and payment processing. Employees can easily manage payroll through our platform, ensuring their household staff are paid accurately and on time.", 
    },
    {
      id: 7,
      question: "What is the process for corporate clients to get started with Caring Blocks?",
      answer: "Getting started with Caring Blocks is simple. Corporate clients can schedule a call with our team to discuss their needs and customize a care package that suits their workforce. Once the details are finalized, we begin the implementation process, which includes setting up the necessary infrastructure and onboarding employees to the platform.", 
    },

    {
      id: 8,
      question: "Can Caring Blocks’ services be customized to fit the specific needs of our company?",
      answer: "Absolutely. We understand that every company has unique needs, and we work closely with our corporate clients to customize our services accordingly. Whether you need specific training for caregivers or a tailored onboarding process, we can adapt our offerings to meet your requirements.", 
    },
   
    {
      id: 9,
      question: "How does Caring Blocks help improve employee retention and productivity?",
      answer: "By offering reliable childcare and household support, Caring Blocks helps alleviate the stress and time constraints that often accompany parenthood. This support enables employees to focus more on their work, leading to increased productivity. Additionally, employees who feel their personal lives are supported are more likely to stay with the company long-term, improving overall retention rates.", 
    },
    {
      id: 10,
      question: "What measures are in place to ensure the safety and security of household staff?",
      answer: "Safety and security are top priorities at Caring Blocks. We conduct thorough background checks, health assessments, and vetting processes for all household staff. Additionally, we provide ongoing support and performance management to ensure that caregivers maintain high standards of care.", 
    },
    {
      id: 11,
      question: "What are your pricing models for corporations looking to support their employees?",
      answer: `At Caring Blocks, we offer flexible pricing models to help corporations provide childcare solutions tailored to their workforce’s needs:

      Full Benefit: The corporation covers the entire cost of the nanny’s annual gross salary plus the agency fee. This comprehensive package ensures that your employees receive top-tier care without any financial burden.

      Split Benefit: The corporation and employee share the costs by splitting the agency fee and the nanny’s salary. This balanced approach allows companies to contribute significantly while sharing responsibility with their employees.

      Partial Salary Benefit: The corporation covers the full salary of the nanny, while the employee is responsible for the upfront payment of the agency fee. This model supports employees by covering the ongoing costs, easing their financial commitment.

      Partial Fee Benefit: The corporation pays only the agency fee, leaving the employee to cover the nanny’s salary. This option allows employers to provide crucial assistance with the initial setup, while employees manage the ongoing costs.

      These models are designed to be adaptable to your organization’s budget and your employees' needs, ensuring that everyone benefits from a supportive and sustainable childcare solution.
`, 
    },
  ]
  