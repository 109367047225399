import { Box, Button, Typography } from "@mui/material";
import React from "react";
import MuiLink from "@mui/material/Link";
import Lottie from "react-lottie";

import * as animationData from "../../../static/json-animate/wallet icon.json";
import * as animationData2 from "../../../static/json-animate/reciept.json";
import useDialog from "../../../hooks/use-dialog";
import UploadDialog from "./UploadDialog";
import PaymeDialog from "./PaymeDialog";

type Props = {};

const UploadReceipt = (props: Props) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    openDialog: openPayDialog,
    handleOpenDialog: handleOpenPayDialog,
    handleCloseDialog: handleClosePayDialog,
  } = useDialog();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleUpload = () => {
    // console.log("Uploaded Receipt");
    handleOpenDialog();
  };
  return (
    <React.Fragment>
      {/* <Button className="bg-[#F7F9F9] ring-1 ring-[#D2E0CB]">
        <Typography
          variant="subtitle1"
          fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
          gutterBottom
          className=" capitalize"
        >
          Upload your receipts
        </Typography>
      </Button> */}

      <MuiLink
        onClick={handleOpenPayDialog}
        className="flex float-left no-underline place-content-around items-center bg-[#F7F9F9] ring-1 ring-[#D2E0CB] w-fit p-3 rounded-lg cursor-pointer"
      >
        <Box className="w-fit">
          <Lottie options={defaultOptions2} height={"33px"} width={"50px"} />
        </Box>

        <Typography
          variant="body1"
          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
          // gutterBottom
          className="mt-2 "
        >
          Pay Me
        </Typography>
      </MuiLink>
      <MuiLink
        onClick={handleUpload}
        className="flex float-right no-underline place-content-around items-center bg-[#F7F9F9] ring-1 ring-[#D2E0CB] w-fit p-3 rounded-lg cursor-pointer"
      >
        <Box className="w-fit">
          <Lottie options={defaultOptions} height={"33px"} width={"50px"} />
        </Box>

        <Typography
          variant="body1"
          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
          // gutterBottom
          className="mt-2 "
        >
          Upload your receipts
        </Typography>
      </MuiLink>

      {openDialog && (
        <UploadDialog open={openDialog} onClose={handleCloseDialog} />
      )}
      {openPayDialog && (
        <PaymeDialog open={openPayDialog} onClose={handleClosePayDialog} />
      )}
    </React.Fragment>
  );
};

export default UploadReceipt;
