import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import MHFormControl from "../../components/Common/Form/MHFormControl";
import InputAdornment from "../../components/Common/Form/InputAdornment";

import * as validators from "../../utils/validators";
import useInput from "../../hooks/use-input";

import { ReactComponent as SearchIcon } from "../../static/svg/search.svg";
import MHTextInput from "../../components/Common/Form/MHTextInput";
import NotificationCard from "../../components/Features/Dashboard/NotificationCard";

const messages = [
  {
    id: 1,
    type: "Email Notification",
    subject: "mail has been sent",
    sender: "Blessing Adesiyan",
    recepient: "andrewjames@gmail.com",
    body: "We are please to inform you that a sucessfull registration email has been sent to James Andrew  at the email address provided during the unboarding stage which is",
    time: "29 Jan 2020 at 09:30 AM",
  },
  {
    id: 2,
    type: "Contract Notification",
    subject: "contract has been sent",
    sender: "Blessing Adesiyan",
    recepient: "andrewjames@gmail.com",
    body: "We are please to inform you that a contract has been sent to James Andrew  at the email address provided during the unboarding stage which is",
    time: "19 Mar 2020 at 10:30 AM",
  },
  {
    id: 3,
    type: "Email Notification",
    subject: "mail has been sent",
    sender: "Blessing Adesiyan",
    recepient: "andrewjames@gmail.com",
    body: "We are please to inform you that a sucessfull registration email has been sent to James Andrew  at the email address provided during the unboarding stage which is",
    time: "29 Jan 2020 at 09:30 AM",
  },
  {
    id: 4,
    type: "Contract Notification",
    subject: "contract has been sent",
    sender: "Blessing Adesiyan",
    recepient: "andrewjames@gmail.com",
    body: "We are please to inform you that a contract has been sent to James Andrew  at the email address provided during the unboarding stage which is",
    time: "19 Mar 2020 at 10:30 AM",
  },
  {
    id: 5,
    type: "Email Notification",
    subject: "mail has been sent",
    sender: "Blessing Adesiyan",
    recepient: "andrewjames@gmail.com",
    body: "We are please to inform you that a sucessfull registration email has been sent to James Andrew  at the email address provided during the unboarding stage which is",
    time: "29 Jan 2020 at 09:30 AM",
  },
  {
    id: 6,
    type: "Contract Notification",
    subject: "contract has been sent",
    sender: "Blessing Adesiyan",
    recepient: "andrewjames@gmail.com",
    body: "We are please to inform you that a contract has been sent to James Andrew  at the email address provided during the unboarding stage which is",
    time: "19 Mar 2020 at 10:30 AM",
  },
];

type Props = {};

const EmployeeNotifications = (props: Props) => {
  const [word, setWord] = React.useState("");
  // const {
  //   value: enteredSearch,
  //   valid: enteredSearchIsValid,
  //   error: searchInputHasError,
  //   onChange: searchInputChangeHandler,
  //   onBlur: searchInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWord(event.target.value);
  };

  return (
    <React.Fragment>
      <Box className="bg-[#F9F9F7] h-auto">
        <Box py={4}></Box>
        <Paper
          sx={{
            px: 3,
            pt: 3,
            boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
          }}
          className="h-full max-w-6xl mx-auto"
        >
          <Box className="flex justify-between" mb={3}>
            <Box>
              <Typography
                variant="subtitle1"
                color={"primary"}
                fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                lineHeight={{ xs: "29px", sm: "29px", md: "29px" }}
                align="center"
                letterSpacing={"0.01em"}
                className=" capitalize"
              >
                Notification
              </Typography>
            </Box>
            <Box>
              {/* <MHFormControl
                id="Search"
                type="text"
                label=""
                placeholder="Search"
                value={enteredSearch}
                onChange={searchInputChangeHandler}
                onBlur={searchInputBlurHandler}
                autoComplete="off"
                startAdornment={
                  <InputAdornment>
                    <SearchIcon width="1rem" />
                  </InputAdornment>
                }
                required
                autoFocus
              /> */}

              <MHTextInput
                id="text"
                type={"text"}
                // label="role"
                placeholder="Search"
                value={word}
                onChange={handleSearch}
                // onBlur={roleInputBlurHandler}
                // disabled={true}
                className={"bg-[#F9F9F9] w-[300px]"}
                startAdornment={
                  <InputAdornment>
                    <SearchIcon width="1rem" />
                  </InputAdornment>
                }
                required
                // autoFocus
              />
            </Box>
          </Box>

          <Divider />

          <Box p={2} className=" h-full">
            <NotificationCard searchWord={word} notifications={messages} />
          </Box>
        </Paper>
        <Box py={4}></Box>
      </Box>
    </React.Fragment>
  );
};

export default EmployeeNotifications;
