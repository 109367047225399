import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill, Range } from "react-quill";
import "react-quill/dist/quill.snow.css";
import MHButton from "../Button/MHButton";
import {
  householdDefaultContract,
  nannyDefaultContract,
  driverDefaultContract,
  driverScopeData,
  contractHtml,
  chefDefaultContract,
  guardDefaultContract,
  tutorDefaultContract,
} from "../../../utils/contracts";
import SectionCard from "./SectionCard";
import { useOnboardForm } from "../../../store/context/onboard-context";

import "../../../../src/CustomEditor.scss";

const Delta = Quill.import("delta");

const MHEditor: React.FC = () => {
  // Importing the necessary formats from Quill
  const Font = Quill.import("formats/font");
  const editorRef = useRef<ReactQuill | null>(null); // Define the type of editorRef

  const [range, setRange] = useState<Range | null>(null);

  const { contractForm, addToContract } = useOnboardForm();

  // Whitelist your custom fonts
  Font.whitelist = [
    "Columbia-Sans",
    "Area-Extended",
    "Area-Normal",
    "Area-Normal-Bold",
    "Area-Normal-Semibold",
  ];

  // Register the custom font formats
  Quill.register(Font, true);

  const modules = {
    toolbar: {
      container: [
        // [{ header: "1" }, { header: "2" }],
        // [
        //   {
        //     font: [
        //       "Columbia-Sans",
        //       "Area-Extended",
        //       "Area-Normal",
        //       "Area-Normal-Bold",
        //       "Area-Normal-Semibold",
        //     ],
        //   },
        // ],
        // [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote", "code"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ color: [] }, { background: [] }],
        [{ direction: "rtl" }], // text direction
        // dropdown with defaults from theme
        // [{ font: [] }],
        [{ align: [] }],
        ["clean"],
      ],
    },
    clipboard: { matchVisual: false },
    history: { userOnly: true },
    syntax: false,
  };

  const [editorHtml, setEditorHtml] = useState<any>(
    // contractForm?.editorHtml === "" ? contractHtml : contractForm?.editorHtml
    contractForm.role === "nanny"
      ? nannyDefaultContract
      : contractForm.role === "driver/chauffeur"
      ? driverDefaultContract
      : contractForm.role === "chef/cook"
      ? chefDefaultContract
      : contractForm.role === "security guard"
      ? guardDefaultContract
      : contractForm.role === "home tutor"
      ? tutorDefaultContract
      : householdDefaultContract
  );

  // if (organization?.jobTitle === "nanny") {
  //   setContractData(nannyDefaultContract);
  // } else if (organization?.jobTitle === "driver/chauffeur") {
  //   setContractData(driverDefaultContract);
  // } else {
  //   setContractData(contractHtml);
  // }
  // const handleChange = (content: string) => {
  //   // Set the content of the editor
  //   setEditorHtml(content);
  // };

  // const handleAddCustomText = () => {
  //   const customText =
  //     "<h4>Test Section</h4><ul><li>Item 1</li><li>Item 3</li><li>Item 4</li></ul>";
  //   setEditorHtml(editorHtml + customText);
  // };
  const handleAddCustomText = (label: string) => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      if (quill) {
        const selection = quill.getSelection();
        if (selection) {
          const cursorIndex = selection.index;

          // Find the item in driverScopeData based on the label
          const selectedItem = filteredData.find(
            (item) => item.label === label
          );

          if (selectedItem) {
            // Extract the HTML content from the selected item
            const customText = selectedItem.data;

            // Insert the custom HTML text at the cursor position
            quill.clipboard.dangerouslyPasteHTML(cursorIndex, customText);
          }
        } else {
          // If no selection, insert at the end of the editor's content
          const length = quill.getLength();
          const selectedItem = filteredData.find(
            (item) => item.label === label
          );

          if (selectedItem) {
            const customText = selectedItem.data;
            quill.clipboard.dangerouslyPasteHTML(length, customText);
          }
        }
      }
    }
  };

  // console.log(editorHtml);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    // Filter data based on type === "driver" and role === "driver"
    const filtered = driverScopeData.filter(
      (item) => item.role === contractForm.role?.toLowerCase()
    );
    setFilteredData(filtered);
  }, []);

  useEffect(() => {
    // Check if the contractForm is not empty before updating it
    if (contractForm.role !== "") {
      addToContract({
        editorHtml: editorHtml,
      });
    }
  }, [editorHtml, contractForm, addToContract]);

  // console.log("filteredData", filteredData);

  return (
    <React.Fragment>
      <Box className="relative quillEditor">
        {/* <Box className="mb-48"></Box> */}
        <ReactQuill
          theme="snow"
          value={editorHtml}
          onChange={setEditorHtml}
          modules={modules}
          ref={editorRef}
          className="h-auto"
          onChangeSelection={(selection) => setRange(selection)}
          // onTextChange={setLastChange}
        />
        {/* <MHButton onClick={handleAddCustomText}>Add Custom Text</MHButton> */}

        {/* {filteredData.map((list, index) => (
          <SectionCard
            handleAdd={() => handleAddCustomText(list?.label)} // Pass a function reference
            data={list?.data}
            label={list.label}
          />
        ))} */}
      </Box>
    </React.Fragment>
  );
};

export default MHEditor;
