import React from "react";
import { matchPath } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";

import AppBar from "./AppBar";
import Sidebar from "./MainSidebar";

import { FnComponent } from "../../models/component.model";
import ScrollToTop from "./ScrollToTop";
import { useMediaQuery, useTheme } from "@mui/material";
import ErrorBoundaryPage from "../../pages/Error/ErrorBoundaryPage";
import useOnline from "../../hooks/use-online";
import Footer from "./Footer";

const Layout: FnComponent = (props) => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const userIsOnline = useOnline();

  return (
    <Box>
      {ScrollToTop()}
      <AppBar />

      <Stack direction="row" component={"main"}>
        {matchesMdDown ? <Sidebar /> : null}

        <Box
          sx={{
            // backgroundColor: (theme) => '#fefefe',
            backgroundColor: (theme) => "#ffffff",
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Toolbar />

          {userIsOnline ? (
            props.children
          ) : (
            <ErrorBoundaryPage
              title="No connection"
              content="It looks like you are offline. Please check your internet connection."
              buttonLabel="Retry"
              onButtonClick={() => window.location.reload()}
              includeFooter
            />
          )}
        </Box>
      </Stack>
      {/* <Footer /> */}
    </Box>
  );
};

export default Layout;
