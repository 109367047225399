import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  formatNumber,
  getURLWithQueryParams,
  isEmpty,
} from "../../../utils/utils";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import MHButton from "../../Common/Button/MHButton";

import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";
import useDialog from "../../../hooks/use-dialog";
import EmployeePayrollDialog from "./EmployeePayrollDialog";
import { EmployeeData } from "../../../models/employee.model";
import useHttp from "../../../hooks/use-http";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";
import { HttpResponse } from "../../../models/api.interface";
import NewPaySlip from "../Payments/NewPaySlip";
import { useOnboardForm } from "../../../store/context/onboard-context";

type Props = {
  recurringAmount: string | number | any;
  expensesAmount?: string | number | any;
};

const SpendingDesign = (props: Props) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<EmployeeData | null>(null);

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  const { accountDetails, getAcctDetails } = useOnboardForm();

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        className=" place-content-start md:justify-between gap-2 lg:gap-0 z-20 bg-inherit"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6.6}
          px={2}
          py={4}
          boxShadow="0px 5px 26px #C7D8E4"
          sx={{
            // cursor: "pointer",
            backgroundColor: "#fff",
            borderRadius: 3,
          }}
          className=" order-2 md:order-1  flex justify-between"
        >
          <Box className=" z-10">
            <Typography
              variant="body2"
              fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
              gutterBottom
              className=" capitalize"
            >
              monthly recurring &copy;
            </Typography>
            {props.recurringAmount === 0 ? (
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "25px", md: "25px" }}
                display="flex"
                alignItems="center"
              >
                ----
              </Typography>
            ) : (
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "25px", md: "25px" }}
                display="flex"
                alignItems="center"
              >
                <NairaIcon className=" w-5 h-5 mt-1" />
                {formatNumber(props.recurringAmount)}
              </Typography>
            )}

            <MuiLink
              component={Link}
              underline="always"
              to="#"
              // display="block"
              // align="left"
              className="text-blue-100 mt-2 removeUnderline"
            >
              View {"  > "}
            </MuiLink>
          </Box>

          <Box className="flex flex-2">
            <Box className="inline-block h-full w-0.5 self-stretch bg-[#D1D1D1]"></Box>
          </Box>

          <Box className=" z-10">
            <Typography
              variant="body2"
              fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
              gutterBottom
              className=" capitalize"
            >
              Expenses &copy;
            </Typography>
            {props.expensesAmount === 0 ? (
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "25px", md: "25px" }}
                display="flex"
                alignItems="center"
              >
                ----
              </Typography>
            ) : (
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "25px", md: "25px" }}
                display="flex"
                alignItems="center"
              >
                <NairaIcon className=" w-5 h-5 mt-1" />
                {formatNumber(props.expensesAmount)}
              </Typography>
            )}

            <MuiLink
              component={Link}
              underline="always"
              to="#"
              // display="block"
              // align="left"
              className="text-blue-100 mt-2 removeUnderline"
            >
              View {"  > "}
            </MuiLink>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5.2}
          px={2}
          py={4}
          boxShadow="0px 5px 26px #C7D8E4"
          sx={{
            // cursor: "pointer",
            backgroundColor: "#fff",
            borderRadius: 3,
          }}
          className=" order-2 md:order-1 ring-1 ring-[#8BC34A] flex justify-between"
        >
          <Box className=" z-10">
            <Typography
              variant="body2"
              fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
              gutterBottom
              className=" capitalize"
            >
              Total Payment
            </Typography>

            {props.recurringAmount + props.expensesAmount === 0 ? (
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "25px", md: "25px" }}
                display="flex"
                alignItems="center"
              >
                ----
              </Typography>
            ) : (
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "25px", md: "25px" }}
                display="flex"
                alignItems="center"
              >
                <NairaIcon className=" w-5 h-5 mt-1" />
                {formatNumber(props.recurringAmount + props.expensesAmount)}
              </Typography>
            )}
          </Box>

          <MHButton
            color="success"
            sx={{}}
            type="submit"
            className=" w-[67px] h-[40px] rounded-md text-white hover:text-[#194049]"
            // startIcon={<PlusIcon />}
            onClick={handleOpenDialog}
            disabled={
              isEmpty(employees) || isEmpty(accountDetails?.AccountNumber)
            }
          >
            Pay
          </MHButton>
        </Grid>
      </Grid>
      {openDialog && (
        <EmployeePayrollDialog open={openDialog} onClose={handleCloseDialog} />
      )}

      {selectedEmployee && <NewPaySlip employee={selectedEmployee} />}
    </React.Fragment>
  );
};

export default SpendingDesign;
