import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";

import * as animationData from "../../static/json-animate/Baby.json";

type Props = {};

const Home = (props: Props) => {
  const verbiage = [
    "Hire, Vet, Onboard, Train, And Manage Household Contractors",
    "Pay Salary, Provide Benefits, And Retain Quality Worker",
    "Raise The Standard Of Care For Your Family and Household",
  ];

  let history = useHistory();

  const [textIndex, setTextIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % verbiage.length);
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <div id="home"></div>

      <Grid
        container
        spacing={2}
        sx={{
          paddingTop: "20px",
          justifyContent: "center",
          alignItems: "center",
          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "800px", sm: "520px", md: "610px" },
          backgroundColor: "#ffffff",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
      >
        <Grid item xs={11} sm={5.5} md={4.8}>
          <Typography
            variant="subtitle1"
            color="primary"
            fontSize={{ xs: "27px", sm: "27px", md: "28px" }}
            lineHeight={{ xs: "160.5%", sm: "160.5%" }}
            letterSpacing={"-4%"}
            className=" capitalize mx-auto md:mx-0 text-center md:text-start md:order-first order-last"
            width={{ xs: "90%", sm: "90%", md: "60%" }}
          >
            {/* quality care and the peace of mind that comes with knowing they are
            in capable hands. */}
            {verbiage[textIndex]} <br />
          </Typography>

          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="py-8 capitalize mx-auto md:mx-0 text-center md:text-start"
            width={{ xs: "90%", sm: "75%" }}
          >
            Perfect for nannies, drivers, housekeepers, home tutors, and other
            household contractors you employ
          </Typography>

          <Box className=" mx-auto">
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className="mb-12  lg:mb-24 capitalize w-full md:w-[280px] h-[54px]"
              // startIcon={<PlusIcon />}
              // onClick={handleOpenEmployee}
              onClick={() => history.push("/auth/onboard")}
            >
              {/* Start Free Trial */}
              Get Started
            </MHButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={11}
          sm={5.5}
          md={4.8}
          sx={{ display: { xs: "grid", sm: "grid", justifyContent: "center" } }}
          className=" md:order-last order-first"
        >
          {/* <img src="mama.png" alt="CaringBlocks" className=" lg:h-[469px]" /> */}
          <Lottie options={defaultOptions} height={"100%"} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Home;
