import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as PlaySettingIcon } from "../../../static/svg/play-setting.svg";
import { ReactComponent as ArrowRightIcon } from "../../../static/svg/arrow-right-white.svg";
import { ReactComponent as CheckIcon } from "../../../static/svg/check-mark-md.svg";
import { ReactComponent as LadyIcon } from "../../../static/svg/lady-on-call.svg";
import { ReactComponent as HelpCenterIcon } from "../../../static/svg/help-center.svg";
import MHButton from "../../Common/Button/MHButton";

type Props = {};

const SideComponent = (props: Props) => {
  const textArray = [
    "Vaccination Verification",
    "Vision Screening Test",
    " Drug And Alcohol Test",
    "Driving Record Check",
    "Reference Checks",
  ];
  return (
    <React.Fragment>
      <Box className="h-fit w-full ring-1 ring-[#D9D9D9] rounded-md mb-2">
        <Box className="bg-[#D9D9D9] w-full flex gap-4 md:gap-2 lg:gap-4 bg-opacity-30 pt-2 px-2 border-[#D9D9D9] border-b-[1px] ring-[#D9D9D9] rounded-t-md">
          <Box className="h-fit">{<PlaySettingIcon className="w-4 h-4" />}</Box>
          <Box className="">
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "16px", sm: "14px", md: "16px" }}
              gutterBottom
            >
              Start-off Requirement
            </Typography>
          </Box>
        </Box>

        <Box className="bg-white rounded-b-md p-3">
          <Box
            className="bg-[#D9D9D9] bg-opacity-30 w-fit px-3 py-1 rounded-sm"
            mb={3}
          >
            <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              lineHeight={"24px"}
              letterSpacing={"0px"}
              gutterBottom
              className=" text-[#194049]"
            >
              By accepting this agreement/offer, please be aware that we have
              been directed to conduct a series of checks to determine your
              suitability for the role.
            </Typography>
          </Box>
          {textArray.map((label: any, index: any) => (
            <Box className=" flex justify-start gap-4 items-center" key={index}>
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "14px", sm: "12px", md: "12px" }}
                gutterBottom
                mb={1}
                className="capitalize text-[#194049]"
              >
                {<CheckIcon className="w-4 h-4" />}
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "16px", sm: "14px", md: "14px" }}
                gutterBottom
                mb={1}
                className={`${
                  label === "email" ? "lowercase" : "capitalize"
                } text-navy-900`}
              >
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="h-fit w-full ring-1 ring-[#D9D9D9] rounded-md mb-2">
        <Box className="bg-white rounded-md">
          <Box className=" w-full flex gap-4 md:gap-2 lg:gap-4 pt-2 px-2 items-center  rounded-t-md">
            <Box className="h-fit">{<LadyIcon className="w-16 h-16" />}</Box>
            <Box className=" ">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                gutterBottom
              >
                Need Assistance?
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                gutterBottom
                className="text-start w-[90%]"
              >
                You can contact one of our Caring Blocks Associate.
              </Typography>
            </Box>
          </Box>

          <Box className="h-fit relative flex place-content-end">
            {<HelpCenterIcon className="w-fit h-40" />}

            <div className=" absolute right-0 left-[75px] bottom-6">
              <MHButton
                //   type="submit"
                form="add-employee-form"
                //   loading={loading}
                className="rounded-[4px] cursor-pointer h-[36px]"
                // fullWidth
                endIcon={<ArrowRightIcon className="w-4 h-4" />}
                //   onClick={handleSignState}
              >
                Contact Now
              </MHButton>
            </div>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SideComponent;
