import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useDrawer, { Anchor } from '../../hooks/use-drawer';

type LayoutContextShape = {
  isSidebarOpen: boolean;
  toggleSidebar: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  sidebarVariant: 'permanent' | 'persistent' | 'temporary';
  anchor: Anchor;
};

const LayoutContext = React.createContext<LayoutContextShape>({
  isSidebarOpen: false,
  toggleSidebar: (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {},
  sidebarVariant: 'permanent',
  anchor: 'left'
});

export const LayoutContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const {
    open: isSidebarOpen,
    anchor,
    toggleDrawer: toggleSidebar
  } = useDrawer('left', matchesMdDown ? false : true);

  const value: LayoutContextShape = {
    isSidebarOpen,
    toggleSidebar,
    sidebarVariant: matchesMdDown ? 'temporary' : 'permanent',
    anchor: 'left'
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export default LayoutContext;
