import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";

export const MHPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: "#194049", // Default text color
    backgroundColor: "#fff", // Default background color
    cursor: "pointer",
  },
  "& .MuiPaginationItem-root:hover": {
    backgroundColor: "#ccc", // Hover background color for inactive items
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    backgroundColor: "#194049 !important", // Active background color
    color: "#fff !important", // Active text color
    "&:hover": {
      backgroundColor: "#fff", // Hover background color for active item
      color: "#194049", // Hover text color for active item
    },
  },
}));
