import { Box, Typography } from "@mui/material";
import React from "react";
import ProfileInfo from "../../components/Features/Dashboard/ProfileInfo";
import ProfileOverview from "../../components/Features/Dashboard/ProfileOverview";
import AuthContext from "../../store/context/auth-context";

type Props = {};

const EmployerProfile = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  // const [value, setValue] = React.useState(0);

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <React.Fragment>
      <Box className="bg-[#F5F5F5]">
        <Box
          display="flex"
          justifyContent="left"
          pt={{ xs: 4, sm: 8 }}
          className="pl-4 md:pl-10 lg:pl-20"
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "20px", sm: "30px" }}
            align="center"
            mt={3}
            gutterBottom
            display={"flex"}
            alignSelf={"start"}
            className=" capitalize"
          >
            Hi {user?.firstName}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="left"
          mb={4}
          className="pl-4 md:pl-10 lg:pl-20"
        >
          <Typography
            variant="body2"
            mb={3}
            gutterBottom
            display={"flex"}
            alignSelf={"start"}
            className=" opacity-50"
          >
            Below are your activities overview
          </Typography>
        </Box>

        <Box className="md:flex md:flex-row gap-3 md:gap-0 lg:gap-8 justify-center pb-4 w-full">
          <Box className="lg:bg-white px-4 md:px-0 lg:px-4 md:basis-3/12 lg:basis-2/12 rounded-lg drop-shadow-lg h-full md:h-screen">
            <ProfileInfo
            // tabValue={value} handleTabChange={handleTabChange}
            />
          </Box>
          <Box className=" pr-2 px-2 lg:p-4 md:basis-8/12 lg:basis-8/12 overflow-auto md:overflow-auto lg:overflow-visible h-full">
            <ProfileOverview
            // tabValue={value}
            // handleTabChange={handleTabChange}
            />
          </Box>
        </Box>
        {/* <Box className="md:flex md:flex-row gap-3 lg:gap-8 justify-center pb-4">
          <Box className="px-4 md:basis-3/12 lg:basis-2/12  rounded-lg drop-shadow-lg h-full md:h-screen ">
            <ProfileInfo
            // tabValue={value} handleTabChange={handleTabChange}
            />
          </Box>
          <Box className="p-4 md:basis-8/12 lg:basis-8/12">
            <ProfileOverview
            // tabValue={value}
            // handleTabChange={handleTabChange}
            />
          </Box>
        </Box> */}
      </Box>
    </React.Fragment>
  );
};

export default EmployerProfile;
