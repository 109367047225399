import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import MHButton from "../../Common/Button/MHButton";
import useInput from "../../../hooks/use-input";
// import useInputArray from "../../../hooks/use-input-array";

import { parseAmount, resolveErrorMessage } from "../../../utils/utils";
// import { ReactComponent as DollarIcon } from "../../../static/svg/dollar.svg";
import DashboardContext from "../../../store/context/dashboard.context";

import * as validators from "../../../utils/validators";
import useHttp from "../../../hooks/use-http";
// import { HttpResponse } from "../../../models/api.interface";
import AuthContext from "../../../store/context/auth-context";
import SnackbarContext from "../../../store/context/snackbar.context";
import { Organization } from "../../../models/employer.model";
import { EmployeeData } from "../../../models/employee.model";

type EmployeeProps = {
  employee: EmployeeData | null;
  onClose: () => void;
};

const BucketAllocation = (props: EmployeeProps) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest: signIn } = useHttp();

  const {
    value: enteredAllocation,
    valid: enteredAllocationIsValid,
    error: enteredAllocationHasError,
    onChange: allocationInputChangeHandler,
    onBlur: allocationInputBlurHandler,
    markAsTouched: markAllocationInputAsTouched,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredMeals,
    valid: enteredMealsIsValid,
    error: enteredMealsHasError,
    onChange: mealsInputChangeHandler,
    onBlur: mealsInputBlurHandler,
    markAsTouched: mealsInputAsTouched,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredTransportation,
    valid: enteredTransportationIsValid,
    error: enteredTransportationHasError,
    onChange: transportationInputChangeHandler,
    onBlur: transportationInputBlurHandler,
    markAsTouched: transportationInputAsTouched,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAccomodation,
    valid: enteredAccomodationIsValid,
    error: enteredAccomodationHasError,
    onChange: accomodationInputChangeHandler,
    onBlur: accomodationInputBlurHandler,
    markAsTouched: accomodationInputAsTouched,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAirtimeData,
    valid: enteredAirtimeDataIsValid,
    error: enteredAirtimeDataHasError,
    onChange: airtimeDataInputChangeHandler,
    onBlur: airtimeDataInputBlurHandler,
    markAsTouched: airtimeDataInputAsTouched,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const dashboardCtx = React.useContext(DashboardContext);
  const { updateEmployeeDetails } = dashboardCtx;

  const submitHandler = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!updateEmployeeDetails) {
      console.error("updateEmployeeDetails is not defined in DashboardContext");
      return;
    }

    const updatedOrganization: Organization = {
      ...props.employee,

      id: props.employee?.id,
      uuid: props.employee?.uuid,
      salary: Number(enteredAllocation),
      Accomodation: Number(enteredAccomodation),
      meals: Number(enteredMeals),
      airtimeOrData: Number(enteredAirtimeData),
      transportation: Number(enteredTransportation),

      // Add other properties as needed
    };

    console.log(updatedOrganization);

    if (props.employee) {
      await updateEmployeeDetails(updatedOrganization, props.employee);
      toast({
        message: "Successfully Updated Employee Salary.",
        variant: "success",
      });
      window.location.reload();
      console.log(props.employee);
    } else {
      console.error("Employee data is null");
    }
  };

  return (
    <Box component="form" id="allocation-form" onSubmit={submitHandler}>
      <MHFormControl
        id="allocation"
        type="text"
        label={""}
        placeholder="Basic Salary"
        // value={enteredAllocation}
        value={
          enteredAllocation === "" ? props.employee?.salary : enteredAllocation
        }
        onChange={allocationInputChangeHandler}
        onBlur={() => {
          allocationInputBlurHandler();
        }}
        precision={2}
        startAdornment={
          <InputAdornment className="bg-[#404040] md:h-[43px] w-6">
            ₦
          </InputAdornment>
        }
        autoComplete="off"
        error={resolveErrorMessage(enteredAllocationHasError)(
          "Please enter employee salary"
        )}
      />
      <MHFormControl
        id="meals"
        type="text"
        label={""}
        placeholder="Meals"
        // value={enteredMeals}
        value={enteredMeals === "" ? props.employee?.meals : enteredMeals}
        onChange={mealsInputChangeHandler}
        onBlur={() => {
          mealsInputBlurHandler();
        }}
        precision={2}
        startAdornment={
          <InputAdornment className="bg-[#404040] md:h-[43px] w-6">
            ₦
          </InputAdornment>
        }
        autoComplete="off"
        error={resolveErrorMessage(enteredMealsHasError)(
          "Please enter employee meal amount"
        )}
      />
      <MHFormControl
        id="Transportation"
        type="text"
        label={""}
        placeholder="Transportation"
        // value={enteredTransportation}
        value={
          enteredTransportation === ""
            ? props.employee?.transportation
            : enteredTransportation
        }
        onChange={transportationInputChangeHandler}
        onBlur={() => {
          transportationInputBlurHandler();
        }}
        precision={2}
        startAdornment={
          <InputAdornment className="bg-[#404040] md:h-[43px] w-6">
            ₦
          </InputAdornment>
        }
        autoComplete="off"
        error={resolveErrorMessage(enteredTransportationHasError)(
          "Please enter employee Transportation amount"
        )}
      />
      <MHFormControl
        id="Accomodation"
        type="text"
        label={""}
        placeholder="Accomodation"
        // value={enteredAccomodation}
        value={
          enteredAccomodation === ""
            ? props.employee?.Accomodation
            : enteredAccomodation
        }
        onChange={accomodationInputChangeHandler}
        onBlur={() => {
          accomodationInputBlurHandler();
        }}
        precision={2}
        startAdornment={
          <InputAdornment className="bg-[#404040] md:h-[43px] w-6">
            ₦
          </InputAdornment>
        }
        autoComplete="off"
        error={resolveErrorMessage(enteredAccomodationHasError)(
          "Please enter employee Accomodation amount"
        )}
      />
      <MHFormControl
        id="Airtime / Data"
        type="text"
        label={""}
        placeholder="Airtime / Data"
        // value={enteredAirtimeData}
        value={
          enteredAirtimeData === ""
            ? props.employee?.airtimeOrData
            : enteredAirtimeData
        }
        onChange={airtimeDataInputChangeHandler}
        onBlur={() => {
          airtimeDataInputBlurHandler();
        }}
        precision={2}
        startAdornment={
          <InputAdornment className="bg-[#404040] md:h-[43px] w-6">
            ₦
          </InputAdornment>
        }
        autoComplete="off"
        error={resolveErrorMessage(enteredAirtimeDataHasError)(
          "Please enter employee Airtime / Data amount"
        )}
      />
      <MHButton loading={loading} type="submit" fullWidth>
        Submit
      </MHButton>
    </Box>
  );
};

export default BucketAllocation;
