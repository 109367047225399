import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useRouteMatch } from "react-router-dom";
import useDialog from "../../hooks/use-dialog";

// type Props = {}

const CaringApproachSection = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/employers`);
  };

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog,
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Box className="pt-10">
        <Box className="items-center place-content-center ">
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            THE ENTERPRISE PLAN
          </Typography> */}

          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="h2"
            width={{ xs: "90%", md: "65%" }}
            fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            mx="auto"
            className="py-8  text-center"
          >
            How It Works
          </Typography>

          {/* Web */}

          <Box
            className="hidden md:grid grid-cols-3 items-center mx-auto text-center"
            justifyContent="center"
          >
            {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
              Personalized Employee Work- <br /> Life Care Solutions
            </Typography> */}
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Apply Today
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Start by filling out our online application. Provide your
                <br /> details, experience, and any relevant certifications.
                <br /> Once submitted, our team will review your application.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Get Vetted
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                After your application is approved, you'll go through
                <br /> our vetting process. This includes a background
                <br />
                check, health assessment, and reference verification to ensure
                you're ready for your new role.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Receive Training
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Enhance your skills with training sessions at Caring
                <br />
                Blocks Institute. We offer courses that cover
                <br />
                everything from childcare techniques to household
                <br /> management, helping you become the best at <br /> what
                you do.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Match with Families
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Once you've completed the vetting and training,
                <br /> you'll be matched with families or corporate clients
                <br />
                looking for your specific skills. You can review the job details
                and decide if it's the right fit for you.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Start Your New Role
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                After matching, you'll sign your contract and begin
                <br /> your new role. Caring Blocks will handle payroll and
                <br /> provide ongoing support to ensure everything runs
                smoothly.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Get Paid
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Enjoy the rewards of your hard work! Caring Blocks will manage
                your payroll, ensuring you are paid on time and fairly for the
                exceptional care and service you provide.
              </Typography>
            </Box>
            {/* &bull; */}
          </Box>

          {/* Mobile */}

          <Box className="md:hidden block items-center place-content-center mx-auto text-center">
            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Apply Today
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4 text-center"
              >
                Start by filling out our online application. Provide your
                details, experience, and any relevant certifications. Once
                submitted, our team will review your application.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Get Vetted
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                After your application is approved, you'll go through
                <br /> our vetting process. This includes a background
                <br />
                check, health assessment, and reference verification to ensure
                you're ready for your new role.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Receive Training
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Enhance your skills with training sessions at Caring
                <br />
                Blocks Institute. We offer courses that cover
                <br />
                everything from childcare techniques to household
                <br /> management, helping you become the best at <br /> what
                you do.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Match with Families
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Once you've completed the vetting and training,
                <br /> you'll be matched with families or corporate clients
                <br />
                looking for your specific skills. You can review the job details
                and decide if it's the right fit for you.
              </Typography>
            </Box>

            <Box className="ml-8 mr-8 py-2">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Start Your New Role
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                After matching, you'll sign your contract and begin
                <br /> your new role. Caring Blocks will handle payroll and
                <br /> provide ongoing support to ensure everything runs
                smoothly.
              </Typography>
            </Box>
            <Box className="ml-8 mr-8 py-8">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={"102%"}
                letterSpacing={"0.1em"}
                className="uppercase text-center"
              >
                Get Paid
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px", md: "15px" }}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                className="py-4  text-center  "
              >
                Enjoy the rewards of your hard work! Caring Blocks will manage
                your payroll, ensuring you are paid on time and fairly for the
                exceptional care and service you provide.
              </Typography>
            </Box>

            {/* <Typography
              variant="body1"
              fontSize={{ xs: "12px" }}
              lineHeight={"170%"}
              letterSpacing={"0.02em"}
              className=" py-3 text-center"
            >
              Employees need help navigating life hurdles and
              <br /> the daily transitions between caregiving,
              <br /> work and life.
            </Typography> */}
          </Box>

          {/* <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={handleOpen}>
              Request a Demo
            </MHButton>
          </Box> */}
        </Box>

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
      </Box>
    </React.Fragment>
  );
};

export default CaringApproachSection;
