import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as CalenderIcon } from "../../../static/svg/calender-sm.svg";
import { ReactComponent as HealthIcon } from "../../../static/svg/health-icon.svg";
import { ReactComponent as IdentityIcon } from "../../../static/svg/identity-icon.svg";
import Pagination from "../../Common/UI/Pagination";
import MHSlider, {
  MHSliderItem,
} from "../../Common/SliderCarousel/Slider/MHSlider";
import { matchPath, useLocation } from "react-router-dom";
import { MHPagination } from "../../Common/UI/MHPagination";

type CheckProps = {
  title?: string;
  date?: string;
  type?: string;
};

type Props = {
  testArray: CheckProps[];
};

const UpcomingSchedule = (props: Props) => {
  const [pageType, setPageType] = React.useState(1);
  const theme = useTheme();

  // 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const isWeb = useMediaQuery(theme.breakpoints.down("lg"));

  React.useEffect(() => {
    if (isMobile) {
      setPageType(1);
      setPostsPerPage(1);
    } else if (isTab) {
      setPageType(3);
      setPostsPerPage(3);
    } else {
      setPageType(5);
      setPostsPerPage(5);
    }
  }, [isMobile, isTab, isWeb]);

  let itemsPerPage = 4;
  let noOfPage = Math.ceil((pageType || 0) / itemsPerPage);

  //   Training Pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(pageType);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts =
    props.testArray && props.testArray.slice(firstPostIndex, lastPostIndex);

  const location = useLocation();

  const dashboardMatch = matchPath(location.pathname, {
    path: "/organization/dashboard",
    exact: true,
    strict: true,
  });

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  return (
    <React.Fragment>
      <Box className="ring-1 ring-[#D9D9D9]">
        <Box className={`bg-[#F7F9F9]  px-8 pt-2 pb-1`}>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            lineHeight={{ xs: "135%", sm: "135%", md: "135%" }}
            // gutterBottom
            className="mt-2 mb-4 "
          >
            Upcoming Schedule Activities
          </Typography>
        </Box>

        <Box className="block md:hidden">
          <MHSlider
            slidesToShow={dashboardMatch ? 1 : 2}
            slidesToScroll={1}
            className="block md:hidden"
          >
            {props.testArray &&
              props.testArray.map((test, index) => (
                <MHSliderItem width="100%" key={index}>
                  <Box
                    className={`${
                      test.type === "health" ? "bg-green-200 " : "bg-blue-200 "
                    } h-48 w-52  my-5 mx-auto px-7 py-5 rounded-lg relative`}
                    key={index}
                  >
                    <Box className="mt-2 mb-4 text-center">
                      {test.type === "health" ? (
                        <HealthIcon className="rounded-xl" />
                      ) : (
                        <IdentityIcon className="rounded-xl" />
                      )}
                    </Box>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
                      lineHeight={{ xs: "135%", sm: "135%", md: "135%" }}
                      // gutterBottom
                      className="mt-2 mb-4 w-[85%]"
                    >
                      {test.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "8px", sm: "8px", md: "8px" }}
                      // gutterBottom
                      className="mt-2 absolute bottom-10"
                    >
                      <strong className="font-areaBlack"> Test Date:</strong>{" "}
                      {test.date}
                    </Typography>

                    <Box className="absolute bottom-2 left-0 right-0 text-center">
                      <CalenderIcon
                        className={`${
                          test.type === "health"
                            ? "bg-green-300 "
                            : "bg-blue-300 "
                        } mx-auto  rounded-xl`}
                      />
                    </Box>
                  </Box>
                </MHSliderItem>
              ))}
          </MHSlider>
        </Box>

        <Box className="p-16 gap-3 hidden md:flex">
          {currentPosts.length !== 0 ? (
            currentPosts.map((test, index) => (
              <Box
                className={`${
                  test.type === "health" ? "bg-green-200 " : "bg-blue-200 "
                } h-48 w-52 px-7 py-5 rounded-lg relative`}
                key={index}
              >
                <Box className="mt-2 mb-4 text-center">
                  {test.type === "health" ? (
                    <HealthIcon className="rounded-xl" />
                  ) : (
                    <IdentityIcon className="rounded-xl" />
                  )}
                </Box>
                <Typography
                  variant="body1"
                  fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
                  lineHeight={{ xs: "135%", sm: "135%", md: "135%" }}
                  // gutterBottom
                  className="mt-2 mb-4 w-[85%]"
                >
                  {test.title}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={{ xs: "8px", sm: "8px", md: "8px" }}
                  // gutterBottom
                  className="mt-2 absolute bottom-10"
                >
                  <strong className="font-areaBlack"> Test Date:</strong>{" "}
                  {test.date}
                </Typography>

                <Box className="absolute bottom-2 left-0 right-0 text-center">
                  <CalenderIcon
                    className={`${
                      test.type === "health" ? "bg-green-300 " : "bg-blue-300 "
                    } mx-auto  rounded-xl`}
                  />
                </Box>
              </Box>
            ))
          ) : (
            <p className="md:pl-[200px] lg:pl-[450px] text-center">
              You have no upcoming schedule
            </p>
          )}
        </Box>
        {props.testArray.length > 0 ? (
          <Box display={"flex"} justifyContent={"center"} pb={2}>
            <MHPagination
              count={noOfPage}
              variant="outlined"
              shape="rounded"
              page={currentPage}
              onChange={handlePageChange}
              color={"primary"}
            />
          </Box>
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export default UpcomingSchedule;
