import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { formatAmount } from "../../../utils/utils";
import DashboardContext from "../../../store/context/dashboard.context";
import { EmployeeData } from "../../../models/employee.model";
import MHButton from "../../Common/Button/MHButton";
import generatePDF, { Options } from "react-to-pdf";
import MHLink from "../../Common/Link/MHLink";

import { ReactComponent as DownloadIcon } from "../../../static/svg/download.svg";

const TextWidget = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <Box gap={1} display={"flex"}>
      {title && (
        <Typography
          variant="body2"
          fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
          lineHeight={{ xs: "150%", sm: "130%" }}
          letterSpacing={"0.02em"}
          color={"#A9A9A9"}
        >
          {title}{" "}
        </Typography>
      )}

      {description && (
        <Typography
          variant="body2"
          fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
          lineHeight={{ xs: "150%", sm: "130%" }}
          letterSpacing={"0.02em"}
          className=" capitalize"
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};
const BodyTextWidget = ({
  title,
  title2,
  body,
  body2,
  dot,
}: {
  title?: string;
  title2?: string;
  body?: string;
  body2?: string;
  dot?: boolean;
}) => {
  return (
    <Grid
      container
      spacing={0}
      // paddingX={1}
      display={"flex"}
      justifyContent={"center"}
      className=" mx-auto mb-2"
    >
      <Grid item xs={6} md={6} lg={6}>
        <Typography
          variant={`${title ? "subtitle1" : "subtitle2"}`}
          fontSize={`${title ? "16px" : "16px"}`}
          className=" capitalize"
        >
          {dot && " •"} {title || title2}
        </Typography>
      </Grid>
      <Grid item xs={3} md={3} lg={3}>
        <Typography
          variant="body1"
          fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
          className="capitalize"
        >
          {body}
        </Typography>
      </Grid>
      <Grid item xs={3} md={3} lg={3}>
        <Typography
          variant="body1"
          fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
          gutterBottom
          className="capitalize"
        >
          {body2}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface PaySlipProps {
  employee: EmployeeData | null;
  homeAddress?: string;
  setDownloadPdfRef?: (downloadFunction: () => void) => void;
}

const NewPaySlip = React.forwardRef(
  ({ employee }: { employee: EmployeeData }, ref) => {
    const dashboardCtx = React.useContext(DashboardContext);

    const { organization } = dashboardCtx;

    let employer = organization?.firstName + " " + organization?.lastName;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const currentYear = new Date().getFullYear();

    const monthName = new Date().getMonth();
    const currentMonth = monthNames[monthName];

    const employeeID = "CB_Docs_" + employee?.id;
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const payslipNumber = `INV-${currentYear}-${employeeID}-${unixTimestamp}`;
    const total = employee?.salary;

    const SERVICE_CHARGE_PERCENT = 10;

    // Calculate service charge
    const serviceCharge = (total * SERVICE_CHARGE_PERCENT) / 100;
    const totalWithServiceCharge = total + serviceCharge;

    // const downloadPdf = () => generatePDF(getTargetElement, options);

    const [paydate, setPaydate] = React.useState<string | null>(null);

    useEffect(() => {
      const today = new Date();
      const currentDay = today.getDate();

      // Check if today is the 31st day of the month
      if (currentDay >= 31) {
        // Set paydate to the 31st of the next month
        const nextMonth = new Date(today);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        nextMonth.setDate(31);
        setPaydate(nextMonth.toDateString());
      } else {
        // Set paydate to the 31st of the current month
        const currentMonth = new Date(today);
        currentMonth.setDate(31);
        setPaydate(currentMonth.toDateString());
      }
    }, []);

    return (
      <React.Fragment>
        <Box className="" ref={ref} id="pdfcontainer">
          {/* <Box display={"flex"} justifyContent={"end"} justifyItems={"end"}>
          <MHLink
            type="default"
            underline="none"
            // className=" !no-underline"
            onClick={downloadPdf}
            sx={{ textDecorationLine: "none" }}
          >
            <Typography
              variant="body2"
              fontSize={{ xs: "10px", sm: "10px" }}
              gutterBottom
              display={"flex"}
              gap={0.5}
              sx={{ textDecorationLine: "none" }}
            >
              <DownloadIcon />
              Download
            </Typography>
          </MHLink>
        </Box> */}

          <Box className="w-[1000px] h-full">
            <Grid container spacing={1}>
              <Grid item xs={6} md={6} lg={6}>
                <Box
                  className=""
                  marginLeft={-1.2}
                  marginBottom={-2.3}
                  display={"grid"}
                  justifyContent={"space-between"}
                >
                  <img
                    src="../../../../careBlocks.png"
                    alt="CaringBlocks-Logo"
                    // className=" bg-cover object-cover"
                    width={150}
                    height={70}
                  />
                </Box>
                <Typography
                  variant="body2"
                  fontSize={{ sm: "10px", xs: "10px", md: "10px" }}
                >
                  1625B Saka Jojo Str, VI, Lagos <br />
                  Tel: 07044595899
                </Typography>
              </Grid>
            </Grid>

            <Typography
              variant="subtitle1"
              fontSize={{ sm: "28px", xs: "28px", md: "28px" }}
              lineHeight={"139%"}
              display="flex"
              alignSelf={"center"}
              justifySelf={"center"}
              justifyContent={"center"}
              my={3}
            >
              PAY SLIP
            </Typography>

            <Grid
              container
              spacing={0}
              display={"flex"}
              justifyContent="space-between"
              className=" mb-6"
            >
              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  HOUSEHOLD STAFF INFORMATION
                </Typography>
                <TextWidget
                  title="Employee Name:"
                  description={`${employee?.firstName ?? ""} ${
                    employee?.lastName ?? ""
                  }`}
                />
                <TextWidget
                  title="Address:"
                  description="1Banana Island Rd, Ikoyi"
                />
                <TextWidget
                  title="Employee ID:"
                  description={`CB${employee?.uuid ?? ""}-${
                    employee?.id ?? ""
                  }`}
                />
                <TextWidget title="Job Title:" description={employee?.role} />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  PAY SLIP INFORMATION
                </Typography>

                <TextWidget
                  title="Pay Period Start:"
                  description="February 1, 2024"
                />
                <TextWidget
                  title="Pay Period End:"
                  description="February 29, 2024"
                />
                <TextWidget title="Pay Date:" description="February 29, 2024" />
                <TextWidget title="NIN:" description={employee?.nin} />
              </Grid>
            </Grid>

            <Divider
              orientation="horizontal"
              sx={{
                my: 2,
              }}
            />

            <Grid
              container
              spacing={0}
              display={"flex"}
              justifyContent="space-between"
              className=" mx-auto mb-6"
            >
              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  PAY DESCRIPTION
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  THIS PAY PERIOD
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  YEAR-TO-DATE
                </Typography>
              </Grid>
            </Grid>

            <Divider
              orientation="horizontal"
              sx={{
                mb: 2,
              }}
            />

            {/* Money starts */}

            <BodyTextWidget
              title="Basic Salary"
              body="₦100,000"
              body2="₦200,000"
            />

            <Divider
              orientation="horizontal"
              sx={{
                my: 1,
              }}
            />
            <BodyTextWidget title="Other Earnings" body="" body2="" />
            <BodyTextWidget title2="Overtime" body="-" body2="-" dot />
            <BodyTextWidget
              title2="Housing Allowance"
              body="₦10,000"
              body2="₦20,000"
              dot
            />
            <BodyTextWidget
              title2="Meal Allowance"
              body="₦10,000"
              body2="₦20,000"
              dot
            />
            <BodyTextWidget
              title2="Transportation Allowance"
              body="₦10,000"
              body2="₦20,000"
              dot
            />

            <BodyTextWidget
              title2="HMO / Health Insurance"
              body="-"
              body2="-"
              dot
            />

            <BodyTextWidget title2="Bonus" body="-" body2="-" dot />
            <Divider
              orientation="horizontal"
              sx={{
                my: 1,
              }}
            />
            <BodyTextWidget title="Deductions" body="" body2="" />

            <BodyTextWidget
              title2="State Taxes"
              body="₦5,000"
              body2="₦10,000"
              dot
            />
            <BodyTextWidget title2="Loans" body="₦5,000" body2="₦10,000" dot />
            <Divider
              orientation="horizontal"
              sx={{
                my: 2,
              }}
            />

            <Grid
              container
              spacing={0}
              // paddingX={1}
              display={"flex"}
              justifyContent={"center"}
              className=" mx-auto mb-6"
            >
              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  NET PAY
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  ₦120,000
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  ₦240,000
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              // spacing={0}
              // paddingX={1}
              //   mt={-5}
              display={"flex"}
              justifyContent="space-between"
              // className=" mx-auto"
            >
              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  HOUSEHOLD EMPLOYER
                </Typography>
                <TextWidget description={employer} />
                <TextWidget description={organization?.homeAddress} />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  fontSize={{ sm: "16px", xs: "16px", md: "16px" }}
                  gutterBottom
                  className=""
                >
                  PAYMENT INFORMATION
                </Typography>

                <TextWidget description="Blesing Oyeleye" />
                <TextWidget description="Providus Bank" />
                <TextWidget description="" />
                <Box gap={1} display={"flex"}>
                  <Typography
                    variant="body2"
                    fontSize={{ sm: "18px", xs: "18px", md: "18px" }}
                    lineHeight={{ xs: "200%", sm: "200%" }}
                    letterSpacing={"0.02em"}
                    className=" capitalize"
                  >
                    Acct #: 5404029752
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* <Box
          className=" mx-auto"
          my={3}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Typography
            variant="body1"
            fontSize={{ sm: "10px", xs: "12px", md: "12px" }}
            lineHeight={{ xs: "150%", sm: "150%" }}
            letterSpacing={"0.02em"}
            gutterBottom
          >
            Next pay date is {paydate}
          </Typography>
        </Box> */}

            {/* Money ends */}
          </Box>
        </Box>
      </React.Fragment>
    );
  }
);

export default NewPaySlip;
