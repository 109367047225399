import { Box } from "@mui/material";
import React, { useEffect } from "react";
import MHSlider, {
  MHSliderItem,
} from "../../Common/SliderCarousel/Slider/MHSlider";
import TrainingCard, {
  CourseType,
  TrainingData,
} from "../Training/TrainingCard";
import { matchPath, useLocation } from "react-router-dom";
import useDialog from "../../../hooks/use-dialog";
import ReserveTrainingCard from "./ReserveTrainingCard";
import PlainDialog from "../../Common/Dialog/PlainDailog";
import { convertTo12Hour } from "../../../utils/utils";

type Props = {
  dataIn?: TrainingData[];
};

const TrainingEmployee = (props: Props) => {
  const location = useLocation();
  const [selectedTraining, setSelectedTraining] =
    React.useState<CourseType | null>(null);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const dashboardMatch = matchPath(location.pathname, {
    path: "/employee/dashboard",
    exact: true,
    strict: true,
  });

  let slicedSingleTrainings = props.dataIn
    ? props.dataIn.filter((training) => {
        if (!training) return false; // Add a null check for training

        return training.trainingMode && training.category;
      })
    : [];

  const handleView = (course: any) => {
    setSelectedTraining(course);
    handleOpenDialog();
  };

  return (
    <React.Fragment>
      <Box className=" p-6 ring-1 ring-[#D9D9D9]">
        {slicedSingleTrainings.length < 1 ? (
          "No training assigned to you"
        ) : (
          <MHSlider slidesToShow={dashboardMatch ? 1 : 3} slidesToScroll={1}>
            {slicedSingleTrainings.map((course: any, index) => (
              <MHSliderItem key={index} width="100%">
                <TrainingCard
                  trainingMode={course.trainingMode}
                  title={course.title}
                  createdDate={course.date}
                  startDate={convertTo12Hour(course.startDate)}
                  endDate={convertTo12Hour(course.endDate)}
                  objectives={course.objectives}
                  price={course.price}
                  handleTrainingViewClick={() => handleView(course)}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        )}
      </Box>

      {openDialog && (
        <PlainDialog
          open={openDialog}
          title={``}
          handleClose={handleCloseDialog}
        >
          {/* <Box>{selectedTraining?.title}</Box> */}
          <ReserveTrainingCard training={selectedTraining} />
        </PlainDialog>
      )}
    </React.Fragment>
  );
};

export default TrainingEmployee;
