import { Box } from "@mui/material";
import React from "react";
import AllTrainingHeader from "../../components/Features/Training/AllTrainingHeader";
import AllTrainingBody from "../../components/Features/Training/AllTrainingBody";
import { testTraining } from "../../utils/constants";
import { TrainingData } from "../../components/Features/Training/TrainingCard";
import useHttp from "../../hooks/use-http";
import { getURLWithQueryParams } from "../../utils/utils";
import { HttpResponse } from "../../models/api.interface";

type Props = {};

const AllTrainingPage = (props: Props) => {
  const [selectedRole, setSelectedRole] = React.useState("");
  const [training, setTraining] = React.useState<TrainingData[]>([]);
  const { loading, error, sendHttpRequest } = useHttp();
  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
  };
  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<TrainingData[]>) => {
        setTraining(response.data);
      }
    );
  }, []);

  const filteredTraining =
    training && training.filter((train) => train.status !== "INACTIVE");

  // console.log("trainings", training);
  // console.log("filteredTraining", filteredTraining);

  return (
    <React.Fragment>
      <Box className=" allTrainParent" position={"relative"}>
        <AllTrainingHeader
          onRoleChange={handleRoleChange}
          dataIn={filteredTraining}
        />

        <AllTrainingBody selectedRole={selectedRole} dataIn={training} />
      </Box>
    </React.Fragment>
  );
};

export default AllTrainingPage;
