import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";

import { ReactComponent as ExpandMoreIcon } from "../../../static/svg/caret-down-black.svg";
import { ReactComponent as InfoIcon } from "../../../static/svg/info-rounded.svg";
import { ReactComponent as CheckIcon } from "../../../static/svg/check-mark-rounded-lg.svg";
import { ReactComponent as CancelIcon } from "../../../static/svg/cancel-mark-rounded-lg.svg";

import { Avatar, Box, Divider, Grid } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../../store/context/auth-context";
import useHttp from "../../../hooks/use-http";
import { getURLWithQueryParams, isEmpty } from "../../../utils/utils";
import { EmployeeData } from "../../../models/employee.model";
import { HttpResponse } from "../../../models/api.interface";
import useDialog from "../../../hooks/use-dialog";
import MHDialog from "../../Common/Dialog/MHDialog";
import MHButton from "../../Common/Button/MHButton";
import { useOnboardForm } from "../../../store/context/onboard-context";
import dayjs from "dayjs";
import { generateUniqueReference } from "../../../utils/contracts";
import { ContractProps } from "../../../models/contract.model";

const bgArray = [
  {
    label: "ID Verification",
    amount: 2000,
    location: "bgCheck.id",
    place: "id",
  },
  {
    label: "Address Verification",
    amount: 5000,
    location: "bgCheck.address",
    place: "address",
  },
  {
    label: "Guarantor Verification",
    amount: 4000,
    location: "bgCheck.guarantor",
    place: "guarantor",
  },
  {
    label: "Employment Verification",
    amount: 4000,
    location: "bgCheck.employment",
    place: "employment",
  },
  {
    label: "Education Verification",
    amount: 4000,
    location: "bgCheck.education",
    place: "education",
  },
];

const healthArray = [
  {
    value: "Nanny & Housekeeper",
    amount: 35000,
    label: "Nanny & Housekeeper",
    text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype,, and BP",
  },
  {
    value: "Drivers & Security",
    amount: 50000,
    label: "Drivers & Security",
    text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype, and BP,. Drug Screening, Vision Test, Hearing Test, and Diabetes Screening",
  },
  {
    value: "Cook / Chef",
    amount: 45000,
    label: "Cook / Chef",
    text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy Test, Genotype Test, and BP, Stool Test, Drug Screening",
  },
  {
    value: "Home Tutors",
    amount: 40000,
    label: "Home Tutors",
    text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Drug Screening",
  },
];

export const CheckerDialog = ({
  ignore,
  accept,
}: // healthChecks,
// bgChecks,
{
  ignore?: string | any;
  accept?: string | any;
  // healthChecks?: string[] | any;
  // bgChecks?: string[] | any;
}) => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        gap={2}
        paddingX={{ xs: 0, sm: 2, md: 4 }}
        className=" "
        justifyContent="space-between"
        height={{ xs: "100%", sm: "420px" }}
      >
        <Grid
          item
          xs={12}
          sm={5}
          className=""
          display={"grid"}
          justifyContent="space-evenly"
        >
          <Box>
            <Typography
              variant="h1"
              fontSize={{ xs: "20px", sm: "23px" }}
              mt={3}
              gutterBottom
              display={"flex"}
              alignSelf={"start"}
            >
              Background Assesstment
            </Typography>
            <Typography
              variant="body2"
              fontSize={{ xs: "9px", sm: "9px" }}
              color="#A9A9A9"
              mb={0}
              gutterBottom
              display={"flex"}
              align="left"
              alignSelf={"start"}
              width={{ xs: "90%", sm: "60%" }}
            >
              {/* Concise profile capturing applicant's expertise,
              accomplishments, // and suitability for the role, providing a
              snapshot of their // background and value proposition to the
              employer */}
              Detailed records of assessments done by Employer for this employee
            </Typography>
          </Box>
          <Box my={{ xs: 0, sm: 0 }} justifyItems="center">
            {bgArray &&
              bgArray.map((item: any, index: any) => (
                <Box
                  className="mx-auto"
                  my={2}
                  gap={2}
                  display="flex"
                  justifyContent=""
                  alignItems="center"
                  key={index}
                >
                  <Box className="">
                    {item.status === true ? (
                      <CheckIcon className="" height={20} width={20} />
                    ) : (
                      <CancelIcon className="" height={20} width={20} />
                    )}
                  </Box>
                  <Box className=" mr-auto ml-5">
                    <Typography
                      variant="h1"
                      fontSize={{ xs: "12px", sm: "14px" }}
                      // align="center"
                      // mt={3}
                      gutterBottom
                      // display={"flex"}
                      // alignSelf={"center"}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Grid>

        <Grid
          item
          xs={0}
          sm={0.01}
          md={0.01}
          sx={{ alignItems: "center", display: { xs: "none", sm: "grid" } }}
        >
          <div
            style={{ borderLeft: "1px solid #A9A9A9", height: "278px" }}
          ></div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          className=""
          display={"grid"}
          justifyContent="space-evenly"
        >
          <Box>
            <Typography
              variant="h1"
              fontSize={{ xs: "20px", sm: "23px" }}
              mt={3}
              gutterBottom
              display={"flex"}
              alignSelf={"start"}
            >
              Health Assessment
            </Typography>
            <Typography
              variant="body2"
              fontSize={{ xs: "9px", sm: "9px" }}
              color="#A9A9A9"
              mb={1}
              gutterBottom
              display={"flex"}
              align="left"
              alignSelf={"start"}
              width={{ xs: "90%", sm: "60%" }}
            >
              {/* Concise profile capturing applicant's expertise,
              accomplishments, // and suitability for the role, providing a
              snapshot of their // background and value proposition to the
              employer */}
              Detailed records of assessments done by Employer for this employee
            </Typography>
          </Box>
          <Box my={{ xs: 0, sm: 0 }} justifyItems="center">
            {healthArray &&
              healthArray.map((item: any, index: any) => (
                <Box
                  className="mx-auto"
                  my={2}
                  gap={2}
                  display="flex"
                  justifyContent=""
                  alignItems="center"
                  key={index}
                >
                  <Box className="">
                    {item.status === true ? (
                      <CheckIcon className="" height={20} width={20} />
                    ) : (
                      <CancelIcon className="" height={20} width={20} />
                    )}
                  </Box>
                  <Box className=" mr-auto ml-5">
                    <Typography
                      variant="h1"
                      fontSize={{ xs: "12px", sm: "14px" }}
                      // align="center"
                      // mt={3}
                      gutterBottom
                      // display={"flex"}
                      // alignSelf={"center"}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={{ xs: "8px", sm: "8px" }}
                      // align="center"
                      // mt={3}
                      gutterBottom
                      // display={"flex"}
                      // alignSelf={"center"}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>

      <Box
        display={{ xs: "hidden", sm: "flex" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        width={{ xs: "full", sm: "full" }}
      >
        <MHButton
          sx={{ height: "50px", width: "full", color: "white" }}
          onClick={ignore}
          color="secondary"
          className=" "
          fullWidth
        >
          View Status
        </MHButton>

        <MHButton
          sx={{ height: "50px", width: "full" }}
          onClick={accept}
          fullWidth
          className=""
        >
          Proceed to Contract
        </MHButton>
      </Box>
    </React.Fragment>
  );
};

export const PendingActivityWidget = ({
  name,
  linkName,
  gotoLink,
  onClick,
}: {
  name?: string;
  linkName?: string | any;
  gotoLink?: string | any;
  onClick?: string | any;
}) => {
  return (
    <Box className=" flex align-middle items-center gap-4 w-[90%] mx-auto mb-3">
      <Avatar sx={{ bgcolor: "#204952", width: "27px", height: "27px" }}>
        <Typography
          variant="subtitle2"
          color="white"
          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
          lineHeight={{ xs: "100%", sm: "100%" }}
          letterSpacing={"0.01em"}
          alignSelf={"center"}
        >
          {name && name?.slice(0, 1)}
        </Typography>
      </Avatar>
      <Typography
        variant="subtitle2"
        color="primary"
        fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
        lineHeight={{ xs: "100%", sm: "100%" }}
        letterSpacing={"0.01em"}
        alignSelf={"center"}
      >
        {name}
      </Typography>

      {gotoLink && (
        <MuiLink
          component={Link}
          underline="always"
          // to={"#"}
          to={gotoLink}
          // display="block"
          // align="left"
          className="text-blue-100 mr-0 ml-auto removeUnderline"
        >
          {linkName}
        </MuiLink>
      )}
      {onClick && (
        <MuiLink
          underline="always"
          onClick={onClick}
          // display="block"
          // align="left"
          className="text-blue-100 mr-0 ml-auto removeUnderline cursor-pointer"
        >
          {linkName}
        </MuiLink>
      )}
    </Box>
  );
};

export const ExpensesWidget = ({
  title,
  price,
  age,
  ignore,
  accept,
  receiptImg,
}: {
  title?: string;
  price?: number | any;
  age?: number | any;
  ignore?: string | any;
  accept?: string | any;
  receiptImg?: string | any;
}) => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        gap={4}
        paddingX={{ xs: 0, sm: 2, md: 4 }}
        className=" place-content-center"
        height={{ xs: "100%", sm: "420px" }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          className=""
          display={"grid"}
          justifyContent="space-evenly"
        >
          <Box>
            <Typography
              variant="h1"
              fontSize={{ xs: "20px", sm: "23px" }}
              mt={3}
              gutterBottom
              display={"flex"}
              alignSelf={"start"}
            >
              {isEmpty(price) ? "Applicant Overview" : "Expense Review Receipt"}
            </Typography>
            <Typography
              variant="body2"
              fontSize={{ xs: "9px", sm: "9px" }}
              color="#A9A9A9"
              mb={3}
              gutterBottom
              display={"flex"}
              align="left"
              alignSelf={"start"}
              width={{ xs: "90%", sm: "60%" }}
            >
              {/* Concise profile capturing applicant's expertise,
              accomplishments, // and suitability for the role, providing a
              snapshot of their // background and value proposition to the
              employer */}

              {isEmpty(price)
                ? `
              Brief profile providing a quick overview for easy reference.
              `
                : `
              A detailed record of expenses submitted by an employee for review,
              outlining costs incurred during business activities, along with
              supporting documentation.
              `}
            </Typography>
          </Box>
          <Box>
            <Box display={"flex"}>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px" }}
                color="#A9A9A9"
                gutterBottom
                display={"flex"}
                align="left"
                alignSelf={"start"}
                paddingRight={1}
              >
                {isEmpty(price) ? "Full Name" : "Receipt title:"}
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px" }}
                color="primary"
                gutterBottom
                display={"flex"}
                align="left"
                alignSelf={"start"}
              >
                {title}
              </Typography>
            </Box>
            <Box display={"flex"}>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px" }}
                color="#A9A9A9"
                gutterBottom
                display={"flex"}
                align="left"
                alignSelf={"start"}
                paddingRight={1}
              >
                {isEmpty(price) ? "Age" : "Price:"}
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "14px" }}
                color="primary"
                gutterBottom
                display={"flex"}
                align="left"
                alignSelf={"start"}
              >
                {isEmpty(price) ? age + "years" : "₦" + price}
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ xs: "hidden", sm: "flex" }}
            justifyContent="space-between"
            width={{ xs: "full", sm: "335px" }}
          >
            <MHButton
              sx={{ height: "38px", width: "158px", color: "white" }}
              onClick={ignore}
              color="secondary"
              className=" "
              fullWidth
            >
              Ignore
            </MHButton>

            <MHButton
              sx={{ height: "38px", width: "158px" }}
              onClick={accept}
              fullWidth
              className=""
            >
              Accept
            </MHButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} className="">
          <Box
            border={1}
            borderRadius={3}
            sx={{
              boxShadow:
                "0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)", // This is similar to 'shadow-lg'
            }}
            borderColor="#EBEBEB"
            height={{ xs: "400px", sm: "350px", md: "400px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={{ xs: 3, sm: 3, md: 4 }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className=" rounded-md lg:w-[300px] md:w-[300px]  lg:h-[350px] md:h-[350px] "
            >
              <Avatar
                alt="Image"
                variant="square"
                src={receiptImg}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 2,
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default function AccordionDashboard() {
  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  const [employeeContracts, setEmployeeContracts] = React.useState<
    ContractProps[]
  >([]);
  const [expandedAccordion, setExpandedAccordion] = React.useState<
    number | false
  >(0); // State to control which accordion is expanded

  const [employeePath, setEmployeePath] = React.useState("");
  const [employeeUUID, setEmployeeUUID] = React.useState("");

  // const newEmployee = employees.slice(0, 2);
  const newEmployee = employees ? employees.slice(0, 2) : [];

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  // console.log("RefID", user?.employerRefId);

  const handleAccordionChange =
    (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  let employeesChecker = isEmpty(employees);

  const filteredEmployeesByTestStatus = employees
    ? employees.filter(
        (employee) => employee && employee.healthInsurance === false
      )
    : // .slice(0, 4)
      [];

  const filteredEmployeesWithNoContracts = employees
    ? employees.filter(
        (employee) => employee.status && employee.status === "INACTIVE"
      )
    : [];

  const filteredActiveEmployees = employees
    ? employees.filter(
        (employee) => employee.status && employee.status === "ACTIVE"
      )
    : // .slice(0, 4)
      [];

  let noOfDays = 6;
  function getNextPayDays(): number {
    const today: Date = new Date();
    const currentDay: number = today.getDate();
    const currentMonth: number = today.getMonth();
    const currentYear: number = today.getFullYear();

    // Function to calculate days difference
    function daysBetween(date1: Date, date2: Date): number {
      const oneDay: number = 24 * 60 * 60 * 1000;
      return Math.ceil((date2.getTime() - date1.getTime()) / oneDay);
    }

    // Calculate the number of days in the current month
    function daysInMonth(year: number, month: number): number {
      return new Date(year, month + 1, 0).getDate();
    }

    // Determine the next bi-weekly payroll date
    let nextBiWeeklyPayroll: Date;
    if (currentDay < 15) {
      nextBiWeeklyPayroll = new Date(currentYear, currentMonth, 15);
    } else {
      nextBiWeeklyPayroll = new Date(
        currentYear,
        currentMonth,
        daysInMonth(currentYear, currentMonth)
      );
    }

    // Determine the next monthly payroll date
    let nextMonthlyPayroll: Date;
    if (currentDay <= 27) {
      nextMonthlyPayroll = new Date(currentYear, currentMonth, 27);
    } else {
      nextMonthlyPayroll = new Date(currentYear, currentMonth + 1, 27);
    }

    // Calculate days to next payrolls
    const daysToNextBiWeekly: number = daysBetween(today, nextBiWeeklyPayroll);
    const daysToNextMonthly: number = daysBetween(today, nextMonthlyPayroll);

    // Return the closest next payroll date
    return Math.min(daysToNextBiWeekly, daysToNextMonthly);
  }

  // Example usage
  const nextPayrollDays = getNextPayDays();
  // console.log(`Days until next payroll: ${nextPayrollDays}`);
  function daysToNextPayroll(): { biWeekly: number; monthly: number } {
    const today: Date = new Date();
    const currentDay: number = today.getDate();
    const currentMonth: number = today.getMonth();
    const currentYear: number = today.getFullYear();

    // Function to calculate days difference
    function daysBetween(date1: Date, date2: Date): number {
      const oneDay: number = 24 * 60 * 60 * 1000;
      return Math.ceil((date2.getTime() - date1.getTime()) / oneDay);
    }

    // Calculate the number of days in a specific month and year
    function daysInMonth(year: number, month: number): number {
      // February (month 1) check for leap year
      if (month === 1) {
        return new Date(year, month + 1, 0).getDate();
      }
      return new Date(year, month + 1, 0).getDate();
    }

    // Calculate next bi-weekly payroll date
    let nextBiWeeklyPayroll: Date;
    if (currentDay < 15) {
      nextBiWeeklyPayroll = new Date(currentYear, currentMonth, 15);
    } else if (currentDay < daysInMonth(currentYear, currentMonth)) {
      nextBiWeeklyPayroll = new Date(
        currentYear,
        currentMonth,
        daysInMonth(currentYear, currentMonth)
      );
    } else {
      nextBiWeeklyPayroll = new Date(currentYear, currentMonth + 1, 15);
    }

    // Calculate next monthly payroll date
    let nextMonthlyPayroll: Date;
    if (currentDay <= 27) {
      nextMonthlyPayroll = new Date(currentYear, currentMonth, 27);
    } else {
      nextMonthlyPayroll = new Date(currentYear, currentMonth + 1, 27);
    }

    // Calculate days to next payrolls
    const daysToBiWeekly: number = daysBetween(today, nextBiWeeklyPayroll);
    const daysToMonthly: number = daysBetween(today, nextMonthlyPayroll);

    return {
      biWeekly: daysToBiWeekly,
      monthly: daysToMonthly,
    };
  }

  // Example usage
  const daysUntilNextPayroll = daysToNextPayroll();
  // console.log(
  //   `Days until next bi-weekly payroll: ${daysUntilNextPayroll.biWeekly}`
  // );
  // console.log(
  //   `Days until next monthly payroll: ${daysUntilNextPayroll.monthly}`
  // );

  const { sendHttpRequest: sendContractsRequest } = useHttp();

  // console.log("RefID", user?.employerRefId);

  React.useEffect(() => {
    sendContractsRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract/byempRef",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps[]>) => {
        // setEmployees(response.data);
        setEmployeeContracts(response.data);
      }
    );
  }, []);

  const completedContractsEmployees = employeeContracts
    ? employeeContracts.filter(
        (employee) => employee.status && employee.status === "COMPLETED"
      )
    : [];

  // console.log("completedContractsEmployees", completedContractsEmployees);

  // const filteredEmployeesStatus = employees
  //   ? employees
  //       .slice(0, 2)
  //       .filter(
  //         (employee) =>
  //           employee.role && employee.role.toLowerCase().includes("chef")
  //       )
  //   : [];

  const history = useHistory();

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const {
    openDialog: openApplicantDialog,
    handleOpenDialog: handleOpenApplicantDialog,
    handleCloseDialog: handleCloseApplicantDialog,
  } = useDialog();

  const { addToContract } = useOnboardForm();

  const currentDate = dayjs();
  const uniqueReference = generateUniqueReference();

  const handlePrompt = (employee: any) => {
    setEmployeePath(`/organization/employee/view/${employee.uuid}`);
    setEmployeeUUID(employee.uuid);
    // console.log("employeePath", employeePath);
    // handleOpenDialog();
    addToContract({
      role: employee.jobTitle,
      id: Number(employee?.customerId),
      employeeUUID: Number(employee.uuid),
      employeeName: `${employee.firstName} ${employee.lastName}`,
      employeePhone: employee?.whatsAppNumber,
      employeeEmail: employee?.email,
      startDate: currentDate,
      endDate: currentDate.add(Number("5"), "days"),
      contractRef: uniqueReference,
      contractLength: "5", //contract termination days
    });
    handleEmployeeContract();
  };

  const handleEmployeeContract = () => {
    history.push(`/organization/contract`);
    // console.log(employeeUUID);
    addToContract({
      employeeUUID: Number(employeeUUID),
    });
  };

  const healthChecks = [
    { id: 0, text: "ID Verification", status: false },
    { id: 1, text: "Hepatitis B", status: false },
    { id: 2, text: "Tuberculosis", status: false },
    { id: 3, text: "Drug Screening", status: false },
    { id: 4, text: "Pregnancy Test", status: false },
    { id: 5, text: "Hearing Test", status: false },
  ];
  const bgChecks = [
    { id: 0, text: "ID Verification", status: false },
    { id: 1, text: "Hepatitis B", status: false },
    { id: 2, text: "Tuberculosis", status: false },
    { id: 3, text: "Drug Screening", status: false },
    { id: 4, text: "Pregnancy Test", status: false },
    { id: 5, text: "Hearing Test", status: false },
  ];

  return (
    <React.Fragment>
      {employeesChecker === true ? (
        <Box className=" text-center m-0">
          <Typography
            variant="caption"
            color={"primary"}
            fontSize={{ xs: "18px", sm: "20px", md: "20px" }}
            // lineHeight={{ xs: "19px", sm: "19px" }}
            // letterSpacing={"0.01em"}
            gutterBottom
            className=" text-center items-center pb-2"
          >
            Activities
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            className="text-center items-center opacity-50"
          >
            Your Activities will be displayed here{" "}
          </Typography>
        </Box>
      ) : (
        <>
          <Box>
            <Typography
              variant="caption"
              color={"primary"}
              fontSize={{ xs: "18px", sm: "20px", md: "20px" }}
              lineHeight={{ xs: "19px", sm: "19px" }}
              letterSpacing={"0.01em"}
              className=" text-start mb-2"
            >
              Activities
            </Typography>
          </Box>
          <Box className=" bg-[#fff]">
            <Accordion
              expanded={expandedAccordion === 0}
              onChange={handleAccordionChange(0)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className=" bg-[#F9F9F7] flex"
              >
                <InfoIcon width={"0.7em"} className=" mr-1" />

                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                  lineHeight={{ xs: "120%", sm: "100%" }}
                  letterSpacing={"0.01em"}
                  alignSelf={"center"}
                >
                  {/* You have pending contract signatures */}
                  {filteredEmployeesByTestStatus.length} Employee(s) have
                  pending onboarding activities
                </Typography>
              </AccordionSummary>
              <AccordionDetails className=" m-2">
                {filteredEmployeesByTestStatus &&
                  filteredEmployeesByTestStatus.map((employee, index) => (
                    <PendingActivityWidget
                      key={employee.id} // Assuming employee.id is a unique identifier
                      name={employee.firstName + " " + employee.lastName}
                      // gotoLink="/organization/documents"
                      // onClick={handleOpenDialog}
                      linkName="assessment"
                      gotoLink={`/organization/employee/view/${employee.customerId}`}

                      // onClick={() => handlePrompt(employee)}
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedAccordion === 1}
              onChange={handleAccordionChange(1)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                className=" bg-[#F9F9F7]"
              >
                <InfoIcon width={"0.7em"} className=" mr-1" />

                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                  lineHeight={{ xs: "120%", sm: "100%" }}
                  letterSpacing={"0.01em"}
                  alignSelf={"center"}
                >
                  {completedContractsEmployees.length} Employee(s) have
                  completed their onboarding process
                  {/* You have 2 applicants */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className=" m-2">
                {completedContractsEmployees &&
                  completedContractsEmployees.map((employee, index) => (
                    <PendingActivityWidget
                      key={employee.id} // Assuming employee.id is a unique identifier
                      name={employee.employeeName}
                      gotoLink={`/organization/documents/contract/preview/${employee.customerId}`}
                      linkName="agreement"
                      // onClick={handleOpenDialog}
                      // onClick={() => handlePrompt(employee)}
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedAccordion === 2}
              onChange={handleAccordionChange(2)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel2-header"
                className=" bg-[#F9F9F7]"
              >
                <InfoIcon width={"0.7em"} className=" mr-1" />

                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                  lineHeight={{ xs: "120%", sm: "100%" }}
                  letterSpacing={"0.01em"}
                  alignSelf={"center"}
                >
                  {filteredEmployeesWithNoContracts.length} Employee(s) have no
                  contract
                </Typography>
              </AccordionSummary>
              <AccordionDetails className=" m-2">
                {filteredEmployeesWithNoContracts &&
                  filteredEmployeesWithNoContracts.map((employee, index) => (
                    <PendingActivityWidget
                      key={employee.customerId}
                      name={employee.firstName + " " + employee.lastName}
                      // gotoLink={`/organization/documents/contract/preview/${employee.customerId}`}
                      onClick={() => handlePrompt(employee)}
                      linkName="create contract "
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedAccordion === 3}
              onChange={handleAccordionChange(3)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
                className=" bg-[#F9F9F7]"
              >
                <InfoIcon width={"0.7em"} className=" mr-1" />

                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                  lineHeight={{ xs: "120%", sm: "100%" }}
                  letterSpacing={"0.01em"}
                  alignSelf={"center"}
                >
                  {nextPayrollDays} days to process next household payroll
                </Typography>
              </AccordionSummary>
              <AccordionDetails className=" m-2">
                {filteredActiveEmployees &&
                  filteredActiveEmployees.map((employee, index) => (
                    <PendingActivityWidget
                      key={employee.id} // Assuming employee.id is a unique identifier
                      name={employee.firstName + " " + employee.lastName}
                      // onClick={handleOpenDialog}
                      gotoLink="#"
                      linkName={
                        employee.payFrequency === "Bi-Weekly"
                          ? daysUntilNextPayroll.biWeekly + " day(s)"
                          : employee.payFrequency === "Monthly"
                          ? daysUntilNextPayroll.monthly + " day(s)"
                          : null
                      }
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedAccordion === 4}
              onChange={handleAccordionChange(4)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
                className=" bg-[#F9F9F7]"
              >
                <InfoIcon width={"0.7em"} className=" mr-1" />

                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                  lineHeight={{ xs: "120%", sm: "100%" }}
                  letterSpacing={"0.01em"}
                  alignSelf={"center"}
                >
                  {/* {noOfDays} Employees in care and household training */}
                  Employees in care and household training
                </Typography>
              </AccordionSummary>
              <AccordionDetails className=" m-2">
                {/* {filteredActiveEmployees &&
                  filteredActiveEmployees.map((employee, index) => (
                    <PendingActivityWidget
                      key={employee.id} // Assuming employee.id is a unique identifier
                      name={employee.firstName + " " + employee.lastName}
                      onClick={handleOpenDialog}
                    />
                  ))} */}

                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                  lineHeight={{ xs: "120%", sm: "100%" }}
                  letterSpacing={"0.01em"}
                  alignSelf={"center"}
                >
                  No Employees in care and household training
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      )}

      <MHDialog
        open={openDialog}
        title={" "}
        // sx={{ textAlign: "center", height: "100%" }}
        handleClose={handleCloseDialog}
        scroll="paper"
        maxWidth={"md"}
        fullWidth
      >
        {openDialog && (
          <CheckerDialog
            // healthChecks={healthChecks}
            // bgChecks={bgChecks}
            // title="Food Stuffs"
            // price={3000}
            // receiptImg="https://pbs.twimg.com/media/Fy-NwjCWAAArwhX.jpg:large"
            accept={handleEmployeeContract}
            ignore={() => history.push(employeePath)}
          />
        )}
      </MHDialog>
      <MHDialog
        open={openApplicantDialog}
        title={" "}
        // sx={{ textAlign: "center" }}
        handleClose={handleCloseApplicantDialog}
        scroll="paper"
        maxWidth={"md"}
        fullWidth
      >
        {/* {openDialog && (
          <ExpensesWidget
            title="Food Stuffs"
            price={3000}
            receiptImg="https://pbs.twimg.com/media/Fy-NwjCWAAArwhX.jpg:large"
            accept={handleCloseDialog}
            ignore={handleCloseDialog}
          />
        )} */}

        {openApplicantDialog && (
          <ExpensesWidget
            title="Titilayo Opeoluwa"
            age={33}
            receiptImg="https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88"
            accept={handleCloseApplicantDialog}
            ignore={handleCloseApplicantDialog}
          />
        )}
      </MHDialog>
    </React.Fragment>
  );
}
