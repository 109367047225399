import { Avatar, Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import AuthContext from "../../../store/context/auth-context";

import { HashLink } from "react-router-hash-link";

import { ReactComponent as AvatarBadgeIcon } from "../../../static/svg/avatar-badge.svg";
import DashboardContext from "../../../store/context/dashboard.context";
import useDialog from "../../../hooks/use-dialog";
import AvatarDialog from "./AvatarDialog";

type Props = {
  //   tabValue?: number;
  //   handleTabChange?: (event: React.SyntheticEvent, newValue: number) => void;
};

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ProfileInfo = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const dashboardCtx = React.useContext(DashboardContext);

  const { organization } = dashboardCtx;

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [value, setValue] = React.useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <React.Fragment>
      <Box className=" md:px-5  ">
        <Box className=" relative w-[258px] mx-auto md:ml-0 pt-4 md:pt-0 my-4 md:my-4 centerItems">
          <Avatar
            alt="Remy Sharp"
            variant="square"
            src="https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88"
            sx={{
              width: { xs: "300px", sm: "258px", md: "258px" },
              height: { xs: "281px", sm: "258px", md: "258px" },
            }}
            className=" rounded-t-md"
          />
          <AvatarBadgeIcon
            className=" cursor-pointer absolute bottom-2 right-2"
            onClick={handleOpenDialog}
          />
        </Box>
        <Box className="w-[258px] md:w-full  lg:w-[258px] my-4 ">
          <Typography
            variant="subtitle1"
            mb={2}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "20px", sm: "20px", md: "20px" }}
            className=""
          >
            {user?.firstName + " " + user?.lastName}
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            {organization?.address + ", " + organization?.state}
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            {organization?.email}
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            {organization?.telephone}
          </Typography>
        </Box>
      </Box>

      <Divider className=" w-[91%] md:w-[93%] lg:w-[99.5%]" />

      {/* <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        // onChange={props.handleTabChange}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
        className="mt-4"
      >
        <Tab
          className={`${
            value === 0 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
          } text-start text-xs  capitalize leading-[102%] tracking-[0.04em]`}
          label="Account Settings"
          {...a11yProps(0)}
          sx={{ alignItems: "start" }}
        />
        <Tab
          className={`${
            value === 1 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
          } text-start text-xs  capitalize leading-[102%] tracking-[0.04em]`}
          label="Password Settings"
          {...a11yProps(1)}
          sx={{ alignItems: "start" }}
        />
        <Tab
          className={`${
            value === 2 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
          } text-start text-xs  capitalize leading-[102%] tracking-[0.04em]`}
          label="Transfer Settings"
          {...a11yProps(2)}
          sx={{ alignItems: "start" }}
        />
      </Tabs> */}
      <Box className=" space-y-6 mt-4 pb-8 hidden md:block">
        <Box>
          <HashLink
            smooth
            to="/organization/settings/profile/#accountsetting"
            className={`${
              value === 0 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
            } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 pl-[25px] md:pl-[20px] pr-[171px]  md:pr-[145px]  lg:pr-[165px]`}
            // } text-start shiftLeft text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 px-[115px] md:px-[86px] lg:px-[93px]`}
            onClick={() => setValue(0)}
          >
            Account Settings
          </HashLink>
          {/* <a
            href="/organization/employee/settings/profile/#accsettings"
            className={`${
              value === 0 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
            } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 px-[93px]`}
            onClick={() => setValue(0)}
          >
            Account Settings
          </a> */}
        </Box>

        <Box className="mb-5 ">
          <HashLink
            smooth
            to="/organization/settings/profile/#passwordsetting"
            className={`${
              value === 1 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
            } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 pl-[25px] md:pl-[20px] pr-[163px] md:pr-[137px] lg:pr-[157px]`}
            // } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] `}
            onClick={() => setValue(1)}
          >
            Password Settings
          </HashLink>
        </Box>

        {/* <Box>
          <HashLink
            smooth
            to="/organization/settings/profile/#pinsetting"
            className={`${
              value === 2 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
            } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 px-[115px] md:px-[86px] lg:px-[93px]`}
            onClick={() => setValue(2)}
          >
            Transfer Settings
          </HashLink>
        </Box> */}
      </Box>

      {openDialog && (
        <AvatarDialog open={openDialog} onClose={handleCloseDialog} />
      )}
    </React.Fragment>
  );
};

export default ProfileInfo;
