import React from "react";

import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";

import MHDataTable, { GridColDef } from "../../Common/DataTable/MHDataTable";
import { EmployeeData } from "../../../models/employee.model";
import MHLink from "../../Common/Link/MHLink";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import BackdropLoader from "../../Common/Loading/BackdropLoader";
import SnackbarContext from "../../../store/context/snackbar.context";
import {
  convertUTCToDate,
  convertUTCToWordDate,
  formatAmount,
  getURLWithQueryParams,
} from "../../../utils/utils";
import AuthContext from "../../../store/context/auth-context";
import StackedIconWithText from "../../Common/UI/StackedIconWithText";
import { ReactComponent as PendingIcon } from "../../../static/svg/pending.svg";
import { ReactComponent as CompletedIcon } from "../../../static/svg/completed.svg";
import { ReactComponent as UploadedIcon } from "../../../static/svg/uploaded.svg";
import { ReactComponent as PDFIcon } from "../../../static/svg/pdf.svg";
import { ReactComponent as SearchIcon } from "../../../static/svg/search.svg";
import { Typography } from "@mui/material";
import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import { ContractProps } from "../../../models/contract.model";
import { useHistory } from "react-router-dom";

const DocumentSubComponent = () => {
  const [employeeContracts, setEmployeeContracts] = React.useState<
    ContractProps[]
  >([]);
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<EmployeeData | null>(null);

  const history = useHistory();

  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const columns: GridColDef<ContractProps>[] = [
    {
      headerName: "Document Name",
      type: "text",
      field: "employeeName",
      width: 300,
      cellRenderer: (row: ContractProps) => (
        <StackedIconWithText
          justifyContent="start"
          alignItems="center"
          alignContent={"center"}
          spacing={2}
        >
          <Box className=" flex align-middle items-center gap-2">
            <PDFIcon />

            <Box className=" grid space-y-1 mt-2">
              <Typography
                variant="body1"
                color="primary"
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "120%", sm: "120%" }}
                letterSpacing={"0.01em"}
                alignSelf={"center"}
                className=" line-clamp-1"
              >
                {row.contractName}
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                lineHeight={{ xs: "100%", sm: "100%" }}
                letterSpacing={"0.01em"}
                alignSelf={"center"}
                className=" opacity-40 capitalize"
              >
                Uploaded:{" "}
                {row.createdDate && convertUTCToWordDate(row?.createdDate)}
              </Typography>
            </Box>
          </Box>
        </StackedIconWithText>
      ),
    },
    {
      headerName: "Sender",
      type: "text",
      field: "employerName",
      width: 200,
      valueGetter: (row: ContractProps) => {
        return <div className=" capitalize">{row?.employerName}</div>;
      },
    },
    {
      headerName: "Recipient",
      type: "text",
      field: "email",
      width: 300,
    },
    // {
    //   headerName: "Phone",
    //   type: "text",
    //   field: "telephone",
    //   width: 200,
    // },

    {
      headerName: "Ref no",
      type: "text",
      field: "salary",
      width: 200,
      valueGetter: (row: ContractProps) => {
        // return "REF" + row?.contractName;
        return row?.contractRef;
      },
    },

    {
      headerName: "Details",
      type: "text",
      field: "",
      width: 150,
      // align: "center",
      cellRenderer: (row: ContractProps) => (
        <MuiLink
          type="info"
          color="#3C72FF"
          // underline="none"
          className=" removeUnderline cursor-pointer"
          onClick={() =>
            history.push(
              `/organization/documents/contract/preview/${row.customerId}`
            )
          }
        >
          Preview
        </MuiLink>
      ),
    },
    {
      headerName: "Status",
      type: "text",
      field: "status",
      width: 150,
      // align: "center",
      cellRenderer: (row: ContractProps) => (
        <StackedIconWithText
          // justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box pr={1}>
            {row.status === "COMPLETED" ? (
              <CompletedIcon />
            ) : row.status === "UPLOADED" ? (
              <UploadedIcon />
            ) : (
              <PendingIcon />
            )}
          </Box>
        </StackedIconWithText>
      ),
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleOpen = (employee: any) => {
    setOpen(true);
    setSelectedEmployee(employee);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  // console.log("RefID", user?.employerRefId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract/byempRef",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps[]>) => {
        // setEmployees(response.data);
        setEmployeeContracts(response.data);
      }
    );
  }, []);

  // console.log("employeeContracts", employeeContracts);

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  // const filteredEmployees = employees.filter(
  //   (employee) =>
  //     employee.firstName &&
  //     employee.firstName.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const filteredEmployees =
    employeeContracts && employeeContracts
      ? employeeContracts.filter(
          (employee) =>
            (employee.employeeName &&
              employee.employeeName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (employee.email &&
              employee.email
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (employee.status &&
              employee.status.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      : [];

  return (
    <React.Fragment>
      <BackdropLoader open={loading} />
      <Box className=" flex justify-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "20px", sm: "30px" }}
          align="center"
          mt={3}
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Documents
        </Typography>
      </Box>
      <Box className=" w-[226px] mr-0 ml-auto">
        <MHFormControl
          id="text"
          type="text"
          label=""
          placeholder="Search contract"
          onChange={(event) => setSearchQuery(event.target.value)}
          startAdornment={
            <InputAdornment>
              <SearchIcon width="1rem" />
            </InputAdornment>
          }
          required
          // autoFocus
        />
      </Box>
      <MHDataTable
        title=""
        rows={searchQuery ? filteredEmployees : employeeContracts}
        columns={columns}
        frontEndPagination
        // hidePagination
      />
    </React.Fragment>
  );
};

export default DocumentSubComponent;
