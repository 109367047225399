import React, { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SelectOption } from "@mui/base";

import MHDialog from "../../../components/Common/Dialog/MHDialog";
import MHFormControl from "../../../components/Common/Form/MHFormControl";
import StyledActionButton from "../../../components/Common/Button/StyledActionButton";
import useInputArray from "../../../hooks/use-input-array";

import * as validators from "../../../utils/validators";
import MHButton from "../../../components/Common/Button/MHButton";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";
import useInput from "../../../hooks/use-input";
import { Grid, Typography } from "@mui/material";
import { MHSelect } from "../../../components/Common/Form/MHSelect";
import {
  getURLWithQueryParams,
  resolveErrorMessage,
} from "../../../utils/utils";
import DashboardContext from "../../../store/context/dashboard.context";
import { useOnboardForm } from "../../../store/context/onboard-context";
import { EmployeeData } from "../../../models/employee.model";
import InputAdornment from "../../../components/Common/Form/InputAdornment";

import { ReactComponent as WhatsappGreyIcon } from "../../../static/svg/whatsapp-grey.svg";
import { useHistory } from "react-router-dom";

// type SelectOption<T> = {
//   value: T;
//   label: string;
// };

const EmployeeContractDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { loading, error, sendHttpRequest: createContract } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const authCtx = React.useContext(AuthContext);

  const { user } = authCtx;

  const { contractForm, addToContract, clearContract } = useOnboardForm();

  const history = useHistory();
  const {
    value: enteredEmployeeName,
    valid: enteredEmployeeNameIsValid,
    error: employeeNameInputHasError,
    onChange: employeeNameInputChangeHandler,
    onBlur: employeeNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmployeePhone,
    valid: enteredEmployeePhoneIsValid,
    error: employeePhoneInputHasError,
    onChange: employeePhoneInputChangeHandler,
    onBlur: employeePhoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmployeeAddress,
    valid: enteredEmployeeAddressIsValid,
    error: employeeAddressInputHasError,
    onChange: employeeAddressInputChangeHandler,
    onBlur: employeeAddressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);
  const {
    value: enteredWhatsapp,
    valid: enteredWhatsappIsValid,
    error: whatsappInputHasError,
    onChange: whatsappInputChangeHandler,
    onBlur: whatsappInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const formIsValid = enteredWhatsappIsValid && enteredEmailIsValid;

  // const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  // const [selectOptions, setSelectOptions] = React.useState<
  //   SelectOption<string>[]
  // >([]);

  // // console.log("employees", employees);

  // React.useEffect(() => {
  //   sendHttpRequest(
  //     getURLWithQueryParams(
  //       process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
  //       {
  //         employerRefId: String(user?.employerRefId),
  //       }
  //     ),
  //     {
  //       method: "GET",
  //     },
  //     (response: HttpResponse<EmployeeData[]>) => {
  //       setEmployees(response.data);
  //     }
  //   );
  // }, []);

  // React.useEffect(() => {
  //   // Filter employees based on status "PENDING"
  //   const pendingEmployees = employees.filter(
  //     (employee) => employee.Status === "PENDING"
  //   );

  //   // Transform filtered employees to select options
  //   const transformedOptions: SelectOption<string>[] = pendingEmployees.map(
  //     (employee) => ({
  //       value: employee.firstName + " " + employee.lastName, // Convert the EmployeeData object to a string
  //       label: employee.firstName + " " + employee.lastName || "", // Use the name as the label
  //     })
  //   );

  //   // Set the select options
  //   setSelectOptions(transformedOptions);

  //   // Log the select options for debugging
  //   // console.log("selectOptions", selectOptions);
  // }, [employees]);

  const dashboardCtx = React.useContext(DashboardContext);
  const { updateEmployeeStatus } = dashboardCtx;

  const updatedEmployee: EmployeeData = {
    // ...employee,
    status: "CONTRACT-CREATED",
    id: contractForm?.id || 0,
    customerId: contractForm?.id,
    employerRefId: contractForm?.employerRefId,
    // Add other properties as needed
  };

  const handleForm = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    // if (!formIsValid) {
    //   // console.log("Complete Form");
    //   toast({
    //     message: "Please input employee details",
    //     variant: "warning",
    //   });
    //   return;
    // }

    addToContract({
      // employeeEmail: enteredEmail,
      // employeePhone: enteredWhatsapp,
      contractInvite: "pending",
    });

    await createContract(
      process.env.REACT_APP_API_BASE_URL + "/employer/contract",
      {
        method: "POST",
        body: JSON.stringify({
          contractRef: contractForm.contractRef,
          contractName: contractForm.documentName,
          employerRefId: Number(contractForm.employerRefId),
          customerId: Number(contractForm.id),
          employerName: contractForm.employerName,
          employeeName: contractForm.employeeName,
          employerImgUrl: contractForm.employerSignature,
          employeeImgUrl: contractForm.employeeSignature,
          whatsAppNo: contractForm.employeePhone,
          content: contractForm.editorHtml,
          email: contractForm.employeeEmail,
          status: "CREATED",
          createdDate: contractForm.startDate,
          expiryDate: contractForm.endDate,
        }),
      },
      (response: HttpResponse<unknown>) => {
        toast({
          message: `Invitation Sent to Household Staff`,
          variant: "success",
        });

        updateEmployeeStatus(updatedEmployee);

        onClose();
        emailInputChangeHandler("");
        whatsappInputChangeHandler("");
        clearContract();

        history.push("/organization/documents");
        // window.location.reload();
      }
    );
  };

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  return (
    <MHDialog
      title="Invite Household Employee"
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      // scroll="paper"
      actions={
        <MHButton
          type="submit"
          onClick={handleForm}
          form="add-employee-form"
          loading={loading}
          fullWidth
        >
          Send Invite
        </MHButton>
      }
      fullWidth
    >
      <Box
        component="form"
        id="add-employee-form"
        // onSubmit={handleForm}
        autoComplete="off"
        noValidate
      >
        <br />

        <Typography
          variant="body1"
          align="left"
          color={"primary"}
          fontSize={{ xs: "16px", sm: "19px", md: "16px" }}
          lineHeight={{ xs: "19px", sm: "19px" }}
          letterSpacing={"0.01em"}
          gutterBottom
          className=""
        >
          Enter email or Whatsapp number of Household staff
        </Typography>
        <br />

        <Stack height={150} direction="column" alignItems="center" spacing={2}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12}>
              <MHSelect
                label="Select Employee"
                placeholder="Select"
                options={selectOptions}
                value={enteredEmployeeName}
                onChange={(val) => {
                  // Find the selected employee based on the selected value
                  const selectedEmployee = employees.find(
                    (employee) =>
                      employee.firstName + " " + employee.lastName === val
                  );

                  // Call your context API function to add the selected employee details
                  addToContract({
                    employeeName: val as string, // Use the selected employee name
                    employeePhone: selectedEmployee?.telephone || 0, // Get the phone from selected employee (assuming it's a string)
                    employeeUUID: selectedEmployee?.uuid || 0, // Get the phone from selected employee (assuming it's a string)
                    // employeeAddress: selectedEmployee?.address || '', // Get the address from selected employee (assuming it's a string)
                  });

                  // Call your input change handler with the selected value
                  employeeNameInputChangeHandler(val as string);
                }}
                onBlur={employeeNameInputBlurHandler}
                error={resolveErrorMessage(employeeNameInputHasError)(
                  "Please select employee"
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <MHFormControl
                disabled={true}
                id="email"
                type="email"
                label=""
                placeholder="Email"
                value={contractForm.employeeEmail}
                onChange={emailInputChangeHandler}
                onBlur={emailInputBlurHandler}
                required
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <MHFormControl
                disabled={true}
                id="whatsapp"
                type="text"
                label=""
                placeholder="Whatsapp Number"
                value={String(contractForm.employeePhone)}
                onChange={whatsappInputChangeHandler}
                onBlur={whatsappInputBlurHandler}
                startAdornment={
                  <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                    <WhatsappGreyIcon />
                  </InputAdornment>
                }
                required
                autoFocus
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </MHDialog>
  );
};

export default EmployeeContractDialog;
