import { Box, Button, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as ArticleHeaderBg } from "../../../static/svg/articleHeader.svg";
import { ReactComponent as TrainIcon } from "../../../static/svg/trainingVidIcon.svg";
import useInput from "../../../hooks/use-input";
import * as validators from "../../../utils/validators";
import { HouseholdRoles } from "../../../utils/contracts";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import { useLocation } from "react-router-dom";

type TrainingHeaderProps = {
  onRoleChange: (role: string) => void;
  title?: string;
  content?: string;
};

export const TrainingHeader = ({
  onRoleChange,
  title,
  content,
}: TrainingHeaderProps) => {
  const [selectedRole, setSelectedRole] = React.useState("");

  const location = useLocation();
  let currentPath = location.pathname;

  const householdRolesOptions: Option[] = HouseholdRoles.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const {
    value: enteredRole,
    valid: enteredRoleIsValid,
    error: enteredRoleHasError,
    onChange: roleInputChangeHandler,
    onBlur: roleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  React.useEffect(() => {
    setSelectedRole(enteredRole);
    onRoleChange(enteredRole);

    console.log("selectedRole", selectedRole);
  }, [enteredRole]);

  return (
    <React.Fragment>
      <Box
        height={{ xs: "500px", sm: "500px" }}
        // bgcolor={"#"}
        position="relative"
        className=" overflow-hidden"
      >
        <Box
          width={{ xs: "full", sm: "497px" }}
          height={{ xs: "400px", sm: "400px" }}
          className=" md:place-content-start place-content-center md:ml-10 lg:ml-20 md:my-14 lg:my-20 my-10"
          zIndex={10}
          display="grid"
          alignSelf="center"
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="caption"
            color={"primary"}
            fontSize={{ xs: "20px", sm: "26px", md: "31px" }}
            width={{ xs: "300px", sm: "350px", md: "497px" }}
            lineHeight={{ xs: "40px", sm: "40px", md: "53px" }}
            textAlign={{ xs: "center", sm: "start" }}
            letterSpacing={"0.01em"}
            className=" mb-2 capitalize"
          >
            {title}
          </Typography>

          <Typography
            variant="body1"
            fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
            lineHeight={{ xs: "29px", sm: "29px" }}
            width={{ xs: "300px", sm: "300px", md: "421px" }}
            textAlign={{ xs: "center", sm: "start" }}
            color="primary"
            gutterBottom
            display={"flex"}
          >
            {content}
          </Typography>

          {currentPath.includes("training") ? (
            <Box
              display={{ xs: "grid", sm: "grid", md: "flex" }}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "start" }}
              gap={{ xs: 0, sm: 3 }}
              mx={{ xs: "auto", sm: "0px" }}
            >
              <Box width={{ xs: "250px", sm: "200px", md: "269px" }} mt={2}>
                {/* <MHSelect
                label=""
                placeholder="Select a Role"
                options={HouseholdRoles}
                value={enteredRole}
                onChange={(val) => roleInputChangeHandler(val as string)}
                onBlur={roleInputBlurHandler}
                error={resolveErrorMessage(enteredRoleHasError)(
                  "Please select role"
                )}
              /> */}

                <MHAutocomplete
                  label=""
                  placeholder="Select a Role"
                  options={householdRolesOptions}
                  // onInputChange={handleBankNameChange}
                  onInputChange={(val) => roleInputChangeHandler(val as string)}
                />
              </Box>

              <Box
                width={{ xs: "200px", sm: "200px", md: "269px" }}
                display={"flex"}
                alignItems="center"
                justifyContent={{ xs: "center", sm: "start" }}
              >
                <Button
                  className=" removeUnderline gap-2"
                  onClick={() => console.log("Watch Video")}
                >
                  <TrainIcon />
                  Watch video
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              display={{ xs: "grid", sm: "grid", md: "flex" }}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "start" }}
              gap={{ xs: 0, sm: 3 }}
              mx={{ xs: "auto", sm: "0px" }}
            >
              <Box width={{ xs: "250px", sm: "200px", md: "269px" }} mt={2}>
                {/* <MHSelect
                label=""
                placeholder="Select a Role"
                options={HouseholdRoles}
                value={enteredRole}
                onChange={(val) => roleInputChangeHandler(val as string)}
                onBlur={roleInputBlurHandler}
                error={resolveErrorMessage(enteredRoleHasError)(
                  "Please select role"
                )}
              /> */}

                {/* <MHAutocomplete
                  label=""
                  placeholder="Sort By"
                  options={householdRolesOptions}
                  // onInputChange={handleBankNameChange}
                  onInputChange={(val) => roleInputChangeHandler(val as string)}
                /> */}
              </Box>
            </Box>
          )}
        </Box>
        {currentPath.includes("training") ? (
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caringBlocks/trainingHeader.webp"
            alt=""
            draggable={false}
            className=" md:h-[600px] lg:h-[650px] xl:h-[700px] z-10 absolute md:-top-8 lg:-top-14 -right-24 lg:-right-4 xl:right-20 hidden md:block"
          />
        ) : (
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caringBlocks/articleHeader.webp"
            alt=""
            draggable={false}
            className=" md:h-[750px] lg:h-[850px] xl:h-[900px] z-10 absolute md:-top-8 lg:-top-28 -right-28 lg:-right-24 xl:right-20 hidden md:block"
          />
        )}
      </Box>
    </React.Fragment>
  );
};
