import React, { useEffect } from "react";
import { useOnboardForm } from "../../../store/context/onboard-context";
import MHDialog from "../../Common/Dialog/MHDialog";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import SnackbarContext from "../../../store/context/snackbar.context";
import DashboardContext from "../../../store/context/dashboard.context";

import { SelectOption } from "@mui/base";

import { ReactComponent as ArrowLeftIcon } from "../../../static/svg/arrow-left.svg";
import { ReactComponent as CancelIcon } from "../../../static/svg/cancel-mark-rounded-lg.svg";

import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import { SubscriptionData } from "../../../models/subscription.model";
import { HttpResponse } from "../../../models/api.interface";
import useHttp from "../../../hooks/use-http";
import {
  formatAmount,
  getURLWithQueryParams,
  isEmpty,
  sortArray,
} from "../../../utils/utils";
import useInput from "../../../hooks/use-input";
import * as validators from "../../../utils/validators";
import MHButton from "../../Common/Button/MHButton";
import Label from "../../Common/Form/Label";
import MHFormControl from "../../Common/Form/MHFormControl";

import Lottie from "react-lottie";
import * as animationData from "../../../static/json-animate/success.json";

interface SubscribeDialogProps {
  open: boolean;
  onClose: () => void;
}

const InputSubs = () => {
  const {
    value: enteredSubs,
    valid: enteredSubsIsValid,
    error: subsInputHasError,
    onChange: subsInputChangeHandler,
    onBlur: subsInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredSubsDuration,
    valid: enteredSubsDurationIsValid,
    error: subsDurationInputHasError,
    onChange: subsDurationInputChangeHandler,
    onBlur: subsDurationInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAccountNo,
    onChange: accountNoInputChangeHandler,
    onBlur: accountNoInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const { setActiveStep } = useOnboardForm();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const { sendHttpRequest } = useHttp();

  const [subscription, setSubscription] = React.useState<SubscriptionData[]>(
    []
  );

  const [selectedSubs, setSelectedSubs] = React.useState("");

  const [banks, setBanks] = React.useState<SelectOption<string>[]>([]);
  // const [bankName, setBankName] = React.useState<SelectOption<string>[]>([]);
  const [bankName, setBankName] = React.useState<SelectOption<string> | null>(
    null
  );

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/subscription/plan/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<SubscriptionData[]>) => {
        setSubscription(response.data);
      }
    );
  }, []);

  const householdSubscription =
    subscription &&
    subscription.filter(
      (subs, index, self) =>
        subs.name && // Ensure name is not empty
        subs.name !== "Enterprise" && // Exclude "Enterprise"
        index === self.findIndex((s) => s.name === subs.name) // Ensure unique names
    );

  const selectedSubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name && subs?.name.toLowerCase() === selectedSubs.toLowerCase()
      )
    : [];

  React.useEffect(() => {
    // Reset the duration whenever the subscription type changes
    subsDurationInputChangeHandler(""); // <-- Reset duration
  }, [enteredSubs]); // Trigger when enteredSubs changes

  const subsTypeOptions: Option[] = householdSubscription.map((role) => ({
    label: role.name as string,
    value: role.name as string,
  }));

  const subsDurationOptions: Option[] = selectedSubscription.map((role) => {
    // Map duration to corresponding label
    let durationLabel = "";
    switch (role.duration) {
      case 1:
        durationLabel = "Monthly";
        break;
      case 3:
        durationLabel = "Quarterly";
        break;
      case 12:
        durationLabel = "Yearly";
        break;
      default:
        durationLabel = `${role.duration} Months`; // Default case for any other duration
    }

    return {
      label: `${role.name} - ${durationLabel}: (${formatAmount(role?.price)})`, // Create label with duration and price
      value: String(role.id), // Ensure id is treated as a string
    };
  });

  const handleSubsType = (val: string | null) => {
    subsInputChangeHandler(val as string);
    setSelectedSubs(val as string);
  };

  const [enteredBankName, setEnteredBankName] = React.useState<string>(""); // State for selected bank name

  const getBanks = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/tranx/paystack/banks",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsonData = await response.json();

      if (response.ok) {
        const bankOptions = jsonData.data.map(
          (bank: { id: number; code: string; name: string }) => ({
            value: bank.code.toString(), // Convert 'code' to string if necessary
            label: bank.name.toString(), // Use 'name' as the label
          })
        );
        setBanks(bankOptions);

        // console.log("Banks Info fetched successfully");
        return response.status; // Return the status code for further processing
      } else {
        // const errorData = await response.json();
        // console.error("Banks Info fetch failed:", errorData);
        return response.status; // Return the status code for further processing
      }
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  React.useEffect(() => {
    getBanks();
  }, []); // Empty dependency array to ensure it runs only once

  React.useEffect(() => {
    const selectedBank = banks.find((item) => item.value === enteredBankName);
    setBankName(selectedBank || null);
  }, [enteredBankName, banks]);

  const sortedBankNames = sortArray(banks);

  const handleInputSubs = () => {
    localStorage.setItem("planId", enteredSubsDuration);
    localStorage.setItem("subscription", enteredSubs);
    // console.log("planId", enteredSubsDuration);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const bankAcctVerifier = async () => {
    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/tranx/paystack/verify-account?bankCode=${enteredBankName}&accountNumber=${enteredAccountNo}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(verifyBody),
      });

      if (response.ok) {
        // Parse the response body as JSON
        const resData = await response.json();
        const accountName = resData.data.account_name;
        console.log("bankName", bankName); // Correctly accessing the 'data' property

        localStorage.setItem("accountName", accountName);
        localStorage.setItem("accountNumber", enteredAccountNo);

        localStorage.setItem("bankCode", enteredBankName);
        if (bankName && typeof bankName.label === "string") {
          localStorage.setItem("bankName", bankName.label);
        } else {
          localStorage.setItem("bankName", " ");
        }
        // setAcctName(accountName);
        handleInputSubs();
      } else {
        toast({
          message: "Invalid Account Details",
          variant: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return null;
    }
  };

  const handleVerifier = (e: any) => {
    e.preventDefault();
    if (enteredSubsDuration === "") {
      toast({
        message: "A valid subscription duration is required",
        variant: "error",
      });
    }
    // if (bankName?.label === "") {
    //   toast({
    //     message: "Select your bank",
    //     variant: "error",
    //   });
    // }
    // if (enteredAccountNo === "") {
    //   toast({
    //     message: "Account number is required",
    //     variant: "error",
    //   });
    // }

    bankAcctVerifier();
  };

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "420px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="caption"
          fontSize={{ xs: "1rem", sm: "1rem" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Subscription Requirement
        </Typography>
      </Box>

      {/* <Box component={"form"} onSubmit={handleInputSubs}> */}
      <Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <Box width="350px">
            <Box my={2}>
              <MHAutocomplete
                label=""
                placeholder="Select Subscription"
                options={subsTypeOptions}
                onInputChange={handleSubsType}
              />
            </Box>

            {enteredSubs !== "" && (
              <Box my={2}>
                <MHAutocomplete
                  label=""
                  placeholder="Select Duration"
                  options={subsDurationOptions}
                  onInputChange={(val) =>
                    subsDurationInputChangeHandler(val as string)
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box width="350px">
            {banks.length === 0 ? (
              <Box mb={2}>
                <Label
                  style={{
                    fontFamily: "Area-Normal-Semibold",
                    fontSize: 13,
                    fontWeight: 400,
                  }}
                >
                  Bank Name
                </Label>
                <Label
                  style={{
                    fontFamily: "Area-Normal-Semibold",
                    fontSize: 13,
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  Please wait while we fetch banks
                  <CircularProgress size={20} color="primary" />
                </Label>
              </Box>
            ) : (
              <FormControl fullWidth>
                <label
                  htmlFor="fundbank-autocomplete-employee"
                  style={{ marginBottom: "4px" }}
                >
                  Bank Name
                </label>
                <Autocomplete
                  id="fundbank-autocomplete-employee"
                  options={sortedBankNames}
                  // getOptionLabel={(option) => option.label || ""}
                  getOptionLabel={(options) =>
                    (options as { label: string }).label
                  }
                  renderOption={(items, options) => (
                    <Button
                      onClick={() => setEnteredBankName(options.value || "")}
                      fullWidth
                      className="removeUnderline no-underline text-start"
                      sx={{
                        justifyContent: "start", // Aligns button content to the start
                      }}
                    >
                      <Box
                        component="li"
                        //   height={{ xs: "200px", sm: "200px" }}
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "space-between",
                        }}
                        padding={1}
                        {...items}
                        className=" space-x-6 w-full"
                      >
                        <Typography
                          variant="subtitle2"
                          fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                          lineHeight={{ xs: "10px", sm: "10px" }}
                          textAlign={{ xs: "start", sm: "start" }}
                          color="primary"
                          className=" "
                          gutterBottom
                        >
                          {options.label}
                        </Typography>
                      </Box>
                    </Button>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        style: { fontSize: "12px" },
                        sx: {
                          height: "45px",
                          padding: "0 14px",
                          border: "none",
                          borderRadius: 0,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box width="350px">
            <MHFormControl
              id="employee_bank_account"
              type="text"
              label="Bank Account"
              placeholder=""
              value={enteredAccountNo}
              onChange={accountNoInputChangeHandler}
              onBlur={accountNoInputBlurHandler}
              required
              autoFocus
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box my={4} width={{ xs: "100%", sm: "350px" }}>
            <MHButton
              color="primary"
              sx={{}}
              //   type="submit"
              onClick={handleVerifier}
              className="  "
              fullWidth
            >
              Submit
            </MHButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ConfirmSubs = () => {
  const { setActiveStep } = useOnboardForm();

  let planId = localStorage.getItem("planId");
  let bankCode = localStorage.getItem("bankCode");
  let bankName = localStorage.getItem("bankName");
  let accountNumber = localStorage.getItem("accountNumber");
  let accountName = localStorage.getItem("accountName");
  let subscription = localStorage.getItem("subscription");

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const requestBody = {
    customerId: organization?.customerId,
    planId: Number(planId),
    bankCode: bankCode,
    accountNumber: accountNumber,
  };

  const handleInputVerify = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/subscription/subscribe`;

    console.log("requestBody", requestBody);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Check if the response has content
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          // Parse the response body as JSON
          const resData = await response.json();
          console.log("resData", resData.data);
          toast({
            message: `${resData.data}`,
            variant: "success",
          });
        } else {
          console.log("No JSON response");
          toast({
            message: "Subscription successful.",
            variant: "success",
          });
        }
        setActiveStep(2);
      } else {
        toast({
          message: "Subscription failed",
          variant: "error",
        });
        setActiveStep(3);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast({
        message: "An error occurred while processing your request",
        variant: "error",
      });
      return null;
    }
  };

  if (isEmpty(planId)) {
    setActiveStep(0);
  }

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "510px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
      sx={{
        overflow: "hidden",
        overscrollBehaviorY: "none",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="caption"
          fontSize={{ xs: "1rem", sm: "1rem" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Verification
        </Typography>
      </Box>
      <Box display="flex" alignItems="start" justifyContent="start" mt={-3.5}>
        <Button
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
          className=" removeUnderline"
        >
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
            color={"primary"}
            gutterBottom
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <ArrowLeftIcon />
            Back
          </Typography>
        </Button>
      </Box>

      <Box
        className=""
        alignItems={"center"}
        alignContent={"center"}
        alignSelf={"center"}
        display={"grid"}
        mt={3}
      >
        {planId ? (
          <>
            <Box className="" my={1} px={3}>
              <Box width="100%">
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                    gutterBottom
                    align="left"
                    justifyContent="start"
                    pl={1}
                  >
                    Subscription Type
                  </Typography>

                  <Button
                    // onClick={copyBankToClipboard}
                    disabled
                    className="removeUnderline"
                    fullWidth
                    disableRipple
                  >
                    <Box
                      className=""
                      width={{ xs: "100%", sm: "100%" }}
                      height={{ xs: "45px", sm: "45px" }}
                      sx={{
                        border: 1,
                        borderColor: "#D9D9D9",
                        borderRadius: 1,
                        px: 2,
                      }}
                      alignItems="center"
                      display="flex"
                      gap={2}
                    >
                      <Typography
                        variant="subtitle1"
                        fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                        color={"primary"}
                        gutterBottom
                        align="left"
                        justifyContent="start"
                        width={{ xs: "100%", sm: "100%" }}
                        my={1}
                      >
                        {subscription}
                      </Typography>
                    </Box>
                  </Button>
                </Box>
                <br />
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                    gutterBottom
                    align="left"
                    justifyContent="start"
                    pl={1}
                  >
                    Bank Name
                  </Typography>

                  <Button
                    // onClick={copyBankToClipboard}
                    disabled
                    className="removeUnderline"
                    fullWidth
                    disableRipple
                  >
                    <Box
                      className=""
                      width={{ xs: "100%", sm: "100%" }}
                      height={{ xs: "45px", sm: "45px" }}
                      sx={{
                        border: 1,
                        borderColor: "#D9D9D9",
                        borderRadius: 1,
                        px: 2,
                      }}
                      alignItems="center"
                      display="flex"
                      gap={2}
                    >
                      <Typography
                        variant="subtitle1"
                        fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                        color={"primary"}
                        gutterBottom
                        align="left"
                        justifyContent="start"
                        width={{ xs: "100%", sm: "100%" }}
                        my={1}
                      >
                        {bankName}
                      </Typography>
                    </Box>
                  </Button>
                </Box>

                <br />
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                    gutterBottom
                    align="left"
                    justifyContent="start"
                    pl={1}
                  >
                    Account Name
                  </Typography>

                  <Button
                    // onClick={copyAccountToClipboard}
                    disabled
                    className="removeUnderline"
                    fullWidth
                    disableRipple
                  >
                    <Box
                      className=""
                      width={{ xs: "100%", sm: "100%" }}
                      height={{ xs: "45px", sm: "45px" }}
                      sx={{
                        border: 1,
                        borderColor: "#D9D9D9",
                        borderRadius: 1,
                        px: 2,
                      }}
                      alignItems="center"
                      display="flex"
                      gap={2}
                    >
                      <Typography
                        variant="subtitle1"
                        fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                        color={"primary"}
                        gutterBottom
                        align="left"
                        justifyContent="start"
                        width={{ xs: "100%", sm: "100%" }}
                        my={1}
                      >
                        {accountName}
                      </Typography>
                    </Box>
                  </Button>
                </Box>
                <br />
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                    gutterBottom
                    align="left"
                    justifyContent="start"
                    pl={1}
                  >
                    Account Number
                  </Typography>

                  <Button
                    // onClick={copyAccountToClipboard}
                    disabled
                    className="removeUnderline"
                    fullWidth
                    disableRipple
                  >
                    <Box
                      className=""
                      width={{ xs: "100%", sm: "100%" }}
                      height={{ xs: "45px", sm: "45px" }}
                      sx={{
                        border: 1,
                        borderColor: "#D9D9D9",
                        borderRadius: 1,
                        px: 2,
                      }}
                      alignItems="center"
                      display="flex"
                      gap={2}
                    >
                      <Typography
                        variant="subtitle1"
                        fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                        color={"primary"}
                        gutterBottom
                        align="left"
                        justifyContent="start"
                        width={{ xs: "100%", sm: "100%" }}
                        my={1}
                      >
                        {accountNumber}
                      </Typography>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="fullCenter " px={3} height={"300px"}>
            <Typography
              variant="subtitle1"
              color={"#6B6B6B"}
              fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
              width={{ xs: "80%", sm: "80%" }}
              gutterBottom
              align="center"
              justifyContent="center fullCenter"
            >
              Please select a subscription plan
            </Typography>
          </Box>
        )}
      </Box>

      {/* <Box component={"form"} onSubmit={handleInputVerify}> */}
      <Box>
        <Box display="flex" justifyContent="center">
          <Box my={4} width={{ xs: "100%", sm: "350px" }}>
            <MHButton
              color="primary"
              sx={{}}
              //   type="submit"
              onClick={handleInputVerify}
              className="  "
              fullWidth
            >
              Submit
            </MHButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SuccessSubs = (props: SubscribeDialogProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let subscription = localStorage.getItem("subscription");

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "420px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
    >
      <Box
        display="flex"
        alignItems="center"
        // alignSelf="center"
        // alignContent="center"
        justifyContent="center"
        mt={5}
      >
        <Box className="w-fit">
          <Lottie options={defaultOptions} />
        </Box>
      </Box>
      <Box display="flex" alignItems="start" justifyContent="center">
        <Typography
          variant="h1"
          fontSize={{ xs: "18px", sm: "18px" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
          ml={2}
        >
          Subscription Complete
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box my={4} width={{ xs: "100%", sm: "350px" }}>
          <Typography
            variant="subtitle2"
            fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
            color={"primary"}
            gutterBottom
            align="center"
            justifyContent="center"
            my={3}
          >
            Thank you for subscribing for {subscription} Feel free to navigate
            our Care-Ecosystem
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const FailedSubs = (props: SubscribeDialogProps) => {
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const handleFailure = () => {
    toast({
      message: "Failed to subscribe",
      variant: "error",
    });
  };

  const closure = () => {
    // setWithdrawActiveStep(0);
    props.onClose();
  };

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "420px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
    >
      <Box
        display="flex"
        alignItems="center"
        // alignSelf="center"
        // alignContent="center"
        justifyContent="center"
        my={4}
      >
        <Box className="w-fit">
          <CancelIcon width={100} height={100} />
        </Box>
      </Box>
      <Box display="flex" alignItems="start" justifyContent="center">
        <Typography
          variant="h1"
          fontSize={{ xs: "18px", sm: "18px" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Subscription Failed
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box my={4} width={{ xs: "100%", sm: "350px" }}>
          <Typography
            variant="subtitle2"
            fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
            color={"primary"}
            gutterBottom
            align="center"
            justifyContent="center"
            my={3}
          >
            Something went wrong, please go back and try again or contact Caring
            Blocks associate for assistance if issue persists.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const SubscribeDialog: React.FC<SubscribeDialogProps> = ({ open, onClose }) => {
  const { activeStep, setActiveStep } = useOnboardForm();

  const closure = () => {
    localStorage.removeItem("subscription");
    localStorage.removeItem("bankCode");
    localStorage.removeItem("accountNumber");
    localStorage.removeItem("planId");

    setActiveStep(0);
    onClose();
  };

  const handlePayFlow = () => {
    if (activeStep === 0) {
      return <InputSubs />;
    } else if (activeStep === 1) {
      return <ConfirmSubs />;
    } else if (activeStep === 2) {
      return <SuccessSubs open={open} onClose={onClose} />;
    } else if (activeStep === 3) {
      return <FailedSubs open={open} onClose={onClose} />;
    }
    return null;
  };

  return (
    <MHDialog
      open={open}
      title={` `}
      handleClose={closure}
      maxWidth="md"
      // actions={}
      scroll="paper"
      style={{ zIndex: 1049 }}
    >
      {handlePayFlow()}
    </MHDialog>
  );
};

export default SubscribeDialog;
