import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import ContentCard from "./ContentCard";
import MHSlider, {
  MHSliderItem,
} from "../../components/Common/SliderCarousel/CBSlider/MHSlider";

const CONTENT = [
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Improve+Employee+Retension.png",
    title: "Improve Employee Retention",
    description:
      "Employees who feel supported in their personal lives are more likely to stay with your company long-term. By offering reliable childcare and household support, you demonstrate a commitment to their well-being that goes beyond the workplace.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Enhance+Productivity.png",
    title: "Enhance Productivity",
    description:
      "When employees don't have to worry about finding or managing childcare, they can focus more on their work, leading to better outcomes and higher efficiency.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Reduce+Absenteeism.png",
    title: "Reduce Absenteeism",
    description:
      "With access to 24/7 care managers and comprehensive support services, employees are less likely to miss work due to childcare emergencies or other household issues.",
  },
];

const CorporateHeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box width={{ xs: "90%", sm: "75%" }} mx="auto" mt={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          {/* extend care beyond the company’s walls, creating a win-win scenario
          where you and <br /> your employees thrive and prosper. */}
          Your employee care challenges are unique - so is our solution
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph
        >
          In today's competitive business environment, retaining top talent and
          ensuring their productivity is paramount. By addressing the childcare
          and household needs of your employees, you can significantly enhance
          retention, reduce absenteeism, and boost overall productivity. Our
          solution is designed to
        </Typography>
      </Box>

      <Box py="3rem" px={{ xs: 0, md: "4rem" }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>

      {/* <Typography
        variant="body1"
        fontFamily="Area-Extended"
        textTransform="uppercase"
        textAlign="center"
        color="#194049"
        fontSize={12}
        letterSpacing="0.1em"
        sx={{
          mt: 0,
          mb: 2,
        }}
      >
        Top Companies Trust MH
      </Typography> */}

      {/* <MHmarquee /> */}

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={5}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', md: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          An Array Of Care Solutions Throughout Your Employees Working Lives
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Many Employees Are Being Crushed Under The Unrelenting and Unexpected
          Burden Of Care, It’s Time Your Benefits Care
        </Typography>
      </Box>

      {!matchesMdDown && (
        <Box py="3rem" px={'4rem'}>
          <MHSlider slidesToShow={3} slidesToScroll={1}>
            {CONTENT.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <ContentCard
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  onMouseClick={() => {}}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        </Box>
      )}

      {matchesMdDown && (
        <Grid
          container
          direction={{ xs: 'row' }}
          flexWrap="nowrap"
          rowSpacing={{ xs: 0, sm: 0 }}
          columnSpacing={3}
          mt={2}
          width={{ xs: '90%', sm: '80%' }}
          mx="auto"
          mb={8}
          overflow="auto"
          className="horizontal-scroll">
          {CONTENT.map((item) => (
            <Grid item xs={12} sm={4} flexShrink={0}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
    </div>
  );
};

export default CorporateHeroSlider;
