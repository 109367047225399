import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./theme/mui/dashboard.theme";
import { AuthContextProvider } from "./store/context/auth-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter basename="/">
    {/* <BrowserRouter basename="/employer-dashboard-v1"> */}
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </ThemeProvider>
    </AuthContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
