import React from "react";
import { TrainingData } from "../../components/Features/Training/TrainingCard";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Pagination from "../../components/Common/UI/Pagination";
import InstituteCard from "./InstituteCard";
import { convertTo12Hour } from "../../utils/utils";
import { MHPagination } from "../../components/Common/UI/MHPagination";

type Props = {
  selectedRole?: string | any;
  dataIn?: TrainingData[];
};

const InstituteBody = (props: Props) => {
  let slicedSelectedTrainings = props.dataIn
    ? props.dataIn.filter((training) => {
        if (!training) return false; // Add a null check for training

        // If selectedRole is defined and not empty, filter by it
        if (props.selectedRole) {
          return (
            training.trainingMode &&
            training.category &&
            training.category
              .toLowerCase()
              .includes(props.selectedRole.toLowerCase())
          );
        }

        // If no selectedRole, return all trainings
        return training.trainingMode && training.category;
      })
    : [];

  //   Training Pagination
  // const [currentPage, setCurrentPage] = React.useState(1);
  // const [postsPerPage, setPostsPerPage] = React.useState(8);
  // const lastPostIndex = currentPage * postsPerPage;
  // const firstPostIndex = lastPostIndex - postsPerPage;

  // const currentTrainingPost = slicedSelectedTrainings.slice(
  //   firstPostIndex,
  //   lastPostIndex
  // );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(9);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = slicedSelectedTrainings.slice(
    firstPostIndex,
    lastPostIndex
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  let itemsPerPage = 9;
  let noOfPage = Math.ceil(
    (slicedSelectedTrainings?.length || 0) / itemsPerPage
  );
  return (
    <React.Fragment>
      <Box
        px={{ xs: 2, sm: 4, md: 6 }}
        mt={{ xs: 2, sm: 2, md: 2 }}
        mb={{ xs: 4, sm: 4, md: 4 }}
      >
        <Typography
          color="primary"
          variant="body1"
          fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
          lineHeight={{ xs: "29px", sm: "29px" }}
          align="right"
          className=" opacity-80"
        >
          {slicedSelectedTrainings.length} Results
        </Typography>
        <Divider />

        <Grid
          container
          spacing={{ xs: 0, sm: 4, md: 4 }}
          className="mx-auto place-content-center"
          justifyContent="center"
        >
          {slicedSelectedTrainings && slicedSelectedTrainings.length < 1 ? (
            <Grid item xs={12} sm={6} md={4} className=" ">
              <h1>....Coming Soon</h1>
            </Grid>
          ) : (
            currentPosts &&
            currentPosts.map((course: any, index: any) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                key={index}
                className="md:px-3 px-6 place-content-center  mt-6 md:mt-3"
              >
                <InstituteCard
                  trainingMode={course.trainingMode}
                  title={course.title}
                  createdDate={course.date}
                  reference={course.reference}
                  startDate={convertTo12Hour(course.startDate)}
                  endDate={convertTo12Hour(course.endDate)}
                  objectives={course.objectives}
                  price={course.price}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>

      {/* <Pagination
        totalPosts={slicedSelectedTrainings.length}
        postsPerPage={postsPerPage}
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}
        onClicked={() => {
          setCurrentPage(currentPage + 1);
        }}
        currentPage={currentPage}
      /> */}

      <Box display={"flex"} justifyContent={"center"} my={2}>
        <MHPagination
          count={noOfPage}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePageChange}
          color={"primary"}
        />
      </Box>
    </React.Fragment>
  );
};

export default InstituteBody;
