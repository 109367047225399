import React from "react";
import ProfileSubHeader from "./ProfileSubHeader";
import { Box, Divider } from "@mui/material";
import { EmployeeData } from "../../../models/employee.model";
import MHButton from "../../Common/Button/MHButton";
import EmployeeReportTable from "./ReportTable";

type EmployeeProps = {
  employee: EmployeeData | null;
};

const ReportView = ({ employee }: EmployeeProps) => {
  return (
    <React.Fragment>
      <Divider />

      <ProfileSubHeader employee={employee} />
      <Box display="flex" justifyContent="center" my={4}>
        <MHButton
          color="primary"
          sx={{}}
          type="submit"
          className=" w-[215px]"
          // startIcon={<PlusIcon />}
          // onClick={handleOpenEmployee}
          // onClick={() =>
          //   history.push("/organization/employee-onboarding")
          // }
        >
          Request Employee Report
        </MHButton>
      </Box>

      {/* <EmployeeReportTable /> */}
    </React.Fragment>
  );
};

export default ReportView;
