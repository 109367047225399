import { Box, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import { ReactComponent as CheckIcon } from "../../static/svg/check.svg";

import { formatNumber, getURLWithQueryParams } from "../../utils/utils";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import MHSlider, {
  MHSliderItem,
} from "../../components/Common/SliderCarousel/Slider/MHSlider";
import useHttp from "../../hooks/use-http";
import { SubscriptionData } from "../../models/subscription.model";
import { HttpResponse } from "../../models/api.interface";

export interface SubsTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function SubsTabPanel(props: SubsTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-module--tabpanel-${index}`}
      aria-labelledby={`simple-module--tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function SubsAllyProps(index: number) {
  return {
    id: `simple-module--tab-${index}`,
    "aria-controls": `simple-module--tabpanel-${index}`,
  };
}

export const CardWidget = ({
  title,
  description,
  amount,
  benefits,
  linkTo,
}: {
  title?: string;
  description?: string;
  linkTo?: string | any;
  amount?: number;
  benefits?: string;
}) => {
  const history = useHistory();

  const benString = benefits?.split("##");

  // console.log("benefits", benefits);
  // console.log("benString", benString);

  // const handleSubscribe = (links: any) => {
  //   // history.push(linkTo);
  //   if (title === "Household") {
  //     localStorage.setItem("Household", encrypt("Household"));
  //     localStorage.removeItem("Household Pro");
  //   } else if ((title = "Household Pro")) {
  //     localStorage.setItem("Household Pro", encrypt("Household Pro"));
  //     localStorage.removeItem("Household");
  //   } else {
  //     return;
  //   }

  //   const subs = localStorage.getItem(JSON.parse(decrypt("Household")));

  //   console.log(subs);
  // };

  // const handleSubscribe = (links: any) => {
  //   history.push(linkTo);
  //   if (title === "Household") {
  //     localStorage.setItem(
  //       "subscriptionType",
  //       encrypt(JSON.stringify({ subscriptionType: "Household" }))
  //     );
  //     localStorage.removeItem("Household Pro");
  //   } else if (title === "Household Pro") {
  //     localStorage.setItem(
  //       "subscriptionType",
  //       encrypt(JSON.stringify({ subscriptionType: "Household Pro" }))
  //     );
  //     localStorage.removeItem("Household");
  //   } else {
  //     return;
  //   }

  //   // GET SUBSCRIPTION PLAN
  //   const subs = localStorage.getItem(title);
  //   if (!subs) {
  //     return null;
  //   }
  //   const deSub = JSON.parse(decrypt(subs));
  //   console.log(deSub);
  // };

  return (
    <Box
      bgcolor={`#fff`}
      height="420px"
      width="300px"
      sx={{
        borderRadius: 5,
        boxShadow: "0px 14px 14px rgba(185, 185, 185, 0.25)",
      }}
      className={`${title === "Enterprise" ? "subscriptionCard" : ""} `}
    >
      <Box height="160px" py={2} px={4}>
        <Box
          bgcolor="#194049"
          py={0.3}
          px={1.5}
          sx={{
            height: "22px",
            width: "46%",
            // width: title === "Household Pro" ? "45%" : "45%",
            borderRadius: 5,
          }}
        >
          <Typography
            variant="subtitle2"
            color="#fff"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="text-center"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "8.2px", sm: "8.2px", md: "8.2px" }}
          lineHeight={{ xs: "200%", sm: "200%" }}
          letterSpacing={"-4%"}
          className="w-[237px] py-3 text-start h-16"
        >
          {description}
        </Typography>
        <Typography
          variant="subtitle1"
          color="primary"
          fontSize={{ xs: "20px", sm: "20px", md: "29px" }}
          lineHeight={{ xs: "160.5%", sm: "160.5%" }}
          letterSpacing={"-4%"}
          className="pt-3 flex items-baseline text-start capitalize text-navy-900"
        >
          {amount ? "₦" + formatNumber(amount) : " Let's Talk"}
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="text-start"
          >
            {amount ? "/ per employee" : ""}
          </Typography>
        </Typography>
      </Box>
      <Divider />
      <Box height="180px" px={4} alignItems="center" alignContent="center">
        {benString?.map((benefit, index) => (
          <Box display="flex" alignItems="center" gap={1} py={1} key={index}>
            <CheckIcon />
            <Typography
              variant="subtitle1"
              color="primary"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="w-[237px] text-start"
            >
              {benefit}
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box px={2} py={2}>
        {title === "Enterprise" ? (
          <MHButton
            sx={{ height: "38px", width: "270px" }}
            color="primary"
            className="rounded-md"
            fullWidth
            onClick={() => {
              window.open(linkTo);
            }}
          >
            Schedule A Call
          </MHButton>
        ) : (
          <MHButton
            sx={{ height: "38px", width: "270px", color: "white" }}
            className="bg-white text-navy-900 ring-1 ring-navy-900 rounded-md"
            fullWidth
            // onClick={() => handleSubscribe(linkTo)}
            onClick={() => history.push(linkTo)}
          >
            Get Started
          </MHButton>
        )}
      </Box>
    </Box>
  );
};

const Subscribe = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();

  const dashboardMatch = matchPath(location.pathname, {
    path: "/",
    exact: true,
    strict: true,
  });

  const { loading, error, sendHttpRequest } = useHttp();

  const [subscription, setSubscription] = React.useState<SubscriptionData[]>(
    []
  );

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/subscription/plan/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<SubscriptionData[]>) => {
        setSubscription(response.data);
      }
    );
  }, []);

  // const householdSubscription =
  //   subscription &&
  //   subscription.filter((subs) => subs.name?.includes("Household"));

  const householdMonthlySubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name &&
          subs?.name.toLowerCase().includes("household") &&
          subs.duration &&
          subs?.duration === 1
      )
    : [];
  const householdQuarterlySubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name &&
          subs?.name.toLowerCase().includes("household") &&
          subs.duration &&
          subs?.duration === 3
      )
    : [];
  const householdYearlySubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name &&
          subs?.name.toLowerCase().includes("household") &&
          subs.duration &&
          subs?.duration === 12
      )
    : [];

  // console.log("Monthly", householdMonthlySubscription);
  // console.log("Quarterly", householdQuarterlySubscription);
  // console.log("Yearly", householdYearlySubscription);

  return (
    <React.Fragment>
      <Box
        sx={{
          paddingTop: "40px",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "100%", sm: "100%", md: "100%" },
          backgroundColor: "#f6f6f6",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
        className="subscriptionPlan"
      >
        <div id="subscription"></div>

        <Typography
          variant="subtitle2"
          color="primary"
          fontSize={{ xs: "28px", sm: "27px", md: "29px" }}
          lineHeight={{ xs: "130%", sm: "160.5%" }}
          letterSpacing={"-4%"}
          className="py-4 text-center mx-auto md:mx-0 capitalize text-navy-900"
          width={{ xs: "80%", sm: "100%" }}
        >
          Choose Your Subscription Plan
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
          lineHeight={{ xs: "200%", sm: "200%" }}
          letterSpacing={"-4%"}
          className="py-4 text-center w-[80%] md:w-[35%] capitalize mx-auto text-navy-900"
        >
          We believe managing your household employees should be
          straightforward, transparent, and secure.
        </Typography>

        <Box sx={{ width: "100%" }} className="my-6">
          <Tabs
            value={value}
            className="font-areaNorm place-items-center mx-auto place-content-center"
            onChange={handleChange}
            sx={{
              width: { xs: "90%", sm: "70%" },
              display: "flex",
              bgcolor: "#FFFFFF",
              borderRadius: "20px",
              justifyItems: "center",
              justifyContent: "center",
              justifySelf: "center",
              padding: "2px",
              border: "2px solid #D2E0CB",
            }}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <Tab
              className={`rounded-[20px] text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-fit  flex-1 ${
                value === 0
                  ? "bg-[#194049] text-white"
                  : "bg-[#FFFFFF] text-[#194049]"
              }`}
              label="Monthly"
              disableRipple
              {...SubsAllyProps(0)}
            />
            <Tab
              className={`rounded-[20px] text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-fit  flex-1 ${
                value === 1
                  ? "bg-[#194049] text-white"
                  : "bg-[#FFFFFF] text-[#194049]"
              }`}
              label="Quarterly"
              disableRipple
              {...SubsAllyProps(1)}
            />
            <Tab
              className={`rounded-[20px] text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-fit  flex-1 ${
                value === 2
                  ? "bg-[#194049] text-white"
                  : "bg-[#FFFFFF] text-[#194049]"
              }`}
              label="Annually"
              disableRipple
              {...SubsAllyProps(2)}
            />
          </Tabs>

          <Box my={10}>
            <SubsTabPanel value={value} index={0}>
              <Grid
                container
                spacing={{ xs: 4, sm: 1 }}
                px={{ xs: "0px", sm: "40px" }}
                display="flex"
                justifyContent="center"
                justifyItems="center"
                className=" hidden md:flex"
              >
                {householdMonthlySubscription &&
                  householdMonthlySubscription.map((subs, index) => (
                    <Grid
                      item
                      xs={10.5}
                      sm={5.5}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      key={index}
                    >
                      <CardWidget
                        title={subs.name}
                        description={subs.description}
                        amount={subs.price}
                        benefits={subs.benefit}
                        linkTo={subs.link}
                      />
                    </Grid>
                  ))}
                <CardWidget
                  title="Enterprise"
                  description="(perfect for businesses and corporations as a benefit)"
                  amount={0}
                  benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                />
              </Grid>

              <MHSlider
                slidesToShow={dashboardMatch ? 1 : 3}
                slidesToScroll={1}
                className="md:hidden place-self-center mx-auto w-[80%]"
              >
                {[
                  ...(householdMonthlySubscription
                    ? householdMonthlySubscription.map((subs, index) => (
                        <MHSliderItem key={index} width="100%">
                          <CardWidget
                            title={subs.name}
                            description={subs.description}
                            amount={subs.price}
                            benefits={subs.benefit}
                            linkTo={subs.link}
                          />
                        </MHSliderItem>
                      ))
                    : []),
                  <MHSliderItem key="enterprise" width="100%">
                    <CardWidget
                      title="Enterprise"
                      description="(perfect for businesses and corporations as a benefit)"
                      amount={0}
                      benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                      linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                    />
                  </MHSliderItem>,
                ]}
              </MHSlider>
            </SubsTabPanel>
            <SubsTabPanel value={value} index={1}>
              <Grid
                container
                spacing={{ xs: 4, sm: 1 }}
                px={{ xs: "0px", sm: "40px" }}
                display="flex"
                justifyContent="center"
                justifyItems="center"
                className=" hidden md:flex"
              >
                {householdQuarterlySubscription &&
                  householdQuarterlySubscription.map((subs, index) => (
                    <Grid
                      item
                      xs={10.5}
                      sm={5.5}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      key={index}
                    >
                      <CardWidget
                        title={subs.name}
                        description={subs.description}
                        amount={subs.price}
                        benefits={subs.benefit}
                        linkTo={subs.link}
                      />
                    </Grid>
                  ))}
                <CardWidget
                  title="Enterprise"
                  description="(perfect for businesses and corporations as a benefit)"
                  amount={0}
                  benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                />
              </Grid>

              <MHSlider
                slidesToShow={dashboardMatch ? 1 : 3}
                slidesToScroll={1}
                className="md:hidden place-self-center mx-auto w-[80%]"
              >
                {[
                  ...(householdQuarterlySubscription
                    ? householdQuarterlySubscription.map((subs, index) => (
                        <MHSliderItem key={index} width="100%">
                          <CardWidget
                            title={subs.name}
                            description={subs.description}
                            amount={subs.price}
                            benefits={subs.benefit}
                            linkTo={subs.link}
                          />
                        </MHSliderItem>
                      ))
                    : []),
                  <MHSliderItem key="enterprise" width="100%">
                    <CardWidget
                      title="Enterprise"
                      description="(perfect for businesses and corporations as a benefit)"
                      amount={0}
                      benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                      linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                    />
                  </MHSliderItem>,
                ]}
              </MHSlider>
            </SubsTabPanel>
            <SubsTabPanel value={value} index={2}>
              <Grid
                container
                spacing={{ xs: 4, sm: 1 }}
                px={{ xs: "0px", sm: "40px" }}
                display="flex"
                justifyContent="center"
                justifyItems="center"
                className=" hidden md:flex"
              >
                {householdYearlySubscription &&
                  householdYearlySubscription.map((subs, index) => (
                    <Grid
                      item
                      xs={10.5}
                      sm={5.5}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      key={index}
                    >
                      <CardWidget
                        title={subs.name}
                        description={subs.description}
                        amount={subs.price}
                        benefits={subs.benefit}
                        linkTo={subs.link}
                      />
                    </Grid>
                  ))}

                <CardWidget
                  title="Enterprise"
                  description="(perfect for businesses and corporations as a benefit)"
                  amount={0}
                  benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                />
              </Grid>

              <MHSlider
                slidesToShow={dashboardMatch ? 1 : 3}
                slidesToScroll={1}
                className="md:hidden place-self-center mx-auto w-[80%]"
              >
                {[
                  ...(householdYearlySubscription
                    ? householdYearlySubscription.map((subs, index) => (
                        <MHSliderItem key={index} width="100%">
                          <CardWidget
                            title={subs.name}
                            description={subs.description}
                            amount={subs.price}
                            benefits={subs.benefit}
                            linkTo={subs.link}
                          />
                        </MHSliderItem>
                      ))
                    : []),
                  <MHSliderItem key="enterprise" width="100%">
                    <CardWidget
                      title="Enterprise"
                      description="(perfect for businesses and corporations as a benefit)"
                      amount={0}
                      benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                      linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                    />
                  </MHSliderItem>,
                ]}
              </MHSlider>
            </SubsTabPanel>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Subscribe;
