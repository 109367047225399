import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";
import { ReactComponent as EditIcon } from "../../../static/svg/edit-black.svg";
import { ReactComponent as Visibility } from "../../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../static/svg/visibility-off.svg";

import useInput from "../../../hooks/use-input";
import MHTextInput from "../../Common/Form/MHTextInput";
import MHPasswordControl from "../../Common/Form/MHPasswordControl";
import MHButton from "../../Common/Button/MHButton";
import AuthContext from "../../../store/context/auth-context";
import DashboardContext from "../../../store/context/dashboard.context";
import useDialog from "../../../hooks/use-dialog";
import PinDialog from "./PinDialog";
import { Organization } from "../../../models/employer.model";
import SnackbarContext from "../../../store/context/snackbar.context";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
type Props = {};

const AccountSettings = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization, updateEmployeeRecord } = dashboardCtx;

  // const { loading, error, sendHttpRequest: signUp } = useHttp();

  const [disabledState, setDisabledState] = React.useState({
    fname: true,
    lname: true,
    email: true,
    phone: true,
    address: true,
    role: true,
  });

  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: firstNameInputHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    error: lastNameInputHasError,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  const {
    value: enteredAddress,
    valid: enteredAddressIsValid,
    error: addressInputHasError,
    onChange: addressInputChangeHandler,
    onBlur: addressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    error: phoneInputHasError,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const handleCancel = () => {
    console.log("Reset all inputs");
  };

  const handleSaveAccount = async () => {
    // console.log("Save new account settings");
    // console.log("Save new account settings");

    // console.log("enteredPhone", enteredPhone);
    // console.log("enteredAddress", enteredAddress);

    if (!updateEmployeeRecord) {
      console.error("updateEmployeeRecord is not defined in DashboardContext");
      return;
    }

    const updatedOrganization: Organization = {
      ...organization,
      firstName: enteredFirstName,
      lastName: enteredLastName,
      email: enteredEmail,
      address: enteredAddress,
      telephone: enteredPhone,
      jobTitle: "nanny",
      // Add other properties as needed
    };

    console.log(updatedOrganization);

    await updateEmployeeRecord(updatedOrganization);
    toast({
      message: "Profile updated successfully.",
      variant: "success",
    });
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Box className="bg-white w-full drop-shadow-lg py-6" id="accountsetting">
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Account Settings
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            The information here can be edited
          </Typography>
        </Box>

        <Divider />

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 pt-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className="col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              First Name
            </label>
            <MHTextInput
              id="firstName"
              type="text"
              // label="First Name"
              placeholder=""
              value={
                enteredFirstName === ""
                  ? organization?.firstName
                  : enteredFirstName
              }
              onChange={firstNameInputChangeHandler}
              onBlur={firstNameInputBlurHandler}
              disabled={disabledState.fname}
              className={`${
                disabledState.fname === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      fname: !disabledState.fname,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Last Name
            </label>
            <MHTextInput
              id="lastName"
              type="text"
              // label="First Name"
              placeholder=""
              value={
                enteredLastName === ""
                  ? organization?.lastName
                  : enteredLastName
              }
              onChange={lastNameInputChangeHandler}
              onBlur={lastNameInputBlurHandler}
              disabled={disabledState.lname}
              className={`${
                disabledState.lname === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      lname: !disabledState.lname,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>
          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Email
            </label>
            <MHTextInput
              id="email"
              type="email"
              // label="Email"
              placeholder=""
              value={organization?.email}
              // onChange={roleInputChangeHandler}
              // onBlur={roleInputBlurHandler}
              disabled={disabledState.email}
              className={`${
                disabledState.email === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              // endAdornment={
              //   <InputAdornment
              //     className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
              //     onClick={() => {
              //       setDisabledState({
              //         ...disabledState,
              //         fname: !disabledState.fname,
              //       });
              //     }}
              //   >
              //     <EditIcon className=" text-navy-900 2-6 h-6" />
              //   </InputAdornment>
              // }
              required
              autoFocus
            />
          </Box>
        </Box>

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 py-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%] -mt-6 lg:mt-0">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Phone number
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="phone"
              placeholder=""
              value={
                enteredPhone === "" ? organization?.telephone : enteredPhone
              }
              onChange={phoneInputChangeHandler}
              onBlur={phoneInputBlurHandler}
              disabled={disabledState.phone}
              className={`${
                disabledState.phone === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      phone: !disabledState.phone,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Address
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="address"
              placeholder=""
              value={
                enteredAddress === "" ? organization?.address : enteredAddress
              }
              onChange={addressInputChangeHandler}
              onBlur={addressInputBlurHandler}
              disabled={disabledState.address}
              className={`${
                disabledState.address === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              endAdornment={
                <InputAdornment
                  className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setDisabledState({
                      ...disabledState,
                      address: !disabledState.address,
                    });
                  }}
                >
                  <EditIcon className=" text-navy-900 2-6 h-6" />
                </InputAdornment>
              }
              required
              autoFocus
            />
          </Box>

          <Box className=" col-span-1 md:col-span-1 lg:basis-[28%]">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Job Title
            </label>
            <MHTextInput
              id="text"
              type="text"
              // label="role"
              placeholder=""
              value={organization?.jobTitle?.toUpperCase()}
              // onChange={roleInputChangeHandler}
              // onBlur={roleInputBlurHandler}
              disabled={disabledState.role}
              className={`${
                disabledState.role === true ? "bg-[#D9D9D9]" : "bg-white"
              }`}
              // endAdornment={
              //   <InputAdornment
              //     className="bg-[#D9D9D9] md:h-[43px] text-navy-900 cursor-pointer"
              //     onClick={() => {
              //       setDisabledState({
              //         ...disabledState,
              //         role: !disabledState.role,
              //       });
              //     }}
              //   >
              //     <EditIcon className=" text-navy-900 2-6 h-6" />
              //   </InputAdornment>
              // }
              required
              autoFocus
            />
          </Box>
        </Box>

        <Divider />

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton>

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            onClick={handleSaveAccount}
            fullWidth
            className="rounded-sm"
          >
            Save Changes
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const PasswordSettings = (props: Props) => {
  const [visibility, setVisibility] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");

  const { loading, error, sendHttpRequest: sendResetPasswordLink } = useHttp();

  const dashboardCtx = React.useContext(DashboardContext);

  const { organization, fetchOrganizationData } = dashboardCtx;

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const {
    value: enteredOldPassword,
    valid: enteredOldPasswordIsValid,
    error: oldPasswordInputHasError,
    onChange: oldPasswordInputChangeHandler,
    onBlur: oldPasswordInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const handleNewPasswordChange = (value: string) => {
    setNewPassword(value);
    // console.log("password", newPassword);
  };

  const isValidPassword = validators.validatePassword(
    newPassword,
    8, // minLength
    true, // requiredUppercase
    true, // requiredLowercase
    true, // requiredNumber
    true // requiredSpecialChar
  );

  const handleCancel = () => {
    console.log("Reset all inputs");
  };

  const sendResetPasswordLinkHandler = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (!isValidPassword) {
      return;
    }

    sendResetPasswordLink(
      process.env.REACT_APP_API_BASE_URL + "/employer/password/change",
      {
        method: "PATCH",
        body: JSON.stringify({
          employerRefId: Number(organization?.employerRefId),
          customerId: Number(organization?.customerId),
          type: "Employee",
          oldPassword: enteredOldPassword,
          newPassword: newPassword,
        }),
      },
      (response: HttpResponse<unknown>) => {
        toast({
          variant: "success",
          message: "Password updated successful!",
        });
        window.location.reload();
      }
    );
  };

  return (
    <React.Fragment>
      <Box
        className="bg-white w-full drop-shadow-lg py-6"
        id="passwordsetting"
        component="form"
        onSubmit={sendResetPasswordLinkHandler}
      >
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Password
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            Please enter your current password to change your password
          </Typography>
        </Box>

        <Divider />

        <Box className=" lg:flex lg:flex-row lg:justify-center gap-6 pt-8 px-4 md:px-4 lg:px-0 space-y-3 md:space-y-0">
          <Box className=" basis-2/5">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              Current Password
            </label>
            <MHTextInput
              id="text"
              type={visibility === true ? "text" : "password"}
              // label="role"
              placeholder=""
              value={enteredOldPassword}
              onChange={oldPasswordInputChangeHandler}
              onBlur={oldPasswordInputBlurHandler}
              // disabled={true}
              className={"bg-white"}
              endAdornment={
                <InputAdornment
                  className="bg-white md:h-[43px] text-navy-900 cursor-pointer"
                  onClick={() => {
                    setVisibility(!visibility);
                  }}
                >
                  {visibility === true ? (
                    <VisibilityOff className="h-4 w-4" />
                  ) : (
                    <Visibility className="h-4 w-4" />
                  )}
                </InputAdornment>
              }
            />
          </Box>

          <Box className=" basis-2/5">
            <label
              className=""
              style={{
                color: "#21392E",
              }}
            >
              New Password
            </label>
            <MHPasswordControl
              label="Enter New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              minLength={8}
              requiredUppercase={true}
              requiredLowercase={true}
              requiredNumber={true}
              requiredSpecialChar={true}
            />
          </Box>
        </Box>

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 pr-[30px] py-6 pl-4 md:pl-0 "
        >
          {/* <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton> */}

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "40%" } }}
            // onClick={sendResetPasswordLinkHandler}
            type="submit"
            fullWidth
            className="rounded-sm"
            loading={loading}
            disabled={!isValidPassword}
          >
            Update Password
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const PinSettings = (props: Props) => {
  const [pin, setpin] = React.useState(0);
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <Box className="bg-white w-full drop-shadow-lg py-6" id="pinsetting">
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Transfer Settings
          </Typography>
        </Box>

        <Divider />

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="center"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 pr-[50px] py-12 pl-12 md:pl-0"
        >
          {pin === 0 ? (
            <MHButton
              sx={{ height: "38px", width: "225px" }}
              onClick={handleOpenDialog}
              fullWidth
              className="rounded-sm"
            >
              Set Transfer PIN
            </MHButton>
          ) : (
            <MHButton
              sx={{ height: "38px", width: "225px" }}
              onClick={handleOpenDialog}
              fullWidth
              className="rounded-sm"
            >
              Change PIN
            </MHButton>
          )}
        </Box>
      </Box>

      {openDialog && (
        <PinDialog open={openDialog} onClose={handleCloseDialog} />
      )}
    </React.Fragment>
  );
};

const ProfileSettings = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="space-y-8">
        <AccountSettings />
        <PasswordSettings />
        <PinSettings />
      </Box>
    </React.Fragment>
  );
};

export default ProfileSettings;
