import { Box, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import {
  CardWidget,
  SubsAllyProps,
  SubsTabPanel,
} from "../../../pages/Landing/Subscribe";
import MHSlider, {
  MHSliderItem,
} from "../../Common/SliderCarousel/Slider/MHSlider";
import React from "react";
import { HttpResponse } from "../../../models/api.interface";
import { SubscriptionData } from "../../../models/subscription.model";
import { formatNumber, getURLWithQueryParams } from "../../../utils/utils";
import useHttp from "../../../hooks/use-http";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import MHButton from "../../Common/Button/MHButton";

import { ReactComponent as CheckIcon } from "../../../static/svg/check.svg";
import RequestConfirmationPrompt from "../../Common/Dialog/RequestConfirmation";
import useDialog from "../../../hooks/use-dialog";
import ConfirmationDialog from "../../Common/Dialog/ConfirmationDialog";
import SnackbarContext from "../../../store/context/snackbar.context";
import DashboardContext from "../../../store/context/dashboard.context";
import { Organization } from "../../../models/employer.model";

const SubsCardWidget = ({
  title,
  description,
  amount,
  benefits,
  handleSubs,
}: {
  title?: string;
  description?: string;
  handleSubs?: string | any;
  //   handleSubs?: () => void;
  amount?: number;
  benefits?: string;
}) => {
  const benString = benefits?.split("##");

  return (
    <Box
      bgcolor={`#fff`}
      height="420px"
      width="300px"
      sx={{
        borderRadius: 5,
        boxShadow: "0px 14px 14px rgba(185, 185, 185, 0.25)",
      }}
      className={`${title === "Enterprise" ? "subscriptionCard" : ""} `}
    >
      <Box height="160px" py={2} px={4}>
        <Box
          bgcolor="#194049"
          py={0.3}
          px={1.5}
          sx={{
            height: "22px",
            width: "46%",
            // width: title === "Household Pro" ? "45%" : "45%",
            borderRadius: 5,
          }}
        >
          <Typography
            variant="subtitle2"
            color="#fff"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="text-center"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "8.2px", sm: "8.2px", md: "8.2px" }}
          lineHeight={{ xs: "200%", sm: "200%" }}
          letterSpacing={"-4%"}
          className="w-[237px] py-3 text-start h-16"
        >
          {description}
        </Typography>
        <Typography
          variant="subtitle1"
          color="primary"
          fontSize={{ xs: "20px", sm: "20px", md: "29px" }}
          lineHeight={{ xs: "160.5%", sm: "160.5%" }}
          letterSpacing={"-4%"}
          className="pt-3 flex items-baseline text-start capitalize text-navy-900"
        >
          {amount ? "₦" + formatNumber(amount) : " Let's Talk"}
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="text-start"
          >
            {amount ? "/ per employee" : ""}
          </Typography>
        </Typography>
      </Box>
      <Divider />
      <Box height="180px" px={4} alignItems="center" alignContent="center">
        {benString?.map((benefit, index) => (
          <Box display="flex" alignItems="center" gap={1} py={1} key={index}>
            <CheckIcon />
            <Typography
              variant="subtitle1"
              color="primary"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              lineHeight={{ xs: "200%", sm: "200%" }}
              letterSpacing={"-4%"}
              className="w-[237px] text-start"
            >
              {benefit}
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box px={2} py={2}>
        <MHButton
          sx={{ height: "38px", width: "270px", color: "white" }}
          className="bg-white text-navy-900 ring-1 ring-navy-900 rounded-md"
          fullWidth
          onClick={handleSubs}
        >
          Get Started
        </MHButton>
      </Box>
    </Box>
  );
};

const EmployerSubscribe = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let history = useHistory();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization, updateEmployerRecord } = dashboardCtx;

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [subsSelected, setSubsSelected] = React.useState(0);
  const { sendHttpRequest: upgradePlanRequest } = useHttp();

  const handleApprovedSubscription = (subsId: number) => {
    setSubsSelected(subsId);
    handleOpenDialog();
  };

  const reqBody = {
    planId: String(subsSelected),
    customerId: organization?.customerId,
  };

  const subsciberUpgrade = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/subscription/subscribe`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        confirmedSubscription();
      } else {
        toast({
          message: "Failed to upgrade plan",
          variant: "error",
        });
      }
    } catch (error) {
      toast({
        message: `An error occurred: ${error}`,
        variant: "error",
      });
      console.error("An error occurred:", error);
      return null;
    }
  };

  const confirmedSubscription = () => {
    if (!updateEmployerRecord) {
      console.error("updateEmployerRecord is not defined in DashboardContext");
      return;
    }

    const updatedOrganization: Organization = {
      ...organization,
      subscriptionType: String(subsSelected),
      // Add other properties as needed
    };
    // console.log(updatedOrganization);
    updateEmployerRecord(updatedOrganization);
    // console.log("subsSelected", subsSelected);

    toast({
      message: "Successfully updated subscription plan",
      variant: "success",
    });

    handleCloseDialog();
    // history.push("/organization/dashboard");
  };

  const location = useLocation();

  const dashboardMatch = matchPath(location.pathname, {
    path: "/",
    exact: true,
    strict: true,
  });

  const { sendHttpRequest } = useHttp();

  const [subscription, setSubscription] = React.useState<SubscriptionData[]>(
    []
  );

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/subscription/plan/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<SubscriptionData[]>) => {
        setSubscription(response.data);
      }
    );
  }, []);

  // const householdSubscription =
  //   subscription &&
  //   subscription.filter((subs) => subs.name?.includes("Household"));

  const householdMonthlySubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name &&
          subs?.name.toLowerCase().includes("household") &&
          subs.duration &&
          subs?.duration === 1
      )
    : [];
  const householdQuarterlySubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name &&
          subs?.name.toLowerCase().includes("household") &&
          subs.duration &&
          subs?.duration === 3
      )
    : [];
  const householdYearlySubscription = subscription
    ? subscription.filter(
        (subs) =>
          subs.name &&
          subs?.name.toLowerCase().includes("household") &&
          subs.duration &&
          subs?.duration === 12
      )
    : [];

  // console.log("Monthly", householdMonthlySubscription);
  // console.log("Quarterly", householdQuarterlySubscription);
  // console.log("Yearly", householdYearlySubscription);

  return (
    <React.Fragment>
      <Box
        sx={{
          paddingTop: "40px",
          marginTop: { xs: "15px", sm: "15px", md: "25px" },
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "100%", sm: "100%", md: "100%" },
          backgroundColor: "#f6f6f6",
          overflow: "hidden",
          overscrollBehaviorY: "hidden",
        }}
        className="subscriptionPlan"
      >
        <Typography
          variant="subtitle2"
          color="primary"
          fontSize={{ xs: "28px", sm: "27px", md: "29px" }}
          lineHeight={{ xs: "130%", sm: "160.5%" }}
          letterSpacing={"-4%"}
          className="py-4 text-center mx-auto md:mx-0 capitalize text-navy-900"
          width={{ xs: "80%", sm: "100%" }}
        >
          Upgrade Your Subscription Plan
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
          lineHeight={{ xs: "200%", sm: "200%" }}
          letterSpacing={"-4%"}
          className="py-4 text-center w-[80%] md:w-[35%] capitalize mx-auto text-navy-900"
        >
          We believe managing your household employees should be
          straightforward, transparent, and secure.
        </Typography>

        <Box sx={{ width: "100%" }} className="my-6">
          <Tabs
            value={value}
            className="font-areaNorm place-items-center mx-auto place-content-center"
            onChange={handleChange}
            sx={{
              width: { xs: "90%", sm: "70%" },
              display: "flex",
              bgcolor: "#FFFFFF",
              borderRadius: "20px",
              justifyItems: "center",
              justifyContent: "center",
              justifySelf: "center",
              padding: "2px",
              border: "2px solid #D2E0CB",
            }}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <Tab
              className={`rounded-[20px] text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-fit  flex-1 ${
                value === 0
                  ? "bg-[#194049] text-white"
                  : "bg-[#FFFFFF] text-[#194049]"
              }`}
              label="Monthly"
              disableRipple
              {...SubsAllyProps(0)}
            />
            <Tab
              className={`rounded-[20px] text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-fit  flex-1 ${
                value === 1
                  ? "bg-[#194049] text-white"
                  : "bg-[#FFFFFF] text-[#194049]"
              }`}
              label="Quarterly"
              disableRipple
              {...SubsAllyProps(1)}
            />
            <Tab
              className={`rounded-[20px] text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-fit  flex-1 ${
                value === 2
                  ? "bg-[#194049] text-white"
                  : "bg-[#FFFFFF] text-[#194049]"
              }`}
              label="Annually"
              disableRipple
              {...SubsAllyProps(2)}
            />
          </Tabs>

          <Box my={10}>
            <SubsTabPanel value={value} index={0}>
              <Grid
                container
                spacing={{ xs: 4, sm: 1 }}
                px={{ xs: "0px", sm: "40px" }}
                display="flex"
                justifyContent="center"
                justifyItems="center"
                className=" hidden md:flex"
              >
                {householdMonthlySubscription &&
                  householdMonthlySubscription.map((subs: any, index) => (
                    <Grid
                      item
                      xs={10.5}
                      sm={5.5}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      key={index}
                    >
                      <SubsCardWidget
                        title={subs.name}
                        description={subs.description}
                        amount={subs.price}
                        benefits={subs.benefit}
                        handleSubs={() => handleApprovedSubscription(subs.id)} // Change here
                      />
                    </Grid>
                  ))}
                {/* <SubsCardWidget
                  title="Enterprise"
                  description="(perfect for businesses and corporations as a benefit)"
                  amount={0}
                  benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                /> */}
              </Grid>

              <MHSlider
                slidesToShow={dashboardMatch ? 1 : 3}
                slidesToScroll={1}
                className="md:hidden place-self-center mx-auto w-[80%]"
              >
                {[
                  ...(householdMonthlySubscription
                    ? householdMonthlySubscription.map((subs: any, index) => (
                        <MHSliderItem key={index} width="100%">
                          <SubsCardWidget
                            title={subs.name}
                            description={subs.description}
                            amount={subs.price}
                            benefits={subs.benefit}
                            handleSubs={() =>
                              handleApprovedSubscription(subs.id)
                            } // Change here
                          />
                        </MHSliderItem>
                      ))
                    : []),
                  //   <MHSliderItem key="enterprise" width="100%">
                  //     <SubsCardWidget
                  //       title="Enterprise"
                  //       description="(perfect for businesses and corporations as a benefit)"
                  //       amount={0}
                  //       benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  //       linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                  //     />
                  //   </MHSliderItem>,
                ]}
              </MHSlider>
            </SubsTabPanel>
            <SubsTabPanel value={value} index={1}>
              <Grid
                container
                spacing={{ xs: 4, sm: 1 }}
                px={{ xs: "0px", sm: "40px" }}
                display="flex"
                justifyContent="center"
                justifyItems="center"
                className=" hidden md:flex"
              >
                {householdQuarterlySubscription &&
                  householdQuarterlySubscription.map((subs: any, index) => (
                    <Grid
                      item
                      xs={10.5}
                      sm={5.5}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      key={index}
                    >
                      <SubsCardWidget
                        title={subs.name}
                        description={subs.description}
                        amount={subs.price}
                        benefits={subs.benefit}
                        handleSubs={() => handleApprovedSubscription(subs.id)} // Change here
                      />
                    </Grid>
                  ))}
                {/* <SubsCardWidget
                  title="Enterprise"
                  description="(perfect for businesses and corporations as a benefit)"
                  amount={0}
                  benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                /> */}
              </Grid>

              <MHSlider
                slidesToShow={dashboardMatch ? 1 : 3}
                slidesToScroll={1}
                className="md:hidden place-self-center mx-auto w-[80%]"
              >
                {[
                  ...(householdQuarterlySubscription
                    ? householdQuarterlySubscription.map((subs: any, index) => (
                        <MHSliderItem key={index} width="100%">
                          <SubsCardWidget
                            title={subs.name}
                            description={subs.description}
                            amount={subs.price}
                            benefits={subs.benefit}
                            handleSubs={() =>
                              handleApprovedSubscription(subs.id)
                            } // Change here
                          />
                        </MHSliderItem>
                      ))
                    : []),
                  //   <MHSliderItem key="enterprise" width="100%">
                  //     <SubsCardWidget
                  //       title="Enterprise"
                  //       description="(perfect for businesses and corporations as a benefit)"
                  //       amount={0}
                  //       benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  //       linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                  //     />
                  //   </MHSliderItem>,
                ]}
              </MHSlider>
            </SubsTabPanel>
            <SubsTabPanel value={value} index={2}>
              <Grid
                container
                spacing={{ xs: 4, sm: 1 }}
                px={{ xs: "0px", sm: "40px" }}
                display="flex"
                justifyContent="center"
                justifyItems="center"
                className=" hidden md:flex"
              >
                {householdYearlySubscription &&
                  householdYearlySubscription.map((subs: any, index) => (
                    <Grid
                      item
                      xs={10.5}
                      sm={5.5}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      key={index}
                    >
                      <SubsCardWidget
                        title={subs.name}
                        description={subs.description}
                        amount={subs.price}
                        benefits={subs.benefit}
                        handleSubs={() => handleApprovedSubscription(subs.id)} // Change here
                      />
                    </Grid>
                  ))}

                {/* <SubsCardWidget
                  title="Enterprise"
                  description="(perfect for businesses and corporations as a benefit)"
                  amount={0}
                  benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                /> */}
              </Grid>

              <MHSlider
                slidesToShow={dashboardMatch ? 1 : 3}
                slidesToScroll={1}
                className="md:hidden place-self-center mx-auto w-[80%]"
              >
                {[
                  ...(householdYearlySubscription
                    ? householdYearlySubscription.map((subs: any, index) => (
                        <MHSliderItem key={index} width="100%">
                          <SubsCardWidget
                            title={subs.name}
                            description={subs.description}
                            amount={subs.price}
                            benefits={subs.benefit}
                            handleSubs={() =>
                              handleApprovedSubscription(subs.id)
                            } // Change here
                          />
                        </MHSliderItem>
                      ))
                    : []),
                  //   <MHSliderItem key="enterprise" width="100%">
                  //     <SubsCardWidget
                  //       title="Enterprise"
                  //       description="(perfect for businesses and corporations as a benefit)"
                  //       amount={0}
                  //       benefits={`All Household Pro features##Personalized consultation##Bulk Employee enrollment##Employee Benefit Integration`}
                  //       linkTo="https://calendly.com/motherhonestlygroup/caringblocks"
                  //     />
                  //   </MHSliderItem>,
                ]}
              </MHSlider>
            </SubsTabPanel>
          </Box>
        </Box>
      </Box>

      {openDialog && (
        <ConfirmationDialog
          content={` Are you sure you want to subscribe to this plan?`}
          open={openDialog}
          onClose={handleCloseDialog}
          handleApprove={subsciberUpgrade}
          handleCancel={handleCloseDialog}
        />
      )}
    </React.Fragment>
  );
};

export default EmployerSubscribe;
