import React, { useState } from "react";
import ProfileSubHeader from "./ProfileSubHeader";
import { Box, Button, Divider, Grid, Link, Typography } from "@mui/material";
import { EmployeeData } from "../../../models/employee.model";
import MHButton from "../../Common/Button/MHButton";

import { ReactComponent as BackgroundCheckIcon } from "../../../static/svg/checks/background.svg";
import { ReactComponent as BackgroundCheckDisabledIcon } from "../../../static/svg/checks/background-disabled.svg";
import { ReactComponent as DrugCheckIcon } from "../../../static/svg/checks/drug.svg";
import { ReactComponent as DrugCheckDisabledIcon } from "../../../static/svg/checks/drug-disabled.svg";
import { ReactComponent as VisionCheckIcon } from "../../../static/svg/checks/vision.svg";
import { ReactComponent as VisionCheckDisabledIcon } from "../../../static/svg/checks/vision-disabled.svg";
import { ReactComponent as MedicalCheckIcon } from "../../../static/svg/checks/medical.svg";
import { ReactComponent as MedicalCheckDisabledIcon } from "../../../static/svg/checks/medical-disabled.svg";
import { ReactComponent as CommCheckIcon } from "../../../static/svg/checks/comm.svg";
import { ReactComponent as CommCheckDisabledIcon } from "../../../static/svg/checks/comm-disabled.svg";
import { ReactComponent as ReferenceCheckIcon } from "../../../static/svg/checks/reference.svg";
import { ReactComponent as ReferenceCheckDisabledIcon } from "../../../static/svg/checks/reference-disabled.svg";
import { ReactComponent as ChildcareCheckIcon } from "../../../static/svg/checks/childcare.svg";
import { ReactComponent as ChildcareCheckDisabledIcon } from "../../../static/svg/checks/childcare-disabled.svg";
import { ReactComponent as DriveCheckIcon } from "../../../static/svg/checks/drive.svg";
import { ReactComponent as DriveCheckDisabledIcon } from "../../../static/svg/checks/drive-disabled.svg";
import { ReactComponent as CriminalCheckIcon } from "../../../static/svg/checks/criminal.svg";
import { ReactComponent as CriminalCheckDisabledIcon } from "../../../static/svg/checks/criminal-disabled.svg";
import { ReactComponent as NairaIcon } from "../../../static/svg/naira.svg";

import useDialog from "../../../hooks/use-dialog";
import MHCheckboxContent from "../../Common/Form/MHCheckboxContent";
import CBDialog from "../../Common/Dialog/CBDialog";
import AssignedTraining from "./AssignedTraining";
import EmployeeCertificates from "./EmployeeCertificates";
import MHRadioContent from "../../Common/Form/MHRadioContent";
import useInput from "../../../hooks/use-input";
import * as validators from "../../../utils/validators";
import RunCheckDialog from "../../Common/Dialog/RunCheckDialog";
import SnackbarContext from "../../../store/context/snackbar.context";
import { ASSESSDATA } from "../../../utils/assessment";

type EmployeeProps = {
  employee: EmployeeData | null;
};

interface CheckState {
  [key: string]: boolean;
}

type HealthCheck = {
  medic: boolean;
  vaccine: boolean;
  disease: boolean;
  vision: boolean;
  drug: boolean;
};

type BGCheck = {
  reference: boolean;
  driving: boolean;
  childAbuse: boolean;
  criminal: boolean;
};

interface CheckWidgetProps {
  condition: boolean;
  enabled: React.ReactNode;
  disabled: React.ReactNode;
  label: string;
}

type BgAmount = {
  [key: string]: number; // Keys are strings, values are numbers
};

const healthArray = [
  {
    label: "Medical History & Physical Examination (via HealthTracka)",
    amount: 2500,
    location: "healthCheck.medic", //can only be used within Component
    place: "medic",
  },
  {
    label: "Vaccination Verification",
    amount: 2000,
    location: "healthCheck.vaccine",
    place: "vaccine",
  },
  {
    label: "Communicable Disease Screening (via HealthTracka)",
    amount: 3000,
    location: "healthCheck.disease",
    place: "disease",
  },
  {
    label: "Vision Screening Test",
    amount: 3500,
    location: "healthCheck.vision",
    place: "vision",
  },
  {
    label: "Drug And Alcohol Test",
    amount: 10000,
    location: "healthCheck.drug",
    place: "drug",
  },
];

const CheckWidget: React.FC<CheckWidgetProps> = ({
  condition,
  enabled,
  disabled,
  label,
}) => (
  <Box
    className="grid"
    sx={{
      width: "100%",
    }}
    justifyContent="center"
    justifyItems="center"
    alignContent="center"
    gap={1.5}
  >
    <Box justifyContent="center" justifyItems="center" alignContent="center">
      {condition ? enabled : disabled}
    </Box>
    <Typography
      variant="subtitle2"
      fontSize={{ xs: "10px", sm: "12px" }}
      lineHeight="120%"
      align="center"
      color={condition ? "primary" : "#A9A9A9"}
      // whiteSpace="nowrap"
      // overflow="hidden"
      // textOverflow="ellipsis"
      className=" text-center w-[80%]"
    >
      {label}
    </Typography>
  </Box>
);

export const bgArray = [
  {
    label: "ID Verification",
    amount: 2000,
    location: "bgCheck.id",
    place: "id",
  },
  {
    label: "Address Verification",
    amount: 5000,
    location: "bgCheck.address",
    place: "address",
  },
  {
    label: "Guarantor Verification",
    amount: 4000,
    location: "bgCheck.guarantor",
    place: "guarantor",
  },
  {
    label: "Employment Verification",
    amount: 4000,
    location: "bgCheck.employment",
    place: "employment",
  },
  {
    label: "Education Verification",
    amount: 4000,
    location: "bgCheck.education",
    place: "education",
  },
];

const SummaryView = ({ employee }: EmployeeProps) => {
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    openDialog: openDialog2,
    handleOpenDialog: handleOpenDialog2,
    handleCloseDialog: handleCloseDialog2,
  } = useDialog();

  // const [clickState, setClickState] = useState(false);

  const [healthCheck, setHealthCheck] = useState<CheckState>({
    medic: false,
    vaccine: false,
    disease: false,
    vision: false,
    drug: false,
  });

  const [bgCheck, setBgCheck] = useState<CheckState>({
    id: false,
    address: false,
    guarantor: false,
    employment: false,
    education: false,
  });

  React.useEffect(() => {}, [healthCheck, bgCheck]);

  const checker = false;

  const {
    value: enteredHealth,
    valid: enteredHealthIsValid,
    error: healthInputHasError,
    onChange: healthInputChangeHandler,
    onBlur: healthInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  // console.log("bgCheck", bgCheck);
  // console.log("healthCheck", healthCheck);

  const [healthAmount, setHealthAmount] = useState(0);
  const [healthText, setHealthText] = useState("");
  const initialBgAmount: BgAmount = bgArray.reduce((acc, item) => {
    acc[item.place] = 0; // Initialize all with 0
    return acc;
  }, {} as BgAmount); // Explicitly cast to BgAmount

  const [bgAmount, setBgAmount] = useState<BgAmount>(initialBgAmount);

  // console.log("bgAmount", bgAmount);

  const handleHealthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // const description = event.target.text;
    healthInputChangeHandler(event);

    if (value === "Nanny / Housekeeper") {
      setHealthAmount(35000);
      setHealthText(
        "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype,, and BP | N35,000"
      );
    } else if (value === "Drivers & Security") {
      setHealthAmount(50000);
      setHealthText(
        "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype, and BP,. Drug Screening, Vision Test, Hearing Test, and Diabetes Screening"
      );
    } else if (value === "Cook / Chef") {
      setHealthAmount(45000);
      setHealthText(
        "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy Test, Genotype Test, and BP, Stool Test, Drug Screening"
      );
    } else if (value === "Home Tutors / Others") {
      setHealthAmount(40000);
      setHealthText(
        "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Drug Screening"
      );
    }
  };

  const reqHealth = {
    customerId: employee?.customerId,
    employerRefId: employee?.employerRefId,
    vaccination: false,
    vaccinationCost: 0,
    communicableDiseaseTest: false,
    commDiseaseTestCost: 0,
    vasionScreenTest: false,
    vasionScreenTestCost: 0,
    drugTest: false,
    drugTestCost: 0,
    medHistoryCheck: healthAmount > 0 ? true : false,
    medHistoryCheckCost: healthAmount,
    description: healthText,
  };

  const reqBackg = {
    customerId: employee?.customerId,
    employerRefId: employee?.employerRefId,
    idCheck: bgAmount.id > 0 ? true : false,
    idCost: bgAmount.id,
    addressCheck: bgAmount.address > 0 ? true : false,
    addressCost: bgAmount.address,
    educationCheck: bgAmount.education > 0 ? true : false,
    educationCost: bgAmount.education,
    employmentCheck: bgAmount.employment > 0 ? true : false,
    employmentCost: bgAmount.employment,
    guarantorCheck: bgAmount.guarantor > 0 ? true : false,
    guarantorCost: bgAmount.guarantor,
  };

  const handleBgVerification = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/background-check/store`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBackg),
      });

      if (response.ok) {
        // Handle success
        const successData = await response.json();

        handleCloseDialog2();
        toast({
          variant: "success",
          message: "Background check request successful!",
        });
      } else {
        handleCloseDialog2();
        const errorData = await response.json();
        console.error("Request submission failed:", errorData);
        toast({
          variant: "error",
          message: `${errorData.error}`,
        });
      }
    } catch (error) {
      handleCloseDialog2();
      console.error("An error occurred:", error);
      toast({
        variant: "error",
        message: " An error occurred",
      });
    }
  };
  const handleHealthVerification = async () => {
    const url =
      process.env.REACT_APP_API_BASE_URL + `/background-check/health/store`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqHealth),
      });

      if (response.ok) {
        // Handle success
        const successData = await response.json();
        handleCloseDialog();
        toast({
          variant: "success",
          message: "Health verification request successful!",
        });
      } else {
        handleCloseDialog();
        const errorData = await response.json();
        console.error("Request submission failed:", errorData);
        toast({
          variant: "error",
          message: `${errorData.error}`,
        });
      }
    } catch (error) {
      handleCloseDialog();
      console.error("An error occurred:", error);
      toast({
        variant: "error",
        message: " An error occurred",
      });
    }
  };

  const bgChecker = () => {
    // console.log("reqBackg", reqBackg);
    handleBgVerification();
  };
  const healthChecker = () => {
    // console.log("reqHealth", reqHealth);
    handleHealthVerification();
    // console.log("Health Assessment For", enteredHealth);
    // console.log("Health Assessment Amount", healthAmount);
  };

  const selectedTest = ASSESSDATA.filter((item) => {
    if (item.value === employee?.jobTitle) {
      return true; // Include the item if it matches the job title
    }
    return item.value === "other"; // Fallback to "other" if no match
  });

  let employeeTest = selectedTest[0];
  let testArr = employeeTest.text.split("##");

  // console.log("employee", employee);
  // console.log("employeeTest", employeeTest);
  // console.log("testArr", testArr);

  return (
    <React.Fragment>
      <Divider />

      <ProfileSubHeader employee={employee} />

      <Box className=" border-2 mt-4 lg:h-[204px] border-[#D9D9D9] rounded-md overflow-hidden">
        <Box className=" justify-around md:justify-between flex items-center md:px-4 w-full h-[64px] rounded-t-md bg-[#F9F9F7] border-b-[2px] border-[#D9D9D9]">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "14px", sm: "14px" }}
            lineHeight="150%"
            align="left"
            color="primary"
            whiteSpace="nowrap" // Prevents text from wrapping
            overflow="hidden" // Hides any overflow
            textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
            // className="  justify-start"
          >
            Health Assessments
          </Typography>
          <Box className=" " width={{ xs: "full", sm: "189px" }}>
            <MHButton
              sx={{}}
              onClick={handleOpenDialog}
              fullWidth
              //   startIcon={<EditIcon />}
              className=" w-[120px]  md:w-[189px] h-[32px]  rounded-md"
            >
              Run Check
            </MHButton>
          </Box>
          {/* Web Check */}
        </Box>
        <Box
          bgcolor={"#fff"}
          className=" hidden md:flex md:justify-evenly items-center p-4 md:p-0 h-full md:h-[140px]"
        >
          <CheckWidget
            condition={checker}
            enabled={
              <BackgroundCheckIcon className="col-span-5 w-28 md:w-32" />
            }
            disabled={
              <BackgroundCheckDisabledIcon className="col-span-5 w-28 md:w-32" />
            }
            label="Background Check"
          />
          <CheckWidget
            condition={checker}
            enabled={<DrugCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <DrugCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Drug And Alcohol Test"
          />
          <CheckWidget
            condition={checker}
            enabled={<VisionCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <VisionCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Vision Screening Test"
          />

          <CheckWidget
            condition={checker}
            enabled={<MedicalCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <MedicalCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Medical History & Physical Examination"
          />
          <CheckWidget
            condition={checker}
            enabled={<CommCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <CommCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Communicable Disease Screening"
          />
        </Box>
        {/* Mobile Check */}
        <Box bgcolor={"#fff"} className=" flex md:hidden lg:hidden py-2">
          <CheckWidget
            condition={checker}
            enabled={
              <BackgroundCheckIcon className="col-span-5 w-28 md:w-32" />
            }
            disabled={
              <BackgroundCheckDisabledIcon className="col-span-5 w-28 md:w-32" />
            }
            label="Background Check"
          />
          <CheckWidget
            condition={checker}
            enabled={<DrugCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <DrugCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Drug And Alcohol Test"
          />
          <CheckWidget
            condition={checker}
            enabled={<VisionCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <VisionCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Vision Screening Test"
          />
        </Box>
        {/* Mobile Check */}
        <Box bgcolor={"#fff"} className=" flex md:hidden lg:hidden py-2">
          <CheckWidget
            condition={checker}
            enabled={<MedicalCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <MedicalCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Medical History & Physical Examination"
          />
          <CheckWidget
            condition={checker}
            enabled={<CommCheckIcon className=" col-span-5 w-28 md:w-32" />}
            disabled={
              <CommCheckDisabledIcon className=" col-span-5 w-28 md:w-32" />
            }
            label="Communicable Disease Screening"
          />
        </Box>
      </Box>
      <Box className=" border-2 mt-4 lg:h-[204px] border-[#D9D9D9] rounded-md overflow-hidden">
        <Box className=" justify-around md:justify-between flex items-center md:px-4 w-full h-[64px] rounded-t-md bg-[#F9F9F7] border-b-[2px] border-[#D9D9D9]">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "14px", sm: "14px" }}
            lineHeight="150%"
            align="left"
            color="primary"
            whiteSpace="nowrap" // Prevents text from wrapping
            overflow="hidden" // Hides any overflow
            textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
            // className="  justify-start"
          >
            Background Check
          </Typography>
          <Box className=" " width={{ xs: "full", sm: "189px" }}>
            <MHButton
              sx={{}}
              onClick={handleOpenDialog2}
              fullWidth
              //   startIcon={<EditIcon />}
              className=" w-[120px]  md:w-[189px] h-[32px]  rounded-md"
            >
              Run Check
            </MHButton>
          </Box>
        </Box>

        {/* Web Check */}
        <Box
          bgcolor={"#fff"}
          className=" hidden md:flex md:justify-evenly items-center p-4 md:p-0 h-full md:h-[140px]"
        >
          <CheckWidget
            condition={checker}
            enabled={
              <ReferenceCheckIcon className=" col-span-5 w-24 md:w-32 " />
            }
            disabled={
              <ReferenceCheckDisabledIcon className=" col-span-5 w-24 md:w-32 " />
            }
            label="Reference Checks "
          />
          <CheckWidget
            condition={checker}
            enabled={<DriveCheckIcon className=" col-span-5  w-28 md:w-32 " />}
            disabled={
              <DriveCheckDisabledIcon className=" col-span-5  w-28 md:w-32 " />
            }
            label="Driving Record Check "
          />
          <CheckWidget
            condition={checker}
            enabled={
              <ChildcareCheckIcon className=" col-span-5  w-32 md:w-40" />
            }
            disabled={
              <ChildcareCheckDisabledIcon className=" col-span-5  w-32 md:w-40" />
            }
            label="Child Abuse Registry Check"
          />
          <CheckWidget
            condition={checker}
            enabled={
              <CriminalCheckIcon className=" col-span-5  w-40 md:w-[180px]" />
            }
            disabled={
              <CriminalCheckDisabledIcon className=" col-span-5  w-40 md:w-[180px]" />
            }
            label="Criminal Records Registry Check"
          />
        </Box>
        {/* Mobile Check */}
        <Box bgcolor={"#fff"} className=" md:hidden lg:hidden flex py-2">
          <CheckWidget
            condition={checker}
            enabled={
              <ReferenceCheckIcon className=" col-span-5 w-24 md:w-32 " />
            }
            disabled={
              <ReferenceCheckDisabledIcon className=" col-span-5 w-24 md:w-32 " />
            }
            label="Reference Checks "
          />
          <CheckWidget
            condition={checker}
            enabled={<DriveCheckIcon className=" col-span-5  w-28 md:w-32 " />}
            disabled={
              <DriveCheckDisabledIcon className=" col-span-5  w-28 md:w-32 " />
            }
            label="Driving Record Check "
          />
        </Box>
        {/* Mobile Check */}
        <Box bgcolor={"#fff"} className=" md:hidden lg:hidden flex py-2">
          <CheckWidget
            condition={checker}
            enabled={
              <ChildcareCheckIcon className=" col-span-5  w-32 md:w-40" />
            }
            disabled={
              <ChildcareCheckDisabledIcon className=" col-span-5  w-32 md:w-40" />
            }
            label="Child Abuse Registry Check"
          />
          <CheckWidget
            condition={checker}
            enabled={
              <CriminalCheckIcon className=" col-span-5  w-40 md:w-[180px]" />
            }
            disabled={
              <CriminalCheckDisabledIcon className=" col-span-5  w-40 md:w-[180px]" />
            }
            label="Criminal Records Registry Check"
          />
        </Box>
      </Box>

      {/* Training & Certificates */}
      <Box className="  lg:flex lg:justify-between my-4 ">
        <AssignedTraining employee={employee} />
        <EmployeeCertificates employee={employee} />
      </Box>
      {/*  */}

      <RunCheckDialog
        open={openDialog}
        title={`Health Check`}
        handleClose={handleCloseDialog}
      >
        <Box p={2}>
          <Box
            className={`h-[480px] md:h-[450px] ring-1 ring-[#009688] rounded-lg py-6 px-2 mb-3 md:mb-0`}
            // ${
            //   clickState === false
            //     ? "grayscale-0 opacity-100"
            //     : "grayscale opacity-50"
            // }
          >
            <Typography
              variant="h1"
              fontSize={{ xs: "18px", sm: "20px", md: "20px" }}
              component="div"
              gutterBottom
              mb={3}
              className=" capitalize"
            >
              {/* Health Assessment */}
              {employee?.jobTitle}
            </Typography>
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              component="div"
              gutterBottom
              mb={5}
            >
              Health Assessments are important to safeguard your loved ones, and
              verify your household employee's fitness to work.
            </Typography>

            <Typography
              variant="caption"
              fontSize={{ xs: "14px", sm: "16px", md: "16px" }}
              component="div"
              gutterBottom
              mb={3}
              className=" capitalize text-center"
            >
              Overview
            </Typography>

            <Grid
              container
              spacing={{ xs: 0, md: 2, mb: 5 }}
              sx={{ px: { xs: 0, sm: 2 } }}
            >
              <Grid item xs={12} md={6}>
                {testArr
                  .filter((item: any, index) => index % 2 === 0)
                  .map((item: any) => (
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                      lineHeight={{ xs: "22px", sm: "22px" }}
                      component="div"
                      gutterBottom
                      mb={1}
                    >
                      ~ {item}
                    </Typography>
                  ))}
              </Grid>

              <Grid item xs={12} md={6}>
                {testArr
                  .filter((item: any, index) => index % 2 !== 0)
                  .map((item: any) => (
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                      lineHeight={{ xs: "22px", sm: "22px" }}
                      component="div"
                      gutterBottom
                      mb={1}
                    >
                      ~ {item}
                    </Typography>
                  ))}
              </Grid>
            </Grid>
            {/* <Typography
              variant="caption"
              fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              component="div"
              gutterBottom
              mb={1}
              className=" absolute bottom-1 right-1"
            >
              Cost: {employeeTest.amount}
            </Typography> */}

            <Box
              mt={4}
              mx={8}
              // width="193px"
              p={2}
              borderRadius={2}
              bgcolor="#D2E0CB"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden" // Prevents content overflow from floating out of the box
            >
              <Button disabled>
                <Typography
                  variant="h1"
                  fontSize={{ xs: "20px", sm: "24px" }}
                  // lineHeight="100%"
                  gap={2}
                  align="center"
                  alignItems="center"
                  display="flex"
                  color="primary"
                  whiteSpace="nowrap" // Prevents text from wrapping
                  overflow="hidden" // Hides any overflow
                  textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                >
                  Total:
                  <Box display="flex" alignItems="center">
                    <NairaIcon className=" ml-2 w-6 h-6" />
                    <Typography
                      variant="h1"
                      fontSize={{ xs: "24px", sm: "28px" }}
                      // lineHeight="100%"
                      gap={2}
                      align="center"
                      alignItems="center"
                      display="flex"
                      color="primary"
                      whiteSpace="nowrap" // Prevents text from wrapping
                      overflow="hidden" // Hides any overflow
                      textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                    >
                      {employeeTest.amount}
                    </Typography>
                  </Box>
                </Typography>
              </Button>
            </Box>

            {/* <Box className=" space-y-6">
                {healthArray.map((item, index) => (
                  <MHCheckboxContent
                    key={index}
                    label={item.label}
                    amount={item.amount}
                    value={healthCheck[item.place]}
                    // value={item.location}
                    handleCheck={(e) => {
                      setHealthCheck((prevHealthCheck) => ({
                        ...prevHealthCheck,
                        [item.place]: e.target.checked,
                      }));
                    }}
                  />
                ))}
              </Box> */}
            {/* <MHRadioContent
              row
              name="allocation-period"
              id="allocation-period"
              options={[
                {
                  value: "Nanny / Housekeeper",
                  amount: 35000,
                  label: "Nanny / Housekeeper",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype,, and BP | N35,000",
                },
                {
                  value: "Drivers & Security",
                  amount: 50000,
                  label: "Drivers & Security",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype, and BP,. Drug Screening, Vision Test, Hearing Test, and Diabetes Screening",
                },
                {
                  value: "Cook / Chef",
                  amount: 45000,
                  label: "Cook / Chef",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy Test, Genotype Test, and BP, Stool Test, Drug Screening",
                },
                {
                  value: "Home Tutors / Others",
                  amount: 40000,
                  label: "Home Tutors / Others",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Drug Screening",
                },
              ]}
              value={enteredHealth}
              onChange={handleHealthChange}
              // color="default"
              controlSx={{
                mb: 0,
              }}
            /> */}
          </Box>

          <Box display="flex" justifyContent="center" my={4}>
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className=" w-[215px]"
              // startIcon={<PlusIcon />}
              onClick={healthChecker}
              // onClick={() =>
              //   history.push("/organization/employee-onboarding")
              // }
            >
              Run Check
            </MHButton>
          </Box>
        </Box>
      </RunCheckDialog>
      <RunCheckDialog
        open={openDialog2}
        title={"Background Check "}
        handleClose={handleCloseDialog2}
      >
        <Box p={2}>
          <Box
            className={`h-[480px] md:h-[450px] ring-1 ring-[#009688] rounded-lg py-6 px-2 mb-3 md:mb-0 
              
            `}
            // ${
            //   clickState === true
            //     ? "grayscale-0 opacity-100"
            //     : "grayscale opacity-50"
            // }
          >
            <Typography
              variant="h1"
              fontSize={{ xs: "18px", sm: "20px", md: "20px" }}
              component="div"
              gutterBottom
              mb={3}
              className=" capitalize"
            >
              {/* Background Check */}
              {employee?.jobTitle}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              component="div"
              gutterBottom
              mb={5}
            >
              Conducting thorough background checks to verify the staff's
              identity, criminal history, and past employment records.
            </Typography>

            <Box className=" space-y-6" px={1.5}>
              {bgArray.map((item, index) => (
                <MHCheckboxContent
                  key={index}
                  label={item.label}
                  amount={item.amount}
                  value={bgCheck[item.place]}
                  // value={item.location}
                  handleCheck={(e) => {
                    setBgCheck((prevBgCheck) => ({
                      ...prevBgCheck,
                      [item.place]: e.target.checked,
                    }));
                    setBgAmount((prev) => ({
                      ...prev,
                      [item.place]: e.target.checked ? item.amount : 0, // Update amount based on check status
                    }));
                  }}
                />
              ))}
              {/* 
                <MHCheckboxContent
                  label="Reference Checks"
                  amount={2500}
                  value={bgCheck.reference}
                  handleCheck={(e) =>
                    setBgCheck({ ...bgCheck, reference: e.target.checked })
                  }
                />
              */}
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" my={4}>
          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className=" w-[215px]"
            // startIcon={<PlusIcon />}
            onClick={bgChecker}
            // onClick={() =>
            //   history.push("/organization/employee-onboarding")
            // }
          >
            Run Check
          </MHButton>
        </Box>
      </RunCheckDialog>
    </React.Fragment>
  );
};

export default SummaryView;
