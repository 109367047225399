import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Tabs } from "@mui/material";
import { EmployeeData } from "../../../models/employee.model";
import SummaryView from "./SummaryView";
import PerformanceView from "./PerformanceView";
import ReportView from "./ReportView";
import TransactionList from "./TransactionsList";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type EmployeeProps = {
  employee: EmployeeData | null;
};

export default function EmployeeProfileTab({ employee }: EmployeeProps) {
  const [value, setValue] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [indicatorWidth, setIndicatorWidth] = React.useState(0);
  const tabIndicatorRef = React.useRef<HTMLDivElement>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (tabIndicatorRef.current) {
      const selectedTab = document.querySelector(
        `.MuiTab-root:nth-child(${value + 1})`
      ) as HTMLElement;
      setIndicatorWidth(selectedTab.offsetWidth);
    }
  }, [value]);

  return (
    <Box>
      <Tabs
        value={value}
        className="font-areaNorm"
        onChange={handleChange}
        aria-label="basic tabs example-1-1"
      >
        <Tab
          className=" md:mr-0 -ml-4 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Summary"
          disableRipple
          {...a11yProps(0)}
        />
        <Tab
          className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Performance"
          disableRipple
          {...a11yProps(1)}
        />
        <Tab
          className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Report"
          disableRipple
          {...a11yProps(2)}
        />
        {/* <Tab
          className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Transactions"
          disableRipple
          {...a11yProps(3)}
        /> */}
      </Tabs>

      <Box my={3}>
        <TabPanel value={value} index={0}>
          <SummaryView employee={employee} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PerformanceView employee={employee} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ReportView employee={employee} />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <TransactionList employee={employee} />
        </TabPanel> */}
      </Box>
    </Box>
  );
}
