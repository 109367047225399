import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AuthContext from "../store/context/auth-context";

// Styled Wrapper Component
const Wrapper = styled("div")<{ noWrapper?: boolean }>(
  ({ theme }) => ({
    padding: theme.spacing(3),
  }),
  ({ noWrapper }) =>
    noWrapper && {
      padding: 0,
    }
);

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  noWrapper?: boolean; // Allow passing noWrapper prop
}

const EmployerProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  noWrapper,
  ...rest
}) => {
  const authCtx = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        authCtx.isAuthenticated && authCtx.role === "Employer" ? (
          noWrapper ? (
            <Component {...props} />
          ) : (
            <Wrapper>
              <Component {...props} />
            </Wrapper>
          )
        ) : (
          <Redirect to="/auth/sign-in" />
        )
      }
    />
  );
};

const EmployeeProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  noWrapper,
  ...rest
}) => {
  const authCtx = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        authCtx.isAuthenticated && authCtx.role === "Employee" ? (
          noWrapper ? (
            <Component {...props} />
          ) : (
            <Wrapper>
              <Component {...props} />
            </Wrapper>
          )
        ) : (
          <Redirect to="/auth/sign-in" />
        )
      }
    />
  );
};

export { EmployerProtectedRoute, EmployeeProtectedRoute };
