import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  convertTo12Hour,
  getURLWithQueryParams,
  isEmpty,
} from "../../../utils/utils";
import { matchPath, useLocation } from "react-router-dom";
import TrainingCard, { TrainingData } from "./TrainingCard";
import moment from "moment";
import Pagination from "../../Common/UI/Pagination";
import MHButton from "../../Common/Button/MHButton";
import useDialog from "../../../hooks/use-dialog";
import { EmployeeData } from "../../../models/employee.model";
import { HttpResponse } from "../../../models/api.interface";
import useHttp from "../../../hooks/use-http";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";

type Props = {
  selectedRole?: string | any;
  dataIn?: TrainingData[];
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a11yPropsFilter(index: number) {
  return {
    id: `simple-filter-tab-${index}`,
    "aria-controls": `simple-filter-tabpanel-${index}`,
  };
}

const ReusedPanels = ({
  trainingData,
  handleSelectedMode,
}: {
  trainingData?: string | any;
  handleSelectedMode: (label: string) => void;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  // Explicitly type the orientation
  let orientation: "horizontal" | "vertical" = "horizontal"; // Default orientation
  if (isMdUp) {
    orientation = "vertical";
  }

  //   Training Pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(6);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;

  const currentTrainingPost = trainingData.slice(firstPostIndex, lastPostIndex);

  const [selectedChecks, setSelectedChecks] = React.useState<any[]>([]);
  const [valueFilter, setValueFilter] = React.useState(0);
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;
  const { loading, error, sendHttpRequest } = useHttp();

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  const handleChangeFilter = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueFilter(newValue);
  };

  const handleAssignCheck = (course: any) => {
    setSelectedChecks((prevSelectedChecks) => {
      if (prevSelectedChecks.some((c) => c.id === course.id)) {
        return prevSelectedChecks.filter((c) => c.id !== course.id);
      } else {
        return [...prevSelectedChecks, course];
      }
    });
  };

  const url = process.env.REACT_APP_API_BASE_URL + `/training/employee`;

  const groupAssign = async (employee: any) => {
    try {
      const postRequests = selectedChecks.map(async (course) => {
        const requestBody = {
          reference: course.reference, // Assign the reference from the course
          employerRefId: Number(employee.employerRefId),
          customerId: Number(employee.uuid),
          uuid: Number(employee.uuid),
          attendanceStatus: "ASSIGNED",
          image: "https://image.png",
          date: course.createdDate,
          startDate: course.startDate,
          endDate: course.endDate,
        };

        // console.log("requestBody", requestBody);

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(` ${errorData.error}`);
        }

        return response.json(); // Return the response data if needed
      });

      // Wait for all POST requests to complete
      await Promise.all(postRequests);

      // Handle success after all requests are done
      handleCloseDialog();
      toast({
        message: "Training assigned to Employee",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      handleCloseDialog();
      toast({
        message: `${error}`,
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    console.log("Selected checks updated:", selectedChecks);
  }, [selectedChecks]);

  return (
    <>
      <Grid container gap={6} className=" mx-auto" px={{ xs: 4, sm: 4, md: 0 }}>
        <Grid item xs={12} sm={12} md={3} className="flex justify-evenly">
          <Box
            width={{ xs: "100%", sm: "100%", md: "80%" }}
            justifyContent={"center"}
            border={1}
            p={{ xs: 2, sm: 2 }}
            borderColor={"#A9A9A9"}
            height={{ xs: "100%", md: "300px" }}
            sx={{
              overflowX: "hidden",
              overscrollBehaviorX: "auto",
            }}
          >
            <Typography
              variant="caption"
              fontSize={{ xs: "18px", sm: "21px", md: "21px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              width={{ xs: "90%", sm: "90%", md: "90%" }}
              textAlign={{
                xs: "start",
                sm: "start",
                md: "start",
              }}
              className=" text-start capitalize"
              color="primary"
              gutterBottom
            >
              Filters
            </Typography>

            <Box>
              <Tabs
                orientation={orientation}
                value={valueFilter}
                variant="scrollable"
                onChange={handleChangeFilter}
                aria-label="Vertical filter tabs"
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "nowrap", // Prevent wrapping
                  },
                  "& .MuiTab-root": {
                    flexShrink: 0, // Prevent tabs from shrinking
                    whiteSpace: "nowrap", // Prevent text from wrapping
                  },
                }}
              >
                <Tab
                  disableRipple
                  label="All"
                  {...a11yPropsFilter(0)}
                  className={` text-xs md:text-sm lg:text-base cursor-pointer  justify-start capitalize leading-[102%] tracking-[0.04em] w-52 md:w-fit lg:w-full lg:place-content-start lg:place-items-start lg:flex-1 ${
                    valueFilter === 0
                      ? "bg-[#EEEEEE] text-[#194049]"
                      : "bg-transparent text-[#194049]"
                  }`}
                  onClick={() => handleSelectedMode("")}
                  sx={{ textAlign: "left" }} // Add this line to align text to the start
                />
                <Tab
                  disableRipple
                  label="In-Person Training"
                  {...a11yPropsFilter(1)}
                  onClick={() => handleSelectedMode("in-person")}
                  className={` text-xs md:text-sm lg:text-base cursor-pointer justify-start capitalize leading-[102%] tracking-[0.04em] w-52 md:w-fit lg:w-full lg:place-content-start lg:place-items-start lg:flex-1 ${
                    valueFilter === 1
                      ? "bg-[#EEEEEE] text-[#194049]"
                      : "bg-transparent text-[#194049]"
                  }`}
                />
                <Tab
                  disableRipple
                  label="Online Training"
                  {...a11yPropsFilter(2)}
                  onClick={() => handleSelectedMode("online")}
                  className={` text-xs md:text-sm lg:text-base cursor-pointer justify-start capitalize leading-[102%] tracking-[0.04em] w-52 md:w-fit lg:w-full lg:place-content-start lg:place-items-start lg:flex-1 ${
                    valueFilter === 2
                      ? "bg-[#EEEEEE] text-[#194049]"
                      : "bg-transparent text-[#194049]"
                  }`}
                />
                <Tab
                  disableRipple
                  label="On-The-Job Training"
                  {...a11yPropsFilter(3)}
                  onClick={() => handleSelectedMode("on-the-job")}
                  className={` text-xs md:text-sm lg:text-base cursor-pointer justify-start capitalize leading-[102%] tracking-[0.04em]  w-52 md:w-fit lg:w-full lg:place-content-start lg:place-items-start lg:flex-1 ${
                    valueFilter === 3
                      ? "bg-[#EEEEEE] text-[#194049]"
                      : "bg-transparent text-[#194049]"
                  }`}
                />
              </Tabs>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="" height={"100%"}>
          <Grid container spacing={8} className="">
            {trainingData.length < 1 ? (
              <Grid item xs={12} sm={6} md={4} className=" ">
                <h1>....Coming Soon</h1>
              </Grid>
            ) : (
              currentTrainingPost.map((course: any, index: any) => (
                <Grid item xs={12} sm={6} md={4} className=" " key={index}>
                  <TrainingCard
                    trainingMode={course.trainingMode}
                    title={course.title}
                    createdDate={course.date}
                    reference={course.reference}
                    startDate={convertTo12Hour(course.startDate)}
                    endDate={convertTo12Hour(course.endDate)}
                    objectives={course.objectives}
                    price={course.price}
                    handleChanger={() => handleAssignCheck(course)}
                  />
                </Grid>
              ))
            )}
          </Grid>
          <Pagination
            totalPosts={trainingData.length}
            postsPerPage={postsPerPage}
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            onClicked={() => {
              setCurrentPage(currentPage + 1);
            }}
            currentPage={currentPage}
          />

          <Box className=" centerItems" height={{ xs: "65px", sm: "full" }}>
            <Box
              width={{
                xs: "80%",
                sm: "256px",
              }}
            >
              {trainingData.length < 1 ? (
                <MHButton
                  color="primary"
                  sx={{ borderRadius: "5px" }}
                  type="submit"
                  className="  "
                  fullWidth
                  disabled={true}
                >
                  Assign
                </MHButton>
              ) : (
                <MHButton
                  color="primary"
                  sx={{ borderRadius: "5px" }}
                  type="submit"
                  className="  "
                  fullWidth
                  //   onClick={handleAssignCheck}
                  onClick={handleOpenDialog}
                >
                  Assign
                </MHButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{ height: "100%" }}
      >
        {openDialog && (
          <Box
            height={{ xs: "full", sm: "100%" }}
            width={{ xs: "full", sm: "350px" }}
            p={5}
            mx={"auto"}
          >
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              // width={{ xs: "300px", sm: "264px", md: "264px" }}
              textAlign={{ xs: "start", sm: "start" }}
              color="primary"
              className=""
              gutterBottom
            >
              Assign course(s) to Household
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
              lineHeight={{ xs: "21px", sm: "21px" }}
              // width={{ xs: "300px", sm: "264px", md: "264px" }}
              textAlign={{ xs: "start", sm: "start" }}
              color="#A9A9A9"
              className=""
              gutterBottom
            >
              Search for staff by name and assign them to the selected course{" "}
            </Typography>
            <Autocomplete
              id="employee-select-1sdd"
              sx={{
                width: "fit",
                mt: 2,
                height: "100%",
              }}
              options={employees}
              autoHighlight
              getOptionLabel={(option) => option.firstName || ""}
              renderOption={(item, option) => (
                <Box
                  component="li"
                  //   height={{ xs: "200px", sm: "200px" }}
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                  padding={2}
                  {...item}
                  className=" space-x-6"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                    lineHeight={{ xs: "21px", sm: "21px" }}
                    textAlign={{ xs: "start", sm: "start" }}
                    color="primary"
                    className=""
                    gutterBottom
                  >
                    {option.firstName}
                  </Typography>
                  <Button
                    // onClick={() => handleSingleAssign(option.uuid || 0)}
                    onClick={() => groupAssign(option)}
                    className="ring-1 removeUnderline"
                    sx={{ border: 1, borderRadius: 3, fontSize: "12px" }}
                  >
                    Assign
                  </Button>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                      lineHeight={{ xs: "21px", sm: "21px" }}
                      // width={{ xs: "300px", sm: "264px", md: "264px" }}
                      textAlign={{ xs: "start", sm: "start" }}
                      color="#A9A9A9"
                      className=""
                      gutterBottom
                    >
                      Search for employee
                    </Typography>
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "employee-to-be-assigned", // disable autocomplete and autofill
                  }}
                  sx={{}}
                />
              )}
            />
          </Box>
        )}
      </Dialog>
    </>
  );
};

const AllTrainingBody = (props: Props) => {
  const [value, setValue] = React.useState(0);
  const [selectedMode, setSelectedMode] = React.useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();

  const dashboardMatch = matchPath(location.pathname, {
    path: "/organization/dashboard",
    exact: true,
    strict: true,
  });

  const handleSelectedMode = (label: string) => {
    setSelectedMode(label);
  };

  let slicedAllTrainings = props.dataIn
    ? props.dataIn.filter((training) => {
        if (!training) return false; // Add a null check for training

        if (selectedMode === "") {
          return (
            training.trainingMode &&
            training.level === value &&
            training.category &&
            training.category.toLowerCase().includes(props.selectedRole)
          );
        } else {
          return (
            training.trainingMode &&
            training.category &&
            training.category.toLowerCase().includes(props.selectedRole) &&
            training.level === value &&
            training.trainingMode.toLowerCase() === selectedMode
          );
        }
      })
    : [];

  let slicedSingleTrainings = props.dataIn
    ? props.dataIn.filter((training) => {
        if (!training) return false; // Add a null check for training

        return (
          training.trainingMode &&
          training.category &&
          training.category.toLowerCase().includes(props.selectedRole) &&
          training.level === value
        );
      })
    : [];

  return (
    <React.Fragment>
      <Box
        // height={{ xs: "full", sm: "full" }}
        pt={{ xs: 0, sm: 15, md: 15 }}
        // bgcolor={"#ff"}
        className=" allTrainBody"
      >
        {isEmpty(props.selectedRole) ? (
          ""
        ) : (
          <>
            <Box
              height={{ xs: "150px", sm: "150px" }}
              mt={5}
              alignSelf={"center"}
            >
              <Typography
                variant="h1"
                fontSize={{ xs: "28px", sm: "34px", md: "34px" }}
                lineHeight={{ xs: "29px", sm: "29px" }}
                width={{ xs: "90%", sm: "90%", md: "90%" }}
                textAlign={{ xs: "center", sm: "center", md: "start" }}
                className=" mx-auto text-center capitalize"
                color="primary"
                gutterBottom
              >
                {props.selectedRole}, Level {value}
              </Typography>

              <Box className=" flex gap-4 place-content-center mt-3">
                <Box className=" relative ">
                  {" "}
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                    lineHeight="150%"
                    align="left"
                    color="primary"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    In-Person{" "}
                    <b className="text-[14px] text-[#D2E0CB]">
                      {"(" +
                        slicedSingleTrainings?.filter(
                          (item) =>
                            item.trainingMode?.toLowerCase() === "in-person"
                        ).length +
                        ")"}
                    </b>
                  </Typography>
                </Box>
                <Box className=" relative">
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                    lineHeight="150%"
                    align="left"
                    color="primary"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    | Online{" "}
                    <b className=" text-[14px] text-[#D2E0CB]">
                      {"(" +
                        slicedSingleTrainings?.filter(
                          (item) =>
                            item.trainingMode?.toLowerCase() === "online"
                        ).length +
                        ")"}
                    </b>
                  </Typography>
                </Box>
                <Box className=" relative">
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                    lineHeight="150%"
                    align="left"
                    color="primary"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    | On-The-Job{" "}
                    <b className=" text-[14px] text-[#D2E0CB]">
                      {"(" +
                        slicedSingleTrainings?.filter(
                          (item) =>
                            item.trainingMode?.toLowerCase() === "on-the-job"
                        ).length +
                        ")"}
                    </b>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ width: "90%" }}
              className=" place-content-center mx-auto"
            >
              <Tabs
                value={value}
                className="font-areaNorm mx-auto justify-center"
                onChange={handleChange}
                sx={{
                  width: "100%",
                  display: "flex",
                  bgcolor: "#EEEEEE",
                  justifyContent: "space-between",
                }}
              >
                <Tab
                  className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] ${
                    value === 0
                      ? "bg-[#194049] text-white"
                      : "bg-[#EEEEEE] text-[#194049]"
                  }`}
                  label="Onboarding"
                  disableRipple
                  {...a11yProps(0)}
                  sx={{ width: "33%", textAlign: "center" }}
                />
                <Tab
                  className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] ${
                    value === 1
                      ? "bg-[#194049] text-white"
                      : "bg-[#EEEEEE] text-[#194049]"
                  }`}
                  label="Level 1"
                  disableRipple
                  {...a11yProps(1)}
                  sx={{ width: "33%", textAlign: "center" }}
                />
                <Tab
                  className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] ${
                    value === 2
                      ? "bg-[#194049] text-white"
                      : "bg-[#EEEEEE] text-[#194049]"
                  }`}
                  label="Level 2"
                  disableRipple
                  {...a11yProps(2)}
                  sx={{ width: "33%", textAlign: "center" }}
                />
                {/* <Tab
                  className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] ${
                    value === 3
                      ? "bg-[#194049] text-white"
                      : "bg-[#EEEEEE] text-[#194049]"
                  }`}
                  label="Level 3"
                  disableRipple
                  {...a11yProps(3)}
                  sx={{ width: "33%", textAlign: "center" }}
                /> */}
              </Tabs>

              <Divider className=" w-[100%] mx-auto pt-2" />

              <Box my={2}>
                <TabPanel value={value} index={0}>
                  <ReusedPanels
                    trainingData={slicedAllTrainings}
                    handleSelectedMode={handleSelectedMode}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ReusedPanels
                    trainingData={slicedAllTrainings}
                    handleSelectedMode={handleSelectedMode}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ReusedPanels
                    trainingData={slicedAllTrainings}
                    handleSelectedMode={handleSelectedMode}
                  />
                </TabPanel>
                {/* <TabPanel value={value} index={3}>
                  <ReusedPanels
                    trainingData={slicedAllTrainings}
                    handleSelectedMode={handleSelectedMode}
                  />
                </TabPanel> */}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default AllTrainingBody;
