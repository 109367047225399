import React from 'react';

import MuiLink, { LinkProps } from '@mui/material/Link';

type MHLinkProps = {
  type: 'default' | 'info' | 'danger';
  onClick: () => void;
};

const MHLink = (props: MHLinkProps & LinkProps) => {
  let color = 'primary.main';

  switch (props.type) {
    case 'default':
      color = 'primary.main';
      break;

    case 'info':
      color = '#3C72FF';
      break;

    case 'danger':
      color = '#FF5C00';
      break;
  }

  return (
    <MuiLink
      color={color}
      sx={{
        display: 'block',
        cursor: 'pointer',
        '&:hover': {
            color: props.type === 'danger' ? '#FF5C00' : 'primary.main'
        }
        // my: 2
      }}
      onClick={props.onClick}>
      {props.children}
    </MuiLink>
  );
};

export default MHLink;
