import React from "react";
import { Box, Typography } from "@mui/material";

import { ReactComponent as HeaderBg } from "../../../static/svg/trainingMiniHeader.svg";
import useInput from "../../../hooks/use-input";

import * as validators from "../../../utils/validators";
import { HouseholdRoles } from "../../../utils/contracts";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import { TrainingData } from "./TrainingCard";

type TrainingHeaderProps = {
  onRoleChange: (role: string) => void;
  // dataIn?: { trainingMode: string }[]; // Define the type of dataIn items
  dataIn: TrainingData[];
  // dataIn?: { trainingMode: string }[]; // Define the type of dataIn items
};

const AllTrainingHeader = ({ onRoleChange, dataIn }: TrainingHeaderProps) => {
  const [selectedRole, setSelectedRole] = React.useState("");

  const householdRolesOptions: Option[] = HouseholdRoles.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const {
    value: enteredRole,
    valid: enteredRoleIsValid,
    error: enteredRoleHasError,
    onChange: roleInputChangeHandler,
    onBlur: roleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  React.useEffect(() => {
    setSelectedRole(enteredRole);
    onRoleChange(enteredRole);
  }, [enteredRole]);

  return (
    <React.Fragment>
      <Box
        height={{ xs: "500px", sm: "349px" }}
        bgcolor={"#194049"}
        sx={{ overflow: "hidden" }}
        position={"relative"}
      >
        <Typography
          variant="caption"
          color={"#fff"}
          width={{ xs: "80%", sm: "90%", md: "800px" }}
          fontSize={{ xs: "30px", sm: "30px", md: "40px" }}
          lineHeight={{ xs: "100%", sm: "40px", md: "53px" }}
          textAlign={{ xs: "center", sm: "center" }}
          letterSpacing={"0.01em"}
          mb={2}
          className=" capitalize z-30 drop-shadow-md absolute top-[20%]  md:top-1/2 left-1/2 transform -translate-x-1/2"
        >
          Household Employee Training{" "}
        </Typography>
        <HeaderBg className=" absolute z-0 -right-52 md:-right-6 md:block" />
      </Box>

      <Box
        height={{ xs: "200px", sm: "200px" }}
        width={{ xs: "80%", sm: "80%" }}
        bgcolor={"#fff"}
        //   px={{ xs: "10px", sm: "0px" }}
        borderRadius={3}
        className=" allTrainMiniHead z-40 absolute top-60 md:top-[250px] left-1/2 transform -translate-x-1/2 shadow-lg"
      >
        <Box
          className=" mx-auto md:flex md:justify-around px-4 md:px-0"
          mt={{ xs: "70px", sm: "70px", md: "70px" }}
        >
          <Box
            width={{ xs: "250px", sm: "250px", md: "377px" }}
            // height={{ xs: "50px", sm: "50px", md: "50px" }}
          >
            {/* <MHSelect
              label=""
              placeholder="Select a Role"
              options={HouseholdRoles}
              value={enteredRole}
              onChange={(val) => roleInputChangeHandler(val as string)}
              onBlur={roleInputBlurHandler}
              error={resolveErrorMessage(enteredRoleHasError)(
                "Please select role"
              )}
            /> */}

            <MHAutocomplete
              label="Select Role"
              placeholder="Please Select Role"
              options={householdRolesOptions}
              // onInputChange={handleBankNameChange}
              onInputChange={(val) => roleInputChangeHandler(val as string)}
            />
          </Box>

          <Box className=" flex gap-4 place-content-center mt-3">
            <Box className=" relative ">
              {" "}
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                lineHeight="150%"
                align="left"
                color="primary"
                whiteSpace="nowrap" // Prevents text from wrapping
                overflow="hidden" // Hides any overflow
                textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
              >
                In-Person{" "}
                <b className="text-[9px] absolute -top-3 -right-2">
                  {
                    dataIn?.filter(
                      (item) => item.trainingMode?.toLowerCase() === "in-person"
                    ).length
                  }
                </b>
              </Typography>
            </Box>
            <Box className=" relative">
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                lineHeight="150%"
                align="left"
                color="primary"
                whiteSpace="nowrap" // Prevents text from wrapping
                overflow="hidden" // Hides any overflow
                textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
              >
                Online{" "}
                <b className=" text-[9px] absolute -top-3 -right-2">
                  {
                    dataIn?.filter(
                      (item) => item.trainingMode?.toLowerCase() === "online"
                    ).length
                  }
                </b>
              </Typography>
            </Box>
            <Box className=" relative">
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
                lineHeight="150%"
                align="left"
                color="primary"
                whiteSpace="nowrap" // Prevents text from wrapping
                overflow="hidden" // Hides any overflow
                textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
              >
                On-The-Job{" "}
                <b className=" text-[9px] absolute -top-3 -right-2">
                  {
                    dataIn?.filter(
                      (item) =>
                        item.trainingMode?.toLowerCase() === "on-the-job"
                    ).length
                  }
                </b>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AllTrainingHeader;
