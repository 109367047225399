import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ReactComponent as ExpandActiveIcon } from "../../static/svg/arrowUp.svg";
import { ReactComponent as ExpandMoreIcon } from "../../static/svg/arrowDown.svg";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type DATA = {
  id: number;
  label: string;
  content: string;
};

type FaqProps = {
  data: DATA[];
};

export default function FaqAccordion(props: FaqProps) {
  const [expanded, setExpanded] = React.useState<number | false>(0);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      {props.data.map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary
            aria-controls={`panel${item.id}-content`}
            id={`panel${item.id}-header`}
            expandIcon={
              expanded === item.id ? (
                <ExpandActiveIcon
                  className="w-4 h-4"
                  style={{ color: "#61C453" }}
                />
              ) : (
                <ExpandMoreIcon className="w-4 h-4" />
              )
            }
          >
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className="capitalize"
              sx={{
                color: expanded === item.id ? "#61C453" : "primary",
              }}
            >
              {item.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body1"
              fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "160.5%", sm: "160.5%" }}
              letterSpacing={"-4%"}
              className=" text-[#a9a9a9]"
            >
              {item.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
