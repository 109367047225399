import React from "react";
import { Box } from "@mui/material";

import DocumentSubComponent from "../../components/Features/Documents/DocumentSubComponent";

type Props = {};

const Documents = (props: Props) => {
  return (
    <React.Fragment>
      <Box
        my={4}
        // sx={{
        //   p: 3,
        // }}
        p={{ xs: 0, sm: 3, md: 3 }}
      >
        <Box>
          <DocumentSubComponent />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Documents;
