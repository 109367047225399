import React, { useState } from "react";

import Radio, { RadioProps, radioClasses } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { SxProps, Theme } from "@mui/material/styles";

import Label from "./Label";
import { Box, Typography } from "@mui/material";

type RadioButtonProps = {
  label?: string;
  id: string;
  name: string;
  options: { value: string; label: string; amount: number; text?: string }[];
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: any) => void;
  controlSx?: SxProps<Theme>;
  row?: boolean;
};

const MHRadio = (props: RadioProps) => {
  return (
    <Radio
      color={props.color || "secondary"}
      sx={{
        p: 0.5,
        [`&.${radioClasses.root}`]: {
          color: "#dadad8",
        },
        [`&.${radioClasses.checked}`]: {
          color: (theme) => theme.palette.primary.main,
        },
      }}
      {...props}
    />
  );
};

const MHRadioContent = ({
  label,
  id,
  name,
  color,
  options,
  value,
  onChange,
  controlSx,
  row,
  ...props
}: RadioButtonProps) => {
  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    onChange(event, event.target.value);
  };

  return (
    <FormControl fullWidth>
      {label && <Label id={id}>{label}</Label>}
      <RadioGroup
        row={row || false}
        aria-labelledby="radio-buttons-group-label"
        name={name}
        value={value}
        onChange={handleOptionChange}
        sx={{
          mb: 2,
          "&:last-child": {
            mb: 1,
          },
        }}
      >
        {options.map((option) => (
          <>
            <Box
              className="flex place-content-between w-full"
              key={option.value}
            >
              <FormControlLabel
                value={option.value}
                label={option.label}
                control={<MHRadio color={color} />}
                sx={controlSx}
              />
              <Box
                className={`${
                  option.value === selectedOption
                    ? "bg-[#009688]"
                    : "bg-[#D9D9D9] "
                } w-[70px] h-[30px] py-1 px-2`}
              >
                <Typography
                  variant="body1"
                  fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
                  lineHeight={{ xs: "22px", sm: "22px" }}
                  component="div"
                  gutterBottom
                  // mb={5}
                  className={`${
                    option.value === selectedOption
                      ? "text-white"
                      : "text-[#A9A9A9] "
                  } `}
                >
                  {"N" + option.amount}
                </Typography>
              </Box>
            </Box>
            {option.text && (
              <Typography
                variant="body1"
                fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                lineHeight={{ xs: "12px", sm: "12px" }}
                component="div"
                gutterBottom
                mb={1.5}
                className={`${"text-[#A9A9A9]"} `}
              >
                {option.text}
              </Typography>
            )}
          </>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default MHRadioContent;
