// FundDialog.tsx
import React from "react";
import MHDialog from "../../Common/Dialog/MHDialog";
import {
  Box,
  Button,
  Typography,
  IconButton,
  TextField,
  Paper,
  Alert,
} from "@mui/material";
import { useOnboardForm } from "../../../store/context/onboard-context";
import MHButton from "../../Common/Button/MHButton";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";

import useInput from "../../../hooks/use-input";
import { isEmpty } from "../../../utils/utils";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import DashboardContext from "../../../store/context/dashboard.context";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";

import { ReactComponent as Visibility } from "../../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../static/svg/visibility-off.svg";
import { ReactComponent as PayPromptIcon } from "../../../static/svg/payprompt.svg";
import { ReactComponent as ThumbsUpIcon } from "../../../static/svg/thumbs-up.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../static/svg/arrow-left.svg";

import Lottie from "react-lottie";

import * as animationData from "../../../static/json-animate/success.json";

import MHTextInput from "../../Common/Form/MHTextInput";
import useHttp from "../../../hooks/use-http";

interface PinDialogProps {
  open: boolean;
  onClose: () => void;
}

const VerifyPinOne = () => {
  const { activeStep, setActiveStep } = useOnboardForm();

  const {
    value: enteredAmount,
    valid: enteredAmountIsValid,
    error: amountInputHasError,
    onChange: amountInputChangeHandler,
    onBlur: amountInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;
  const dashboardCtx = React.useContext(DashboardContext);

  const { organization, fetchOrganizationData } = dashboardCtx;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [otp, setOtp] = React.useState(["", "", "", ""]);

  const { loading, error, sendHttpRequest: signIn } = useHttp();

  const handleVerifyChange = (index: number, value: string) => {
    if (value.match(/^\d*$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < otp.length - 1 && value.length === 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleVerifyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.match(/^\d{4}$/)) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);
    }
  };

  const handleVerifyPin = () => {
    const verifiedOtp = otp.join("");

    if (verifiedOtp.length !== 4) {
      toast({
        message: "Invalid OTP 2",
        variant: "error",
      });
    }

    if (Number(verifiedOtp) !== 1234) {
      toast({
        message: "Invalid OTP",
        variant: "error",
      });
    } else {
      // console.log("Verified OTP:", verifiedOtp.length);
      console.log({ otp: Number(verifiedOtp) });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          px: 3,
          py: 3,
          // boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
        }}
        className="h-[full] max-w-md mx-auto bg-transparent"
      >
        <Box className="centerItems">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
            component="div"
            gutterBottom
            mb={5}
          >
            Verify Phone Number
          </Typography>
        </Box>
        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 3,
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* autoComplete="off"
          noValidate */}
        <Box>
          <Box className="text-center w-full">
            <Box
              // display="flex"
              // alignItems="center"
              // justifyContent="center"
              className="centerItems"
            >
              <Typography
                variant="body1"
                fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                lineHeight={"19px"}
                component="div"
                gutterBottom
                mb={1.5}
                className="centerItems "
              >
                Please enter the 4 digits OTP code sent to <br />
                your phone number +2348084*******7 below
              </Typography>
            </Box>
            <Box className="centerItems">
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                component="div"
                gutterBottom
                mb={1.5}
              >
                Enter a 4-digit PIN
              </Typography>
            </Box>
          </Box>
          <Box className=" mt-8 mb-14 mx-auto place-content-center text-center rounded-xl">
            {otp.map((value, index) => (
              <TextField
                key={index}
                id={`otp-input-${index}`}
                variant="outlined"
                value={value}
                onChange={(e) => handleVerifyChange(index, e.target.value)}
                onPaste={handleVerifyPaste}
                inputProps={{ maxLength: 1 }}
                style={{
                  marginRight: "10px",
                  width: "50px",
                }}
              />
            ))}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pt={2}
          >
            <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              component="div"
              gutterBottom
              mb={1.5}
              className="text-center mx-auto w-[100%]"
            >
              Expires in{" "}
              <Button
                // component={Link}
                // underline="always"
                // to="/auth/sign-in"
                // display="block"
                // align="left"
                className=" font-areaBold text-xs removeUnderline w-fit"
              >
                8 mins
              </Button>
              <br /> Didn't get code ?{" "}
              <Button
                // component={Link}
                // underline="always"
                // to="/auth/sign-in"
                // display="block"
                // align="left"
                className="font-areaBold text-xs removeUnderline w-fit"
              >
                Resend
              </Button>
            </Typography>
          </Box>

          <Box className="w-full flex  mt-6">
            <Box className=" centerItems">
              <MHButton
                sx={{}}
                onClick={handleVerifyPin}
                className="  "
                fullWidth
              >
                Verify Number
              </MHButton>
            </Box>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

const CreatePinTwo = () => {
  const [visibility, setVisibility] = React.useState(false);
  // const [pin, setPin] = React.useState("");

  // const handlePinChange = (value: string) => {
  //   setPin(value);
  // };
  const { activeStep, setActiveStep } = useOnboardForm();

  const {
    value: enteredNewPin,
    valid: enteredNewPinIsValid,
    error: newPinInputHasError,
    onChange: newPinInputChangeHandler,
    onBlur: newPinInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const handleCreatePin = () => {
    if (!enteredNewPinIsValid) {
      return;
    }
    console.log("Pin Created with PIN:", enteredNewPin);
    setActiveStep(2);
  };
  return (
    <Paper
      sx={{
        px: 3,
        py: 3,
        // boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
      }}
      className="h-[full] max-w-md mx-auto bg-transparent"
    >
      <Box
        mb={0.6}
        // height={{ xs: "full", sm: "500px" }}
        // width={{ xs: "full", sm: "403px" }}
        // className=" ml-0 mr-0 place-content-center items-center mx-auto "
      >
        <Button onClick={() => setActiveStep(1)} className=" removeUnderline">
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
            color={"primary"}
            gutterBottom
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <ArrowLeftIcon />
            Back
          </Typography>
        </Button>

        <Box className="centerItems " py={3}>
          {/* <label
            className=""
            style={{
              color: "#21392E",
            }}
          >
            New Password
          </label> */}
          <MHTextInput
            id="text"
            type={visibility === true ? "text" : "password"}
            // label="role"
            placeholder="New Pin"
            value={enteredNewPin}
            onChange={newPinInputChangeHandler}
            // onBlur={roleInputBlurHandler}
            // disabled={true}
            className={"bg-white theWidth"}
            endAdornment={
              <InputAdornment
                className="bg-white md:h-[43px] text-navy-900 cursor-pointer"
                onClick={() => {
                  setVisibility(!visibility);
                }}
              >
                {visibility === true ? (
                  <VisibilityOff className="iconheight" />
                ) : (
                  <Visibility className="iconheight" />
                )}
              </InputAdornment>
            }
          />
        </Box>
        <Box width={{ xs: "100%", sm: "100%" }} className=" rounded-md ">
          <MHButton
            color="primary"
            sx={{ borderRadius: "5px" }}
            type="submit"
            className=" "
            fullWidth
            // startIcon={<ThumbsUpIcon />}
            onClick={handleCreatePin}
          >
            Create
          </MHButton>
        </Box>
      </Box>
    </Paper>
  );
};

const PinSuccessful = ({ onClose }: { onClose?: () => void }) => {
  const { activeStep, setActiveStep } = useOnboardForm();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const closure = () => {
    setActiveStep(0);
    if (onClose) onClose();
  };

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "430px" }}
      width={{ xs: "full", sm: "403px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
    >
      {/* <Box
        display="flex"
        alignItems="center"
        // alignSelf="center"
        // alignContent="center"
        justifyContent="center"
        mt={3}
      >
        <PayPromptIcon className="w-6 h-6 " color="#C7D8E4" />
      </Box> */}
      <Box pt={5}>
        <Lottie options={defaultOptions} height={"65px"} width={"65px"} />
      </Box>

      <Box display="flex" justifyContent="center">
        <Typography
          variant="subtitle1"
          fontSize={{ xs: "16px", sm: "18px", md: "18px" }}
          color={"primary"}
          gutterBottom
          align="center"
          justifyContent="center"
          // width={{ xs: "full", sm: "185px" }}
          my={5}
        >
          Created successfully
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography
          variant="body2"
          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
          lineHeight={{ xs: "150%", sm: "215%" }}
          color={"primary"}
          gutterBottom
          align="center"
          justifyContent="center"
          width={{ xs: "full", sm: "280px" }}
          mb={4}
        >
          Your PIN has been successfully created, you can now initiate transfer
          with your PIN
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box mt={4} width={{ xs: "100%", sm: "256px" }}>
          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className="  "
            fullWidth
            // startIcon={<ThumbsUpIcon />}
            onClick={closure}

            // onClick={() =>
            //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
            // }
          >
            Close
          </MHButton>
        </Box>
      </Box>
    </Box>
  );
};

const PinDialog: React.FC<PinDialogProps> = ({ open, onClose }) => {
  const { activeStep, setActiveStep } = useOnboardForm();

  const closure = () => {
    setActiveStep(0);
    onClose();
  };

  // React.useEffect(() => {
  //   // console.log(activeStep);
  // }, [activeStep, enteredAmount]);

  const handlePinFlow = ({ onClose }: { onClose: () => void }) => {
    if (activeStep === 0) {
      return <VerifyPinOne />;
    } else if (activeStep === 1) {
      return <CreatePinTwo />;
    } else if (activeStep === 2) {
      return <PinSuccessful onClose={onClose} />;
    } else {
      return null;
    }
  };

  return (
    <MHDialog
      open={open}
      title={` `}
      handleClose={closure}
      maxWidth="md"
      // actions={}
      scroll="paper"
    >
      {handlePinFlow({ onClose })}
    </MHDialog>
  );
};

export default PinDialog;
