import { Alert, Box, Grid, Typography } from "@mui/material";
import React from "react";
import IconCard from "../../components/Features/Household/IconCard";

import { ReactComponent as UserIcon } from "../../static/svg/user-black.svg";
import { ReactComponent as ClockIcon } from "../../static/svg/clock-arrow.svg";
import { ReactComponent as MoneyIcon } from "../../static/svg/money-rounded.svg";
import { ReactComponent as AddIcon } from "../../static/svg/folder-up.svg";
import { ReactComponent as SentIcon } from "../../static/svg/sent.svg";
// import { ReactComponent as DownloadIcon } from "../../static/svg/download-white.svg";
import PDFViewer from "../../components/Common/PDF/PDFViewer";
import MHButton from "../../components/Common/Button/MHButton";
import HouseholdContractSignPad from "../../components/Common/PDF/HouseholdContractSignPad";
import { useHistory, useParams } from "react-router-dom";
import SideComponent from "../../components/Features/Household/SideComponent";
import { useOnboardForm } from "../../store/context/onboard-context";
import DashboardContext from "../../store/context/dashboard.context";
import useHttp from "../../hooks/use-http";
import { getURLWithQueryParams } from "../../utils/utils";
import { HttpResponse } from "../../models/api.interface";
import { ContractProps } from "../../models/contract.model";
import dayjs from "dayjs";
import EmployeeContractSignPad from "../Contract/ContractItems/EmployeeContractSignPad";
import { EmployeeData } from "../../models/employee.model";
import ReactQuill from "react-quill";

type Props = {};

interface RouteParams {
  customerId?: string;
  employerRefId?: string;
}
const ContractAgreement = (props: Props) => {
  // const [signState, setSignState] = React.useState(false);
  // const [signedDocumentUrl, setSignedDocumentUrl] = React.useState("");
  // const [existingPdfBytes, setExistingPdfBytes] =
  //   React.useState<Uint8Array | null>(null);

  const { addToContract, clearContract, contractForm } = useOnboardForm();

  let history = useHistory();

  // const handleCloseSign = () => {
  //   setSignState(false);
  // };

  // const handleSignState = () => {
  //   setSignState(true);
  // };

  const { customerId } = useParams<RouteParams>();
  const { loading, error, sendHttpRequest } = useHttp();
  const [contractDetails, setContractDetails] =
    React.useState<ContractProps | null>(null);
  const [contractData, setContractData] = React.useState("");

  // console.log("customerId", customerId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract",
        {
          customerId: String(customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps>) => {
        if (response.status === 200) {
          setContractDetails(response.data);
        } else {
          console.error("Failed to fetch contract details:", response);
        }
      }
    ).catch((err) => {
      console.error("Error fetching contract details:", err);
    });
  }, []);

  // console.log("contractDetails", contractDetails);

  React.useEffect(() => {
    if (contractDetails?.content) {
      setContractData(contractDetails?.content || "");
    }
  }, [contractDetails]);

  React.useEffect(() => {
    if (contractDetails?.status === "COMPLETED") {
      history.push("/organization/employee/dashboard");
    }
  }, [contractDetails]);

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization, updateEmployeeStatus } = dashboardCtx;

  const {
    loading: contractLoading,
    error: contractError,
    sendHttpRequest: signContract,
  } = useHttp();

  const name = `${organization?.firstName} ${organization?.lastName}`;
  const email = `${organization?.email}`;
  const telephone = `${organization?.telephone}`;
  const jobTitle = `${organization?.jobTitle}`;
  const salary = `${organization?.salary}`;
  const startDate = `${dayjs(contractDetails?.createdDate).format(
    "MMMM D, YYYY"
  )}`;

  const createdDate = `${dayjs(contractDetails?.createdDate).format(
    "MM/DD/YYYY"
  )}`;
  const expiryDate = `${dayjs(contractDetails?.expiryDate).format(
    "MM/DD/YYYY"
  )}`;

  let date1 = new Date(createdDate);
  let date2 = new Date(expiryDate);

  // Calculating the time difference
  // of two dates
  let Difference_In_Time = date2.getTime() - date1.getTime();

  // Calculating the no. of days between
  // two dates
  let noOfDaysLeft = Math.round(Difference_In_Time / (1000 * 60 * 60 * 24));

  // console.log("Difference: ", noOfDaysLeft);

  const handleEmployeeSignState = () => {
    // console.log("employeeState", employeeState);
    // setEmployeeState(false);
    addToContract({
      signState: true,
      contractInvite: "process",
    });
  };

  const updatedEmployee: EmployeeData = {
    // ...employee,
    status: "ACTIVE",
    id: organization?.id || 0,
    customerId: organization?.customerId,
    employerRefId: organization?.employerRefId,
    // Add other properties as needed
  };
  const currentDate = dayjs();

  const handleSendContract = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    // console.log("Sent");
    // update pdf in database

    await signContract(
      process.env.REACT_APP_API_BASE_URL + "/employer/contract/update",
      {
        method: "PATCH",
        body: JSON.stringify({
          id: contractDetails?.id,
          contractRef: contractDetails?.contractRef,
          employerRefId: Number(contractDetails?.employerRefId),
          customerId: Number(contractDetails?.customerId),
          employeeImgUrl: contractForm.employeeSignature,
          status: "COMPLETED",
          contractName: contractDetails?.contractName,
          employerName: contractDetails?.employerName,
          employeeName: contractDetails?.employeeName,
          employerImgUrl: contractDetails?.employerImgUrl,
          whatsAppNo: contractDetails?.whatsAppNo,
          content: contractDetails?.content,
          email: contractDetails?.email,
          createdDate: contractDetails?.createdDate,
          expiryDate: contractDetails?.expiryDate,
          updatedDate: currentDate,
        }),
      },
      (response: HttpResponse<unknown>) => {
        // toast({
        //   message: `Invitation Sent to Household Staff`,
        //   variant: "success",
        // });
        // onClose();
        // emailInputChangeHandler("");
        // whatsappInputChangeHandler("");
        clearContract();

        updateEmployeeStatus(updatedEmployee);
        // console.log("After Update", employee);

        history.push("/organization/employee/contract-signed");

        // window.location.reload();
      }
    );
    // Want to download copy the pdf on this next page
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} className="h-auto">
        <Grid item xs={12} sm={3.5} md={2.5} className="h-auto">
          <Box className=" md:pb-5 lg:pb-10">
            <IconCard
              icon={<UserIcon className="h-6 w-6" />}
              header="Personal Information"
              labels={["name", "email", "phone", "role"]}
              texts={[name, email, telephone, jobTitle]}
            />
          </Box>
          <Box className=" md:pb-5 lg:pb-10">
            <IconCard
              icon={<ClockIcon className="h-6 w-6" />}
              header="Resumption"
              labels={["start date"]}
              texts={[startDate]}
            />
          </Box>
          <Box className=" md:pb-5 lg:pb-10">
            <IconCard
              icon={<MoneyIcon className="h-6 w-6" />}
              header="Basic Salary"
              labels={["salary"]}
              texts={[salary]}
            />
          </Box>

          <div className="my-4">
            <MHButton
              //   type="submit"
              form="add-employee-form"
              //   loading={loading}
              className="rounded-[4px] cursor-pointer "
              fullWidth
              startIcon={<AddIcon />}
              onClick={handleEmployeeSignState}
            >
              Add Signature
            </MHButton>
          </div>
          <div className="lg:hidden block">
            <SideComponent />
          </div>
        </Grid>
        <Grid item xs={12} sm={8.5} md={7} className="">
          <Box className=" mb-4 grid md:flex place-content-between gap-1 md:gap-3 items-center">
            <Alert severity="warning" className="md:w-3/4 w-full">
              You have{" "}
              <strong className="font-areaBlack">{noOfDaysLeft} days</strong>{" "}
              Notice period to respond or this contract will be terminated
            </Alert>

            <MHButton
              //   type="submit"
              form="view-employee-contract"
              //   loading={loading}
              className="rounded-[4px] cursor-pointer md:w-1/4 w-full "
              fullWidth
              startIcon={<SentIcon className="w-5 h-5 -mt-1 text-white" />}
              onClick={handleSendContract}
              disabled={contractForm.employeeSignature === "" ? true : false}
            >
              Submit
            </MHButton>
          </Box>
          {/* <PDFViewer
            fileUrl={`${
              signedDocumentUrl === "" ? myPdfUrl : signedDocumentUrl
            }`}
          /> */}

          <Box
            p={{ xs: 0, sm: 3 }}
            bgcolor={"common.white"}
            borderRadius={2}
            boxShadow={{ xs: "", sm: "0px 5px 26px #C7D8E4" }}
            minHeight={180}
            mt={{ xs: 5, sm: 0 }}
            sx={{
              gap: 2,
              pt: 5,
              mb: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* <Box className="relative quillEditor">
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: contractData }}
              />
            </Box> */}

            <Box className="relative quillEditor">
              <ReactQuill
                value={contractData}
                readOnly={true}
                theme={"bubble"}
              />
            </Box>

            <Box
              mt={4}
              mb={0}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Box
                className="space-y-4 justify-center"
                sx={{ width: { xs: "128px", sm: "192px" } }}
              >
                <Box>
                  {contractDetails?.employerImgUrl && (
                    <>
                      <img
                        src={contractDetails?.employerImgUrl}
                        alt="signature"
                        className="signature"
                      />
                    </>
                  )}
                  <hr className="w-32 md:w-48" />
                </Box>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={{ xs: "12px", sm: "16px", md: "16px" }}
                  className=" capitalize"
                >
                  {/* Employer Name should go here */}
                  {contractDetails?.employerName}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                  className=" capitalize"
                >
                  Date:{" "}
                  {dayjs(contractDetails?.createdDate).format("MMMM DD, YYYY")}
                </Typography>
              </Box>
              <Box
                className="space-y-4 justify-center"
                sx={{ width: { xs: "128px", sm: "192px" } }}
              >
                <Box>
                  {contractForm?.employeeSignature && (
                    <>
                      <img
                        src={contractForm?.employeeSignature}
                        alt="signature"
                        className="signature"
                      />
                    </>
                  )}
                  <hr className="w-32 md:w-48" />
                </Box>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={{ xs: "12px", sm: "16px", md: "16px" }}
                  className=" capitalize"
                >
                  {`${
                    contractDetails?.employeeName === ""
                      ? "[Employee Name]"
                      : contractDetails?.employeeName
                  } `}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                  className=" capitalize"
                >
                  Date: {dayjs(currentDate).format("MMMM DD, YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3.5} md={2.5} className="hidden lg:block">
          <SideComponent />
        </Grid>
      </Grid>
      {/* {signState && existingPdfBytes && (
        <HouseholdContractSignPad
          fileName={fileName}
          open={signState}
          onClose={handleCloseSign}
          existingPdfBytes={existingPdfBytes}
          onDocumentSigned={handleDocumentSigned}
          onPdfSigned={(signedPdfUrl) =>
            addToContract({ signedPdfUrl: signedPdfUrl })
          }
        />
      )} */}

      {contractForm.signState && (
        <EmployeeContractSignPad
        // handleEmployeeName={handleOpenEmployee}
        />
      )}
    </React.Fragment>
  );
};

export default ContractAgreement;
