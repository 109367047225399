import { Box, Typography } from "@mui/material";
import React from "react";
import { MHPagination } from "../../Common/UI/MHPagination";

import { ReactComponent as ClockIcon } from "../../../static/svg/clocks.svg";

type NotifyProps = {
  id?: number;
  type?: string;
  subject?: string;
  sender?: string;
  recepient?: string;
  body?: string;
  time?: string;
};

type Props = {
  notifications: NotifyProps[];
  searchWord?: string;
};

const NotificationCard = (props: Props) => {
  const [filteredData, setFilteredData] = React.useState<NotifyProps[]>([]); // Initialize with an empty array

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(4);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 4;
  let noOfPage = Math.ceil((props.notifications?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  React.useEffect(() => {
    // console.log("Notifications:", props.notifications);
    // console.log("Search Word:", props.searchWord);

    if (props.searchWord) {
      const searchWord = props.searchWord.toLowerCase();
      const filtered = props.notifications.filter(
        (item) =>
          item.subject?.toLowerCase().includes(searchWord) ||
          item.sender?.toLowerCase().includes(searchWord) ||
          item.recepient?.toLowerCase().includes(searchWord) ||
          item.time?.toLowerCase().includes(searchWord) ||
          item.body?.toLowerCase().includes(searchWord)
      );
      //   console.log("Filtered Results:", filtered);
      setFilteredData(filtered);
    } else {
      setFilteredData(props.notifications);
    }
  }, [props.searchWord, props.notifications]);

  return (
    <React.Fragment>
      {currentPosts &&
        currentPosts.map((item) => (
          <Box className="bg-[#F9F9F7] h-40 rounded-lg pl-10" pt={3} mb={1}>
            <Box className=" flex justify-between">
              <Box
                className=" bg-[#009688] text-center w-32  h-6 rounded"
                mb={1}
              >
                <Typography
                  variant="body1"
                  color={"white"}
                  fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                  lineHeight={{ xs: "29px", sm: "29px", md: "29px" }}
                  align="center"
                  letterSpacing={"0.01em"}
                  className=" capitalize"
                >
                  {item.type}
                </Typography>
              </Box>

              <Box className=" text-center w-52 h-6" mb={1} mr={4}>
                <Typography
                  variant="body1"
                  //   color={"white"}
                  fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                  lineHeight={{ xs: "29px", sm: "29px", md: "29px" }}
                  align="center"
                  letterSpacing={"0.01em"}
                  className=" text-[#A9A9A9] w-full flex gap-1 items-center"
                >
                  <ClockIcon />

                  {item.time}
                </Typography>
              </Box>
            </Box>

            <Box className="  w-full ">
              <Typography
                variant="subtitle1"
                color={"primary"}
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "29px", sm: "29px", md: "29px" }}
                align="left"
                letterSpacing={"0.01em"}
                className=" "
              >
                <strong className=" text-blue-100 font-areaBlack">
                  {item.sender}{" "}
                </strong>
                {item.subject}
              </Typography>
            </Box>
            <Box className="  w-full ">
              <Typography
                variant="body1"
                // color={"primary"}
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "19px", sm: "19px", md: "19px" }}
                align="left"
                letterSpacing={"0.01em"}
                className=" text-[#A9A9A9] w-[85%]"
              >
                {item.body}
              </Typography>
            </Box>
            <Box className="  w-full ">
              <Typography
                variant="body1"
                // color={"primary"}
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "29px", sm: "29px", md: "29px" }}
                align="left"
                letterSpacing={"0.01em"}
                className=" text-[#6B6B6B] w-[85%]"
              >
                {item.recepient}
              </Typography>
            </Box>
          </Box>
        ))}

      {/* Pagination */}
      <Box display={"flex"} justifyContent={"center"} my={2}>
        <MHPagination
          count={noOfPage}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePageChange}
          color={"primary"}
        />
      </Box>
    </React.Fragment>
  );
};

export default NotificationCard;
