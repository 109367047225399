import { Backdrop, Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  formatNumber,
  getURLWithQueryParams,
  isEmpty,
} from "../../../utils/utils";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import MHButton from "../../Common/Button/MHButton";
import Lottie from "react-lottie";

import * as animationData from "../../../static/json-animate/Salary and Benefit.json";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";
import useDialog from "../../../hooks/use-dialog";
import EmployeePayrollDialog from "../Dashboard/EmployeePayrollDialog";
import { EmployeeData } from "../../../models/employee.model";
import useHttp from "../../../hooks/use-http";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";
import { HttpResponse } from "../../../models/api.interface";
import PayslipCard from "./PayslipCard";
import PDFViewer from "../../Common/PDF/PDFViewer";
import CBDialog from "../../Common/Dialog/CBDialog";
import MHDialog from "../../Common/Dialog/MHDialog";
import PaymentSlips from "./PaymentSlips";
import PlainDialog from "../../Common/Dialog/PlainDailog";
import BenefitsDialog from "./BenefitsDialog";

type Props = {
  recurringAmount?: string | number | any;
  expensesAmount?: string | number | any;
};

const SalaryPaymentDesign = (props: Props) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<EmployeeData | null>(null);

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { sendHttpRequest } = useHttp();

  // console.log("RefID", user?.employerRefId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [openSlip, setOpenSlip] = React.useState(false);
  const [openAllSlip, setOpenAllSlip] = React.useState(false);

  const handleOpenSlip = () => {
    setOpenSlip(true);
  };
  const handleCloseSlip = () => {
    setOpenSlip(false);
  };

  const handleOpenAllSlip = () => {
    setOpenAllSlip(true);
  };
  const handleCloseAllSlip = () => {
    setOpenAllSlip(false);
  };

  const slipData = [
    {
      id: 1,
      label: "Jan_Salary",
      handleView: () => handleOpenSlip(),
      handleDownload: () => console.log("Downloading January Payslip"),
    },
    {
      id: 2,
      label: "Feb_Salary",
      handleView: () => handleOpenSlip(),
      handleDownload: () => console.log("Downloading February Payslip"),
    },
    {
      id: 3,
      label: "Mar_Salary",
      handleView: () => handleOpenSlip(),
      handleDownload: () => console.log("Downloading March Payslip"),
    },
    {
      id: 4,
      label: "Apr_Salary",
      handleView: () => handleOpenSlip(),
      handleDownload: () => console.log("Downloading April Payslip"),
    },
  ];

  const benefitsArr = [
    "Salary",
    "Meals",
    "Transportation",
    "Accommodation",
    "Airtime / Data",
    // "13 month Salary",
  ];
  const payslipArr = [
    { id: 1, title: "Jan_Salary.pdf", size: "325kb", date: "Jan 20, 2024" },
    {
      id: 2,
      title: "February_Salary.pdf",
      size: "325kb",
      date: "February 22, 2024",
    },
    { id: 3, title: "March_Salary.pdf", size: "325kb", date: "March 31, 2024" },
    { id: 5, title: "April_Salary.pdf", size: "325kb", date: "April 16, 2024" },
    { id: 5, title: "May_Salary.pdf", size: "325kb", date: "May 13, 2024" },
    { id: 6, title: "June_Salary.pdf", size: "325kb", date: "June 10, 2024" },
    { id: 3, title: "July_Salary.pdf", size: "325kb", date: "July 31, 2024" },
    {
      id: 5,
      title: "August_Salary.pdf",
      size: "325kb",
      date: "August 16, 2024",
    },
    {
      id: 5,
      title: "September_Salary.pdf",
      size: "325kb",
      date: "September 13, 2024",
    },
    {
      id: 6,
      title: "October_Salary.pdf",
      size: "325kb",
      date: "October 10, 2024",
    },
    {
      id: 6,
      title: "November_Salary.pdf",
      size: "325kb",
      date: "November 19, 2024",
    },
    {
      id: 6,
      title: "December_Salary.pdf",
      size: "325kb",
      date: "December 25, 2024",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        className=" place-content-start md:justify-between space-y-5 md:space-y-0 lg:gap-0 z-20 bg-inherit "
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          px={2}
          py={4}
          boxShadow="0px 5px 26px #C7D8E4"
          minHeight={180}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#fff",
            borderRadius: 3,
          }}
          className=" order-2 md:order-1 flex alignItems place-content-center"
        >
          <Box className=" z-10">
            <Box className="">
              <Lottie options={defaultOptions} height={"65px"} width={"65px"} />
            </Box>

            <Typography
              variant="subtitle1"
              fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
              gutterBottom
              className=" capitalize"
            >
              Salary & Benefits
            </Typography>

            <MuiLink
              // component={Link}
              underline="always"
              // to="/organization/employee/dashboard"
              onClick={handleOpenDialog}
              // display="block"
              // align="left"
              className="text-blue-100 mt-2 no-underline cursor-pointer centerItems"
            >
              View
            </MuiLink>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7.5}
          md={7.5}
          px={2}
          py={4}
          boxShadow="0px 5px 26px #C7D8E4"
          minHeight={180}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#fff",
            borderRadius: 3,
          }}
          className=" order-2 md:order-1"
        >
          <Box className=" z-10">
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
              gutterBottom
              className=" capitalize"
            >
              Payment Slip
            </Typography>

            {/* Payslips ArrayCard */}
            <Box>
              <PayslipCard slipArray={slipData} />
            </Box>

            <MuiLink
              // component={Link}
              underline="always"
              // to="/organization/employee/dashboard"
              onClick={handleOpenAllSlip}
              // display="block"
              // align="left"
              className="text-blue-100 mt-4 no-underline cursor-pointer centerItems"
            >
              Expand All
            </MuiLink>
          </Box>
        </Grid>
      </Grid>
      {openDialog && (
        <BenefitsDialog
          // benefits={benefitsArr}
          open={openDialog}
          onClose={handleCloseDialog}
        />
      )}

      <MHDialog
        open={openSlip}
        title={` `}
        handleClose={handleCloseSlip}
        maxWidth={"md"}
        scroll="paper"
        fullWidth
      >
        <PDFViewer
          fileUrl={`https://www.aeee.in/wp-content/uploads/2020/08/Sample-pdf.pdf`}
        />
      </MHDialog>

      <CBDialog
        open={openAllSlip}
        title={` `}
        handleClose={handleCloseAllSlip}
        // maxWidth={"md"}
        // scroll="paper"
        // fullWidth
      >
        <PaymentSlips payslips={payslipArr} />
      </CBDialog>

      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openAllSlip}
        onClick={handleCloseAllSlip}
      >
        <PaymentSlips />
      </Backdrop> */}
    </React.Fragment>
  );
};

export default SalaryPaymentDesign;
