import React from "react";
import { InstituteHeader } from "./TrainingInstitute/InstituteHeader";
import { TrainingData } from "../components/Features/Training/TrainingCard";
import useHttp from "../hooks/use-http";
import { getURLWithQueryParams } from "../utils/utils";
import { HttpResponse } from "../models/api.interface";
import InstituteBody from "./TrainingInstitute/InstituteBody";

type Props = {};

const TrainingInstitute = (props: Props) => {
  const [selectedRole, setSelectedRole] = React.useState("");
  const [training, setTraining] = React.useState<TrainingData[]>([]);

  const { loading, error, sendHttpRequest } = useHttp();
  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
  };
  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<TrainingData[]>) => {
        setTraining(response.data);
      }
    );
  }, []);

  const filteredTraining =
    training && training.filter((train) => train.status !== "INACTIVE");

  return (
    <React.Fragment>
      <InstituteHeader
        onRoleChange={handleRoleChange}
        title="A trained Household staff is a win for the family."
        content="Having a trained household staff is more than just a practical
            solution – it's a lifeline of warmth, compassion, and support for
            your family."
      />

      <InstituteBody selectedRole={selectedRole} dataIn={training} />
    </React.Fragment>
  );
};

export default TrainingInstitute;
