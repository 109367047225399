import React from "react";

import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";

import MHDataTable, { GridColDef } from "../../Common/DataTable/MHDataTable";
import { EmployeeData } from "../../../models/employee.model";
import EmployeeModal from "../Employees/EmployeeModal";

import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import BackdropLoader from "../../Common/Loading/BackdropLoader";
import SnackbarContext from "../../../store/context/snackbar.context";
import {
  convertUTCToDate,
  formatAmount,
  getURLWithQueryParams,
} from "../../../utils/utils";
import AuthContext from "../../../store/context/auth-context";
import StackedIconWithText from "../../Common/UI/StackedIconWithText";
import { ReactComponent as Activated } from "../../../static/svg/activate.svg";
import { ReactComponent as Reactivated } from "../../../static/svg/reactivate.svg";
import { Link } from "react-router-dom";

const Employees = () => {
  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<EmployeeData | null>(null);

  const columns: GridColDef<EmployeeData>[] = [
    {
      headerName: "First name",
      type: "text",
      field: "firstName",
      width: 300,
      valueGetter: (row: EmployeeData) => {
        return <div className=" capitalize">{row?.firstName}</div>;
      },
    },
    {
      headerName: "Last name",
      type: "text",
      field: "lastName",
      width: 300,
      valueGetter: (row: EmployeeData) => {
        return <div className=" capitalize">{row?.lastName}</div>;
      },
    },
    // {
    //   headerName: "Phone",
    //   type: "text",
    //   field: "telephone",
    //   width: 200,
    // },
    {
      headerName: "Role",
      type: "text",
      field: "jobTitle",
      width: 200,
      valueGetter: (row: EmployeeData) => {
        return <div className=" capitalize">{row?.jobTitle}</div>;
      },
    },
    {
      headerName: "Salary (₦)",
      type: "text",
      field: "salary",
      width: 200,
      valueGetter: (row: EmployeeData) => {
        return formatAmount(
          row?.salary +
            row?.Accomodation +
            row?.airtimeOrData +
            row?.meals +
            row?.transportation
        );
      },
    },

    {
      headerName: "Date",
      type: "text",
      field: "createdDate",
      valueGetter: (row: EmployeeData) => {
        return convertUTCToDate(row?.createdDate);
      },
      width: 200,
    },
    // {
    //   headerName: "Position",
    //   type: "text",
    //   field: "position",
    //   width: 200,
    // },
    // {
    //   headerName: 'Total Payout',
    //   type: 'text',
    //   field: 'totalPayout',
    //   width: 200
    // },
    // {
    //   headerName: 'Recent Payout',
    //   type: 'text',
    //   field: 'recentPayout',
    //   width: 200
    // },

    // {
    //   headerName: 'Recent Payout Category',
    //   type: 'text',
    //   field: 'recentPayoutCareCategory',
    //   width: 200
    // },

    {
      headerName: "Details",
      type: "text",
      field: "",
      width: 150,
      align: "left",
      cellRenderer: (row: EmployeeData) => (
        <MuiLink
          component={Link}
          underline="always"
          to={`/organization/employee/view/${row.uuid}`}
          className="text-blue-100 mt-2 removeUnderline"
        >
          View
        </MuiLink>
      ),
    },
    {
      headerName: "Status",
      type: "text",
      field: "",
      width: 150,
      align: "center",
      cellRenderer: (row: EmployeeData) => (
        <StackedIconWithText
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box pr={1}>
            {/* {row.status} */}
            {row.status === "ACTIVE" ? <Activated /> : <Reactivated />}
          </Box>
        </StackedIconWithText>
      ),
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleOpen = (employee: any) => {
    setOpen(true);
    setSelectedEmployee(employee);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  // console.log("RefID", user?.employerRefId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  return (
    <React.Fragment>
      <BackdropLoader open={loading} />

      {employees && (
        <MHDataTable
          title="Household Employee"
          rows={employees}
          columns={columns}
          frontEndPagination
          // hidePagination
        />
      )}

      {/* {open && (
        <EmployeeModal
          open={open}
          onClose={handleClose}
          employee={selectedEmployee}
        />
      )} */}
    </React.Fragment>
  );
};

export default Employees;
