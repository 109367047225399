import { ReactComponent as OldMHWhiteIcon } from "../../static/svg/motherhonestly-white.svg";

export default function OldMHLogo({ style }: { style?: object }) {
  return (
    <OldMHWhiteIcon
      width="3.5rem"
      height="auto"
      style={{
        margin: "0 auto",
        ...style,
      }}
    />
  );
}
