import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import DashboardContext from "../../../store/context/dashboard.context";
import dayjs from "dayjs";
import { useOnboardForm } from "../../../store/context/onboard-context";
import MHEditor from "../../../components/Common/Editor/MHEditor";

const ContractSection = () => {
  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const { contractForm } = useOnboardForm();

  // const positionX = lastPage.getWidth() - signatureWidth - 20;
  // const positionY = 20;
  const date = new Date();
  // console.log("date", dayjs(date).format("MMMM DD, YYYY"));

  return (
    <Container maxWidth="md">
      <Box mt={1} mb={0}>
        <MHEditor />

        {/* Add buttons for submitting the form or navigating */}
        <Box mt={4} mb={0} className=" flex justify-between items-baseline">
          <Box
            className="space-y-4 justify-center"
            sx={{ width: { xs: "128px", sm: "192px" } }}
            justifyContent="center"
          >
            <Box>
              {contractForm.employerSignature && (
                <>
                  <img
                    src={contractForm.employerSignature}
                    alt="signature"
                    className="signature"
                  />
                </>
              )}
              <hr className="md:w-48 w-32 justify-center" />
            </Box>
            <Typography
              variant="body1"
              align="center"
              fontSize={{ xs: "12px", sm: "16px", md: "16px" }}
              className=" capitalize"
            >
              {`${organization?.firstName + " " + organization?.lastName} `}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              fontSize={{ xs: "10px", sm: "14px", md: "14px" }}
              className=" capitalize"
            >
              Date: {dayjs(date).format("MMMM DD, YYYY")}
            </Typography>
          </Box>
          <Box
            className="space-y-4 justify-center"
            sx={{ width: { xs: "128px", sm: "192px" } }}
            justifyContent="center"
          >
            <Box>
              {contractForm.employeeSignature && (
                <>
                  <img
                    src={contractForm.employeeSignature}
                    alt="signature"
                    className="signature"
                  />
                </>
              )}
              <hr className="md:w-48 w-32 justify-center" />
            </Box>
            <Typography
              variant="body1"
              align="center"
              fontSize={{ xs: "12px", sm: "16px", md: "16px" }}
              className=" capitalize"
            >
              {`${
                contractForm.employeeName === ""
                  ? "[Employee Name]"
                  : contractForm.employeeName
              } `}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              fontSize={{ xs: "10px", sm: "14px", md: "14px" }}
              className=" capitalize"
            >
              Date:
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ContractSection;
