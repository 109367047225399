import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";

type Props = {
  icon?: React.ReactNode;
  header?: string;
  labels: string[]; // Array of labels
  texts: string[]; // Array of corresponding texts
};

const IconCard: React.FC<Props> = (props) => {
  return (
    <Box className="h-fit w-full ring-1 ring-[#D9D9D9] rounded-md mb-2 ">
      <Box className="bg-[#D9D9D9] w-full flex  gap-4 md:gap-2 lg:gap-4 bg-opacity-30 pt-2 px-2 border-[#D9D9D9] border-b-[1px] ring-[#D9D9D9] rounded-t-md">
        <Box className="h-fit">{props.icon}</Box>
        <Box className="">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
            gutterBottom
          >
            {props.header}
          </Typography>
        </Box>
      </Box>

      <Box className="bg-white rounded-b-md p-3">
        {props.labels.map((label, index) => (
          <Box className=" flex justify-start gap-4 items-center" key={index}>
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "14px", sm: "12px", md: "12px" }}
              gutterBottom
              mb={1}
              className="capitalize text-[#A9A9A9]"
            >
              {label}:
            </Typography>
            <Typography
              variant="body1"
              fontSize={{ xs: "16px", sm: "14px", md: "14px" }}
              gutterBottom
              mb={1}
              className={`${
                label === "email" ? "lowercase" : "capitalize"
              } text-navy-900 flex  `}
            >
              {label === "salary" ? <NairaIcon className="w-fit h-4" /> : null}{" "}
              {props.texts[index]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default IconCard;
