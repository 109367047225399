import { Avatar, Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import AuthContext from "../../../store/context/auth-context";

import { HashLink } from "react-router-hash-link";

import MuiLink from "@mui/material/Link";

import { ReactComponent as AvatarBadgeIcon } from "../../../static/svg/avatar-badge.svg";
import DashboardContext from "../../../store/context/dashboard.context";
import useDialog from "../../../hooks/use-dialog";
import AvatarDialog from "./AvatarDialog";
import { Link } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import { getURLWithQueryParams } from "../../../utils/utils";
import { HttpResponse } from "../../../models/api.interface";
import { ContractProps } from "../../../models/contract.model";

type Props = {
  //   tabValue?: number;
  //   handleTabChange?: (event: React.SyntheticEvent, newValue: number) => void;
};

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ProfileInfo = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const { loading, error, sendHttpRequest } = useHttp();
  const [contractDetails, setContractDetails] =
    React.useState<ContractProps | null>(null);

  // console.log("customerId", customerId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract",
        {
          customerId: String(organization?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps>) => {
        if (response.status === 200) {
          console.log(response.data);
          setContractDetails(response.data);
        } else {
          console.error("Failed to fetch contract details:", response);
        }
      }
    ).catch((err) => {
      console.error("Error fetching contract details:", err);
    });
  }, [organization]);

  return (
    <React.Fragment>
      <Box className="px-2 md:px-4 lg:px-6">
        <Box className=" relative w-[258px] md:w-full lg:w-[258px] mx-auto md:ml-0 pt-4 md:pt-0 my-4 md:my-4 centerItems">
          <Avatar
            alt="Avatar"
            variant="square"
            src="https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88"
            sx={{
              width: { xs: "300px", sm: "258px", md: "258px" },
              height: { xs: "281px", sm: "258px", md: "258px" },
            }}
            className=" rounded-t-md"
          />
          <AvatarBadgeIcon
            className=" cursor-pointer absolute bottom-2 right-2"
            onClick={handleOpenDialog}
          />
        </Box>
        <Box className="w-[258px] md:w-full  lg:w-[258px] my-4 ">
          <Typography
            variant="subtitle1"
            mb={2}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "20px", sm: "20px", md: "20px" }}
            className=""
          >
            {user?.firstName + " " + user?.lastName}
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            {organization?.address + ", " + organization?.stateOfOrigin}
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            {organization?.email}
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            color="#194049"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            {organization?.telephone}
          </Typography>
          {contractDetails?.status === "COMPLETED" ? (
            <MuiLink
              component={Link}
              underline="always"
              to={`/organization/contract/preview/${organization?.customerId}`}
              // display="block"
              // align="left"
              className="text-blue-100 removeUnderline"
            >
              Preview Contract
            </MuiLink>
          ) : (
            ""
          )}
        </Box>
      </Box>

      <Divider className=" w-[91%] md:w-[93%] lg:w-[99.5%]" />

      {/* <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      // onChange={props.handleTabChange}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: "divider" }}
      className="mt-4"
    >
      <Tab
        className={`${
          value === 0 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
        } text-start text-xs  capitalize leading-[102%] tracking-[0.04em]`}
        label="Account Settings"
        {...a11yProps(0)}
        sx={{ alignItems: "start" }}
      />
      <Tab
        className={`${
          value === 1 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
        } text-start text-xs  capitalize leading-[102%] tracking-[0.04em]`}
        label="Password Settings"
        {...a11yProps(1)}
        sx={{ alignItems: "start" }}
      />
      <Tab
        className={`${
          value === 2 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
        } text-start text-xs  capitalize leading-[102%] tracking-[0.04em]`}
        label="Transfer Settings"
        {...a11yProps(2)}
        sx={{ alignItems: "start" }}
      />
    </Tabs> */}
      <Box className=" space-y-6 mt-4 pb-8 hidden md:block">
        <Box>
          <HashLink
            smooth
            to="/organization/employee/profile/#accountsetting"
            className={`${
              value === 0 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
            } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 pl-[25px] md:pl-[20px] pr-[171px]  md:pr-[145px]  lg:pr-[165px]`}
            // } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 px-[115px] md:px-[86px] lg:px-[93px]`}
            onClick={() => setValue(0)}
          >
            Account Settings
          </HashLink>
          {/* <a
          href="/organization/employee/employee/profile/#accsettings"
          className={`${
            value === 0 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
          } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 px-[93px]`}
          onClick={() => setValue(0)}
        >
          Account Settings
        </a> */}
        </Box>

        <Box className="mb-5">
          <HashLink
            smooth
            to="/organization/employee/profile/#passwordsetting"
            className={`${
              value === 1 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
            } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 pl-[25px] md:pl-[20px] pr-[163px] md:pr-[137px] lg:pr-[157px]`}
            // } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 px-[110px] md:px-[82px] lg:px-[88px]`}
            onClick={() => setValue(1)}
          >
            Password Settings
          </HashLink>
        </Box>

        <Box>
          <HashLink
            smooth
            to="/organization/employee/profile/#pinsetting"
            className={`${
              value === 2 ? "bg-navy-900 text-white" : "text-navy-900 bg-white"
            } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 pl-[25px] md:pl-[20px] pr-[173px]  md:pr-[147px]  lg:pr-[167px]`}
            // } text-start text-xs no-underline capitalize leading-[102%] tracking-[0.04em] py-3 px-[115px] md:px-[86px] lg:px-[93px]`}
            onClick={() => setValue(2)}
          >
            Transfer Settings
          </HashLink>
        </Box>
      </Box>

      {openDialog && (
        <AvatarDialog open={openDialog} onClose={handleCloseDialog} />
      )}
    </React.Fragment>
  );
};

export default ProfileInfo;
