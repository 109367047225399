import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import MuiLink from "@mui/material/Link";
import { useLocation } from "react-router-dom";
import { ReactComponent as TrainOnline } from "../../static/svg/train-online.svg";
import { ReactComponent as TrainInPerson } from "../../static/svg/train-inPerson.svg";
import { ReactComponent as TrainOnJob } from "../../static/svg/train-onJob.svg";
import { ReactComponent as CalendarIcon } from "../../static/svg/calend.svg";
import { ReactComponent as NairaIcon } from "../../static/svg/nigeria-naira-icon.svg";

import useDialog from "../../hooks/use-dialog";
import { convertUTCToWordDate, resolveErrorMessage } from "../../utils/utils";
import SnackbarContext from "../../store/context/snackbar.context";
import useHttp from "../../hooks/use-http";
import MHButton from "../../components/Common/Button/MHButton";
import MHDialog from "../../components/Common/Dialog/MHDialog";
import MHFormControl from "../../components/Common/Form/MHFormControl";
import { TrainingData } from "../../components/Features/Training/TrainingCard";
import useInput from "../../hooks/use-input";
import * as validators from "../../utils/validators";
import usePhoneInput from "../../hooks/use-phone";
import MHPhoneInput from "../../components/Common/Form/MHPhoneInput";
import InstituteDialog from "./InstituteDialog";

const InstituteCard: React.FC<TrainingData> = ({
  trainingMode,
  title,
  reference,
  price,
  startDate,
  endDate,
  createdDate,
  objectives,
}) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const obj = objectives?.split(",");
  // console.log("obj", obj);
  const objects = obj?.slice(0, 3);
  // console.log("objects", objects);

  return (
    <React.Fragment>
      <Box
        width={{ xs: "full", sm: "294px" }}
        height={"396px"}
        my={1}
        position={"relative"}
        className=" rounded-md drop-shadow-md shadow-md place-content-center"
      >
        <Box
          className=" trainingHeader relative rounded-t-md"
          //   bgcolor={"#D2E0CB"}
          bgcolor={
            trainingMode?.toLowerCase() === "online"
              ? "#C7D8E4"
              : trainingMode?.toLowerCase() === "in-person"
              ? "#D2E0CB"
              : trainingMode?.toLowerCase() === "on the job"
              ? "#E3CEAE"
              : "#D2E0CB"
          }
          height={"53px"}
          width={{ xs: "full", sm: "294px" }}
        >
          <Box className=" absolute left-4 top-5">
            {trainingMode?.toLowerCase() === "online" ? (
              <TrainOnline />
            ) : trainingMode?.toLowerCase() === "in-person" ? (
              <TrainInPerson />
            ) : trainingMode?.toLowerCase() === "on the job" ? (
              <TrainOnJob />
            ) : null}
          </Box>
          <Typography
            variant="body2"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "29px", sm: "29px" }}
            width={{ xs: "full", sm: "264px", md: "264px" }}
            textAlign={{ xs: "center", sm: "center" }}
            color="primary"
            className=" absolute inset-x-2 top-5"
            gutterBottom
            ml={{ xs: 1, sm: 0 }}
          >
            {trainingMode?.toLowerCase() === "online"
              ? "Online Training"
              : trainingMode?.toLowerCase() === "in-person"
              ? "In-Person Training"
              : trainingMode?.toLowerCase() === "on the job"
              ? "On-the-Job Training"
              : ""}
          </Typography>
        </Box>
        <Box
          className=" trainingBody relative"
          bgcolor={""}
          height={"273px"}
          width={{ xs: "full", sm: "294px" }}
          pt={5}
        >
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "29px", sm: "29px" }}
            // width={{ xs: "300px", sm: "264px", md: "264px" }}
            textAlign={{ xs: "start", sm: "start" }}
            ml={{ xs: 5, sm: 5 }}
            height="50px"
            color="primary"
            mb={3}
            className=" line-clamp-2"
            gutterBottom
          >
            {title}
          </Typography>
          {objects &&
            objects.map((text: string, index: any) => (
              <Typography
                variant="body2"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={{ xs: "29px", sm: "29px" }}
                width={{ xs: "300px", sm: "264px", md: "264px" }}
                textAlign={{ xs: "start", sm: "start" }}
                color="primary"
                className=" capitalize"
                ml={{ xs: 5, sm: 5 }}
                gutterBottom
                key={index}
              >
                • {" " + text}
              </Typography>
            ))}
          <Divider className=" mt-5" />
          <Box className=" flex space-x-3 " ml={{ xs: 5, sm: 5 }} mt={1}>
            <CalendarIcon />
            <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              width={{ xs: "300px", sm: "264px", md: "264px" }}
              //   textAlign={{ xs: "start", sm: "start" }}
              color="#A9A9A9"
              className="capitalize"
              gutterBottom
            >
              {convertUTCToWordDate(createdDate)} | {startDate} - {endDate}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            variant="body1"
            fontSize={{ xs: "8px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "22px", sm: "22px" }}
            width={{ xs: "90%", sm: "264px", md: "264px" }}
            textAlign={{ xs: "end", sm: "end" }}
            color="primary"
            className="capitalize "
            gutterBottom
          >
            20% discount for Caring Blocks Subscribers
          </Typography>
        </Box>
        <Box
          className=" trainingBottom flex justify-between items-center rounded-b-md"
          bgcolor={"#ffff"}
          height={"70px"}
          width={{ xs: "full", sm: "294px" }}
        >
          <Box
            px={2}
            className=" flex  justify-between items-center "
            width={{ xs: "100%", sm: "100%", md: "100%" }}
          >
            <Typography
              variant="h1"
              fontSize={{ xs: "16px", sm: "19px", md: "19px" }}
              lineHeight={{ xs: "120.5%", sm: "120.5%" }}
              //   textAlign={{ xs: "start", sm: "start" }}
              color="primary"
              className="flex align-middle"
              gutterBottom
            >
              {price === 0 ? (
                ""
              ) : (
                <NairaIcon className=" self-center h-4 w-4 mt-[2px]" />
              )}
              {price === 0 ? "FREE" : "" + price}
            </Typography>
            <MuiLink
              // component={Link}
              underline="always"
              // to=""
              onClick={handleOpenDialog}
              // display="block"
              // align="left"
              className="text-blue-100 removeUnderline cursor-pointer"
            >
              Reserve Your Seat
            </MuiLink>
          </Box>
        </Box>
      </Box>
      <InstituteDialog
        open={openDialog}
        onClose={handleCloseDialog}
        price={price}
        reference={reference}
        createdDate={createdDate}
        startDate={startDate}
        endDate={endDate}
      />
    </React.Fragment>
  );
};
export default InstituteCard;
