import React, { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import MHDialog from "../../../components/Common/Dialog/MHDialog";
import MHFormControl from "../../../components/Common/Form/MHFormControl";
import StyledActionButton from "../../../components/Common/Button/StyledActionButton";
import useInputArray from "../../../hooks/use-input-array";

import * as validators from "../../../utils/validators";
import MHButton from "../../../components/Common/Button/MHButton";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";
import useInput from "../../../hooks/use-input";
import { Grid } from "@mui/material";
import { MHSelect } from "../../../components/Common/Form/MHSelect";
import { resolveErrorMessage } from "../../../utils/utils";
import DashboardContext from "../../../store/context/dashboard.context";
import { useOnboardForm } from "../../../store/context/onboard-context";
import { useHistory } from "react-router-dom";

const TemplateDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  // const { inputFields, onChange, addField, removeField } = useInputArray([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  // React.useEffect(() => {
  //   addField();
  // }, []);

  const { loading, error, sendHttpRequest: signIn } = useHttp();
  let history = useHistory();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { addToContract, contractForm } = useOnboardForm();

  const {
    value: enteredTemplate,
    valid: enteredTemplateIsValid,
    error: templateInputHasError,
    onChange: templateInputChangeHandler,
    onBlur: templateInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const formIsValid = enteredTemplateIsValid;

  const handleForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!formIsValid) {
      // console.log("Complete Form");
      toast({
        message: "Please input template name",
        variant: "warning",
      });
      return;
    }

    addToContract({
      documentName: enteredTemplate,
      contractInvite: "process",
    });
    toast({
      message: "Contract Template Saved",
      variant: "success",
    });
    onClose();
    templateInputChangeHandler("");
    history.push("/organization/contract");
  };

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  return (
    <MHDialog
      title="Save Contract"
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      scroll="paper"
      actions={
        <MHButton
          type="submit"
          form="add-employee-form"
          loading={loading}
          fullWidth
        >
          Save Changes
        </MHButton>
      }
      fullWidth
    >
      <Box
        component="form"
        id="add-employee-form"
        onSubmit={handleForm}
        autoComplete="off"
        noValidate
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MHFormControl
                type="text"
                id={"template"}
                label="Template Name"
                placeholder="Enter Template Name"
                value={enteredTemplate}
                onChange={templateInputChangeHandler}
                onBlur={templateInputBlurHandler}
                autoComplete="off"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <MHFormControl
                type="text"
                id={"employeePhone"}
                label="Employee Phone"
                placeholder="Enter Employee Phone"
                value={enteredEmployeePhone}
                onChange={employeePhoneInputChangeHandler}
                onBlur={employeePhoneInputBlurHandler}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <MHFormControl
                type="text"
                id={"employeeAddress"}
                label="Employee Address"
                placeholder="Enter Employee Address"
                value={enteredEmployeeAddress}
                onChange={employeeAddressInputChangeHandler}
                onBlur={employeeAddressInputBlurHandler}
                autoComplete="off"
              />
            </Grid> */}
          </Grid>
        </Stack>
      </Box>
    </MHDialog>
  );
};

export default TemplateDialog;
