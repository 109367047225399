import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";
import MHFormControl from "../../components/Common/Form/MHFormControl";

import * as validators from "../../utils/validators";
import useInput from "../../hooks/use-input";
import OldMHLogo from "../../theme/icons/OldMHLogo";
import OldMHWhiteLogo from "../../theme/icons/OldMHWhiteLogo";
import useHttp from "../../hooks/use-http";
import SnackbarContext from "../../store/context/snackbar.context";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";

type Props = {};

const LandingFooter = (props: Props) => {
  const {
    value: enteredfirstName,
    valid: enteredfirstNameIsValid,
    onChange: firstnameInputChangeHandler,
    onBlur: firstnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredlastName,
    valid: enteredlastNameIsValid,
    onChange: lastnameInputChangeHandler,
    onBlur: lastnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPhone,
    valid: enteredPhoneIsValid,
    onChange: phoneInputChangeHandler,
    onBlur: phoneInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const formIsValid =
    enteredfirstNameIsValid &&
    enteredlastNameIsValid &&
    enteredEmailIsValid &&
    enteredPhoneIsValid;

  const resetForm = () => {
    firstnameInputChangeHandler("");
    lastnameInputChangeHandler("");
    phoneInputChangeHandler("");
    emailInputChangeHandler("");
  };

  const { loading } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const flodeskSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables

    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "Caring Blocks (www.caringblocks.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: enteredfirstName,
          last_name: enteredlastName,
          email: enteredEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
          custom_fields: {
            phone: enteredPhone,
          },
        }),
      });

      flodeskSegmentSubmit();

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["65cf57d9cec97379c8979ca4"]; // Replace with actual segment IDs
    // Segment: caring-blocks-footer-request
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "Caring Blocks (www.caringblocks.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
        resetForm();
        window.location.reload();

        toast({
          message:
            "Thank you for your request. We will get back to you shortly!",
          variant: "success",
        });
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    // emailInputRef.current!.focus();
    preventDefault(event);
    if (!formIsValid) {
      console.log("Complete Form");
      toast({
        message: "Kindly input your request",
        variant: "warning",
      });
      return;
    } else {
      await addDoc(collection(db, "caring-blocks-footer-requests"), {
        firstName: enteredfirstName,
        lastName: enteredlastName,
        email: enteredEmail,
        phone: enteredPhone,
        createdAt: serverTimestamp(),
      });

      flodeskSubmit();

      // toast({
      //   message: "Thank you for your request. We will get back to you shortly!",
      //   variant: "success",
      // });
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        sx={{
          paddingTop: "40px",
          justifyContent: "center",
          alignItems: "center",
          // paddingTop: "80px",
          // paddingBottom: "100px",
          height: { xs: "fit", sm: "650px", md: "720px" },
          // backgroundColor: "#ffffff",
          overflow: "hidden",
          // overscrollBehaviorY: "hidden",
        }}
        className="footerBg"
      >
        <Grid item xs={10.5} sm={5} md={4.8}>
          <Typography
            variant="subtitle1"
            color="primary"
            fontSize={{ xs: "28px", sm: "28px", md: "29px" }}
            lineHeight={{ xs: "160.5%", sm: "160.5%" }}
            letterSpacing={"-4%"}
            className=" capitalize text-center md:text-start mx-auto"
            width={{ xs: "90%", sm: "100%" }}
          >
            Request a Call
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
            fontSize={{ xs: "19px", sm: "19px", md: "21px" }}
            lineHeight={{ xs: "160.5%", sm: "160.5%" }}
            letterSpacing={"-4%"}
            className=" capitalize text-center md:text-start mx-auto"
            width={{ xs: "90%", sm: "100%" }}
          >
            Fill Out The Form And We Will Contact You!
          </Typography>

          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="pt-3 pb-5 text-justify md:text-start mx-auto md:mx-0 w-[95%] md:w-[90%]"
            width={{ xs: "95%", sm: "100%" }}
          >
            Thank you for your interest in Caring Blocks. We are eager to learn
            more about your specific needs for your family. Our consultation is
            limited but free and designed to help you find the perfect solution
            for managing your household contractors and operations. We look
            forward to connecting.
          </Typography>

          {/* <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "12px", md: "14px" }}
            lineHeight={{ xs: "200%", sm: "200%" }}
            letterSpacing={"-4%"}
            className="pb-8  w-[90%]"
          >
            Our consultation is free and designed to help you find the perfect
            solution for managing your household employees.
          </Typography> */}
        </Grid>

        <Grid
          item
          xs={0}
          sm={0.5}
          md={0.5}
          sx={{
            alignItems: "center",
            display: { xs: "none", sm: "grid" },
          }}
        >
          <div
            style={{ borderLeft: "1px solid #A9A9A9", height: "278px" }}
          ></div>
        </Grid>
        <Grid
          item
          xs={10.5}
          sm={5}
          md={3.1}
          sx={{ alignItems: "center", marginTop: "0px" }}
        >
          <Box component={"form"} onSubmit={handleSubmit}>
            <MHFormControl
              id="fname"
              type="text"
              label="First Name"
              placeholder="First Name"
              value={enteredfirstName}
              onChange={firstnameInputChangeHandler}
              onBlur={firstnameInputBlurHandler}
              required
              // autoFocus
            />

            <MHFormControl
              id="lname"
              type="text"
              label="Last Name"
              placeholder="Last Name"
              value={enteredlastName}
              onChange={lastnameInputChangeHandler}
              onBlur={lastnameInputBlurHandler}
              required
              // autoFocus
            />

            <MHFormControl
              id="email"
              type="email"
              label="Email"
              placeholder="Email"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              required
              // autoFocus
            />
            <MHFormControl
              id="phone"
              type="tel"
              label="Phone"
              placeholder="Phone"
              value={enteredPhone}
              onChange={phoneInputChangeHandler}
              onBlur={phoneInputBlurHandler}
              required
            />

            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              loading={loading}
              className="pt-2  capitalize w-full h-[44px]"
              // startIcon={<PlusIcon />}
              // onClick={handleSubmit}
              // onClick={() =>
              //   history.push("/organization/employee-onboarding")
              // }
            >
              Send
            </MHButton>
          </Box>
        </Grid>

        {/* Footer Care */}
        <Grid
          item
          xs={12}
          // sx={{ paddingTop: "60px" }}
          pt={{ xs: "0px", sm: "60px" }}
          mt={{ xs: "60px", sm: "0px" }}
          bgcolor={{ xs: "#194049", sm: "#ffffff" }}
        >
          {/* Divider */}
          <div className="!bg-[#A9A9A9] w-[90%] md:w-[98%] h-[1px] mx-auto" />

          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "center",
              paddingTop: { xs: "20px", sm: "50px" },
              paddingBottom: { xs: "20px", sm: "30px" },
              textAlign: { xs: "center", sm: "inherit" },
            }}
          >
            <Grid item xs={10} sm={3.5} md={3}>
              <Typography
                variant="body1"
                color={"primary"}
                fontSize={{ xs: "22px", sm: "18px", md: "25px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" capitalize hidden md:block"
              >
                <strong className=" font-areaBlack">Care,</strong> Manage,
                Succeed.
              </Typography>
              <Typography
                variant="body1"
                color={"#fff"}
                fontSize={{ xs: "22px", sm: "18px", md: "25px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" capitalize md:hidden"
              >
                <strong className=" font-areaBlack">Care,</strong> Manage,
                Succeed.
              </Typography>
            </Grid>

            {/* Divider */}
            <div className="bg-[#A9A9A9] w-[90%] md:w-[98%] my-3 h-[1px] mx-auto md:hidden" />
            <Grid item xs={9} sm={2} md={2}>
              <Typography
                variant="body1"
                color={"primary"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" hidden md:block"
              >
                <strong className=" font-areaBlack">
                  Email Us: <br />
                </strong>
                hello@caringblocks.com
              </Typography>
              <Typography
                variant="body1"
                color={"#fff"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" text-start md:hidden my-3"
              >
                <strong className=" font-areaBlack">
                  Email Us: <br />
                </strong>
                hello@caringblocks.com
              </Typography>
            </Grid>
            <Grid item xs={9} sm={1.5} md={2}>
              <Typography
                variant="body1"
                color={"primary"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className="hidden md:block "
              >
                <strong className=" font-areaBlack">
                  Call Us: <br />
                </strong>
                +234-704-459-5899
              </Typography>
              <Typography
                variant="body1"
                color={"#fff"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" md:hidden my-3 text-start"
              >
                <strong className=" font-areaBlack">
                  Call Us: <br />
                </strong>
                +234-704-459-5899
              </Typography>
            </Grid>
            <Grid item xs={9} sm={3.5} md={2.5}>
              <Typography
                variant="body1"
                color={"primary"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" hidden md:block"
              >
                <strong className=" font-areaBlack">
                  Visit Us: <br />
                </strong>
                VI, Lagos, Nigeria
              </Typography>
              <Typography
                variant="body1"
                color={"#fff"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" text-start  md:hidden my-3"
              >
                <strong className=" font-areaBlack">
                  Visit Us: <br />
                </strong>
                VI, Lagos, Nigeria
              </Typography>
            </Grid>
          </Grid>

          {/* Divider */}
          <div className="bg-[#A9A9A9] w-[90%] md:w-[98%] h-[1px] mx-auto" />
        </Grid>

        <Grid
          item
          xs={12}
          sm={10.5}
          md={9.5}
          bgcolor={{ xs: "#194049", sm: "#ffffff" }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: { sm: "space-between" },
              textAlign: { xs: "center", sm: "inherit" },
            }}
            py={{ xs: 2, sm: 0 }}
          >
            <Grid item xs={12} sm={5} md={4}>
              <Typography
                variant="body1"
                color={"primary"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" hidden md:block capitalize"
              >
                2024 Caring Blocks, All Rights Reserved | Privacy Policy
              </Typography>
              <Typography
                variant="body1"
                color={"#fff"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className="  md:hidden capitalize"
              >
                2024 Caring Blocks, All Rights Reserved | Privacy Policy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={3} sx={{ textAlign: { sm: "end" } }}>
              <Typography
                variant="body1"
                color={"primary"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className="hidden md:block capitalize"
              >
                Powered by &nbsp;
                <OldMHLogo
                  style={{
                    width: "4rem",
                    display: "inline",
                    verticalAlign: "middle",
                  }}
                />
              </Typography>
              <Typography
                variant="body1"
                color={"#fff"}
                fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                letterSpacing={"-4%"}
                className=" md:hidden capitalize"
              >
                Powered by &nbsp;
                <OldMHWhiteLogo
                  style={{
                    width: "4rem",
                    display: "inline",
                    verticalAlign: "middle",
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LandingFooter;
